<script lang="ts" setup>
	import { defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';
	import LayoutSolotechCareer from '@/custom/solotech-career/vue/components/layout/LayoutSolotechCareer.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import CardPage from '@/custom/solotech-career/vue/components/common/CardPage.vue';

	defineComponent({
		name: ComponentsName.JobOpenings
	});

	const { t } = useI18n();
</script>

<template>
	<layout-solotech-career>
		<card-page :title="t('jobOpenings.title')">
			<iframe
				class="w-100 h-100"
				src="https://usen-careers-solotech.icims.com/jobs/search?ss=1&searchRelation=keyword_all&searchLocation=12781--Las+Vegas&searchLocation=12781-12789-Reedley&searchLocation=12781-12789-Sun+Valley&searchLocation=12781-12789-Westminster&searchLocation=12781-12793-Miami&searchLocation=12781-12793-Orlando&searchLocation=12781-12793-Pensacola&searchLocation=12781-12826-Antioch&searchLocation=12781-12827-"
			/>
		</card-page>
	</layout-solotech-career>
</template>

<i18n>
{
	"en": {
		"jobOpenings": {
			"title": "Job opportunities - Now hiring!"
		}
	},
	"fr": {
		"jobOpenings": {
			"title": "Offres d'emploi - Nous embauchons maintenant!"
		}
	}
}
</i18n>
