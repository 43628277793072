<script lang="ts" setup>
	import type { Ref } from 'vue';
	import { onBeforeMount, ref, useAttrs } from 'vue';
	import type { VForm } from 'vuetify/components/VForm';
	import { useI18n } from 'vue-i18n';

	withDefaults(defineProps<{ loading?: boolean; classSubmit?: string }>(), {
		classSubmit: ''
	});

	const attrs = useAttrs();
	const { t, locale } = useI18n();
	const form: Ref<VForm | null> = ref(null);
	const valid: Ref<boolean> = ref(true);

	onBeforeMount(() => {
		if (form.value) {
			form.value?.validate();
		}
	});
</script>

<template>
	<v-form ref="form" v-model="valid" class="d-flex flex-column gap">
		<slot />
		<slot :disabled="!valid || loading" :loading="loading" name="form-submit">
			<v-btn
				:disabled="!valid || loading"
				:loading="loading"
				block
				class="access bg-primary"
				:class="classSubmit"
				type="submit"
			>
				{{ t('form.submit') }}
			</v-btn>
		</slot>

		<slot name="form-footer" />
	</v-form>
</template>

<style scoped>
	.gap {
		gap: 0.5rem;
	}
</style>
