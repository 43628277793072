<template>
	<v-alert v-if="errorGlobal" class="mb-4" :value="true" type="error">
		{{ errorGlobal }}
	</v-alert>
	<v-row>
		<v-col v-for="(users, i) in usersImported" :key="`imported-users-${i}`" cols="12" :md="colSize">
			<card-alert-message :value="users" />
		</v-col>
	</v-row>
</template>

<script setup lang="ts">
	import CardAlertMessage from './CardAlertMessage.vue';
	import { computed } from 'vue';

	import type { ImportUsersDataBase, SuccessAndError } from './types';

	const props = defineProps<{
		errorGlobal?: string[] | null;
		errorsUsersImported: ImportUsersDataBase[];
		successUsersImported: ImportUsersDataBase[];
	}>();

	const colSize = computed(() => (props.errorsUsersImported.length && props.successUsersImported.length ? '6' : '12'));

	const usersImported = computed<SuccessAndError[]>(() => [
		{
			type: 'error',
			value: props.errorsUsersImported
		},
		{
			type: 'success',
			value: props.successUsersImported
		}
	]);
</script>
