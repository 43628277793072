import { getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { ParticipantRoleChangedEvent } from 'jitsi-meet';

import { Table } from '@/graphql/types';
import { AWSGraphqlMutation } from '@/graphql';
import { createUserTable } from '@/graphql/mutations';
import { toggleMuteUser } from '@/features/Table/UserTable';
import useCurrentUser from '@/features/CurrentUser/store';
import { useTableStore } from '@/features/Table/store';
import { JitsiTableRole } from '@/plugins/jitsi';
import useConfig from '@/features/Config';
import usePlugins from '@/composables/usePlugins';
import { currentEnv } from '@/composables/useApp';
import useCurrentEvent from '@/features/Events/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';

export const useTable = () => {
	const app = getCurrentInstance();
	const env = currentEnv().currentEnv;
	const { Jitsi } = usePlugins(app);

	const tableStore = useTableStore();
	const { setCurrentTableLock, setCurrentTable, setRole, setJitsiMeet, setJitsiConfig, leaveTable } = tableStore;
	const { jitsiMeet, meetContainer } = storeToRefs(tableStore);
	const { get, getAuthorizationToken } = storeToRefs(useCurrentUser());

	const {
		store: { currentConfig }
	} = useConfig();
	const { getCurrentEventNameFromURL: getCurrentEventName } = storeToRefs(useCurrentEvent());

	async function initJitsiMeet(table: Table) {
		setCurrentTable(table);

		const jitsiOption = currentConfig.value?.modules?.tables?.options || {};
		const jitsiConfigOverwrite = table?.config_overwrite ? JSON.parse(table.config_overwrite) : {};

		const currentJitsiOption = {
			...jitsiOption,
			userInfo: {
				email: get.value?.profile?.user_email,
				displayName: get.value?.profile?.user_name
			},
			interfaceConfigOverwrite: {
				...jitsiConfigOverwrite,
				VERTICAL_FILMSTRIP: false
			},
			roomName: `${table.name}-${getCurrentEventName.value}-${env}`,
			parentNode: meetContainer.value,
			jwt: getAuthorizationToken.value
		};

		setJitsiMeet(new Jitsi(currentConfig.value.modules.tables.source, currentJitsiOption));
		setJitsiConfig(currentJitsiOption);

		jitsiMeet?.value?.addListener('passwordRequired', () => {
			setCurrentTableLock(true);
			leaveTable();
		});

		jitsiMeet?.value?.addListener('participantRoleChanged', (event: ParticipantRoleChangedEvent) => {
			if (event.role === JitsiTableRole.moderator) {
				setRole(JitsiTableRole.moderator);
			}
		});

		const {
			data: { createUserTable: user_table }
		} = await AWSGraphqlMutation(createUserTable, {
			tableUsersId: table.id,
			userTableUserId: localStorage.getItem('session_id'),
			is_mute: false,
			is_force_mute: false
		});

		const { setTableToCurrentActiveUser } = useActiveUsers();
		setTableToCurrentActiveUser(user_table.userTableUserId, user_table.id);

		meetContainer.value?.scrollIntoView({ behavior: 'smooth' });

		jitsiMeet?.value?.addListener('audioMuteStatusChanged', () => toggleMuteUser(user_table.userTableUserId));
	}

	return {
		initJitsiMeet
	};
};
