<script lang="ts" setup>
	import { computed, ComputedRef, ref, Ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';

	import { ComponentsName, EmitsName } from '@/plugins/lib/constants';
	import { rulePassword, rulePasswordMatch } from 'lib/validations';
	import { RuleFunctions, UpdatePasswordField } from 'lib/types';
	import useToast from '@/features/Toast';
	import { useRoute, useRouter } from 'vue-router';
	import { Language } from '@/gql/types';

	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import AuthenticationForm from '@/custom/default/vue/components/auth/AuthenticationForm.vue';
	import { useUpdatePasswordMutation } from '@/gql/operations';
	import { MessageColor } from '@/features/Toast/types';
	import useCurrentEvent from '@/features/Events/store';

	const emits = defineEmits([EmitsName.AuthRedirect]);

	const { t, locale } = useI18n();
	const route = useRoute();
	const router = useRouter();
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const passwordValidations: ComputedRef<RuleFunctions> = computed(() => rulePassword(locale.value as Language));
	const passwordConfirmValidations: ComputedRef<RuleFunctions> = computed(() =>
		rulePasswordMatch(locale.value as Language, credentials.value.password)
	);

	const credentials: Ref<UpdatePasswordField> = ref({
		password: '',
		password_confirm: ''
	});

	const isPending: Ref<boolean> = ref(false);

	const onError = () => {
		isPending.value = false;
		useToast().triggerToast(t('signin_reset_password_error'), { color: MessageColor.error });

		router.push({
			name: ComponentsName.SignInView,
			params: { eventName: route.params.eventName }
		});
	};

	const updatePasswordMutation = useUpdatePasswordMutation();
	const onResetPassword = async () => {
		try {
			isPending.value = true;
			const { reset_token, user_email } = route.query as {
				reset_token: string;
				user_email: string;
			};
			if (!reset_token || !user_email) {
				return onError();
			}
			const result = await updatePasswordMutation.executeMutation({
				updatePasswordInput: {
					user_email,
					reset_token,
					event_id: getCurrentEventId.value,
					...credentials.value
				}
			});
			if (result.error) {
				return onError();
			}
			if (result.data?.updatePassword.success) {
				useToast().triggerToast(t('signin_reset_password_success'), { color: MessageColor.success });
				router.push({
					name: ComponentsName.SignInView,
					params: { eventName: route.params.eventName }
				});
			}
		} catch (e) {
			isPending.value = false;
			onError();
		}
	};
</script>

<template>
	<AuthenticationForm
		:loading="isPending"
		data-cy="form-reset-password"
		lazy-validation
		@submit.prevent="onResetPassword"
	>
		<input-field
			v-model:value="credentials.password"
			:label="t('signin_reset_password_password')"
			:rules="passwordValidations"
			:type="'password'"
		/>
		<input-field
			v-model:value="credentials.password_confirm"
			:label="t('signin_reset_password_password_validation')"
			:rules="passwordConfirmValidations"
			:type="'password'"
		/>
		<template #form-submit="{ disabled, loading }">
			<section class="d-flex flex-row justify-end">
				<v-btn
					:to="{ name: ComponentsName.SignInView, params: { eventName: route.params.eventName } }"
					class="me-2"
					color="primary"
				>
					{{ t('signin_reset_password_cancel') }}
				</v-btn>
				<v-btn
					:content="t('signin_reset_password_button')"
					color="primary"
					:disabled="disabled"
					:loading="loading"
					type="submit"
					>{{ t('signin_reset_password_button') }}
				</v-btn>
			</section>
		</template>
	</AuthenticationForm>
</template>

<i18n>
{
	"en": {
		"signin_reset_password_title": "Update your password",
		"signin_reset_password_password": "New password",
		"signin_reset_password_password_validation": "Type again the new password",
		"signin_reset_password_cancel": "Cancel",
		"signin_reset_password_button": "Update",
		"signin_reset_password_success": "Your password has been updated",
		"signin_reset_password_error": "Your updated token has expired, reset your password again"
	},
	"fr": {
		"signin_reset_password_title": "Changer votre mot de passe ",
		"signin_reset_password_password": "Nouveau mot de passe",
		"signin_reset_password_password_validation": "Saisir à nouveau le mot de passe",
		"signin_reset_password_cancel": "Annuler",
		"signin_reset_password_button": "Changer",
		"signin_reset_password_success": "Votre mot de passe est mis à jour",
		"signin_reset_password_error": "Votre token de mise à jour a expiré, réinitialisez votre mot de passe de nouveau"
	}
}
</i18n>
