/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUpdateProfilesEventId = /* GraphQL */ `
  subscription OnCreateUpdateProfilesEventId($event_id: ID!) {
    onCreateUpdateProfilesEventId(event_id: $event_id) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUpdateActiveUsersEventId = /* GraphQL */ `
  subscription OnCreateUpdateActiveUsersEventId($event_id: ID!) {
    onCreateUpdateActiveUsersEventId(event_id: $event_id) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const onCreateUpdateCommandEventId = /* GraphQL */ `
  subscription OnCreateUpdateCommandEventId(
    $event_id: ID!
    $profile_id_to: ID!
  ) {
    onCreateUpdateCommandEventId(
      event_id: $event_id
      profile_id_to: $profile_id_to
    ) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const onUpdatePollsEventId = /* GraphQL */ `
  subscription OnUpdatePollsEventId($event_id: ID!) {
    onUpdatePollsEventId(event_id: $event_id) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUpdatePollAnswersEventId = /* GraphQL */ `
  subscription OnCreateUpdatePollAnswersEventId($event_id: ID!) {
    onCreateUpdatePollAnswersEventId(event_id: $event_id) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEventId = /* GraphQL */ `
  subscription OnUpdateEventId($id: ID!) {
    onUpdateEventId(id: $id) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateFileStorageEventId = /* GraphQL */ `
  subscription OnCreateFileStorageEventId($event_id: ID!) {
    onCreateFileStorageEventId(event_id: $event_id) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateFileStorageEventId = /* GraphQL */ `
  subscription OnUpdateFileStorageEventId($id: ID!) {
    onUpdateFileStorageEventId(id: $id) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteFileStorageEventId = /* GraphQL */ `
  subscription OnDeleteFileStorageEventId($event_id: ID!) {
    onDeleteFileStorageEventId(event_id: $event_id) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onCreateEvents = /* GraphQL */ `
  subscription OnCreateEvents($filter: ModelSubscriptionEventsFilterInput) {
    onCreateEvents(filter: $filter) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdateEvents = /* GraphQL */ `
  subscription OnUpdateEvents($filter: ModelSubscriptionEventsFilterInput) {
    onUpdateEvents(filter: $filter) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeleteEvents = /* GraphQL */ `
  subscription OnDeleteEvents($filter: ModelSubscriptionEventsFilterInput) {
    onDeleteEvents(filter: $filter) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateEventHistory = /* GraphQL */ `
  subscription OnCreateEventHistory(
    $filter: ModelSubscriptionEventHistoryFilterInput
  ) {
    onCreateEventHistory(filter: $filter) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEventHistory = /* GraphQL */ `
  subscription OnUpdateEventHistory(
    $filter: ModelSubscriptionEventHistoryFilterInput
  ) {
    onUpdateEventHistory(filter: $filter) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEventHistory = /* GraphQL */ `
  subscription OnDeleteEventHistory(
    $filter: ModelSubscriptionEventHistoryFilterInput
  ) {
    onDeleteEventHistory(filter: $filter) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const onCreateProfiles = /* GraphQL */ `
  subscription OnCreateProfiles($filter: ModelSubscriptionProfilesFilterInput) {
    onCreateProfiles(filter: $filter) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProfiles = /* GraphQL */ `
  subscription OnUpdateProfiles($filter: ModelSubscriptionProfilesFilterInput) {
    onUpdateProfiles(filter: $filter) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProfiles = /* GraphQL */ `
  subscription OnDeleteProfiles($filter: ModelSubscriptionProfilesFilterInput) {
    onDeleteProfiles(filter: $filter) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateActiveUsers = /* GraphQL */ `
  subscription OnCreateActiveUsers(
    $filter: ModelSubscriptionActiveUsersFilterInput
  ) {
    onCreateActiveUsers(filter: $filter) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const onUpdateActiveUsers = /* GraphQL */ `
  subscription OnUpdateActiveUsers(
    $filter: ModelSubscriptionActiveUsersFilterInput
  ) {
    onUpdateActiveUsers(filter: $filter) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const onDeleteActiveUsers = /* GraphQL */ `
  subscription OnDeleteActiveUsers(
    $filter: ModelSubscriptionActiveUsersFilterInput
  ) {
    onDeleteActiveUsers(filter: $filter) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const onCreatePolls = /* GraphQL */ `
  subscription OnCreatePolls($filter: ModelSubscriptionPollsFilterInput) {
    onCreatePolls(filter: $filter) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePolls = /* GraphQL */ `
  subscription OnUpdatePolls($filter: ModelSubscriptionPollsFilterInput) {
    onUpdatePolls(filter: $filter) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePolls = /* GraphQL */ `
  subscription OnDeletePolls($filter: ModelSubscriptionPollsFilterInput) {
    onDeletePolls(filter: $filter) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePollAnswers = /* GraphQL */ `
  subscription OnCreatePollAnswers(
    $filter: ModelSubscriptionPollAnswersFilterInput
  ) {
    onCreatePollAnswers(filter: $filter) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePollAnswers = /* GraphQL */ `
  subscription OnUpdatePollAnswers(
    $filter: ModelSubscriptionPollAnswersFilterInput
  ) {
    onUpdatePollAnswers(filter: $filter) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePollAnswers = /* GraphQL */ `
  subscription OnDeletePollAnswers(
    $filter: ModelSubscriptionPollAnswersFilterInput
  ) {
    onDeletePollAnswers(filter: $filter) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChats = /* GraphQL */ `
  subscription OnCreateChats($filter: ModelSubscriptionChatsFilterInput) {
    onCreateChats(filter: $filter) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const onUpdateChats = /* GraphQL */ `
  subscription OnUpdateChats($filter: ModelSubscriptionChatsFilterInput) {
    onUpdateChats(filter: $filter) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const onDeleteChats = /* GraphQL */ `
  subscription OnDeleteChats($filter: ModelSubscriptionChatsFilterInput) {
    onDeleteChats(filter: $filter) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const onCreateChatsDeleteHistory = /* GraphQL */ `
  subscription OnCreateChatsDeleteHistory(
    $filter: ModelSubscriptionChatsDeleteHistoryFilterInput
  ) {
    onCreateChatsDeleteHistory(filter: $filter) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const onUpdateChatsDeleteHistory = /* GraphQL */ `
  subscription OnUpdateChatsDeleteHistory(
    $filter: ModelSubscriptionChatsDeleteHistoryFilterInput
  ) {
    onUpdateChatsDeleteHistory(filter: $filter) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const onDeleteChatsDeleteHistory = /* GraphQL */ `
  subscription OnDeleteChatsDeleteHistory(
    $filter: ModelSubscriptionChatsDeleteHistoryFilterInput
  ) {
    onDeleteChatsDeleteHistory(filter: $filter) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const onCreateCommand = /* GraphQL */ `
  subscription OnCreateCommand($filter: ModelSubscriptionCommandFilterInput) {
    onCreateCommand(filter: $filter) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const onUpdateCommand = /* GraphQL */ `
  subscription OnUpdateCommand($filter: ModelSubscriptionCommandFilterInput) {
    onUpdateCommand(filter: $filter) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const onDeleteCommand = /* GraphQL */ `
  subscription OnDeleteCommand($filter: ModelSubscriptionCommandFilterInput) {
    onDeleteCommand(filter: $filter) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const onCreateStream = /* GraphQL */ `
  subscription OnCreateStream($filter: ModelSubscriptionStreamFilterInput) {
    onCreateStream(filter: $filter) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdateStream = /* GraphQL */ `
  subscription OnUpdateStream($filter: ModelSubscriptionStreamFilterInput) {
    onUpdateStream(filter: $filter) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeleteStream = /* GraphQL */ `
  subscription OnDeleteStream($filter: ModelSubscriptionStreamFilterInput) {
    onDeleteStream(filter: $filter) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateBlockedUsers = /* GraphQL */ `
  subscription OnCreateBlockedUsers(
    $filter: ModelSubscriptionBlockedUsersFilterInput
  ) {
    onCreateBlockedUsers(filter: $filter) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdateBlockedUsers = /* GraphQL */ `
  subscription OnUpdateBlockedUsers(
    $filter: ModelSubscriptionBlockedUsersFilterInput
  ) {
    onUpdateBlockedUsers(filter: $filter) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeleteBlockedUsers = /* GraphQL */ `
  subscription OnDeleteBlockedUsers(
    $filter: ModelSubscriptionBlockedUsersFilterInput
  ) {
    onDeleteBlockedUsers(filter: $filter) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreatePermission = /* GraphQL */ `
  subscription OnCreatePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onCreatePermission(filter: $filter) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdatePermission = /* GraphQL */ `
  subscription OnUpdatePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onUpdatePermission(filter: $filter) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeletePermission = /* GraphQL */ `
  subscription OnDeletePermission(
    $filter: ModelSubscriptionPermissionFilterInput
  ) {
    onDeletePermission(filter: $filter) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
    onCreateRole(filter: $filter) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
    onUpdateRole(filter: $filter) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
    onDeleteRole(filter: $filter) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateUserTable = /* GraphQL */ `
  subscription OnCreateUserTable(
    $filter: ModelSubscriptionUserTableFilterInput
  ) {
    onCreateUserTable(filter: $filter) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const onUpdateUserTable = /* GraphQL */ `
  subscription OnUpdateUserTable(
    $filter: ModelSubscriptionUserTableFilterInput
  ) {
    onUpdateUserTable(filter: $filter) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const onDeleteUserTable = /* GraphQL */ `
  subscription OnDeleteUserTable(
    $filter: ModelSubscriptionUserTableFilterInput
  ) {
    onDeleteUserTable(filter: $filter) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const onCreateTable = /* GraphQL */ `
  subscription OnCreateTable($filter: ModelSubscriptionTableFilterInput) {
    onCreateTable(filter: $filter) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const onUpdateTable = /* GraphQL */ `
  subscription OnUpdateTable($filter: ModelSubscriptionTableFilterInput) {
    onUpdateTable(filter: $filter) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const onDeleteTable = /* GraphQL */ `
  subscription OnDeleteTable($filter: ModelSubscriptionTableFilterInput) {
    onDeleteTable(filter: $filter) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const onCreateFileStorage = /* GraphQL */ `
  subscription OnCreateFileStorage(
    $filter: ModelSubscriptionFileStorageFilterInput
  ) {
    onCreateFileStorage(filter: $filter) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateFileStorage = /* GraphQL */ `
  subscription OnUpdateFileStorage(
    $filter: ModelSubscriptionFileStorageFilterInput
  ) {
    onUpdateFileStorage(filter: $filter) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteFileStorage = /* GraphQL */ `
  subscription OnDeleteFileStorage(
    $filter: ModelSubscriptionFileStorageFilterInput
  ) {
    onDeleteFileStorage(filter: $filter) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDisplayConfig = /* GraphQL */ `
  subscription OnCreateDisplayConfig(
    $filter: ModelSubscriptionDisplayConfigFilterInput
  ) {
    onCreateDisplayConfig(filter: $filter) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const onUpdateDisplayConfig = /* GraphQL */ `
  subscription OnUpdateDisplayConfig(
    $filter: ModelSubscriptionDisplayConfigFilterInput
  ) {
    onUpdateDisplayConfig(filter: $filter) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const onDeleteDisplayConfig = /* GraphQL */ `
  subscription OnDeleteDisplayConfig(
    $filter: ModelSubscriptionDisplayConfigFilterInput
  ) {
    onDeleteDisplayConfig(filter: $filter) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const onCreateRolePermissions = /* GraphQL */ `
  subscription OnCreateRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onCreateRolePermissions(filter: $filter) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRolePermissions = /* GraphQL */ `
  subscription OnUpdateRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onUpdateRolePermissions(filter: $filter) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRolePermissions = /* GraphQL */ `
  subscription OnDeleteRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onDeleteRolePermissions(filter: $filter) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserRoles = /* GraphQL */ `
  subscription OnCreateUserRoles(
    $filter: ModelSubscriptionUserRolesFilterInput
  ) {
    onCreateUserRoles(filter: $filter) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserRoles = /* GraphQL */ `
  subscription OnUpdateUserRoles(
    $filter: ModelSubscriptionUserRolesFilterInput
  ) {
    onUpdateUserRoles(filter: $filter) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserRoles = /* GraphQL */ `
  subscription OnDeleteUserRoles(
    $filter: ModelSubscriptionUserRolesFilterInput
  ) {
    onDeleteUserRoles(filter: $filter) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
