<script lang="ts" setup>
	import { defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';

	import { ComponentsName } from '@/plugins/lib/constants';
	import SignUp from '@/custom/default/vue/components/auth/SignUp.vue';
	import AuthenticationCard from '@/custom/default/vue/components/auth/AuthenticationCard.vue';
	import HelpButton from '@/custom/default/vue/components/common/HelpButton.vue';

	defineComponent({
		name: ComponentsName.SignUpView
	});

	const { t, locale } = useI18n();
</script>

<template>
	<AuthenticationCard>
		<template #auth-card-title>
			<h1 class="text-h5 text-uppercase font-weight-bold">{{ t('signup_title') }}</h1>
		</template>
		<template #auth-card-method>
			<SignUp />
		</template>
		<template #auth-card-content-footer>
			<HelpButton btn-class-name="w-50" class="bg-primary" class-card="bg-secondary" language-switcher />
		</template>
	</AuthenticationCard>
</template>

<i18n>
{
	"en": {
		"signup_title": "New account"
	},
	"fr": {
		"signup_title": "Nouveau compte"
	}
}
</i18n>
