import { computed, markRaw } from 'vue';
import { createPinia } from 'pinia';
import router from '@/plugins/router';
import useAPUtils from './useAPUtils';

import type { App, Plugin } from 'vue';

export const amplifyPinia = async (app: App): Promise<Plugin> => {
	const pinia = createPinia();

	pinia.use((context) => {
		const { store, pinia } = context;
		if (router) store.router = markRaw(router);
		store.models = computed(() => pinia.state.value);
	});

	const { resetStore } = useAPUtils();
	pinia.use(resetStore);

	app.use(pinia);

	return {
		install() {}
	};
};
