<script lang="ts" setup>
	import { defineComponent, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { ComponentsName } from '@/plugins/lib/constants';
	import LayoutSolotechCarriere from '@/custom/solotech-carriere-virtuelle/vue/components/layout/LayoutSolotechCarriere.vue';

	defineComponent({
		name: ComponentsName.HRInterview
	});

	const { t, locale } = useI18n();

	const urlsIframe = {
		fr: 'https://docs.google.com/forms/d/e/1FAIpQLSeV2La_wFOPQzmkz20AeEJrK-i78FxEdwgr5PK4QFGqJgwK7Q/viewform?usp=pp_url',
		en: 'https://docs.google.com/forms/d/e/1FAIpQLSeFbXxIO6sr790QeONZn9RRJIDye_4BH5SIFWV7BeTbyI9G2A/viewform?usp=pp_url'
	};

	type lang = 'fr' | 'en';

	const srcIframe = ref(getSrcIframe(locale.value as lang));

	watch(
		locale,
		(value, oldValue) => {
			if (value !== oldValue) {
				srcIframe.value = getSrcIframe(value as lang);
			}
		},
		{
			immediate: true
		}
	);

	function getSrcIframe(locale: lang) {
		return urlsIframe[locale] ?? urlsIframe['fr'];
	}
</script>

<template>
	<layout-solotech-carriere>
		<card-page title="HRInterview_title" :container-full-height="true">
			<iframe class="min-h-100" width="100%" height="100%" :src="srcIframe"></iframe>
		</card-page>
	</layout-solotech-carriere>
</template>

<i18n>
{
	"en": {
		"HRInterview_title": "Book a meeting with HR by filling out the form!"
	},
	"fr": {
		"HRInterview_title": "Réservez un rendez-vous avec les RH en remplissant le formulaire !"
	}
}
</i18n>
