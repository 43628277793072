<script setup lang="ts">
	import { ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useDisplay } from 'vuetify';
	import { useRoute } from 'vue-router';
	import { onClickOutside, useVModel } from '@vueuse/core';
	import { ComponentsName } from '@/plugins/lib/constants';
	import bars from '@/custom/solotech-carriere-virtuelle/assets/bars.png';
	const props = defineProps<{
		drawer: boolean;
	}>();
	const emits = defineEmits(['update:drawer']);
	const route = useRoute();
	const { t } = useI18n();
	const { lgAndUp, mdAndDown } = useDisplay();

	const drawerRef = ref<HTMLElement | null>(null);
	const drawerVModel = useVModel(props, 'drawer', emits);

	onClickOutside(drawerRef, (event: any) => {
		if (!event.target.classList.contains('btn-toggle-drawer') && mdAndDown.value && drawerVModel.value) {
			drawerVModel.value = false;
		}
	});

	const links = [
		{
			name: 'main_drawer_home',
			icon: 'mdi-home-outline',
			to: {
				path: '/event/solotech-carriere-virtuelle'
			}
		},
		{
			name: 'main_drawer_job_openings',
			icon: 'mdi-briefcase-outline',
			to: {
				name: ComponentsName.JobOpenings
			}
		},
		{
			name: 'main_drawer_h_r_interview',
			icon: 'mdi-calendar-account-outline',
			to: {
				name: ComponentsName.HRInterview
			}
		},
		{
			name: 'main_drawer_send_resume',
			icon: 'mdi-email-newsletter',
			to: {
				name: ComponentsName.SendResume
			}
		},
		{
			name: 'main_drawer_about_us',
			icon: 'mdi-information-outline',
			to: {
				name: ComponentsName.AboutUs
			}
		}
	];
</script>

<template>
	<v-navigation-drawer ref="drawerRef" :model-value="drawerVModel" :permanent="lgAndUp" :temporary="mdAndDown">
		<v-list nav>
			<v-list-item
				v-for="{ name, icon, to } in links"
				:key="name"
				:to="to"
				color="white"
				:prepend-icon="icon"
				active-class="active-color"
				:title="t(name)"
			/>
		</v-list>
		<template #append>
			<v-img class="d-flex ml-auto" width="82px" heigth="83px" :src="bars" alt="" />
		</template>
	</v-navigation-drawer>
</template>

<style scoped>
	.active-color {
		background-color: rgba(237, 26, 105, 0.28);
	}
</style>

<i18n>
	{
		"en": {
			"main_drawer_home": "Home",
			"main_drawer_job_openings": "Job Openings",
			"main_drawer_h_r_interview": "Book your HR interview",
			"main_drawer_event_scheduling": "Event Scheduling",
			"main_drawer_send_resume": "Send your resume",
			"main_drawer_about_us": "About Us"
		},
		"fr": {
			"main_drawer_home": "Accueil",
			"main_drawer_job_openings": "Offres d'emploi",
			"main_drawer_event_scheduling": "Planification d'événements",
			"main_drawer_h_r_interview": "Réserver une entrevue RH",
			"main_drawer_send_resume": "Envoyer un CV",
			"main_drawer_about_us": "À propos"
		}
	}
</i18n>
