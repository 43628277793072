import type { Router, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router';
import { storeToRefs } from 'pinia';
import { intersection } from 'lodash';
import i18n from '@/plugins/i18n';
import { ComponentsName } from '@/plugins/lib/constants';
import { RoleName } from '@/graphql/types';
import useApp from '@/composables/useApp';
import useAPUtils from '@/amplify-pinia/useAPUtils';
import useCurrentUser from '@/features/CurrentUser/store';
import useConfig from '@/features/Config';
import useToast from '@/features/Toast';
import { getCurrentUser } from '@/features/CurrentUser';
import ViewLoader from '@/custom/default/vue/views/ViewLoader.vue';
import EventManagement from '@/custom/default/vue/components/controlroom/EventManagement.vue';
import AdminSupport from '@/custom/default/vue/components/admin/AdminSupport.vue';
import AdminDashboard from '@/custom/default/vue/components/admin/AdminDashboard.vue';
import ChatGlobal from '@/custom/default/vue/components/chat/ChatGlobal.vue';
import UsersManagement from '@/custom/default/vue/components/admin/UsersManagement.vue';
import SignInView from '@/custom/default/vue/views/auth/SignInView.vue';
import SignUpView from '@/custom/default/vue/views/auth/SignUpView.vue';
import SignInRecoveryView from '@/custom/default/vue/views/auth/SignInRecoveryView.vue';
import ResetPasswordView from '@/custom/default/vue/views/auth/ResetPasswordView.vue';
import JobOpeningsView from '@/custom/solotech-carriere-virtuelle/vue/views/JobOpeningsView.vue';
import HRInterviewView from '@/custom/solotech-carriere-virtuelle/vue/views/HRInterviewView.vue';
import SendResumeView from '@/custom/solotech-carriere-virtuelle/vue/views/SendResumeView.vue';
import AboutUsView from '@/custom/solotech-carriere-virtuelle/vue/views/AboutUsView.vue';
import JobOpeningsViewCareer from '@/custom/solotech-career/vue/views/JobOpeningsView.vue';
import AboutUsViewCareer from '@/custom/solotech-career/vue/views/AboutUsView.vue';
import useCurrentEvent from '@/features/Events/store';
import { urqlClient } from '@/plugins/urql';
import { GetEventByName } from '@/gql/operations';

export enum ADMIN_ROUTES {
	HOME = '',
	COMMANDS = 'commands',
	DASHBOARD = 'dashboard',
	CHAT = 'chat',
	USERS = 'users'
}

const pathSolotechCarriereVirtuelle = `/event/:eventName(\\bsolotech-carriere-virtuelle\\b)/`;

const pathSolotechCareer = `/event/:eventName(\\bsolotech-career\\b)/`;

// @ts-ignore
// @ts-ignore
const routes: Array<RouteRecordRaw> = [
	{
		name: 'Main',
		path: '/',
		redirect: '/event/default/login',
		meta: {}
	},
	{
		path: '/event/:eventName?',
		name: ComponentsName.Main,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.Main },
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_event-main-page'
		}
	},
	{
		path: '/event/:eventName?',
		name: ComponentsName.Authentication,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.Authentication },
		meta: {
			i18nPageTitle: 'route_authentication'
		},
		children: [
			{
				name: ComponentsName.SignInView,
				path: 'login',
				component: SignInView,
				beforeEnter: async (to) => {
					const {
						query: { redirect }
					} = to;
					if (redirect) localStorage.setItem('redirect', redirect as string);
					return;
				}
			},
			{
				name: ComponentsName.SignUpView,
				path: 'register',
				component: SignUpView
			},
			{
				name: ComponentsName.SignInRecoveryView,
				path: 'recovery-password',
				component: SignInRecoveryView
			},
			{
				name: ComponentsName.SignInResetPasswordView,
				path: 'reset-password',
				component: ResetPasswordView
			}
		]
	},
	{
		path: '/event/:eventName?/admin',
		name: ComponentsName.Administrator,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.Administrator },
		meta: {
			requiredRoles: [RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_admin-interface'
		}
	},
	{
		path: '/event/:eventName?/help',
		name: ComponentsName.Help,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.Help },
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_help'
		}
	},
	{
		path: '/event/:eventName?/question',
		name: ComponentsName.Question,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.Question },
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_question'
		}
	},
	{
		path: '/event/:eventName?/controlroom',
		name: ComponentsName.ControlRoom,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.ControlRoom },
		children: [
			{
				path: ADMIN_ROUTES.HOME,
				name: `${ComponentsName.ControlRoom}-EventManagement`,
				component: EventManagement
			},
			{
				path: ADMIN_ROUTES.COMMANDS,
				name: `${ComponentsName.ControlRoom}-Commands`,
				component: AdminSupport
			},
			{
				path: ADMIN_ROUTES.DASHBOARD,
				name: `${ComponentsName.ControlRoom}-AdminDashboard`,
				component: AdminDashboard
			},
			{
				path: ADMIN_ROUTES.CHAT,
				name: `${ComponentsName.ControlRoom}-ChatGlobal`,
				component: ChatGlobal,
				meta: {
					isInControlRoom: true
				}
			},
			{
				path: ADMIN_ROUTES.USERS,
				name: `${ComponentsName.ControlRoom}-UsersManagement`,
				component: UsersManagement
			}
		],
		meta: {
			requiredRoles: [RoleName.ADMINISTRATOR, RoleName.MODERATOR],
			i18nPageTitle: 'event'
		}
	},
	{
		path: '/event/:eventName?/sso-callback',
		name: ComponentsName.SSOCallback,
		// @ts-ignore
		beforeEnter: async (to) => {
			const {
				params: { eventName },
				query: { access_token, session_id, error }
			} = to;
			if (typeof eventName !== 'string') throw new Error('SSOCallback: Invalid event name');
			return await useApp().ssoCallback(eventName, access_token as string, session_id as string, error as string);
		},
		meta: {
			i18nPageTitle: 'route_authentication'
		}
	},
	{
		path: `${pathSolotechCarriereVirtuelle}job-openings`,
		name: ComponentsName.JobOpenings,
		component: JobOpeningsView,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_job-openings',
			props: true
		}
	},
	{
		path: `${pathSolotechCarriereVirtuelle}send-resume`,
		name: ComponentsName.SendResume,
		component: SendResumeView,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_send-resume',
			props: true
		}
	},
	{
		path: `${pathSolotechCarriereVirtuelle}HR-interview`,
		name: ComponentsName.HRInterview,
		component: HRInterviewView,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_hr-interview',
			props: true
		}
	},
	{
		path: `${pathSolotechCarriereVirtuelle}about-us`,
		name: ComponentsName.AboutUs,
		component: AboutUsView,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_about'
		}
	},
	{
		path: `${pathSolotechCareer}job-openings`,
		name: ComponentsName.JobOpenings,
		component: JobOpeningsViewCareer,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_job-openings',
			props: true
		}
	},
	{
		path: `${pathSolotechCareer}about-us`,
		name: ComponentsName.AboutUs,
		component: AboutUsViewCareer,
		sensitive: true,
		meta: {
			requiredRoles: [RoleName.USER, RoleName.MODERATOR, RoleName.ADMINISTRATOR],
			i18nPageTitle: 'route_about'
		}
	},
	{
		path: '/notfound',
		name: ComponentsName.NotFound,
		component: ViewLoader,
		props: { displayRouterView: ComponentsName.NotFound },
		meta: {
			i18nPageTitle: 'route_not-found'
		}
	}
];

const router: Router = createRouter({
	history: createWebHashHistory(),
	routes
});
router.beforeEach(async (to, from) => {
	if (to.path === '/notfound') {
		return true;
	}
	/* Check if "to" route exists. Otherwise, redirect to 404 page */
	if (!router.resolve(to.path).matched.length) {
		return { path: '/notfound' };
	}
	const {
		store: { currentConfig }
	} = useConfig();

	if (currentConfig.value) {
		// Update document's title based on route's meta
		const { messages, locale } = currentConfig.value;
		let documentTitle = messages?.[locale]?.page_title !== undefined ? messages?.[locale]?.page_title : 'event';
		// @ts-ignore
		const pageTitles = to.matched.map(({ meta }) => meta?.i18nPageTitle ?? null).filter(Boolean);
		if (pageTitles) {
			// @ts-ignore
			documentTitle += `: ${i18n.global.t(pageTitles[pageTitles.length - 1])}`;
		}
		document.title = documentTitle;
	}

	if (to.params.eventName !== from.params.eventName) {
		const result = await urqlClient.query(GetEventByName, { event_name: to.params.eventName }).toPromise();

		if (result.error) {
			return { name: ComponentsName.NotFound, replace: true };
		}
	}

	if (to.name === ComponentsName.SignInResetPasswordView && !to.query.reset_token) {
		return { path: 'login' };
	}
	const { getEventHaveNoPassword } = storeToRefs(useCurrentEvent());
	if (
		(to.name === ComponentsName.SignUpView || to.name === ComponentsName.SignInRecoveryView) &&
		getEventHaveNoPassword.value
	) {
		return { path: 'login' };
	}

	const { get, getAuthorizationToken, error } = storeToRefs(useCurrentUser());
	const { getCurrentEventName: getEventName, getCurrentEventId } = storeToRefs(useCurrentEvent());
	const userIsPublic = getAuthorizationToken.value === 'public';
	if (!userIsPublic && !get.value) {
		await getCurrentUser();
	}
	let userRoles = get.value?.roleNames ?? [];

	// Public routes access management
	if (
		(!to.meta.requiredRoles && to.name !== ComponentsName.SignInView) ||
		(to.name === ComponentsName.SignInView && !get.value)
	) {
		// No restrictions
		return true;
	}

	// Prevent user from accessing login page when already logged in
	if (to.name === ComponentsName.SignInView && userRoles.length) {
		return { name: ComponentsName.Main, params: to.params };
	}

	if ((userIsPublic || error.value) && to.name !== ComponentsName.SignInView) {
		localStorage.removeItem('authorization_token');
		localStorage.removeItem('session_id');
		await useAPUtils().clearAPStores('CurrentUser', { force: true });
		if (to.name !== ComponentsName.SignInView) {
			if (error.value) {
				useToast().triggerToast('Your auth token is not valid, logged in again');
			}
			return { name: ComponentsName.SignInView, params: to.params };
		}
	}

	if (
		getCurrentEventId.value !== get.value?.event_id ||
		(typeof to.params.eventName === 'string' && getEventName.value && getEventName.value !== to.params.eventName)
	) {
		useToast().triggerToast('Not authorized');
		await useApp().onSignOut(to.params.eventName as string);
	}

	// Prevent user from accessing a restricted routed without required roles
	if (!intersection(userRoles, to.meta.requiredRoles as []).length) {
		useToast().triggerToast('Not authorized');
		return false;
	}

	return true;
});

export default router;
