<script lang="ts" setup>
	import { ImportUsersDataBase } from './types';
	import { useVModel } from '@vueuse/core';
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';

	const props = defineProps<{
		isVisibleOverlay: boolean;
		users: ImportUsersDataBase[];
		usersSelected: ImportUsersDataBase[];
	}>();
	const emits = defineEmits(['update:isVisibleOverlay', 'update:isLoading', 'saveSelectedUsers']);

	const { t } = useI18n();

	const isVisibleOverlayVModel = useVModel(props, 'isVisibleOverlay', emits);

	const countUserReadyToImport = computed(
		() => props.usersSelected.filter((user) => user.isValid && user.status === t('labels.to_be_imported')).length
	);
</script>

<template>
	<v-dialog
		v-model="isVisibleOverlayVModel"
		role="alertdialog"
		data-cy="datatable-dialog-confirm-import-users"
		aria-labelledby="dialog-label"
		aria-describedby="dialog-description"
		class="align-center justify-center"
	>
		<v-card color="success">
			<v-card-title class="d-flex">
				<v-btn
					icon="mdi-close"
					size="small"
					variant="text"
					class="ml-auto"
					:aria-label="t('user_deletion_close')"
					@click="isVisibleOverlayVModel = false"
				/>
			</v-card-title>
			<v-card-text class="d-flex align-baseline justify-space-between">
				<p class="mr-2">
					{{ t('export_message', { count: countUserReadyToImport }) }}
				</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn autofocus data-cy="datatable-modal-actions-cancel-import-user" @click="isVisibleOverlayVModel = false">
					{{ t('actions.cancel') }}
				</v-btn>
				<v-btn data-cy="datatable-modal-actions-confirm-import-user" color="#00000" @click="emits('saveSelectedUsers')">
					{{ t('actions.import') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
