<script lang="ts" setup>
	import { defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';

	import { ComponentsName } from '@/plugins/lib/constants';
	import AuthenticationCard from '@/custom/default/vue/components/auth/AuthenticationCard.vue';
	import ResetPassword from '@/custom/default/vue/components/auth/ResetPassword.vue';

	defineComponent({
		name: ComponentsName.SignInResetPasswordView
	});
	const { t, locale } = useI18n();
</script>

<template>
	<AuthenticationCard>
		<template #auth-card-title>
			<h1 class="text-h6 text-uppercase font-weight-bold">{{ t('signin_reset_password_title') }}</h1>
		</template>
		<template #auth-card-method>
			<ResetPassword />
		</template>
	</AuthenticationCard>
</template>

<i18n>
{
	"en": {
		"signin_reset_password_title": "Update your password"
	},
	"fr": {
		"signin_reset_password_title": "Changer votre mot de passe "
	}
}
</i18n>
