import { v4 as uuidV4 } from 'uuid';
import { createEventHistory } from '@/graphql/mutations';
import { storeToRefs } from 'pinia';
import useToast from '@/features/Toast';
import { AWSGraphqlMutation } from '@/graphql';
import useCurrentUser from './store';

import { RoleName, SignInResult } from '@/gql/types';
import { urqlClient } from '@/plugins/urql';
import { GetCurrentUser, UpdateUserSessionGeolocation } from '@/gql/operations';
import { MessageColor } from '@/features/Toast/types';
import { EventHistoriesNames } from '@/graphql/types';
import useCurrentEvent from '@/features/Events/store';
import { useRoute, useRouter } from 'vue-router';
import { ComponentsName } from '@/plugins/lib/constants';

const sessionIdLocalStorageKey = 'session_id';

function getBrowser(agentString: string): string {
	let browser = 'Unknown';
	// Opera
	if (agentString.match(/(Opera|OPR)/)) {
		browser = 'Opera';
	}
	// Legacy Edge
	else if (agentString.match(/Edge/)) {
		browser = 'Microsoft Legacy Edge';
	}
	// Edge (Chromium)
	else if (agentString.match(/Edg/)) {
		browser = 'Microsoft Edge';
	}
	// MSIE
	else if (agentString.match(/(MSIE|Trident)/)) {
		browser = 'Microsoft Internet Explorer';
	}
	// Chrome
	else if (agentString.match(/Chrome/)) {
		browser = 'Chrome';
	}
	// Safari
	else if (agentString.match(/Safari/)) {
		browser = 'Safari';
	}
	// Firefox
	else if (agentString.match(/Firefox/)) {
		browser = 'Firefox';
	}
	return browser;
}

function getOS(agentString: string): string {
	let operating_system;
	const clientStrings = [
		{ s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
		{ s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
		{ s: 'Windows Vista', r: /Windows NT 6.0/ },
		{ s: 'Windows Server 2003', r: /Windows NT 5.2/ },
		{ s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
		{ s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
		{ s: 'Windows 98', r: /(Windows 98|Win98)/ },
		{ s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
		{ s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: 'Windows CE', r: /Windows CE/ },
		{ s: 'Windows 3.11', r: /Win16/ },
		{ s: 'Android', r: /Android/ },
		{ s: 'Open BSD', r: /OpenBSD/ },
		{ s: 'Sun OS', r: /SunOS/ },
		{ s: 'Chrome OS', r: /CrOS/ },
		{ s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
		{ s: 'iOS', r: /(iPhone|iPad|iPod)/ },
		{ s: 'Mac OS X', r: /Mac OS X/ },
		{ s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: 'QNX', r: /QNX/ },
		{ s: 'UNIX', r: /UNIX/ },
		{ s: 'BeOS', r: /BeOS/ },
		{ s: 'OS/2', r: /OS\/2/ },
		{ s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
	];
	//@ts-ignore
	operating_system = clientStrings.find((e) => agentString.match(e.r)).s;
	return operating_system;
}

export const ssoSignIn: (access_token: string, session_id: string) => Promise<any> = async (
	access_token,
	session_id
) => {
	const userStore = useCurrentUser();
	try {
		userStore.setAuthorizationToken(access_token);
		const user_session = session_id;
		localStorage.setItem(sessionIdLocalStorageKey, user_session);
		getCurrentUser().then(({ user }) => {
			setUserSessionGeolocation();
		});
		return { success: true };
	} catch (e: unknown) {
		useToast().triggerToast('Connection not possible', { color: MessageColor.error });
		return { error: true };
	}
};

export const onSignInSuccess = async (signInResult: SignInResult | undefined, eventName: string) => {
	const { isCurrentEventIsLocked } = storeToRefs(useCurrentEvent());

	const userStore = useCurrentUser();

	// @ts-ignore
	userStore.setAuthorizationToken(signInResult?.access_token);
	const user_session = signInResult?.session_id;
	localStorage.setItem(sessionIdLocalStorageKey, user_session);
	const user = signInResult?.user;
	if (isCurrentEventIsLocked.value && user?.roleNames.includes(RoleName.User)) {
		userStore.setAuthorizationToken('public');
		useToast().triggerToast('Event is locked', { color: MessageColor.error });
		return { success: false };
	}

	setUserSessionGeolocation().catch((err) => {
		console.warn('Geolocation not shared', err);
	});
	const redirect = localStorage.getItem('redirect');
	if (redirect) {
		localStorage.removeItem('redirect');
		return {
			path: `/event/${eventName}/${redirect}`
		};
	}
	return {
		name: ComponentsName.Main,
		params: { eventName: eventName }
	};
};

export const getCurrentUser: () => Promise<any> = async () => {
	const userStore = useCurrentUser();
	const { getAuthorizationToken, error } = storeToRefs(useCurrentUser());
	try {
		const result = await urqlClient
			.query(GetCurrentUser, {
				access_token: getAuthorizationToken.value
			})
			.toPromise();
		const user = result.data?.getCurrentUser;
		// @ts-ignore
		userStore.setUser(user);
		return { success: true, user };
	} catch (e: unknown) {
		error.value = e;
		return { error: true };
	}
};

const setUserSessionGeolocation = async () => {
	const geolocationSupported = true;
	await navigator.permissions.query({ name: 'geolocation' });

	if (geolocationSupported) {
		await new Promise<void>((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async (geolocation) => {
					urqlClient
						.mutation(UpdateUserSessionGeolocation, {
							updateUserSessionGeolocationInput: {
								longitude: geolocation.coords.longitude,
								latitude: geolocation.coords.latitude
							}
						})
						.toPromise();
					resolve();
				},
				async (err) => {
					reject(err);
				}
			);
		});
	}
};
