import type { UnwrapRef } from 'vue';
import { computed, ref } from 'vue';
import type { PiniaPluginContext, StoreDefinition, StoreGeneric } from 'pinia';
import { defineStore, getActivePinia } from 'pinia';
import { camelCase, cloneDeep, isEqual, omit, startCase } from 'lodash';
import type {
	APApiCallMeta,
	APApiCallMetaUpdatableFields,
	APExtendedError,
	APStoredCallMeta,
	APStoredCallMetaParams,
	APStoredSubscriptions,
	APStoreMakerParams,
	ExtendedPinia,
	Id
} from '@/amplify-pinia/types';
import { APApiCallStatus, APSubscriptionName } from '@/amplify-pinia/types';

export const getRawStore = (storeId: string) =>
	(
		Array.from((getActivePinia() as ExtendedPinia)._s.values() as unknown as Iterable<StoreGeneric>) as StoreGeneric[]
	).find((store) => store.$id === storeId);

export const itemsArrayToHashTable = <T>(items: T[], primaryKey: keyof T = 'id' as keyof T) =>
	(items.length > 0
		? items.reduce((acc, item) => {
				//TODO: Type issue...
				// @ts-ignore
				const key = item[primaryKey] as string;
				return { ...acc, [key]: item };
		  }, {})
		: {}) as Record<Id, T>;

export const getEnumKeys = (target: Record<string | number, unknown>) =>
	Object.keys(target).filter((v) => isNaN(Number(v)));

export const clearAPStores = async (targets: string | string[] = [], params: { force: boolean } = { force: false }) => {
	// Store that must never be cleared when switching event
	const excludedStores = params.force ? [] : ['CurrentUser', 'CurrentEvent', 'Config', 'Chat'];
	// Stores to reset
	let storeNames = (Array.isArray(targets) ? targets : [targets]).filter((target) => !excludedStores.includes(target));
	const storeProxies = (getActivePinia() as ExtendedPinia)._s.values() as unknown as Iterable<StoreGeneric>;

	await Promise.allSettled(
		(Array.from(storeProxies) as StoreGeneric[])
			.filter((store) => (!storeNames.length && !excludedStores.includes(store.$id)) || storeNames.includes(store.$id))
			.map(async (store) => {
				if (store.deleteSubscriptions) await store.deleteSubscriptions();
				if (store.$reset) store.$reset();
			})
	);
};

export const resetStore = ({ store }: PiniaPluginContext) => {
	const initialState = cloneDeep(store.$state);
	store.$reset = () => (store.$state = initialState);
};

export const makeStore = <T>({ model, primaryKey, storePlugin }: APStoreMakerParams<T>): StoreDefinition => {
	const pk = primaryKey ?? ('id' as keyof T);
	return defineStore(model, () => {
		/* State */
		const items = ref<Record<Id, T>>({});
		const subscriptions = ref<APStoredSubscriptions<T>[]>([]);
		const calls = ref<APApiCallMeta<T>>({});
		const errors = ref<Error[]>([]);

		/* Getters */
		const data = computed<T[]>(() => Object.values(items.value));
		const keyedById = computed<Record<string, T>>(() => items.value);
		const isPending = computed<boolean>(() => pending.value);
		const hasErrors = computed<boolean>(() => !!errors.value.length);
		const hasItems = computed<boolean>(() => !!data.value.length);
		const hasSubscriptions = computed<boolean>(() => !!subscriptions.value.length);
		const pending = computed<boolean>(() =>
			Object.values(calls.value).some((call) => call.status === APApiCallStatus.pending)
		);

		/* Actions */
		const getCallMeta = (callHash: string) => calls.value[callHash];
		const pushNewErrors = (payload: Error[] | APExtendedError[]) =>
			payload.forEach((error) => {
				const err = Array.isArray(error) ? error : [error];
				errors.value = [...errors.value, ...err];
			});

		const existingSubscription = (data: Partial<APStoredSubscriptions<T>>): APStoredSubscriptions<T> => {
			const { variables, gqlOperationName, subscriptionName } = data;

			return subscriptions.value.find(
				(s) =>
					s[subscriptionName as APSubscriptionName] &&
					isEqual(s.variables, variables) &&
					gqlOperationName === s.gqlOperationName
			) as APStoredSubscriptions<T>;
		};

		const saveSubscriptions = (data: APStoredSubscriptions<T> | APStoredSubscriptions<T>[]) => {
			[data].flat().forEach((subscription) => {
				const { variables, gqlOperationName, ...subs } = subscription;

				const storedSubscriptions = {} as APStoredSubscriptions<T>;

				Object.keys(subs).forEach((subscriptionName) => {
					const alreadyExist = existingSubscription({
						subscriptionName: subscriptionName as APSubscriptionName,
						gqlOperationName,
						variables
					});
					if (alreadyExist) return;

					storedSubscriptions[subscriptionName as APSubscriptionName] = subs[subscriptionName as APSubscriptionName];
				});

				if (Object.keys(storedSubscriptions).length) {
					const newSub = { ...storedSubscriptions, variables, gqlOperationName };
					subscriptions.value = [...subscriptions.value, newSub] as UnwrapRef<APStoredSubscriptions<T>>[];
				}
			});
		};

		const deleteSubscription = async (data: APStoredSubscriptions<T>) => {
			const storedSubscription = existingSubscription(data);
			if (!storedSubscription) return;

			const { variables, ...methods } = storedSubscription;

			await Promise.all(
				Object.keys(methods).map(async (method) => {
					const subscription = methods[method as APSubscriptionName];
					if (subscription) await subscription.unsubscribe();
				})
			);

			subscriptions.value = subscriptions.value.filter(
				(subscription) => !isEqual(subscription.variables, data.variables)
			);
		};

		const deleteSubscriptions = async () => {
			await Promise.all(
				subscriptions.value.map(async (data) => await deleteSubscription(data as APStoredSubscriptions<T>))
			);
		};

		const saveCallMeta = (hash: string, data: APStoredCallMeta<T>): void => {
			calls.value[hash] = data;
		};

		const updateCallMeta = (hash: string, input: APApiCallMetaUpdatableFields): void => {
			calls.value[hash] = { ...calls.value[hash], ...input };
		};

		const deleteCallMeta = (hash: string): void => {
			delete calls.value[hash];
		};

		const create = (data: T | T[]) => {
			const dataArray = [data].flat() as T[];

			if (dataArray.some((item) => !item[pk])) {
				throw new Error('Create() & Update() require a valid primary key for each item in data');
			}
			const newItems = itemsArrayToHashTable<T>(dataArray, pk);
			items.value = { ...items.value, ...newItems };
		};

		const update = (data: T | T[]) => {
			create(data);
		};

		const remove = (data: T | T[]) => {
			const dataArray = [data].flat() as T[];
			if (dataArray.some((item) => !item[pk])) {
				throw new Error('Remove() requires a valid primary key in data');
			}
			const ids = dataArray.map((item) => item[pk]) as Array<Id>;
			items.value = omit(items.value, ids);
		};

		const baseStructure = {
			items,
			calls,
			subscriptions,
			pending,
			errors,
			data,
			keyedById,
			isPending,
			hasErrors,
			hasItems,
			hasSubscriptions,
			getCallMeta,
			saveCallMeta,
			updateCallMeta,
			deleteCallMeta,
			create,
			update,
			remove,
			pushNewErrors,
			existingSubscription,
			saveSubscriptions,
			deleteSubscription,
			deleteSubscriptions
		};

		return storePlugin ? storePlugin(baseStructure) : baseStructure;
	});
};

const apError = (
	{ model, code, message }: { model: string; code: string | number; message: string },
	opts?: { mode: 'log' | 'throw' }
) => {
	const mode = opts?.mode ?? 'log';
	const content = `AP ERROR :: (${model}/${code}) ${message}`;

	switch (mode) {
		case 'throw':
			throw new Error(content);
		default:
			console.error(content);
	}
};

const getFormattedFilterKey = <T>(subscription: Partial<APStoredCallMetaParams<T>>) => {
	if (!subscription.variables) return [];
	return Object.keys(subscription.variables).map((key) => startCase(camelCase(key)).replace(/\s/g, ''));
};

const getMissingSubscriptionErrorBody = <T>(
	subscription: APStoredSubscriptions<T>,
	crudMethod: string,
	subscriptionTrigger: string,
	subscriptionName: string,
	filterKeys: string[],
	variableKeys: string[],
	amplifyTableName: string
) => `Missing Amplify subscription:\n
		${subscriptionName}By${filterKeys.join('And')}(${variableKeys
	.map((key) => `${key}: ${startCase(typeof subscription.variables[key]).replace('Number', 'Int')}!`)
	.join(', ')}): ${amplifyTableName} @aws_subscribe(mutations: ["${subscriptionTrigger}${amplifyTableName}"])\n
		Create subscription in file @/amplify/backend/api/.../schema.graphql OR disable subscription for this request using ${crudMethod}({ ..., subscriptions: [] }).`;

export default () => ({
	makeStore,
	clearAPStores,
	itemsArrayToHashTable,
	getEnumKeys,
	resetStore,
	getRawStore,
	getFormattedFilterKey,
	getMissingSubscriptionErrorBody,
	apError
});
