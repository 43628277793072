<script lang="ts" setup>
	import { useAttrs, useSlots } from 'vue';
	import { useI18n } from 'vue-i18n';
	import MessageEventLock from '@/custom/default/vue/components/auth/MessageEventLock.vue';

	withDefaults(defineProps<{ classTitle?: string; classContainer?: string; actionsClass?: string }>(), {
		classTitle: 'bg-primary',
		classContainer: '',
		actionsClass: ''
	});
	const slots = useSlots();
	const { t } = useI18n();
	const attrs = useAttrs();
</script>

<template>
	<v-card class="h-auto">
		<v-card-title class="text-center bg-primary py-4" :class="classTitle">
			<slot name="auth-card-title">
				<h1 class="text-h4 text-uppercase font-weight-bold">{{ t('signin.title') }}</h1>
			</slot>
		</v-card-title>
		<v-card-text class="py-4" :class="classContainer">
			<slot name="auth-card-content-header" />

			<MessageEventLock />
			<slot name="auth-card-method" />
		</v-card-text>

		<v-card-actions v-if="slots['auth-card-content-footer']" class="px-4" :class="actionsClass">
			<slot name="auth-card-content-footer" />
		</v-card-actions>
	</v-card>
</template>

<i18n>
{
	"en": {
		"signin": {
			"title": "Welcome"
		}
	},
	"fr": {
		"signin": {
			"title": "Bienvenue"
		}
	}
}
</i18n>
