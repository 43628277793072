<template>
	<v-card-subtitle class="mt-6">
		{{ t('geolocation_map_subtitle') }}
	</v-card-subtitle>
	<v-container fluid>
		<v-row class="justify-center mb-5" no-gutters>
			<v-col cols="12">
				<div v-if="loading" class="mb-6 text-center">
					<v-progress-circular indeterminate :size="70" :width="7"></v-progress-circular>
				</div>
				<div id="mapContainer" class="px-auto my-2" style="height: 600px" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import L from 'leaflet';
	import { storeToRefs } from 'pinia';
	import 'primevue/resources/themes/saga-blue/theme.css';
	import 'primevue/resources/primevue.min.css';
	import 'primeicons/primeicons.css';
	import 'leaflet/dist/leaflet.css';
	import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
	import iconUrl from 'leaflet/dist/images/marker-icon.png';
	import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
	import { onMounted, watch, ref } from 'vue';
	import { urqlClient } from '@/plugins/urql';
	import { ListGeolocations } from '@/gql/operations';
	import { ListGeolocationsInput, Geolocation } from '@/gql/types';
	import useCurrentEvent from '@/features/Events/store';

	const props = defineProps({
		dateEnd: {
			type: Date,
			required: true
		},
		dateStart: {
			type: Date,
			required: true
		}
	});

	const { t } = useI18n();
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	//@ts-ignore
	delete L.Icon.Default.prototype._getIconUrl;

	L.Icon.Default.mergeOptions({
		iconRetinaUrl,
		iconUrl,
		shadowUrl
	});

	const userLocationLayer: L.FeatureGroup = L.featureGroup();
	const loading = ref(true);

	let userGeolocationMap: L.Map;
	let markers: Array<L.Marker> = [];

	const createMap = (): void => {
		userGeolocationMap = L.map('mapContainer');
		userGeolocationMap.setView([37.7749, -122.4194], 13);
		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 15,
			attribution: '© OpenStreetMap'
		}).addTo(userGeolocationMap);

		L.Control = L.Control.extend({
			onAdd: () => {
				const container = L.DomUtil.create('div', 'd-flex flex-column bg-white rounded elevation-1 ma-2 pa-2');

				return container;
			}
		});

		new L.Control({ position: 'topleft' }).addTo(userGeolocationMap);
	};

	const generateMarkers = (geolocations: Geolocation[]): void => {
		markers.forEach((marker: L.Marker): void => {
			userLocationLayer.removeLayer(marker);
		});
		markers = [];

		geolocations.forEach((geolocation: Geolocation): void => {
			const {
				position: { latitude, longitude }
			} = geolocation;

			const marker = L.marker([latitude, longitude]);
			markers.push(marker);

			userLocationLayer.addLayer(marker);
		});

		if (userLocationLayer.getLayers().length > 1) userGeolocationMap.fitBounds(userLocationLayer.getBounds());
		userLocationLayer.addTo(userGeolocationMap);
	};

	const fetchGeolocations = async (): Promise<Geolocation[]> => {
		const listGeolocationsInput: ListGeolocationsInput = {
			date_start: new Date(props.dateStart),
			date_end: new Date(props.dateEnd)
		};
		const { data } = await urqlClient
			.query(ListGeolocations, {
				listGeolocationsInput
			})
			.toPromise();

		return data.listGeolocations;
	};

	watch(
		() => [props.dateStart, props.dateEnd],
		async () => {
			try {
				const geolocations = await fetchGeolocations();
				generateMarkers(geolocations);
			} catch (e) {
				console.error('Failed to fetch event histories USER_INFO', e);
			}
		}
	);

	onMounted(async () => {
		try {
			const geolocations = await fetchGeolocations();
			loading.value = false;

			createMap();
			generateMarkers(geolocations);
		} catch (e) {
			console.error('Failed to fetch event histories USER_INFO', e);
		}
	});
</script>

<i18n>
{
	"en": {
		"geolocation_map_subtitle": "Shows the location of the active users who allowed location tracking",
		"geolocation_un_located_title": "Un located users"
	},
	"fr": {
		"geolocation_map_subtitle": "Montre la localisation des utilisateurs qui se sont connectes et ont accepte le suivi de position",
		"geolocation_un_located_title": "Utilisateurs non géolocalisé"
	}
}
</i18n>
