import { defineStore } from 'pinia';
import groupBy from 'lodash/groupBy';
import {
	Chats,
	ChatTypes,
	ModelSortDirection,
	OnCreateChatsSubscription,
	OnDeleteChatsSubscription,
	OnUpdateChatsSubscription
} from '@/graphql/types';
import { SubscriptionStatus } from '@/amplify-pinia/types';
import useCurrentUser from '@/features/CurrentUser/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';
import { AWSGraphqlMutation, AWSGraphqlQuery, AWSSubscription } from '@/graphql';
import { onCreateChats, onDeleteChats, onUpdateChats } from '@/graphql/subscriptions';
import { chatsByEvent } from '@/graphql/queries';
import { deleteChats } from '@/graphql/mutations';
import { ChatActions, ChatGetters, ChatState } from '@/features/Chat/types';

const useChat = defineStore<string, ChatState, ChatGetters, ChatActions>('Chat', {
	state: () => ({
		subscriptionStatus: SubscriptionStatus.PENDING,
		subscriptions: {
			onCreateChat: () => {},
			onUpdateChat: () => {},
			onDeleteChat: () => {}
		},
		global: [],
		personal: [],
		chatGlobalIsPending: true
	}),

	getters: {
		getPersonalMessages() {
			return this.personal;
		},

		getChatGlobal() {
			return this.global;
		},

		getPersonalMessagesSearchResults() {
			return (searchText) => {
				const { getUserProfileID } = useCurrentUser();

				const data = groupBy(
					this.getPersonalMessages.filter(
						(msg) =>
							msg.profile_id_from !== getUserProfileID &&
							(msg.user_profile?.user_name?.includes(searchText) || msg.user_profile?.user_email.includes(searchText))
					),
					'profile_id_from'
				);

				return Object.entries(data);
			};
		},

		getMostRecentPrivateMessagesByAuthorId() {
			return (authorId) => {
				const unreadMessages = this.getUnreadPersonalMessagesByAuthor(authorId);
				return unreadMessages[unreadMessages.length - 1];
			};
		},

		getUnreadPersonalMessagesCountByAuthorId() {
			return (authorId) => {
				const unreadMessages = this.getUnreadPersonalMessagesByAuthor(authorId);
				return unreadMessages.length;
			};
		},

		getUnreadPersonalMessages() {
			const { getUserProfileID } = useCurrentUser();
			return this.getPersonalMessages.filter((msg) => msg.profile_id_from !== getUserProfileID && !msg.read);
		},

		getUnreadPersonalMessagesByAuthor() {
			return (authorId: string) => {
				const unreadMessages = this.getUnreadPersonalMessages;
				return unreadMessages.filter((msg) => msg.profile_id_from === authorId && !msg.read);
			};
		},

		getPersonalMessagesByAuthorId() {
			return (authorId) =>
				this.getPersonalMessages.filter((msg) => msg.profile_id_from === authorId || msg.profile_id_to === authorId);
		},

		getGlobalChatContents() {
			return this.getChatGlobal.filter((item) => !item.deleted);
		},

		getGlobalChatContentsByDays() {
			return sortMessagesByDay(this.getGlobalChatContents);
		},

		getGlobalMessagesIds() {
			return this.getChatGlobal.map(({ id }) => id);
		},

		getPersonalChatContentsGroupedByDate() {
			return (selectedUserId) => {
				const contents = this.getPersonalMessagesByAuthorId(selectedUserId);

				const contentsFilteredByDays: { [key: string]: Array<Chats> } = {};

				contents.map((item) => {
					const day = new Date(item.sent_on).setHours(0, 0, 0, 0);
					contentsFilteredByDays[day] = contentsFilteredByDays[day] ? [...contentsFilteredByDays[day], item] : [item];
				});

				return Object.entries(contentsFilteredByDays);
			};
		},

		getNameByProfileId() {
			return (profileId) => {
				const { getActiveUserByProfileId } = useActiveUsers();
				const nameWithActiveUser = getActiveUserByProfileId(profileId)?.user_name || null;
				if (!nameWithActiveUser) {
					const contents = this.getPersonalMessagesByAuthorId(profileId);

					if (contents.length === 0) {
						return null;
					}
					const user = contents.find((item) => item.profile_id_from === profileId);
					return user?.user_profile?.user_name || null;
				}
				return nameWithActiveUser;
			};
		},

		getUnReadingMsgByUser() {
			return (selectedUserId) =>
				this.personal.filter((item) => {
					const isBlockedUser = useActiveUsers().activeUserIsBlocked(selectedUserId);
					return item.profile_id_from === selectedUserId && !isBlockedUser && !item.read;
				});
		},

		getUnReadingMsgCount() {
			const { getUserProfileID } = useCurrentUser();
			const contents = this.personal.filter((item) => {
				const isBlockedUser = useActiveUsers().activeUserIsBlocked(item.profile_id_from);
				return (
					item.profile_id_to === getUserProfileID &&
					item.profile_id_from !== getUserProfileID &&
					!isBlockedUser &&
					!item.read
				);
			});
			return contents.length;
		},

		getIsChatGlobalPending() {
			return this.chatGlobalIsPending;
		}
	},

	actions: {
		createGlobalChat(data) {
			this.global = data;
		},

		createPersonalChat(data) {
			this.personal = data;
		},

		addMessageInGlobalChat(data) {
			this.global = [...this.global, data];
		},

		addMessageInPersonalChat(data) {
			this.personal = [...this.personal, data];
		},

		async setGlobalChatByStreamId(stream_id: string) {
			const data = await fetchChat(ChatTypes.GLOBAL);
			const chatsByStream = data.filter((item: Chats) => item.stream_id === stream_id);
			this.global = chatsByStream;
		},

		updateGlobalChat(data) {
			const index = this.global.findIndex((i) => i.id === data.id);
			this.global[index] = data;
		},

		updatePersonalChat(data) {
			const index = this.personal.findIndex((i) => i.id === data.id);
			this.personal[index] = data;
		},

		deleteMessageInGlobalChat(data) {
			this.global = this.global.filter((item) => data.id !== item.id);
		},

		deleteMessageInPersonalChat(data) {
			this.personal = this.personal.filter((item) => data.id !== item.id);
		},

		deleteAllPersonalChat() {
			this.personal = [];
		},

		deleteAllGlobalChat() {
			const ids = this.getGlobalMessagesIds;
			ids.map((id) => {
				AWSGraphqlMutation(deleteChats, { id }).catch((e) => {
					console.error('error delete chat message ', e);
				});
			});
		},

		async fetchChatGlobal() {
			this.chatGlobalIsPending = true;
			const data = await fetchChat(ChatTypes.GLOBAL);
			this.chatGlobalIsPending = false;
			this.createGlobalChat(data);
		},

		async fetchChatPersonal() {
			const data = await fetchChat(ChatTypes.PERSONAL);
			this.createPersonalChat(data);
		},

		subscribeChat() {
			const { getEventID } = useCurrentUser();
			this.subscriptions.onCreateChat = AWSSubscription(
				onCreateChats,
				{ filter: { event_id: { eq: getEventID } } },
				({
					value
				}: {
					value: {
						data: OnCreateChatsSubscription;
					};
				}) => {
					const message = (value?.data?.onCreateChats || null) as Chats | null;

					if (!message) {
						console.error('subscription onCreateChats message is null');
						return;
					}
					if (!message.type) {
						console.error('subscription onCreateChats message type is null');
						return;
					}
					if (message.type === ChatTypes.GLOBAL) {
						this.addMessageInGlobalChat(message);
						return;
					}
					if (message.type === ChatTypes.PERSONAL) {
						this.addMessageInPersonalChat(message);
						return;
					}
				},
				(err: any) => console.error('errors subscribe on create chats', err)
			);
			this.subscriptions.onUpdateChat = AWSSubscription(
				onUpdateChats,
				{ filter: { event_id: { eq: getEventID } } },
				({
					value
				}: {
					value: {
						data: OnUpdateChatsSubscription;
					};
				}) => {
					const message = (value?.data?.onUpdateChats || null) as Chats | null;

					if (!message) {
						console.error('subscription onUpdateChats message is null');
						return;
					}
					if (!message.type) {
						console.error('subscription onUpdateChats message type is null');
						return;
					}
					if (message.type === ChatTypes.GLOBAL) {
						this.updateGlobalChat(message);
						return;
					}
					if (message.type === ChatTypes.PERSONAL) {
						this.updatePersonalChat(message);
						return;
					}
				},
				(err: any) => console.error('errors subscribe on update chats', err)
			);
			this.subscriptions.onDeleteChat = AWSSubscription(
				onDeleteChats,
				{ filter: { event_id: { eq: getEventID } } },
				({
					value
				}: {
					value: {
						data: { onDeleteChats: OnDeleteChatsSubscription };
					};
				}) => {
					const onDeleteChats = (value?.data?.onDeleteChats || null) as Chats | null;

					if (!onDeleteChats) {
						console.error('subscription onDeleteChats message is null');
						return;
					}
					if (!onDeleteChats.type) {
						console.error('subscription onDeleteChats message type is null');
						return;
					}
					if (onDeleteChats.type === ChatTypes.GLOBAL) {
						this.deleteMessageInGlobalChat(onDeleteChats);
						return;
					}
					if (onDeleteChats.type === ChatTypes.PERSONAL) {
						this.deleteMessageInPersonalChat(onDeleteChats);
						return;
					}
				},
				(err: any) => console.error('errors subscribe ', err)
			);
		},

		unsubscribeChat() {
			this.subscriptions.onCreateChat();
			this.subscriptions.onUpdateChat();
			this.subscriptions.onDeleteChat();
		}
	}
});

function sortMessagesByDay(messages: Array<Chats>): { [key: string]: Array<Chats> } {
	const messagesFilteredByDays: { [key: string]: Array<Chats> } = {};

	messages.map((item) => {
		const day = new Date(item.sent_on).setHours(0, 0, 0, 0);
		messagesFilteredByDays[day] = messagesFilteredByDays[day] ? [...messagesFilteredByDays[day], item] : [item];
	});

	return messagesFilteredByDays;
}

async function fetchChat(type: ChatTypes) {
	const { getEventID, getUserProfileID } = useCurrentUser();
	const optionsQuery =
		type === ChatTypes.GLOBAL
			? {
					event_id: getEventID,
					sortDirection: ModelSortDirection.DESC,
					limit: 1000,
					filter: { type: { eq: type } }
			  }
			: {
					event_id: getEventID,
					sortDirection: ModelSortDirection.DESC,
					limit: 1000,
					filter: {
						or: [{ profile_id_to: { eq: getUserProfileID } }, { profile_id_from: { eq: getUserProfileID } }]
					}
			  };
	try {
		const result = await AWSGraphqlQuery(chatsByEvent, optionsQuery);
		return (result?.data?.chatsByEvent?.items ?? []).reverse();
	} catch (e) {
		console.log(`error fetch ${type} chat`, e);
	}
}

export default useChat;
