<script lang="ts" setup>
	import { computed, onBeforeMount, Ref, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';

	import AdminSupportDatatableLayout from '@/custom/default/vue/components/admin/adminSupport/AdminSupportDatatableLayout.vue';
	import Column from 'primevue/column';

	import useCurrentUser from '@/features/CurrentUser/store';
	import { fetchCommands } from '@/features/Command';
	import { useCommand } from '@/features/Command/store';

	const emits = defineEmits(['submitCommand']);
	const { t } = useI18n();

	const loading: Ref<boolean> = ref(false);
	const commandStore = storeToRefs(useCommand());

	const { getEventID } = storeToRefs(useCurrentUser());
	const commandTo = computed(
		() => (data: any) => data.user_to === null ? 'ALL USERS / TABLE USERS' : data.user_to.user_email
	);
	onBeforeMount(async () => {
		loading.value = true;
		try {
			await fetchCommands(getEventID.value || '');
			loading.value = false;
		} catch (e) {
			loading.value = false;
			console.error('error', e);
		}
	});
</script>

<template>
	<admin-support-datatable-layout
		:global-filter-fields="['type', 'created_at']"
		:loading="loading"
		:paginator="true"
		:rows="10"
		sort-field="created_at"
		:sort-order="-1"
		:value="commandStore.getCommandsHistories.value"
	>
		<Column :header="t('table.type')" field="type" />
		<Column :header="t('table.from')" field="user_from.user_email" />
		<Column :header="t('table.to')" field="user_to.user_email">
			<template #body="{ data }">
				{{ commandTo(data) }}
			</template>
		</Column>
		<Column :header="t('table.date')" field="created_at" />
	</admin-support-datatable-layout>
</template>

<i18n>
{
	"en": {
		"table": {
			"type": "Command type",
			"from": "Sending by",
			"to": "Receipt by",
			"date": "Date sent",
			"no_command": "No commands send",
			"send_all": "All users",
			"title": "Commands history"
		}
	},
	"fr": {
		"table": {
			"type": "Type de commande",
			"from": "Envoyer par",
			"to": "Réception par",
			"date": "Date d'envoie",
			"no_command": "Aucune commande envoyée",
			"send_all": "Tous les utilisateurs",
			"title": "Historique des commandes"
		}
	}
}
</i18n>
