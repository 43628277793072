<script setup lang="ts">
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import useToast from '@/features/Toast';

	const { t } = useI18n();

	const { toastMessage, isShowing, showConfirm, deleteMessage } = useToast();

	const color = computed(() => toastMessage.value?.color ?? 'primary');
</script>

<template>
	<v-snackbar v-model="isShowing" :aria-label="toastMessage" class="message-toast pa-0" :color="color" data-cy="toast">
		<v-btn
			v-if="toastMessage && toastMessage.closable"
			class="close-btn"
			size="x-small"
			icon="mdi-close"
			variant="plain"
			:aria-label="t('snackbar_close')"
			@click="toastMessage.close"
		/>

		<v-fade-transition>
			<div v-if="toastMessage" :class="{ 'pa-2': showConfirm }">
				<p
					v-for="(line, i) in toastMessage.text.split('\r')"
					:key="`message-line-${i}`"
					class="font-weight-bold"
					v-text="line"
				/>
			</div>
		</v-fade-transition>

		<div v-if="showConfirm" class="d-flex justify-space-between mt-2">
			<v-btn
				class="px-0"
				variant="text"
				:color="`on-${color}-variant`"
				@click="toastMessage.cancel"
				v-text="toastMessage.cancelBtnText ?? t('snackbar_close')"
			/>

			<v-btn
				class="px-0"
				variant="text"
				:color="`on-${color}`"
				@click="toastMessage.confirm"
				v-text="toastMessage.confirmBtnText ?? t('snackbar_ok')"
			/>
		</div>

		<v-fade-transition>
			<template v-if="toastMessage && !showConfirm" #actions>
				<v-btn variant="text" @click="deleteMessage" v-text="t('snackbar_close')" />
			</template>
		</v-fade-transition>
	</v-snackbar>
</template>

<style scoped>
	.close-btn {
		position: absolute;
		top: 0;
		right: 0;
	}
</style>

<i18n>
{
	"en": {
		"snackbar_close": "Close",
		"snackbar_ok": "Ok"
	},
	"fr": {
		"snackbar_close": "Fermer",
		"snackbar_ok": "Ok"
	}
}
</i18n>
