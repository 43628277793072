const getDateFromDateString = (input: string): Date => {
	const timestamp = new Date(/^[0-9]+$/.exec(input) ? Number(input) : input).valueOf();
	if (isNaN(timestamp))
		throw new Error(`Function "getDateFromDateString" received invalid input parameter. ${input}, ${typeof input}`);
	return new Date(timestamp);
};

const formatTime = (
	dateString: string,
	locale: string,
	options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
) => {
	const date = getDateFromDateString(dateString);
	return date.toLocaleTimeString(locale, options);
};

const formatDate = (dateString: string, locale: string) => {
	const date = getDateFromDateString(dateString);
	return new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(date);
};

const commonDateFormat = (value: string | number | Date) => {
	const d = new Date(value);
	return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
};

export default (): {
	getDateFromDateString: (input: string) => Date;
	formatTime: (dateString: string, locale: string, options?: Intl.DateTimeFormatOptions) => string;
	formatDate: (dateString: string, locale: string) => string;
	commonDateFormat: (value: string | number | Date) => string;
} => ({ getDateFromDateString, formatTime, formatDate, commonDateFormat });
