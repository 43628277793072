import { defineStore } from 'pinia';
import { SubscriptionStatus } from '@/amplify-pinia/types';
import { Command } from '@/graphql/types';

type CommandState = {
	subscriptionStatus: SubscriptionStatus;
	commands: Command[];
};
type CommandGetters = {
	getCommandsHistories: () => Command[];
};
type CommandActions = {
	setListCommands: (payload: Command[]) => void;
	addCommand: (payload: Command) => void;
};

export const useCommand = defineStore<string, CommandState, CommandGetters, CommandActions>('Command', {
	state: () => ({
		subscriptionStatus: SubscriptionStatus.PENDING,
		commands: []
	}),
	getters: {
		getCommandsHistories() {
			return this.commands.map((command) => {
				command.created_at = new Date(command.created_at).toLocaleString();
				return command;
			});
		}
	},
	actions: {
		setListCommands(payload: Command[]) {
			this.commands = [...this.commands, ...payload];
		},
		addCommand(payload: Command) {
			this.commands = [...this.commands, payload];
		}
	}
});
