import {API, graphqlOperation} from "aws-amplify";
import {DocumentNode} from "graphql";
import {storeToRefs} from "pinia";
import useCurrentUser from "@/features/CurrentUser/store";
import {GraphQLResult} from "@aws-amplify/api";
import awsmobile from "@/aws-exports";


export const AWSGraphqlQuery : (query: string, variables?: object) => Promise<GraphQLResult<any>> = async (query: string, variables: object = {}) => {
	const { getAuthorizationToken } = storeToRefs(useCurrentUser());
	return API.graphql({
		query,
		variables,
		authMode: 'API_KEY',
		authToken: awsmobile.aws_appsync_apiKey
	});
}

export const AWSGraphqlMutation : (mutation: string, input: object) => Promise<GraphQLResult<any>> = async (mutation: string, input: object) => {
	const { getAuthorizationToken } = storeToRefs(useCurrentUser());
	return API.graphql({
		query: mutation,
		variables: { input },
		authMode: 'API_KEY',
		authToken: awsmobile.aws_appsync_apiKey
	});
}

export const AWSSubscription  = (mutation: (string | DocumentNode), input: object, next: Function, error: Function) => {
	const { getAuthorizationToken } = storeToRefs(useCurrentUser());
	const subscription = API.graphql(
		graphqlOperation(
			mutation,
			input,
			awsmobile.aws_appsync_apiKey
		)
	) // @ts-ignore
		.subscribe({
			next,
			error
		});

	return () => {
		subscription.unsubscribe();
	};
}
