<script lang="ts" setup>
	import { nextTick, ref, watch } from 'vue';
	// @ts-ignore
	import { VueCsvImport, VueCsvErrors, VueCsvInput, VueCsvMap, VueCsvToggleHeaders } from 'vue-csv-import';
	import { useI18n } from 'vue-i18n';
	import { v4 as uuidV4 } from 'uuid';
	import { useVModel } from '@vueuse/core';
	import { ImportUsersDataBase } from './types';
	import useUserValidation from '@/composables/useUserValidation';

	const props = defineProps<{ users: ImportUsersDataBase[]; isVisible: boolean; errors: any }>();
	const emits = defineEmits<{
		(e: 'update:users'): void;
		(e: 'update:isVisible'): boolean;
		(e: 'update:errors'): void;
	}>();

	const { t } = useI18n();

	const usersVModel = useVModel(props, 'users', emits);
	const isVisibleVModel = useVModel(props, 'isVisible', emits);
	const errorsVModel = useVModel(props, 'errors', emits);

	const { validation } = useUserValidation(usersVModel, errorsVModel);

	const usersImport = ref<ImportUsersDataBase[]>([]);
	const refInputFile = ref<HTMLInputElement | null>(null);

	watch(isVisibleVModel, (isVisible: boolean) => {
		if (isVisible) {
			usersImport.value = [];
		}
	});

	const updateCsv = (importUserCsv: ImportUsersDataBase[]) => {
		const importUsers = importUserCsv.map((user: any) => {
			const importUser: ImportUsersDataBase = {
				...user,
				id: uuidV4(),
				status: t('labels.to_be_imported'),
				seen_last: 'N/A'
			};
			return importUser;
		});
		usersImport.value = importUsers;
	};

	const submit = async () => {
		usersVModel.value = [...usersVModel.value, ...usersImport.value];
		await nextTick();

		usersVModel.value = usersVModel.value.map((user) => {
			const isValid = validation(user);
			return {
				...user,
				isValid
			};
		});
		isVisibleVModel.value = false;
		await nextTick();
		(document.querySelector('.p-paginator-last') as HTMLButtonElement)?.click();
	};

	function getItems(sample: Record<string, string>[]) {
		return sample.map((title, index) => ({ title, value: index }));
	}
</script>

<template>
	<v-dialog
		v-model="isVisibleVModel"
		content-class="p-4"
		data-cy="dialog-import-users-csv"
		aria-labelledby="dialog-label"
		max-width="600px"
	>
		<v-card ref="" class="bg-white">
			<v-card-title class="d-flex align-baseline justify-space-between bg-primary">
				<h2 id="dialog-label" class="text-h6 font-weight-bold mr-2">{{ t('csv_title') }}</h2>
				<v-btn
					icon="mdi-close"
					size="small"
					variant="text"
					:aria-label="t('actions.close')"
					@click="isVisibleVModel = false"
				/>
			</v-card-title>
			<v-card-text>
				<vue-csv-import
					:model-value="usersImport"
					:fields="{
						user_name: { required: true, label: t('labels.username') },
						user_email: { required: true, label: t('labels.email') },
						role: { required: true, label: t('labels.role') },
						password: { required: true, label: t('labels.password') }
					}"
					@update:model-value="updateCsv"
				>
					<div data-cy="checkbox-csv-header" class="mb-4">
						<vue-csv-toggle-headers />
					</div>
					<div class="mb-4">
						<vue-csv-map v-slot="{ sample, map, fields }" :auto-match="true">
							<v-table v-if="sample" dense class="">
								<thead>
									<tr>
										<th>{{ t('csv_import_label') }}</th>
										<th>{{ t('csv_import_value') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(field, key) in fields" :key="key">
										<td>{{ field.label }}</td>
										<td>
											<div class="mt-4">
												<v-select
													v-model="map[field.key]"
													:label="field.label"
													variant="underlined"
													:items="getItems(sample)"
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</v-table>
						</vue-csv-map>
					</div>
					<div class="mb-4">
						<vue-csv-input
							id="admin-import-user-input-file"
							ref="refInputFile"
							class="admin-import-user-input-file"
							name="csv"
						/>
					</div>
					<div class="d-flex flex-column text-error">
						<vue-csv-errors />
					</div>
					<v-btn
						class="d-flex ml-auto"
						color="success"
						data-cy="btn-submit-import-users-csv"
						:disabled="usersImport.length === 0"
						@click.prevent="submit"
					>
						{{ t('csv_import_submit') }}
					</v-btn>
				</vue-csv-import>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<style>
	.admin-import-user-input-file::file-selector-button {
		min-height: 2.25rem;
		padding: 0 16px;
		font-size: 14px;
		font-weight: bolder;
		text-transform: uppercase;
		background-color: rgb(var(--v-theme-secondary));
		color: #fff;
		border-width: 0;
		border-radius: 4px !important;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 2.00187px 3.99439px -1.00187px, rgba(0, 0, 0, 0.14) 0px 3.99626px 4.99439px 0px,
			rgba(0, 0, 0, 0.12) 0px 1px 9.99065px 0px;
		transition-duration: 0.28s;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-property: box-shadow, transform, opacity;
	}

	.admin-import-user-input-file::file-selector-button:hover {
		cursor: pointer;
		opacity: 0.9;
		backdrop-filter: blur(9px);
		box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
			0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
			0px 1px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
	}
</style>

<i18n>
{
	"en": {
		"csv_import_label": "label",
		"csv_import_value": "value",
		"csv_import_submit": "Submit",
	},
	"fr": {
		"csv_import_label": "label",
		"csv_import_value": "valeur",
		"csv_import_submit": "Soumettre"
	}
}
</i18n>
