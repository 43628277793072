<script setup lang="ts">
	import { defineComponent, ref, computed, watch } from 'vue';
	import useChat from '@/custom/default/js/components/chat/useChat';
	import { ComponentsName, EmitsName } from '@/plugins/lib/constants';

	defineComponent({ name: ComponentsName.EmojiMenu });

	const emits = defineEmits([EmitsName.EmojiClick, 'update:modelValue']);

	const props = defineProps({
		chatWidth: {
			type: Number,
			default: 0
		}
	});

	const show = ref(false);
	const emojiMenuToolbar = ref();
	const emojiMenuToolbarHeight = ref(0);
	const emojiMenuContentHeight = ref(175);
	const emojiMenuHeight = computed(() => emojiMenuContentHeight.value + emojiMenuToolbarHeight.value);

	const { emojis } = useChat();

	const emojisList = ref(emojis);

	const getEmojis = (category: string) => {
		const categoryEmojis: Record<string, string> = {};
		for (const key in emojisList.value[category]) {
			categoryEmojis[key] = emojisList.value[category][key];
		}
		return categoryEmojis;
	};

	const onClose = () => {
		show.value = false;
	};

	const onToggle = () => {
		show.value = !show.value;
	};

	const handleEmojiClick = (emoji: string, category: string) => {
		const emojiArray = Object.entries(getEmojis(category)).map(([key, value]) => ({ key, value }));
		const emojiItem = emojiArray.find((item) => item.value === emoji) || { key: '' };

		if (!emojisList.value?.['Recently used'][emojiItem.key]) {
			emojisList.value['Recently used'][emojiItem.key] = emoji;
		}
		emits(EmitsName.EmojiClick, emoji);
	};

	watch(emojiMenuToolbar, (newValue) => {
		if (!newValue) return;
		emojiMenuToolbarHeight.value = newValue.offsetHeight;
	});
</script>

<template>
	<v-menu
		:width="chatWidth"
		:max-width="400"
		:transition="false"
		:height="emojiMenuHeight"
		:max-height="emojiMenuHeight"
		:close-on-click="false"
		location="top"
		z-index="1000"
	>
		<template #activator="{ props: scopedProps }">
			<v-btn
				v-bind="scopedProps"
				data-cy="form-global-chat-button"
				size="small"
				variant="plain"
				icon="mdi-emoticon"
				@click="onToggle"
			/>
		</template>

		<div ref="emojiMenuToolbar" class="d-flex align-center justify-end pa-1 pl-2 bg-custom-blur">
			<v-btn icon="mdi-close" variant="plain" size="small" @click="onClose" />
		</div>

		<v-card class="overflow-auto pa-1 bg-custom-blur" :height="emojiMenuContentHeight" rounded="0">
			<div
				v-for="(category, i) in Object.keys(emojis)"
				:key="`emoji-category-${i}`"
				class="emoji-menu-content__categories"
			>
				<span class="ml-2" v-text="category" />

				<div class="d-flex flex-wrap emoji-menu-content__items">
					<button
						v-for="(emoji, index) in getEmojis(category)"
						:key="`emoji_${index}`"
						class="emoji-btn ma-1"
						:data-cy="`form-global-chat-button-${emoji}`"
						@click="handleEmojiClick(emoji, category)"
						v-text="emoji"
					/>
				</div>
			</div>
		</v-card>
	</v-menu>
</template>

<style scoped>
	.emoji-menu-content__categories .emoji-btn {
		font-size: 1.5rem;
		height: 36px;
		width: 36px;
	}
</style>
