<script setup lang="ts">
	import { computed, defineComponent } from 'vue';
	import { useDisplay } from 'vuetify';
	import { storeToRefs } from 'pinia';
	import { useI18n } from 'vue-i18n';
	import unescape from 'lodash/unescape';

	import useStringFormatter from '@/composables/useStringFormatter';
	import useDateFormatter from '@/composables/useDateFormatter';
	import useCurrentUser from '@/features/CurrentUser/store';
	import { AWSGraphqlMutation } from '@/graphql';
	import { deleteChats } from '@/graphql/mutations';
	import type { Chats } from '@/graphql/types';

	defineComponent({ name: 'ChatMessage' });

	const props = withDefaults(defineProps<{ message: Chats; deletable?: boolean }>(), {
		deletable: false
	});

	const { t, locale } = useI18n();

	const { mobile } = useDisplay();

	const { formatTime } = useDateFormatter();
	const { vLinkify } = useStringFormatter();

	const { getUserProfileID } = storeToRefs(useCurrentUser());

	const senderInfos = computed(() =>
		!props.message
			? ''
			: `${props.message?.user_profile?.user_email?.toUpperCase() ?? '(anonymous)'}, ${formatTime(
					props.message?.sent_on,
					locale.value
			  )}`
	);

	const doesMessageBelongToCurrentUser = computed(() => props.message?.profile_id_from === getUserProfileID.value);

	const classes = computed(() => ({
		'chat-message': true,
		'is-mobile': mobile.value,
		[`${doesMessageBelongToCurrentUser.value ? 'incoming' : 'outgoing'}-message`]: true,
		'bg-blue-lighten-1': !doesMessageBelongToCurrentUser.value,
		'bg-primary': doesMessageBelongToCurrentUser.value,
		[`${doesMessageBelongToCurrentUser.value ? 'mr' : 'ml'}-auto`]: true,
		'pa-2': true,
		'mb-2': true,
		'text-white': true
	}));

	const deleteMessage = (message: Chats) => {
		AWSGraphqlMutation(deleteChats, {
			id: message.id
		}).catch((e) => {
			console.error('Message deleted error ', e);
		});
	};
</script>

<template>
	<v-card :class="classes" :color="doesMessageBelongToCurrentUser ? 'primary' : 'secondary'">
		<v-card-text :class="{ 'is-deletable': deletable }" class="pa-0 d-flex">
			<small :class="['text-span text-no-wrap', deletable ? 'pl-10' : '']" v-text="senderInfos" />
			<v-slide-x-transition>
				<div v-if="deletable" class="close-button-wrapper font-weight-bold d-flex align-self-stretch">
					<v-tooltip :aria-label="t('chatviewer_delete_chat_message')">
						<template #activator="{ props: scopedProps }">
							<v-btn
								class="mx-1"
								size="small"
								icon="mdi-close"
								density="compact"
								variant="text"
								v-bind="scopedProps"
								flat
								@click="deleteMessage(message)"
							/>
						</template>
						<span v-text="t('chatviewer_delete_chat_message')" />
					</v-tooltip>
				</div>
			</v-slide-x-transition>

			<div :class="['w-100 content-wrapper pa-2 pb-0 flex-grow-1', deletable ? 'pl-8' : 'pl-2']">
				<p v-linkify>{{ unescape(message.content) }}</p>
			</div>
		</v-card-text>
	</v-card>
</template>

<style scoped>
	.content-wrapper {
		overflow-wrap: break-word;
		word-break: normal;
	}
	.v-card .chat-message:not(.is-mobile) {
		width: 90%;
		max-width: 90%;
		height: unset;
	}
	.v-card .chat-message.is-mobile {
		width: 75%;
		max-width: 75%;
		height: unset;
	}
	.chat-message.incoming-message {
		border-radius: 8px 8px 8px 0;
	}
	.chat-message.outgoing-message {
		border-radius: 8px 8px 0 8px;
	}
	.chat-message .close-button-wrapper {
		position: absolute;
		left: 0;
		height: 100%;
	}
	.text-span {
		float: left;
		margin-right: 0.5em;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.5;
		padding-left: 16px;
	}
	.text-span,
	.chat-message .content-wrapper {
		transition: padding-left 0.25s cubic-bezier(0, 0, 0.25, 1);
	}
</style>

<i18n>
{
	"fr":  {
		"chatviewer_delete_chat_message": "Supprimer"
	},
	"en":  {
		"chatviewer_delete_chat_message": "Delete"
	}
}
</i18n>
