<script setup lang="ts">
	import { defineComponent, ref, watch } from 'vue';
	import LayoutSolotechCareer from '@/custom/solotech-career/vue/components/layout/LayoutSolotechCareer.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import CardPage from '@/custom/solotech-career/vue/components/common/CardPage.vue';
	import { useI18n } from 'vue-i18n';
	import pdf from '@/custom/solotech-career/assets/AboutUs.pdf';

	defineComponent({
		name: ComponentsName.AboutUs
	});

	const { locale } = useI18n();

	type lang = 'en' | 'fr';
</script>

<template>
	<layout-solotech-career>
		<card-page :container-full-height="true">
			<iframe :src="pdf" class="min-h-100" width="100%" height="100%"></iframe>
		</card-page>
	</layout-solotech-career>
</template>
