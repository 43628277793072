<script setup lang="ts">
	import { defineComponent, watch } from 'vue';
	import { storeToRefs } from 'pinia';
	import { ComponentsName } from '@/plugins/lib/constants';
	import useAPUtils from '@/amplify-pinia/useAPUtils';
	import useDisplayConfig from '@/features/DisplayConfig';
	import { useActiveUsers } from '@/features/ActiveUsers/store';
	import useCurrentUser from '@/features/CurrentUser/store';
	import { createCommandSubscription } from '@/features/Command';
	import { subscriptionUserTable } from '@/features/Table/UserTable';
	import { useTable } from '@/composables/useTable';
	import useCurrentEvent from '@/features/Events/store';
	import useChat from '@/features/Chat/store';

	defineComponent({ name: ComponentsName.ViewLoader });

	defineProps({
		displayRouterView: {
			type: String,
			required: true
		}
	});

	const { getUserID } = storeToRefs(useCurrentUser());
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());
	const { initJitsiMeet } = useTable();
	const { fetchCurrentEvent, subscribeV2: subscribeCurrentEvent } = useCurrentEvent();

	watch(
		() => getUserID.value && getCurrentEventId.value,
		async (newValue) => {
			if (!newValue) return;
			await useAPUtils().clearAPStores();
			await fetchCurrentEvent();
			await createCommandSubscription(initJitsiMeet);
			subscriptionUserTable(getCurrentEventId.value).catch((err) => console.error('subscriptionUserTable error ', err));
			subscribeCurrentEvent();
			useChat().subscribeChat();

			const apFindParams = { filter: { event_id: { eq: getCurrentEventId.value } } };

			try {
				await useDisplayConfig().find(apFindParams);
			} catch (error) {
				console.error('ViewLoader bulk load Promise.all', error);
			}
		},
		{ immediate: true }
	);
</script>

<template>
	<component :is="displayRouterView" />
</template>
