// This is an auto generated file. This will be overwritten
import * as Types from './types';
import gql from 'graphql-tag';
import * as Urql from '@urql/vue';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const AddAuthMethodsToEventConfig = gql`
	mutation addAuthMethodsToEventConfig($addAuthMethodInput: AddAuthMethodInput!, $event_id: UUID!) {
		addAuthMethodsToEventConfig(addAuthMethodInput: $addAuthMethodInput, event_id: $event_id) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useAddAuthMethodsToEventConfigMutation() {
	return Urql.useMutation<
		Types.AddAuthMethodsToEventConfigMutation,
		Types.AddAuthMethodsToEventConfigMutationVariables
	>(AddAuthMethodsToEventConfig);
}
export const AddClientToSsoAuthMethod = gql`
	mutation addClientToSsoAuthMethod($addSsoClientInput: AddSsoClientInput!, $event_id: UUID!) {
		addClientToSsoAuthMethod(addSsoClientInput: $addSsoClientInput, event_id: $event_id) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useAddClientToSsoAuthMethodMutation() {
	return Urql.useMutation<Types.AddClientToSsoAuthMethodMutation, Types.AddClientToSsoAuthMethodMutationVariables>(
		AddClientToSsoAuthMethod
	);
}
export const AddClosedCaptionToStream = gql`
	mutation addClosedCaptionToStream($addClosedCaptionInput: AddClosedCaptionInput!, $stream_id: UUID!) {
		addClosedCaptionToStream(addClosedCaptionInput: $addClosedCaptionInput, stream_id: $stream_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useAddClosedCaptionToStreamMutation() {
	return Urql.useMutation<Types.AddClosedCaptionToStreamMutation, Types.AddClosedCaptionToStreamMutationVariables>(
		AddClosedCaptionToStream
	);
}
export const AddConferencesToUser = gql`
	mutation addConferencesToUser($addConferencesToUserInput: AddConferencesToUserInput!) {
		addConferencesToUser(addConferencesToUserInput: $addConferencesToUserInput)
	}
`;

export function useAddConferencesToUserMutation() {
	return Urql.useMutation<Types.AddConferencesToUserMutation, Types.AddConferencesToUserMutationVariables>(
		AddConferencesToUser
	);
}
export const AddGeoRestrictedArea = gql`
	mutation addGeoRestrictedArea($AddGeoRestrictedAreaInput: AddGeoRestrictedAreaInput!) {
		addGeoRestrictedArea(AddGeoRestrictedAreaInput: $AddGeoRestrictedAreaInput) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useAddGeoRestrictedAreaMutation() {
	return Urql.useMutation<Types.AddGeoRestrictedAreaMutation, Types.AddGeoRestrictedAreaMutationVariables>(
		AddGeoRestrictedArea
	);
}
export const AddSocialLinkToEvent = gql`
	mutation addSocialLinkToEvent($addSocialLinkToEventInput: AddSocialLinkToEventInput!) {
		addSocialLinkToEvent(addSocialLinkToEventInput: $addSocialLinkToEventInput) {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useAddSocialLinkToEventMutation() {
	return Urql.useMutation<Types.AddSocialLinkToEventMutation, Types.AddSocialLinkToEventMutationVariables>(
		AddSocialLinkToEvent
	);
}
export const AddUsersToChannel = gql`
	mutation addUsersToChannel($addUsersToChannelInput: AddUsersToChannelInput!) {
		addUsersToChannel(addUsersToChannelInput: $addUsersToChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useAddUsersToChannelMutation() {
	return Urql.useMutation<Types.AddUsersToChannelMutation, Types.AddUsersToChannelMutationVariables>(AddUsersToChannel);
}
export const AddUsersToConference = gql`
	mutation addUsersToConference($addUsersToConferenceInput: AddUsersToConferenceInput!) {
		addUsersToConference(addUsersToConferenceInput: $addUsersToConferenceInput) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useAddUsersToConferenceMutation() {
	return Urql.useMutation<Types.AddUsersToConferenceMutation, Types.AddUsersToConferenceMutationVariables>(
		AddUsersToConference
	);
}
export const AssignPublicChannel = gql`
	mutation assignPublicChannel($assignPublicChannelInput: AssignPublicChannelInput!) {
		assignPublicChannel(assignPublicChannelInput: $assignPublicChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useAssignPublicChannelMutation() {
	return Urql.useMutation<Types.AssignPublicChannelMutation, Types.AssignPublicChannelMutationVariables>(
		AssignPublicChannel
	);
}
export const ChangeQuestionStatus = gql`
	mutation changeQuestionStatus($changeQuestionStatusInput: ChangeQuestionStatusInput!) {
		changeQuestionStatus(changeQuestionStatusInput: $changeQuestionStatusInput) {
			conference_id
			content
			created_at
			deleted_at
			edited_by {
				user_email
				user_id
				user_name
			}
			event_id
			id
			old_status
			question_pool_id
			sender {
				user_email
				user_id
				user_name
			}
			status
		}
	}
`;

export function useChangeQuestionStatusMutation() {
	return Urql.useMutation<Types.ChangeQuestionStatusMutation, Types.ChangeQuestionStatusMutationVariables>(
		ChangeQuestionStatus
	);
}
export const CreateConference = gql`
	mutation createConference($createConferenceInput: CreateConferenceInput!) {
		createConference(createConferenceInput: $createConferenceInput) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useCreateConferenceMutation() {
	return Urql.useMutation<Types.CreateConferenceMutation, Types.CreateConferenceMutationVariables>(CreateConference);
}
export const CreateEvent = gql`
	mutation createEvent($createEventInput: CreateEventInput!) {
		createEvent(createEventInput: $createEventInput) {
			event {
				created_at
				date_end
				date_start
				event_state
				guestAccess
				hasEventPassword
				id
				name
				noPassword
				social_links {
					id
					name
					order
					type
					url
				}
				updated_at
			}
			eventConfig {
				auth_methods {
					type
				}
				event_id
				is_geo_restricted
				theme {
					background {
						default
						paper
					}
					contrastThreshold
					primary {
						contrastText
						dark
						light
						main
					}
					secondary {
						contrastText
						dark
						light
						main
					}
					tonalOffset {
						dark
						light
					}
				}
				type
			}
		}
	}
`;

export function useCreateEventMutation() {
	return Urql.useMutation<Types.CreateEventMutation, Types.CreateEventMutationVariables>(CreateEvent);
}
export const CreateFile = gql`
	mutation createFile($createFileInput: CreateFileInput!) {
		createFile(createFileInput: $createFileInput) {
			file {
				content_type
				created_at
				event_id
				id
				name
				owner {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				owner_id
				parent_folder_id
				updated_at
				url
			}
			upload_url
		}
	}
`;

export function useCreateFileMutation() {
	return Urql.useMutation<Types.CreateFileMutation, Types.CreateFileMutationVariables>(CreateFile);
}
export const CreateFolder = gql`
	mutation createFolder($createFolderInput: CreateFolderInput!) {
		createFolder(createFolderInput: $createFolderInput) {
			created_at
			event_id
			files {
				content_type
				created_at
				event_id
				id
				name
				owner {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				owner_id
				parent_folder_id
				updated_at
				url
			}
			folders {
				created_at
				event_id
				files {
					content_type
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					id
					links {
						created_at
						event_id
						id
						name
						owner {
							created_at
							event_id
							id
							reset_token
							reset_token_expiration_time
							roleNames
							updated_at
							userEventId
							userProfileId
							user_email
							user_name
						}
						owner_id
						parent_folder_id
						updated_at
						url
					}
					name
					parent_folder_id
					path
					updated_at
				}
				id
				name
				parent_folder_id
				path
				updated_at
			}
			id
			links {
				created_at
				event_id
				id
				name
				owner_id
				parent_folder_id
				updated_at
				url
			}
			name
			parent_folder_id
			path
			updated_at
		}
	}
`;

export function useCreateFolderMutation() {
	return Urql.useMutation<Types.CreateFolderMutation, Types.CreateFolderMutationVariables>(CreateFolder);
}
export const CreateFrame = gql`
	mutation createFrame($createFrameInput: CreateFrameInput!) {
		createFrame(createFrameInput: $createFrameInput) {
			conference_id
			created_at
			event_id
			id
			type
			url
		}
	}
`;

export function useCreateFrameMutation() {
	return Urql.useMutation<Types.CreateFrameMutation, Types.CreateFrameMutationVariables>(CreateFrame);
}
export const CreateLink = gql`
	mutation createLink($createLinkInput: CreateLinkInput!) {
		createLink(createLinkInput: $createLinkInput) {
			created_at
			event_id
			id
			name
			owner {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			owner_id
			parent_folder_id
			updated_at
			url
		}
	}
`;

export function useCreateLinkMutation() {
	return Urql.useMutation<Types.CreateLinkMutation, Types.CreateLinkMutationVariables>(CreateLink);
}
export const CreatePrivateChannel = gql`
	mutation createPrivateChannel($createPrivateChannelInput: CreatePrivateChannelInput!) {
		createPrivateChannel(createPrivateChannelInput: $createPrivateChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useCreatePrivateChannelMutation() {
	return Urql.useMutation<Types.CreatePrivateChannelMutation, Types.CreatePrivateChannelMutationVariables>(
		CreatePrivateChannel
	);
}
export const CreatePublicChannel = gql`
	mutation createPublicChannel($createPublicChannelInput: CreatePublicChannelInput!) {
		createPublicChannel(createPublicChannelInput: $createPublicChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useCreatePublicChannelMutation() {
	return Urql.useMutation<Types.CreatePublicChannelMutation, Types.CreatePublicChannelMutationVariables>(
		CreatePublicChannel
	);
}
export const CreateQuestionPool = gql`
	mutation createQuestionPool($createQuestionPoolInput: CreateQuestionPoolInput!) {
		createQuestionPool(createQuestionPoolInput: $createQuestionPoolInput) {
			conference_id
			created_at
			description
			event_id
			highlighted_question_id
			id
			name
		}
	}
`;

export function useCreateQuestionPoolMutation() {
	return Urql.useMutation<Types.CreateQuestionPoolMutation, Types.CreateQuestionPoolMutationVariables>(
		CreateQuestionPool
	);
}
export const CreateStream = gql`
	mutation createStream($createStreamInput: CreateStreamInput!) {
		createStream(createStreamInput: $createStreamInput) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useCreateStreamMutation() {
	return Urql.useMutation<Types.CreateStreamMutation, Types.CreateStreamMutationVariables>(CreateStream);
}
export const CreateTalkroom = gql`
	mutation createTalkroom($createTalkroomInput: CreateTalkroomInput!) {
		createTalkroom(createTalkroomInput: $createTalkroomInput) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useCreateTalkroomMutation() {
	return Urql.useMutation<Types.CreateTalkroomMutation, Types.CreateTalkroomMutationVariables>(CreateTalkroom);
}
export const CreateUsers = gql`
	mutation createUsers($event_id: String!, $importUsersInput: [ImportUsersInput!]!) {
		createUsers(event_id: $event_id, importUsersInput: $importUsersInput) {
			hasCreated
			reason
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
		}
	}
`;

export function useCreateUsersMutation() {
	return Urql.useMutation<Types.CreateUsersMutation, Types.CreateUsersMutationVariables>(CreateUsers);
}
export const DeleteConference = gql`
	mutation deleteConference($id: UUID!) {
		deleteConference(id: $id) {
			id
			success
		}
	}
`;

export function useDeleteConferenceMutation() {
	return Urql.useMutation<Types.DeleteConferenceMutation, Types.DeleteConferenceMutationVariables>(DeleteConference);
}
export const DeleteFiles = gql`
	mutation deleteFiles($ids: [UUID!]!) {
		deleteFiles(ids: $ids) {
			id
			success
		}
	}
`;

export function useDeleteFilesMutation() {
	return Urql.useMutation<Types.DeleteFilesMutation, Types.DeleteFilesMutationVariables>(DeleteFiles);
}
export const DeleteFolder = gql`
	mutation deleteFolder($id: UUID!) {
		deleteFolder(id: $id) {
			id
			success
		}
	}
`;

export function useDeleteFolderMutation() {
	return Urql.useMutation<Types.DeleteFolderMutation, Types.DeleteFolderMutationVariables>(DeleteFolder);
}
export const DeleteFrame = gql`
	mutation deleteFrame($id: UUID!) {
		deleteFrame(id: $id) {
			id
			success
		}
	}
`;

export function useDeleteFrameMutation() {
	return Urql.useMutation<Types.DeleteFrameMutation, Types.DeleteFrameMutationVariables>(DeleteFrame);
}
export const DeleteLinks = gql`
	mutation deleteLinks($ids: [UUID!]!) {
		deleteLinks(ids: $ids) {
			id
			success
		}
	}
`;

export function useDeleteLinksMutation() {
	return Urql.useMutation<Types.DeleteLinksMutation, Types.DeleteLinksMutationVariables>(DeleteLinks);
}
export const DeleteMessage = gql`
	mutation deleteMessage($deleteMessageInput: DeleteMessageInput!) {
		deleteMessage(deleteMessageInput: $deleteMessageInput) {
			id
			success
		}
	}
`;

export function useDeleteMessageMutation() {
	return Urql.useMutation<Types.DeleteMessageMutation, Types.DeleteMessageMutationVariables>(DeleteMessage);
}
export const DeletePublicChannel = gql`
	mutation deletePublicChannel($id: UUID!) {
		deletePublicChannel(id: $id) {
			id
			success
		}
	}
`;

export function useDeletePublicChannelMutation() {
	return Urql.useMutation<Types.DeletePublicChannelMutation, Types.DeletePublicChannelMutationVariables>(
		DeletePublicChannel
	);
}
export const DeleteQuestionPool = gql`
	mutation deleteQuestionPool($deleteQuestionPoolInput: DeleteQuestionPoolInput!) {
		deleteQuestionPool(deleteQuestionPoolInput: $deleteQuestionPoolInput) {
			id
			success
		}
	}
`;

export function useDeleteQuestionPoolMutation() {
	return Urql.useMutation<Types.DeleteQuestionPoolMutation, Types.DeleteQuestionPoolMutationVariables>(
		DeleteQuestionPool
	);
}
export const DeleteStream = gql`
	mutation deleteStream($id: UUID!) {
		deleteStream(id: $id) {
			id
			success
		}
	}
`;

export function useDeleteStreamMutation() {
	return Urql.useMutation<Types.DeleteStreamMutation, Types.DeleteStreamMutationVariables>(DeleteStream);
}
export const DeleteTalkroom = gql`
	mutation deleteTalkroom($id: UUID!) {
		deleteTalkroom(id: $id) {
			id
			success
		}
	}
`;

export function useDeleteTalkroomMutation() {
	return Urql.useMutation<Types.DeleteTalkroomMutation, Types.DeleteTalkroomMutationVariables>(DeleteTalkroom);
}
export const DeleteUser = gql`
	mutation deleteUser($deleteUserInput: DeleteUserInput!) {
		deleteUser(deleteUserInput: $deleteUserInput) {
			id
			success
		}
	}
`;

export function useDeleteUserMutation() {
	return Urql.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUser);
}
export const DeleteUsers = gql`
	mutation deleteUsers($deleteUsersInput: [DeleteUsersInput!]!) {
		deleteUsers(deleteUsersInput: $deleteUsersInput) {
			message
			success
			userId
		}
	}
`;

export function useDeleteUsersMutation() {
	return Urql.useMutation<Types.DeleteUsersMutation, Types.DeleteUsersMutationVariables>(DeleteUsers);
}
export const DuplicateConference = gql`
	mutation duplicateConference($duplicateConferenceInput: DuplicateConferenceInput!) {
		duplicateConference(duplicateConferenceInput: $duplicateConferenceInput) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useDuplicateConferenceMutation() {
	return Urql.useMutation<Types.DuplicateConferenceMutation, Types.DuplicateConferenceMutationVariables>(
		DuplicateConference
	);
}
export const DuplicateEvent = gql`
	mutation duplicateEvent($duplicateEventInput: DuplicateEventInput!) {
		duplicateEvent(duplicateEventInput: $duplicateEventInput) {
			event {
				created_at
				date_end
				date_start
				event_state
				guestAccess
				hasEventPassword
				id
				name
				noPassword
				social_links {
					id
					name
					order
					type
					url
				}
				updated_at
			}
			eventConfig {
				auth_methods {
					type
				}
				event_id
				is_geo_restricted
				theme {
					background {
						default
						paper
					}
					contrastThreshold
					primary {
						contrastText
						dark
						light
						main
					}
					secondary {
						contrastText
						dark
						light
						main
					}
					tonalOffset {
						dark
						light
					}
				}
				type
			}
		}
	}
`;

export function useDuplicateEventMutation() {
	return Urql.useMutation<Types.DuplicateEventMutation, Types.DuplicateEventMutationVariables>(DuplicateEvent);
}
export const EditQuestion = gql`
	mutation editQuestion($editQuestionInput: EditQuestionInput!) {
		editQuestion(editQuestionInput: $editQuestionInput) {
			conference_id
			content
			created_at
			deleted_at
			edited_by {
				user_email
				user_id
				user_name
			}
			event_id
			id
			old_status
			question_pool_id
			sender {
				user_email
				user_id
				user_name
			}
			status
		}
	}
`;

export function useEditQuestionMutation() {
	return Urql.useMutation<Types.EditQuestionMutation, Types.EditQuestionMutationVariables>(EditQuestion);
}
export const EnableGeoRestriction = gql`
	mutation enableGeoRestriction($is_enabled: Boolean!) {
		enableGeoRestriction(is_enabled: $is_enabled) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useEnableGeoRestrictionMutation() {
	return Urql.useMutation<Types.EnableGeoRestrictionMutation, Types.EnableGeoRestrictionMutationVariables>(
		EnableGeoRestriction
	);
}
export const ExportConferenceParticipationLogs = gql`
	mutation exportConferenceParticipationLogs(
		$exportConferenceParticipantLogsInput: ExportConferenceParticipantLogsInput!
	) {
		exportConferenceParticipationLogs(exportConferenceParticipantLogsInput: $exportConferenceParticipantLogsInput)
	}
`;

export function useExportConferenceParticipationLogsMutation() {
	return Urql.useMutation<
		Types.ExportConferenceParticipationLogsMutation,
		Types.ExportConferenceParticipationLogsMutationVariables
	>(ExportConferenceParticipationLogs);
}
export const ExportUserSessionLogs = gql`
	mutation exportUserSessionLogs($exportUserSessionLogInput: ExportUserSessionLogInput!) {
		exportUserSessionLogs(exportUserSessionLogInput: $exportUserSessionLogInput)
	}
`;

export function useExportUserSessionLogsMutation() {
	return Urql.useMutation<Types.ExportUserSessionLogsMutation, Types.ExportUserSessionLogsMutationVariables>(
		ExportUserSessionLogs
	);
}
export const ImportUsers = gql`
	mutation importUsers($importUsersInput: [ImportUsersInput!]!) {
		importUsers(importUsersInput: $importUsersInput) {
			hasCreated
			reason
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
		}
	}
`;

export function useImportUsersMutation() {
	return Urql.useMutation<Types.ImportUsersMutation, Types.ImportUsersMutationVariables>(ImportUsers);
}
export const InviteUserToCollaborativeBroadcast = gql`
	mutation inviteUserToCollaborativeBroadcast(
		$inviteUserToCollaborativeBroadcastInput: InviteUserToCollaborativeBroadcastInput!
	) {
		inviteUserToCollaborativeBroadcast(
			inviteUserToCollaborativeBroadcastInput: $inviteUserToCollaborativeBroadcastInput
		) {
			backstage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					presence {
						participant_number
						participants {
							user_id
							user_name
						}
					}
					raised_hands {
						participant {
							user_id
							user_name
						}
						raised_at
					}
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			conference_id
			event_id
			stage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			user_id
		}
	}
`;

export function useInviteUserToCollaborativeBroadcastMutation() {
	return Urql.useMutation<
		Types.InviteUserToCollaborativeBroadcastMutation,
		Types.InviteUserToCollaborativeBroadcastMutationVariables
	>(InviteUserToCollaborativeBroadcast);
}
export const JoinCollaborativeBroadcast = gql`
	mutation joinCollaborativeBroadcast($joinCollaborativeBroadcastInput: JoinCollaborativeBroadcastInput!) {
		joinCollaborativeBroadcast(joinCollaborativeBroadcastInput: $joinCollaborativeBroadcastInput) {
			backstage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					presence {
						participant_number
						participants {
							user_id
							user_name
						}
					}
					raised_hands {
						participant {
							user_id
							user_name
						}
						raised_at
					}
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			conference_id
			event_id
			stage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			user_id
		}
	}
`;

export function useJoinCollaborativeBroadcastMutation() {
	return Urql.useMutation<Types.JoinCollaborativeBroadcastMutation, Types.JoinCollaborativeBroadcastMutationVariables>(
		JoinCollaborativeBroadcast
	);
}
export const JoinTalkroom = gql`
	mutation joinTalkroom($id: UUID!) {
		joinTalkroom(id: $id)
	}
`;

export function useJoinTalkroomMutation() {
	return Urql.useMutation<Types.JoinTalkroomMutation, Types.JoinTalkroomMutationVariables>(JoinTalkroom);
}
export const KickoutTalkroomParticipant = gql`
	mutation kickoutTalkroomParticipant($kickoutTalkroomParticipantInput: KickoutTalkroomParticipantInput!) {
		kickoutTalkroomParticipant(kickoutTalkroomParticipantInput: $kickoutTalkroomParticipantInput)
	}
`;

export function useKickoutTalkroomParticipantMutation() {
	return Urql.useMutation<Types.KickoutTalkroomParticipantMutation, Types.KickoutTalkroomParticipantMutationVariables>(
		KickoutTalkroomParticipant
	);
}
export const LeaveConference = gql`
	mutation leaveConference($connection_id: UUID!) {
		leaveConference(connection_id: $connection_id)
	}
`;

export function useLeaveConferenceMutation() {
	return Urql.useMutation<Types.LeaveConferenceMutation, Types.LeaveConferenceMutationVariables>(LeaveConference);
}
export const LockTalkroom = gql`
	mutation lockTalkroom($lockTalkroomInput: LockTalkroomInput!) {
		lockTalkroom(lockTalkroomInput: $lockTalkroomInput) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useLockTalkroomMutation() {
	return Urql.useMutation<Types.LockTalkroomMutation, Types.LockTalkroomMutationVariables>(LockTalkroom);
}
export const MuteAllTalkroomParticipants = gql`
	mutation muteAllTalkroomParticipants($talkroom_id: UUID!) {
		muteAllTalkroomParticipants(talkroom_id: $talkroom_id)
	}
`;

export function useMuteAllTalkroomParticipantsMutation() {
	return Urql.useMutation<
		Types.MuteAllTalkroomParticipantsMutation,
		Types.MuteAllTalkroomParticipantsMutationVariables
	>(MuteAllTalkroomParticipants);
}
export const MuteTalkroomParticipant = gql`
	mutation muteTalkroomParticipant($muteTalkroomParticipantInput: MuteTalkroomParticipantInput!) {
		muteTalkroomParticipant(muteTalkroomParticipantInput: $muteTalkroomParticipantInput)
	}
`;

export function useMuteTalkroomParticipantMutation() {
	return Urql.useMutation<Types.MuteTalkroomParticipantMutation, Types.MuteTalkroomParticipantMutationVariables>(
		MuteTalkroomParticipant
	);
}
export const OrderSocialLinksOnEvent = gql`
	mutation orderSocialLinksOnEvent($orderSocialLinksInput: OrderSocialLinksInput!) {
		orderSocialLinksOnEvent(orderSocialLinksInput: $orderSocialLinksInput) {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useOrderSocialLinksOnEventMutation() {
	return Urql.useMutation<Types.OrderSocialLinksOnEventMutation, Types.OrderSocialLinksOnEventMutationVariables>(
		OrderSocialLinksOnEvent
	);
}
export const OrderStreams = gql`
	mutation orderStreams($orderStreamsInput: OrderStreamsInput!) {
		orderStreams(orderStreamsInput: $orderStreamsInput) {
			success
		}
	}
`;

export function useOrderStreamsMutation() {
	return Urql.useMutation<Types.OrderStreamsMutation, Types.OrderStreamsMutationVariables>(OrderStreams);
}
export const OrderTalkrooms = gql`
	mutation orderTalkrooms($orderTalkroomsInput: OrderTalkroomsInput!) {
		orderTalkrooms(orderTalkroomsInput: $orderTalkroomsInput) {
			success
		}
	}
`;

export function useOrderTalkroomsMutation() {
	return Urql.useMutation<Types.OrderTalkroomsMutation, Types.OrderTalkroomsMutationVariables>(OrderTalkrooms);
}
export const RemoveAuthMethodFromEventConfig = gql`
	mutation removeAuthMethodFromEventConfig($auth_method_type: AuthMethodType!, $event_id: UUID!) {
		removeAuthMethodFromEventConfig(auth_method_type: $auth_method_type, event_id: $event_id) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useRemoveAuthMethodFromEventConfigMutation() {
	return Urql.useMutation<
		Types.RemoveAuthMethodFromEventConfigMutation,
		Types.RemoveAuthMethodFromEventConfigMutationVariables
	>(RemoveAuthMethodFromEventConfig);
}
export const RemoveClientFromSsoAuthMethod = gql`
	mutation removeClientFromSsoAuthMethod($event_id: UUID!, $id: UUID!) {
		removeClientFromSsoAuthMethod(event_id: $event_id, id: $id) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useRemoveClientFromSsoAuthMethodMutation() {
	return Urql.useMutation<
		Types.RemoveClientFromSsoAuthMethodMutation,
		Types.RemoveClientFromSsoAuthMethodMutationVariables
	>(RemoveClientFromSsoAuthMethod);
}
export const RemoveClosedCaptionFromStream = gql`
	mutation removeClosedCaptionFromStream($closed_caption_id: String!, $stream_id: UUID!) {
		removeClosedCaptionFromStream(closed_caption_id: $closed_caption_id, stream_id: $stream_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useRemoveClosedCaptionFromStreamMutation() {
	return Urql.useMutation<
		Types.RemoveClosedCaptionFromStreamMutation,
		Types.RemoveClosedCaptionFromStreamMutationVariables
	>(RemoveClosedCaptionFromStream);
}
export const RemoveConferencesFromUser = gql`
	mutation removeConferencesFromUser($removeConferencesFromUserInput: RemoveConferencesFromUserInput!) {
		removeConferencesFromUser(removeConferencesFromUserInput: $removeConferencesFromUserInput)
	}
`;

export function useRemoveConferencesFromUserMutation() {
	return Urql.useMutation<Types.RemoveConferencesFromUserMutation, Types.RemoveConferencesFromUserMutationVariables>(
		RemoveConferencesFromUser
	);
}
export const RemoveGeoRestrictedArea = gql`
	mutation removeGeoRestrictedArea($id: UUID!) {
		removeGeoRestrictedArea(id: $id) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useRemoveGeoRestrictedAreaMutation() {
	return Urql.useMutation<Types.RemoveGeoRestrictedAreaMutation, Types.RemoveGeoRestrictedAreaMutationVariables>(
		RemoveGeoRestrictedArea
	);
}
export const RemoveSocialLinkFromEvent = gql`
	mutation removeSocialLinkFromEvent($social_link_id: UUID!) {
		removeSocialLinkFromEvent(social_link_id: $social_link_id) {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useRemoveSocialLinkFromEventMutation() {
	return Urql.useMutation<Types.RemoveSocialLinkFromEventMutation, Types.RemoveSocialLinkFromEventMutationVariables>(
		RemoveSocialLinkFromEvent
	);
}
export const RemoveUsersFromConference = gql`
	mutation removeUsersFromConference($removeUsersFromConferenceInput: RemoveUsersFromConferenceInput!) {
		removeUsersFromConference(removeUsersFromConferenceInput: $removeUsersFromConferenceInput) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useRemoveUsersFromConferenceMutation() {
	return Urql.useMutation<Types.RemoveUsersFromConferenceMutation, Types.RemoveUsersFromConferenceMutationVariables>(
		RemoveUsersFromConference
	);
}
export const ResetPassword = gql`
	mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
		resetPassword(resetPasswordInput: $resetPasswordInput) {
			success
		}
	}
`;

export function useResetPasswordMutation() {
	return Urql.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPassword);
}
export const SendConferenceCommand = gql`
	mutation sendConferenceCommand($sendUserCommandInput: SendUserCommandInput!) {
		sendConferenceCommand(sendUserCommandInput: $sendUserCommandInput) {
			created_at
			event_id
			id
			is_forced
			name
			recipient_name
			sender_name
			timestamp
			type
		}
	}
`;

export function useSendConferenceCommandMutation() {
	return Urql.useMutation<Types.SendConferenceCommandMutation, Types.SendConferenceCommandMutationVariables>(
		SendConferenceCommand
	);
}
export const SendEventCommand = gql`
	mutation sendEventCommand($sendEventCommandInput: SendEventCommandInput!) {
		sendEventCommand(sendEventCommandInput: $sendEventCommandInput) {
			created_at
			event_id
			id
			is_forced
			name
			recipient_name
			sender_name
			timestamp
			type
		}
	}
`;

export function useSendEventCommandMutation() {
	return Urql.useMutation<Types.SendEventCommandMutation, Types.SendEventCommandMutationVariables>(SendEventCommand);
}
export const SendMessage = gql`
	mutation sendMessage($sendMessageInput: SendMessageInput!) {
		sendMessage(sendMessageInput: $sendMessageInput) {
			channel_id
			conference_id
			content
			created_at
			deleted_at
			event_id
			id
			sender {
				user_id
				user_name
			}
			status
		}
	}
`;

export function useSendMessageMutation() {
	return Urql.useMutation<Types.SendMessageMutation, Types.SendMessageMutationVariables>(SendMessage);
}
export const SendQuestion = gql`
	mutation sendQuestion($sendQuestionInput: SendQuestionInput!) {
		sendQuestion(sendQuestionInput: $sendQuestionInput) {
			conference_id
			content
			created_at
			deleted_at
			edited_by {
				user_email
				user_id
				user_name
			}
			event_id
			id
			old_status
			question_pool_id
			sender {
				user_email
				user_id
				user_name
			}
			status
		}
	}
`;

export function useSendQuestionMutation() {
	return Urql.useMutation<Types.SendQuestionMutation, Types.SendQuestionMutationVariables>(SendQuestion);
}
export const SendTalkroomReaction = gql`
	mutation sendTalkroomReaction($sendTalkroomReactionInput: SendTalkroomReactionInput!) {
		sendTalkroomReaction(sendTalkroomReactionInput: $sendTalkroomReactionInput) {
			content
			created_at
			event_id
			id
			sender {
				user_id
				user_name
			}
			talkroom_id
		}
	}
`;

export function useSendTalkroomReactionMutation() {
	return Urql.useMutation<Types.SendTalkroomReactionMutation, Types.SendTalkroomReactionMutationVariables>(
		SendTalkroomReaction
	);
}
export const SendUserCommand = gql`
	mutation sendUserCommand($sendUserCommandInput: SendUserCommandInput!) {
		sendUserCommand(sendUserCommandInput: $sendUserCommandInput) {
			created_at
			event_id
			id
			is_forced
			name
			recipient_name
			sender_name
			timestamp
			type
		}
	}
`;

export function useSendUserCommandMutation() {
	return Urql.useMutation<Types.SendUserCommandMutation, Types.SendUserCommandMutationVariables>(SendUserCommand);
}
export const SignIn = gql`
	mutation signIn($signInInput: SignInInput!) {
		signIn(signInInput: $signInInput) {
			access_token
			session_id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
		}
	}
`;

export function useSignInMutation() {
	return Urql.useMutation<Types.SignInMutation, Types.SignInMutationVariables>(SignIn);
}
export const SignInNoPassword = gql`
	mutation signInNoPassword($signInNoPasswordInput: SignInNoPasswordInput!) {
		signInNoPassword(signInNoPasswordInput: $signInNoPasswordInput) {
			access_token
			session_id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
		}
	}
`;

export function useSignInNoPasswordMutation() {
	return Urql.useMutation<Types.SignInNoPasswordMutation, Types.SignInNoPasswordMutationVariables>(SignInNoPassword);
}
export const SignUp = gql`
	mutation signUp($signUpInput: SignUpInput!) {
		signUp(signUpInput: $signUpInput) {
			success
		}
	}
`;

export function useSignUpMutation() {
	return Urql.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(SignUp);
}
export const SignUpNoPassword = gql`
	mutation signUpNoPassword($signUpNoPasswordInput: SignUpNoPasswordInput!) {
		signUpNoPassword(signUpNoPasswordInput: $signUpNoPasswordInput) {
			access_token
			session_id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
		}
	}
`;

export function useSignUpNoPasswordMutation() {
	return Urql.useMutation<Types.SignUpNoPasswordMutation, Types.SignUpNoPasswordMutationVariables>(SignUpNoPassword);
}
export const StartCollaborativeBroadcast = gql`
	mutation startCollaborativeBroadcast($startCollaborativeBroadcastInput: StartCollaborativeBroadcastInput!) {
		startCollaborativeBroadcast(startCollaborativeBroadcastInput: $startCollaborativeBroadcastInput) {
			backstages {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			broadcast_status
			broadcast_stream_id
			conference_id
			event_id
			is_enabled
			layout
			stage {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
				}
				raised_hands {
					raised_at
				}
				reserved_seats
				type
			}
		}
	}
`;

export function useStartCollaborativeBroadcastMutation() {
	return Urql.useMutation<
		Types.StartCollaborativeBroadcastMutation,
		Types.StartCollaborativeBroadcastMutationVariables
	>(StartCollaborativeBroadcast);
}
export const StopCollaborativeBroadcast = gql`
	mutation stopCollaborativeBroadcast {
		stopCollaborativeBroadcast {
			backstages {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			broadcast_status
			broadcast_stream_id
			conference_id
			event_id
			is_enabled
			layout
			stage {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
				}
				raised_hands {
					raised_at
				}
				reserved_seats
				type
			}
		}
	}
`;

export function useStopCollaborativeBroadcastMutation() {
	return Urql.useMutation<Types.StopCollaborativeBroadcastMutation, Types.StopCollaborativeBroadcastMutationVariables>(
		StopCollaborativeBroadcast
	);
}
export const ToggleHighlightedParticipantInTalkroom = gql`
	mutation toggleHighlightedParticipantInTalkroom(
		$toggleHighlightedParticipantInput: ToggleHighlightedParticipantInput!
	) {
		toggleHighlightedParticipantInTalkroom(toggleHighlightedParticipantInput: $toggleHighlightedParticipantInput) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useToggleHighlightedParticipantInTalkroomMutation() {
	return Urql.useMutation<
		Types.ToggleHighlightedParticipantInTalkroomMutation,
		Types.ToggleHighlightedParticipantInTalkroomMutationVariables
	>(ToggleHighlightedParticipantInTalkroom);
}
export const ToggleHighlightedQuestion = gql`
	mutation toggleHighlightedQuestion($toggleHighlightedQuestionInput: ToggleHighlightedQuestionInput!) {
		toggleHighlightedQuestion(toggleHighlightedQuestionInput: $toggleHighlightedQuestionInput) {
			conference_id
			created_at
			description
			event_id
			highlighted_question_id
			id
			name
		}
	}
`;

export function useToggleHighlightedQuestionMutation() {
	return Urql.useMutation<Types.ToggleHighlightedQuestionMutation, Types.ToggleHighlightedQuestionMutationVariables>(
		ToggleHighlightedQuestion
	);
}
export const ToggleRaisedHandInTalkroom = gql`
	mutation toggleRaisedHandInTalkroom($toggleRaisedHandInput: ToggleRaisedHandInput!) {
		toggleRaisedHandInTalkroom(toggleRaisedHandInput: $toggleRaisedHandInput) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useToggleRaisedHandInTalkroomMutation() {
	return Urql.useMutation<Types.ToggleRaisedHandInTalkroomMutation, Types.ToggleRaisedHandInTalkroomMutationVariables>(
		ToggleRaisedHandInTalkroom
	);
}
export const UnassignPublicChannel = gql`
	mutation unassignPublicChannel($unassignPublicChannelInput: UnassignPublicChannelInput!) {
		unassignPublicChannel(unassignPublicChannelInput: $unassignPublicChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useUnassignPublicChannelMutation() {
	return Urql.useMutation<Types.UnassignPublicChannelMutation, Types.UnassignPublicChannelMutationVariables>(
		UnassignPublicChannel
	);
}
export const UpdateConference = gql`
	mutation updateConference($id: UUID!, $updateConferenceInput: UpdateConferenceInput!) {
		updateConference(id: $id, updateConferenceInput: $updateConferenceInput) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useUpdateConferenceMutation() {
	return Urql.useMutation<Types.UpdateConferenceMutation, Types.UpdateConferenceMutationVariables>(UpdateConference);
}
export const UpdateEvent = gql`
	mutation updateEvent($updateEventInput: UpdateEventInput!) {
		updateEvent(updateEventInput: $updateEventInput) {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useUpdateEventMutation() {
	return Urql.useMutation<Types.UpdateEventMutation, Types.UpdateEventMutationVariables>(UpdateEvent);
}
export const UpdateEventConfigTheme = gql`
	mutation updateEventConfigTheme($event_id: UUID!, $updateEventConfigThemeInput: UpdateEventConfigThemeInput!) {
		updateEventConfigTheme(event_id: $event_id, updateEventConfigThemeInput: $updateEventConfigThemeInput) {
			auth_methods {
				type
			}
			event_id
			is_geo_restricted
			theme {
				background {
					default
					paper
				}
				contrastThreshold
				primary {
					contrastText
					dark
					light
					main
				}
				secondary {
					contrastText
					dark
					light
					main
				}
				tonalOffset {
					dark
					light
				}
			}
			type
		}
	}
`;

export function useUpdateEventConfigThemeMutation() {
	return Urql.useMutation<Types.UpdateEventConfigThemeMutation, Types.UpdateEventConfigThemeMutationVariables>(
		UpdateEventConfigTheme
	);
}
export const UpdatePassword = gql`
	mutation updatePassword($updatePasswordInput: UpdatePasswordInput!) {
		updatePassword(updatePasswordInput: $updatePasswordInput) {
			success
		}
	}
`;

export function useUpdatePasswordMutation() {
	return Urql.useMutation<Types.UpdatePasswordMutation, Types.UpdatePasswordMutationVariables>(UpdatePassword);
}
export const UpdatePublicChannel = gql`
	mutation updatePublicChannel($id: UUID!, $updatePublicChannelInput: UpdatePublicChannelInput!) {
		updatePublicChannel(id: $id, updatePublicChannelInput: $updatePublicChannelInput) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useUpdatePublicChannelMutation() {
	return Urql.useMutation<Types.UpdatePublicChannelMutation, Types.UpdatePublicChannelMutationVariables>(
		UpdatePublicChannel
	);
}
export const UpdateQuestionPool = gql`
	mutation updateQuestionPool($question_pool_id: UUID!, $updateQuestionPoolInput: UpdateQuestionPoolInput!) {
		updateQuestionPool(question_pool_id: $question_pool_id, updateQuestionPoolInput: $updateQuestionPoolInput) {
			conference_id
			created_at
			description
			event_id
			highlighted_question_id
			id
			name
		}
	}
`;

export function useUpdateQuestionPoolMutation() {
	return Urql.useMutation<Types.UpdateQuestionPoolMutation, Types.UpdateQuestionPoolMutationVariables>(
		UpdateQuestionPool
	);
}
export const UpdateStream = gql`
	mutation updateStream($id: UUID!, $updateStreamInput: UpdateStreamInput!) {
		updateStream(id: $id, updateStreamInput: $updateStreamInput) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useUpdateStreamMutation() {
	return Urql.useMutation<Types.UpdateStreamMutation, Types.UpdateStreamMutationVariables>(UpdateStream);
}
export const UpdateUser = gql`
	mutation updateUser($updateUserInput: UpdateUserInput!) {
		updateUser(updateUserInput: $updateUserInput) {
			created_at
			event_id
			id
			profile {
				event_id
				id
				information
				language
				seen_first
				seen_last
				seen_updated
				user_email
				user_name
			}
			reset_token
			reset_token_expiration_time
			roleNames
			updated_at
			userEventId
			userProfileId
			user_email
			user_name
		}
	}
`;

export function useUpdateUserMutation() {
	return Urql.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUser);
}
export const UpdateUserSessionGeolocation = gql`
	mutation updateUserSessionGeolocation($updateUserSessionGeolocationInput: UpdateUserSessionGeolocationInput!) {
		updateUserSessionGeolocation(updateUserSessionGeolocationInput: $updateUserSessionGeolocationInput) {
			created_at
			event_id
			position {
				latitude
				longitude
			}
		}
	}
`;

export function useUpdateUserSessionGeolocationMutation() {
	return Urql.useMutation<
		Types.UpdateUserSessionGeolocationMutation,
		Types.UpdateUserSessionGeolocationMutationVariables
	>(UpdateUserSessionGeolocation);
}
export const UpsertCollaborativeBroadcastConfiguration = gql`
	mutation upsertCollaborativeBroadcastConfiguration(
		$conference_id: String!
		$upsertCollaborativeBroadcastConfigurationInput: UpsertCollaborativeBroadcastConfigurationInput!
	) {
		upsertCollaborativeBroadcastConfiguration(
			conference_id: $conference_id
			upsertCollaborativeBroadcastConfigurationInput: $upsertCollaborativeBroadcastConfigurationInput
		) {
			backstages {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			broadcast_status
			broadcast_stream_id
			conference_id
			event_id
			is_enabled
			layout
			stage {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
				}
				raised_hands {
					raised_at
				}
				reserved_seats
				type
			}
		}
	}
`;

export function useUpsertCollaborativeBroadcastConfigurationMutation() {
	return Urql.useMutation<
		Types.UpsertCollaborativeBroadcastConfigurationMutation,
		Types.UpsertCollaborativeBroadcastConfigurationMutationVariables
	>(UpsertCollaborativeBroadcastConfiguration);
}
export const UserSessionJoinTable = gql`
	mutation userSessionJoinTable($userSessionJoinTableInput: UserSessionJoinTableInput!) {
		userSessionJoinTable(userSessionJoinTableInput: $userSessionJoinTableInput) {
			created_at
			event_id
			id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
			user_id
			user_name
			user_table {
				created_at
				id
				is_force_mute
				is_mute
				table {
					config_overwrite
					created_at
					event_id
					group_ref
					icon
					id
					isPrivate
					max_participants
					name
					order
					updated_at
				}
				table_id
				updated_at
				user_session_id
			}
			user_table_id
		}
	}
`;

export function useUserSessionJoinTableMutation() {
	return Urql.useMutation<Types.UserSessionJoinTableMutation, Types.UserSessionJoinTableMutationVariables>(
		UserSessionJoinTable
	);
}
export const UserSessionLeaveTable = gql`
	mutation userSessionLeaveTable {
		userSessionLeaveTable {
			created_at
			event_id
			id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
			user_id
			user_name
			user_table {
				created_at
				id
				is_force_mute
				is_mute
				table {
					config_overwrite
					created_at
					event_id
					group_ref
					icon
					id
					isPrivate
					max_participants
					name
					order
					updated_at
				}
				table_id
				updated_at
				user_session_id
			}
			user_table_id
		}
	}
`;

export function useUserSessionLeaveTableMutation() {
	return Urql.useMutation<Types.UserSessionLeaveTableMutation, Types.UserSessionLeaveTableMutationVariables>(
		UserSessionLeaveTable
	);
}
export const ValidateMessage = gql`
	mutation validateMessage($message_id: UUID!) {
		validateMessage(message_id: $message_id)
	}
`;

export function useValidateMessageMutation() {
	return Urql.useMutation<Types.ValidateMessageMutation, Types.ValidateMessageMutationVariables>(ValidateMessage);
}
export const GetChannel = gql`
	query getChannel($id: UUID!) {
		getChannel(id: $id) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useGetChannelQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetChannelQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetChannelQuery>({ query: GetChannel, ...options });
}
export const GetCollaborativeBroadcastConfiguration = gql`
	query getCollaborativeBroadcastConfiguration($conference_id: UUID!) {
		getCollaborativeBroadcastConfiguration(conference_id: $conference_id) {
			backstages {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			broadcast_status
			broadcast_stream_id
			conference_id
			event_id
			is_enabled
			layout
			stage {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
				}
				raised_hands {
					raised_at
				}
				reserved_seats
				type
			}
		}
	}
`;

export function useGetCollaborativeBroadcastConfigurationQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetCollaborativeBroadcastConfigurationQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetCollaborativeBroadcastConfigurationQuery>({
		query: GetCollaborativeBroadcastConfiguration,
		...options
	});
}
export const GetConference = gql`
	query getConference($id: UUID!) {
		getConference(id: $id) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useGetConferenceQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetConferenceQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetConferenceQuery>({ query: GetConference, ...options });
}
export const GetCurrentUser = gql`
	query getCurrentUser($access_token: String) {
		getCurrentUser(access_token: $access_token) {
			created_at
			event_id
			id
			profile {
				event_id
				id
				information
				language
				seen_first
				seen_last
				seen_updated
				user_email
				user_name
			}
			reset_token
			reset_token_expiration_time
			roleNames
			updated_at
			userEventId
			userProfileId
			user_email
			user_name
		}
	}
`;

export function useGetCurrentUserQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetCurrentUserQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetCurrentUserQuery>({ query: GetCurrentUser, ...options });
}
export const GetEventByName = gql`
	query getEventByName($event_name: String!) {
		getEventByName(event_name: $event_name) {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useGetEventByNameQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetEventByNameQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetEventByNameQuery>({ query: GetEventByName, ...options });
}
export const GetEventUploadPresignedUrl = gql`
	query getEventUploadPresignedUrl($file_name: FileName!) {
		getEventUploadPresignedUrl(file_name: $file_name)
	}
`;

export function useGetEventUploadPresignedUrlQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetEventUploadPresignedUrlQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetEventUploadPresignedUrlQuery>({ query: GetEventUploadPresignedUrl, ...options });
}
export const GetEventWithConfigByName = gql`
	query getEventWithConfigByName($event_name: String!) {
		getEventWithConfigByName(event_name: $event_name) {
			event {
				created_at
				date_end
				date_start
				event_state
				guestAccess
				hasEventPassword
				id
				name
				noPassword
				social_links {
					id
					name
					order
					type
					url
				}
				updated_at
			}
			eventConfig {
				auth_methods {
					type
				}
				event_id
				is_geo_restricted
				theme {
					background {
						default
						paper
					}
					contrastThreshold
					primary {
						contrastText
						dark
						light
						main
					}
					secondary {
						contrastText
						dark
						light
						main
					}
					tonalOffset {
						dark
						light
					}
				}
				type
			}
		}
	}
`;

export function useGetEventWithConfigByNameQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetEventWithConfigByNameQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetEventWithConfigByNameQuery>({ query: GetEventWithConfigByName, ...options });
}
export const GetFile = gql`
	query getFile($id: UUID!) {
		getFile(id: $id) {
			content_type
			created_at
			event_id
			id
			name
			owner {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			owner_id
			parent_folder_id
			updated_at
			url
		}
	}
`;

export function useGetFileQuery(options: Omit<Urql.UseQueryArgs<never, Types.GetFileQueryVariables>, 'query'> = {}) {
	return Urql.useQuery<Types.GetFileQuery>({ query: GetFile, ...options });
}
export const GetFileStorage = gql`
	query getFileStorage($getFileStorageInput: GetFileStorageInput) {
		getFileStorage(getFileStorageInput: $getFileStorageInput) {
			files {
				content_type
				created_at
				event_id
				id
				name
				owner {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				owner_id
				parent_folder_id
				updated_at
				url
			}
			folders {
				created_at
				event_id
				files {
					content_type
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					id
					links {
						created_at
						event_id
						id
						name
						owner {
							created_at
							event_id
							id
							reset_token
							reset_token_expiration_time
							roleNames
							updated_at
							userEventId
							userProfileId
							user_email
							user_name
						}
						owner_id
						parent_folder_id
						updated_at
						url
					}
					name
					parent_folder_id
					path
					updated_at
				}
				id
				name
				parent_folder_id
				path
				updated_at
			}
			links {
				created_at
				event_id
				id
				name
				owner_id
				parent_folder_id
				updated_at
				url
			}
		}
	}
`;

export function useGetFileStorageQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetFileStorageQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetFileStorageQuery>({ query: GetFileStorage, ...options });
}
export const GetLink = gql`
	query getLink($id: UUID!) {
		getLink(id: $id) {
			created_at
			event_id
			id
			name
			owner {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			owner_id
			parent_folder_id
			updated_at
			url
		}
	}
`;

export function useGetLinkQuery(options: Omit<Urql.UseQueryArgs<never, Types.GetLinkQueryVariables>, 'query'> = {}) {
	return Urql.useQuery<Types.GetLinkQuery>({ query: GetLink, ...options });
}
export const GetQuestion = gql`
	query getQuestion($question_id: UUID!) {
		getQuestion(question_id: $question_id) {
			conference_id
			content
			created_at
			deleted_at
			edited_by {
				user_email
				user_id
				user_name
			}
			event_id
			id
			old_status
			question_pool_id
			sender {
				user_email
				user_id
				user_name
			}
			status
		}
	}
`;

export function useGetQuestionQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetQuestionQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetQuestionQuery>({ query: GetQuestion, ...options });
}
export const GetQuestionPool = gql`
	query getQuestionPool($question_pool_id: UUID!) {
		getQuestionPool(question_pool_id: $question_pool_id) {
			conference_id
			created_at
			description
			event_id
			highlighted_question_id
			id
			name
		}
	}
`;

export function useGetQuestionPoolQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetQuestionPoolQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetQuestionPoolQuery>({ query: GetQuestionPool, ...options });
}
export const GetStream = gql`
	query getStream($id: UUID!) {
		getStream(id: $id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useGetStreamQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetStreamQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetStreamQuery>({ query: GetStream, ...options });
}
export const GetStreamProviderInfo = gql`
	query getStreamProviderInfo($id: UUID!) {
		getStreamProviderInfo(id: $id) {
			type
		}
	}
`;

export function useGetStreamProviderInfoQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetStreamProviderInfoQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetStreamProviderInfoQuery>({ query: GetStreamProviderInfo, ...options });
}
export const GetUser = gql`
	query getUser($getUserInput: GetUserInput!) {
		getUser(getUserInput: $getUserInput) {
			created_at
			event_id
			id
			profile {
				event_id
				id
				information
				language
				seen_first
				seen_last
				seen_updated
				user_email
				user_name
			}
			reset_token
			reset_token_expiration_time
			roleNames
			updated_at
			userEventId
			userProfileId
			user_email
			user_name
		}
	}
`;

export function useGetUserQuery(options: Omit<Urql.UseQueryArgs<never, Types.GetUserQueryVariables>, 'query'> = {}) {
	return Urql.useQuery<Types.GetUserQuery>({ query: GetUser, ...options });
}
export const GetUserSession = gql`
	query getUserSession($getUserSessionInput: GetUserSessionInput!) {
		getUserSession(getUserSessionInput: $getUserSessionInput) {
			created_at
			event_id
			id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
			user_id
			user_name
			user_table {
				created_at
				id
				is_force_mute
				is_mute
				table {
					config_overwrite
					created_at
					event_id
					group_ref
					icon
					id
					isPrivate
					max_participants
					name
					order
					updated_at
				}
				table_id
				updated_at
				user_session_id
			}
			user_table_id
		}
	}
`;

export function useGetUserSessionQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetUserSessionQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetUserSessionQuery>({ query: GetUserSession, ...options });
}
export const GetUserSessionCount = gql`
	query getUserSessionCount {
		getUserSessionCount
	}
`;

export function useGetUserSessionCountQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetUserSessionCountQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetUserSessionCountQuery>({ query: GetUserSessionCount, ...options });
}
export const GetUserSessionCounts = gql`
	query getUserSessionCounts($getUserSessionCountsInput: GetUserSessionCountsInput!) {
		getUserSessionCounts(getUserSessionCountsInput: $getUserSessionCountsInput) {
			avg_count
			count
			created_at
			max_count
			min_count
			timestamp
		}
	}
`;

export function useGetUserSessionCountsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.GetUserSessionCountsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.GetUserSessionCountsQuery>({ query: GetUserSessionCounts, ...options });
}
export const ListCommandHistory = gql`
	query listCommandHistory($listCommandHistoryInput: ListCommandHistoryInput!) {
		listCommandHistory(listCommandHistoryInput: $listCommandHistoryInput) {
			commandHistory {
				created_at
				event_id
				id
				is_forced
				name
				recipient_name
				sender_name
				timestamp
				type
			}
			count
			last_evaluated_key
		}
	}
`;

export function useListCommandHistoryQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListCommandHistoryQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListCommandHistoryQuery>({ query: ListCommandHistory, ...options });
}
export const ListConferences = gql`
	query listConferences {
		listConferences {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useListConferencesQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListConferencesQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListConferencesQuery>({ query: ListConferences, ...options });
}
export const ListEvents = gql`
	query listEvents($listPaginationInputArgsInput: ListPaginationInputArgsInput!) {
		listEvents(listPaginationInputArgsInput: $listPaginationInputArgsInput) {
			count
			events {
				created_at
				date_end
				date_start
				event_state
				guestAccess
				hasEventPassword
				id
				name
				noPassword
				social_links {
					id
					name
					order
					type
					url
				}
				updated_at
			}
			last_evaluated_key
		}
	}
`;

export function useListEventsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListEventsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListEventsQuery>({ query: ListEvents, ...options });
}
export const ListFiles = gql`
	query listFiles($listFilesInput: ListFilesInput!) {
		listFiles(listFilesInput: $listFilesInput) {
			content_type
			created_at
			event_id
			id
			name
			owner {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			owner_id
			parent_folder_id
			updated_at
			url
		}
	}
`;

export function useListFilesQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListFilesQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListFilesQuery>({ query: ListFiles, ...options });
}
export const ListFolders = gql`
	query listFolders($listFoldersInput: ListFoldersInput!) {
		listFolders(listFoldersInput: $listFoldersInput) {
			created_at
			event_id
			files {
				content_type
				created_at
				event_id
				id
				name
				owner {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				owner_id
				parent_folder_id
				updated_at
				url
			}
			folders {
				created_at
				event_id
				files {
					content_type
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					id
					links {
						created_at
						event_id
						id
						name
						owner {
							created_at
							event_id
							id
							reset_token
							reset_token_expiration_time
							roleNames
							updated_at
							userEventId
							userProfileId
							user_email
							user_name
						}
						owner_id
						parent_folder_id
						updated_at
						url
					}
					name
					parent_folder_id
					path
					updated_at
				}
				id
				name
				parent_folder_id
				path
				updated_at
			}
			id
			links {
				created_at
				event_id
				id
				name
				owner_id
				parent_folder_id
				updated_at
				url
			}
			name
			parent_folder_id
			path
			updated_at
		}
	}
`;

export function useListFoldersQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListFoldersQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListFoldersQuery>({ query: ListFolders, ...options });
}
export const ListGeolocations = gql`
	query listGeolocations($listGeolocationsInput: ListGeolocationsInput!) {
		listGeolocations(listGeolocationsInput: $listGeolocationsInput) {
			created_at
			event_id
			position {
				latitude
				longitude
			}
		}
	}
`;

export function useListGeolocationsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListGeolocationsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListGeolocationsQuery>({ query: ListGeolocations, ...options });
}
export const ListLinks = gql`
	query listLinks($listLinksInput: ListLinksInput!) {
		listLinks(listLinksInput: $listLinksInput) {
			created_at
			event_id
			id
			name
			owner {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			owner_id
			parent_folder_id
			updated_at
			url
		}
	}
`;

export function useListLinksQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListLinksQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListLinksQuery>({ query: ListLinks, ...options });
}
export const ListMessages = gql`
	query listMessages($listMessagesInput: ListMessagesInput!) {
		listMessages(listMessagesInput: $listMessagesInput) {
			count
			last_evaluated_key
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
		}
	}
`;

export function useListMessagesQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListMessagesQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListMessagesQuery>({ query: ListMessages, ...options });
}
export const ListModeratedMessages = gql`
	query listModeratedMessages($listMessagesInput: ListMessagesInput!) {
		listModeratedMessages(listMessagesInput: $listMessagesInput) {
			count
			last_evaluated_key
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
		}
	}
`;

export function useListModeratedMessagesQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListModeratedMessagesQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListModeratedMessagesQuery>({ query: ListModeratedMessages, ...options });
}
export const ListPublicChannels = gql`
	query listPublicChannels($conference_id: UUID!) {
		listPublicChannels(conference_id: $conference_id) {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useListPublicChannelsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListPublicChannelsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListPublicChannelsQuery>({ query: ListPublicChannels, ...options });
}
export const ListPublicStreams = gql`
	query listPublicStreams($conference_id: UUID!) {
		listPublicStreams(conference_id: $conference_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useListPublicStreamsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListPublicStreamsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListPublicStreamsQuery>({ query: ListPublicStreams, ...options });
}
export const ListQuestions = gql`
	query listQuestions($listQuestionsInput: ListQuestionsInput!) {
		listQuestions(listQuestionsInput: $listQuestionsInput) {
			count
			last_evaluated_key
			questions {
				conference_id
				content
				created_at
				deleted_at
				edited_by {
					user_email
					user_id
					user_name
				}
				event_id
				id
				old_status
				question_pool_id
				sender {
					user_email
					user_id
					user_name
				}
				status
			}
		}
	}
`;

export function useListQuestionsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListQuestionsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListQuestionsQuery>({ query: ListQuestions, ...options });
}
export const ListStreams = gql`
	query listStreams($conference_id: UUID!) {
		listStreams(conference_id: $conference_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useListStreamsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListStreamsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListStreamsQuery>({ query: ListStreams, ...options });
}
export const ListTalkrooms = gql`
	query listTalkrooms($conference_id: UUID!) {
		listTalkrooms(conference_id: $conference_id) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useListTalkroomsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListTalkroomsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListTalkroomsQuery>({ query: ListTalkrooms, ...options });
}
export const ListUserAssignedConferences = gql`
	query listUserAssignedConferences($user_id: UUID!) {
		listUserAssignedConferences(user_id: $user_id) {
			channel_id
			created_at
			date_end
			date_start
			description
			event_id
			file_storage {
				files {
					content_type
					created_at
					event_id
					id
					name
					owner {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					owner_id
					parent_folder_id
					updated_at
					url
				}
				folders {
					created_at
					event_id
					files {
						content_type
						created_at
						event_id
						id
						name
						owner_id
						parent_folder_id
						updated_at
						url
					}
					folders {
						created_at
						event_id
						id
						links {
							created_at
							event_id
							id
							name
							owner {
								created_at
								event_id
								id
								reset_token
								reset_token_expiration_time
								roleNames
								updated_at
								userEventId
								userProfileId
								user_email
								user_name
							}
							owner_id
							parent_folder_id
							updated_at
							url
						}
						name
						parent_folder_id
						path
						updated_at
					}
					id
					name
					parent_folder_id
					path
					updated_at
				}
				links {
					created_at
					event_id
					id
					name
					owner_id
					parent_folder_id
					updated_at
					url
				}
			}
			frames {
				conference_id
				created_at
				event_id
				id
				type
				url
			}
			id
			name
			question_pool {
				conference_id
				created_at
				description
				event_id
				highlighted_question_id
				id
				name
			}
			streams {
				closed_caption_provider
				closed_captions {
					id
					type
				}
				conference_id
				created_at
				event_id
				id
				is_private
				name
				order
				provider
				status
				type
				url
			}
			talkrooms {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			type
		}
	}
`;

export function useListUserAssignedConferencesQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListUserAssignedConferencesQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListUserAssignedConferencesQuery>({ query: ListUserAssignedConferences, ...options });
}
export const ListUserChannels = gql`
	query listUserChannels {
		listUserChannels {
			channels {
				conference_id
				created_at
				event_id
				id
				messages {
					channel_id
					conference_id
					content
					created_at
					deleted_at
					event_id
					id
					sender {
						user_id
						user_name
					}
					status
				}
				moderation
				name
				talkroom_id
				type
				users {
					created_at
					user {
						created_at
						event_id
						id
						profile {
							event_id
							id
							information
							language
							seen_first
							seen_last
							seen_updated
							user_email
							user_name
						}
						reset_token
						reset_token_expiration_time
						roleNames
						updated_at
						userEventId
						userProfileId
						user_email
						user_name
					}
					user_id
				}
			}
			event_id
			user_id
		}
	}
`;

export function useListUserChannelsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListUserChannelsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListUserChannelsQuery>({ query: ListUserChannels, ...options });
}
export const ListUserSessionLogs = gql`
	query listUserSessionLogs($listUserSessionLogs: ListUserSessionLogsArgs!) {
		listUserSessionLogs(listUserSessionLogs: $listUserSessionLogs) {
			count
			last_evaluated_key
			user_session_logs {
				city
				country
				created_at
				event_id
				id
				logged_in
				logged_out
				session_duration
				user_email
				user_name
			}
		}
	}
`;

export function useListUserSessionLogsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListUserSessionLogsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListUserSessionLogsQuery>({ query: ListUserSessionLogs, ...options });
}
export const ListUserSessions = gql`
	query listUserSessions($listUserSessionsInput: ListUserSessionsInput!) {
		listUserSessions(listUserSessionsInput: $listUserSessionsInput) {
			count
			last_evaluated_key
			user_sessions {
				created_at
				event_id
				id
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_email
				user_id
				user_name
				user_table {
					created_at
					id
					is_force_mute
					is_mute
					table {
						config_overwrite
						created_at
						event_id
						group_ref
						icon
						id
						isPrivate
						max_participants
						name
						order
						updated_at
					}
					table_id
					updated_at
					user_session_id
				}
				user_table_id
			}
		}
	}
`;

export function useListUserSessionsQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListUserSessionsQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListUserSessionsQuery>({ query: ListUserSessions, ...options });
}
export const ListUsers = gql`
	query listUsers($list: ListUsersArgs!) {
		listUsers(list: $list) {
			count
			last_evaluated_key
			users {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
		}
	}
`;

export function useListUsersQuery(
	options: Omit<Urql.UseQueryArgs<never, Types.ListUsersQueryVariables>, 'query'> = {}
) {
	return Urql.useQuery<Types.ListUsersQuery>({ query: ListUsers, ...options });
}
export const JoinConference = gql`
	subscription joinConference($conference_id: UUID!) {
		joinConference(conference_id: $conference_id)
	}
`;

export function useJoinConferenceSubscription<R = Types.JoinConferenceSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.JoinConferenceSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.JoinConferenceSubscription, R>
) {
	return Urql.useSubscription<Types.JoinConferenceSubscription, R, Types.JoinConferenceSubscriptionVariables>(
		{ query: JoinConference, ...options },
		handler
	);
}
export const OnChannelCreated = gql`
	subscription onChannelCreated {
		onChannelCreated {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useOnChannelCreatedSubscription<R = Types.OnChannelCreatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnChannelCreatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnChannelCreatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnChannelCreatedSubscription, R, Types.OnChannelCreatedSubscriptionVariables>(
		{ query: OnChannelCreated, ...options },
		handler
	);
}
export const OnChannelUpdated = gql`
	subscription onChannelUpdated {
		onChannelUpdated {
			conference_id
			created_at
			event_id
			id
			messages {
				channel_id
				conference_id
				content
				created_at
				deleted_at
				event_id
				id
				sender {
					user_id
					user_name
				}
				status
			}
			moderation
			name
			talkroom_id
			type
			users {
				created_at
				user {
					created_at
					event_id
					id
					profile {
						event_id
						id
						information
						language
						seen_first
						seen_last
						seen_updated
						user_email
						user_name
					}
					reset_token
					reset_token_expiration_time
					roleNames
					updated_at
					userEventId
					userProfileId
					user_email
					user_name
				}
				user_id
			}
		}
	}
`;

export function useOnChannelUpdatedSubscription<R = Types.OnChannelUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnChannelUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnChannelUpdatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnChannelUpdatedSubscription, R, Types.OnChannelUpdatedSubscriptionVariables>(
		{ query: OnChannelUpdated, ...options },
		handler
	);
}
export const OnCollaborativeBroadcastConfigurationUpdated = gql`
	subscription onCollaborativeBroadcastConfigurationUpdated(
		$onCollaborativeBroadcastConfigurationUpdatedInput: OnCollaborativeBroadcastConfigurationUpdatedInput!
	) {
		onCollaborativeBroadcastConfigurationUpdated(
			onCollaborativeBroadcastConfigurationUpdatedInput: $onCollaborativeBroadcastConfigurationUpdatedInput
		) {
			backstages {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
					participants {
						user_id
						user_name
					}
				}
				raised_hands {
					participant {
						user_id
						user_name
					}
					raised_at
				}
				reserved_seats
				type
			}
			broadcast_status
			broadcast_stream_id
			conference_id
			event_id
			is_enabled
			layout
			stage {
				channel_id
				conference_id
				created_at
				event_id
				highlighted_participant_id
				id
				is_locked
				max_participants
				name
				order
				presence {
					participant_number
				}
				raised_hands {
					raised_at
				}
				reserved_seats
				type
			}
		}
	}
`;

export function useOnCollaborativeBroadcastConfigurationUpdatedSubscription<
	R = Types.OnCollaborativeBroadcastConfigurationUpdatedSubscription
>(
	options: Omit<
		Urql.UseSubscriptionArgs<never, Types.OnCollaborativeBroadcastConfigurationUpdatedSubscriptionVariables>,
		'query'
	> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnCollaborativeBroadcastConfigurationUpdatedSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnCollaborativeBroadcastConfigurationUpdatedSubscription,
		R,
		Types.OnCollaborativeBroadcastConfigurationUpdatedSubscriptionVariables
	>({ query: OnCollaborativeBroadcastConfigurationUpdated, ...options }, handler);
}
export const OnCollaborativeBroadcastInviteSent = gql`
	subscription onCollaborativeBroadcastInviteSent(
		$onCollaborativeBroadcastInviteSentInput: OnCollaborativeBroadcastInviteSentInput!
	) {
		onCollaborativeBroadcastInviteSent(
			onCollaborativeBroadcastInviteSentInput: $onCollaborativeBroadcastInviteSentInput
		) {
			backstage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					presence {
						participant_number
						participants {
							user_id
							user_name
						}
					}
					raised_hands {
						participant {
							user_id
							user_name
						}
						raised_at
					}
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			conference_id
			event_id
			stage {
				talkroom {
					channel_id
					conference_id
					created_at
					event_id
					highlighted_participant_id
					id
					is_locked
					max_participants
					name
					order
					reserved_seats
					type
				}
				talkroom_token
				user_id
			}
			user_id
		}
	}
`;

export function useOnCollaborativeBroadcastInviteSentSubscription<
	R = Types.OnCollaborativeBroadcastInviteSentSubscription
>(
	options: Omit<
		Urql.UseSubscriptionArgs<never, Types.OnCollaborativeBroadcastInviteSentSubscriptionVariables>,
		'query'
	> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnCollaborativeBroadcastInviteSentSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnCollaborativeBroadcastInviteSentSubscription,
		R,
		Types.OnCollaborativeBroadcastInviteSentSubscriptionVariables
	>({ query: OnCollaborativeBroadcastInviteSent, ...options }, handler);
}
export const OnConferenceCommandSent = gql`
	subscription onConferenceCommandSent($conference_id: UUID!) {
		onConferenceCommandSent(conference_id: $conference_id) {
			event_id
			id
			is_forced
			name
			receiver_id
			type
		}
	}
`;

export function useOnConferenceCommandSentSubscription<R = Types.OnConferenceCommandSentSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnConferenceCommandSentSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnConferenceCommandSentSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnConferenceCommandSentSubscription,
		R,
		Types.OnConferenceCommandSentSubscriptionVariables
	>({ query: OnConferenceCommandSent, ...options }, handler);
}
export const OnEventCommandSent = gql`
	subscription onEventCommandSent {
		onEventCommandSent {
			event_id
			id
			is_forced
			name
			receiver_id
			type
		}
	}
`;

export function useOnEventCommandSentSubscription<R = Types.OnEventCommandSentSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnEventCommandSentSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnEventCommandSentSubscription, R>
) {
	return Urql.useSubscription<Types.OnEventCommandSentSubscription, R, Types.OnEventCommandSentSubscriptionVariables>(
		{ query: OnEventCommandSent, ...options },
		handler
	);
}
export const OnEventUpdated = gql`
	subscription onEventUpdated {
		onEventUpdated {
			created_at
			date_end
			date_start
			event_state
			guestAccess
			hasEventPassword
			id
			name
			noPassword
			social_links {
				id
				name
				order
				type
				url
			}
			updated_at
		}
	}
`;

export function useOnEventUpdatedSubscription<R = Types.OnEventUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnEventUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnEventUpdatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnEventUpdatedSubscription, R, Types.OnEventUpdatedSubscriptionVariables>(
		{ query: OnEventUpdated, ...options },
		handler
	);
}
export const OnMessageCreated = gql`
	subscription onMessageCreated($channel_id: UUID, $conference_id: UUID) {
		onMessageCreated(channel_id: $channel_id, conference_id: $conference_id) {
			channel_id
			conference_id
			content
			created_at
			deleted_at
			event_id
			id
			sender {
				user_id
				user_name
			}
			status
		}
	}
`;

export function useOnMessageCreatedSubscription<R = Types.OnMessageCreatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnMessageCreatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnMessageCreatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnMessageCreatedSubscription, R, Types.OnMessageCreatedSubscriptionVariables>(
		{ query: OnMessageCreated, ...options },
		handler
	);
}
export const OnMessageDeleted = gql`
	subscription onMessageDeleted($channel_id: UUID, $conference_id: UUID) {
		onMessageDeleted(channel_id: $channel_id, conference_id: $conference_id) {
			channel_id
			conference_id
			event_id
			id
		}
	}
`;

export function useOnMessageDeletedSubscription<R = Types.OnMessageDeletedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnMessageDeletedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnMessageDeletedSubscription, R>
) {
	return Urql.useSubscription<Types.OnMessageDeletedSubscription, R, Types.OnMessageDeletedSubscriptionVariables>(
		{ query: OnMessageDeleted, ...options },
		handler
	);
}
export const OnMessageModerated = gql`
	subscription onMessageModerated($channel_id: UUID!) {
		onMessageModerated(channel_id: $channel_id) {
			channel_id
			conference_id
			content
			created_at
			deleted_at
			event_id
			id
			sender {
				user_id
				user_name
			}
			status
		}
	}
`;

export function useOnMessageModeratedSubscription<R = Types.OnMessageModeratedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnMessageModeratedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnMessageModeratedSubscription, R>
) {
	return Urql.useSubscription<Types.OnMessageModeratedSubscription, R, Types.OnMessageModeratedSubscriptionVariables>(
		{ query: OnMessageModerated, ...options },
		handler
	);
}
export const OnQuestionPoolUpdated = gql`
	subscription onQuestionPoolUpdated($onQuestionPoolUpdatedInput: OnQuestionPoolUpdatedInput!) {
		onQuestionPoolUpdated(onQuestionPoolUpdatedInput: $onQuestionPoolUpdatedInput) {
			conference_id
			created_at
			description
			event_id
			highlighted_question_id
			id
			name
		}
	}
`;

export function useOnQuestionPoolUpdatedSubscription<R = Types.OnQuestionPoolUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnQuestionPoolUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnQuestionPoolUpdatedSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnQuestionPoolUpdatedSubscription,
		R,
		Types.OnQuestionPoolUpdatedSubscriptionVariables
	>({ query: OnQuestionPoolUpdated, ...options }, handler);
}
export const OnQuestionUpdated = gql`
	subscription onQuestionUpdated($onQuestionUpdatedInput: OnQuestionUpdatedInput!) {
		onQuestionUpdated(onQuestionUpdatedInput: $onQuestionUpdatedInput) {
			conference_id
			content
			created_at
			deleted_at
			edited_by {
				user_email
				user_id
				user_name
			}
			event_id
			id
			old_status
			question_pool_id
			sender {
				user_email
				user_id
				user_name
			}
			status
		}
	}
`;

export function useOnQuestionUpdatedSubscription<R = Types.OnQuestionUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnQuestionUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnQuestionUpdatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnQuestionUpdatedSubscription, R, Types.OnQuestionUpdatedSubscriptionVariables>(
		{ query: OnQuestionUpdated, ...options },
		handler
	);
}
export const OnStreamCreated = gql`
	subscription onStreamCreated($conference_id: UUID!) {
		onStreamCreated(conference_id: $conference_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useOnStreamCreatedSubscription<R = Types.OnStreamCreatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnStreamCreatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnStreamCreatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnStreamCreatedSubscription, R, Types.OnStreamCreatedSubscriptionVariables>(
		{ query: OnStreamCreated, ...options },
		handler
	);
}
export const OnStreamDeleted = gql`
	subscription onStreamDeleted($conference_id: UUID!) {
		onStreamDeleted(conference_id: $conference_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useOnStreamDeletedSubscription<R = Types.OnStreamDeletedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnStreamDeletedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnStreamDeletedSubscription, R>
) {
	return Urql.useSubscription<Types.OnStreamDeletedSubscription, R, Types.OnStreamDeletedSubscriptionVariables>(
		{ query: OnStreamDeleted, ...options },
		handler
	);
}
export const OnStreamUpdated = gql`
	subscription onStreamUpdated($conference_id: UUID!) {
		onStreamUpdated(conference_id: $conference_id) {
			closed_caption_provider
			closed_captions {
				id
				type
			}
			conference_id
			created_at
			event_id
			id
			is_private
			name
			order
			provider
			status
			type
			url
		}
	}
`;

export function useOnStreamUpdatedSubscription<R = Types.OnStreamUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnStreamUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnStreamUpdatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnStreamUpdatedSubscription, R, Types.OnStreamUpdatedSubscriptionVariables>(
		{ query: OnStreamUpdated, ...options },
		handler
	);
}
export const OnTalkroomParticipantJoined = gql`
	subscription onTalkroomParticipantJoined($onTalkroomParticipantJoinedInput: OnTalkroomParticipantJoinedInput!) {
		onTalkroomParticipantJoined(onTalkroomParticipantJoinedInput: $onTalkroomParticipantJoinedInput) {
			conference_id
			event_id
			participant {
				user_id
				user_name
			}
			talkroom_id
		}
	}
`;

export function useOnTalkroomParticipantJoinedSubscription<R = Types.OnTalkroomParticipantJoinedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnTalkroomParticipantJoinedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnTalkroomParticipantJoinedSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnTalkroomParticipantJoinedSubscription,
		R,
		Types.OnTalkroomParticipantJoinedSubscriptionVariables
	>({ query: OnTalkroomParticipantJoined, ...options }, handler);
}
export const OnTalkroomParticipantLeft = gql`
	subscription onTalkroomParticipantLeft($onTalkroomParticipantLeftInput: OnTalkroomParticipantLeftInput!) {
		onTalkroomParticipantLeft(onTalkroomParticipantLeftInput: $onTalkroomParticipantLeftInput) {
			conference_id
			event_id
			participant {
				user_id
				user_name
			}
			talkroom_id
		}
	}
`;

export function useOnTalkroomParticipantLeftSubscription<R = Types.OnTalkroomParticipantLeftSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnTalkroomParticipantLeftSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnTalkroomParticipantLeftSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnTalkroomParticipantLeftSubscription,
		R,
		Types.OnTalkroomParticipantLeftSubscriptionVariables
	>({ query: OnTalkroomParticipantLeft, ...options }, handler);
}
export const OnTalkroomReactionSent = gql`
	subscription onTalkroomReactionSent($onTalkroomReactionSentInput: OnTalkroomReactionSentInput!) {
		onTalkroomReactionSent(onTalkroomReactionSentInput: $onTalkroomReactionSentInput) {
			content
			created_at
			event_id
			id
			sender {
				user_id
				user_name
			}
			talkroom_id
		}
	}
`;

export function useOnTalkroomReactionSentSubscription<R = Types.OnTalkroomReactionSentSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnTalkroomReactionSentSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnTalkroomReactionSentSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnTalkroomReactionSentSubscription,
		R,
		Types.OnTalkroomReactionSentSubscriptionVariables
	>({ query: OnTalkroomReactionSent, ...options }, handler);
}
export const OnTalkroomUpdated = gql`
	subscription onTalkroomUpdated($onTalkroomUpdatedInput: OnTalkroomUpdatedInput!) {
		onTalkroomUpdated(onTalkroomUpdatedInput: $onTalkroomUpdatedInput) {
			channel_id
			conference_id
			created_at
			event_id
			highlighted_participant_id
			id
			is_locked
			max_participants
			name
			order
			presence {
				participant_number
				participants {
					user_id
					user_name
				}
			}
			raised_hands {
				participant {
					user_id
					user_name
				}
				raised_at
			}
			reserved_seats
			type
		}
	}
`;

export function useOnTalkroomUpdatedSubscription<R = Types.OnTalkroomUpdatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnTalkroomUpdatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnTalkroomUpdatedSubscription, R>
) {
	return Urql.useSubscription<Types.OnTalkroomUpdatedSubscription, R, Types.OnTalkroomUpdatedSubscriptionVariables>(
		{ query: OnTalkroomUpdated, ...options },
		handler
	);
}
export const OnUserCommandSent = gql`
	subscription onUserCommandSent {
		onUserCommandSent {
			event_id
			id
			is_forced
			name
			receiver_id
			type
		}
	}
`;

export function useOnUserCommandSentSubscription<R = Types.OnUserCommandSentSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnUserCommandSentSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnUserCommandSentSubscription, R>
) {
	return Urql.useSubscription<Types.OnUserCommandSentSubscription, R, Types.OnUserCommandSentSubscriptionVariables>(
		{ query: OnUserCommandSent, ...options },
		handler
	);
}
export const OnUserSessionCreated = gql`
	subscription onUserSessionCreated($event_id: String) {
		onUserSessionCreated(event_id: $event_id) {
			created_at
			event_id
			id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
			user_id
			user_name
			user_table {
				created_at
				id
				is_force_mute
				is_mute
				table {
					config_overwrite
					created_at
					event_id
					group_ref
					icon
					id
					isPrivate
					max_participants
					name
					order
					updated_at
				}
				table_id
				updated_at
				user_session_id
			}
			user_table_id
		}
	}
`;

export function useOnUserSessionCreatedSubscription<R = Types.OnUserSessionCreatedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnUserSessionCreatedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnUserSessionCreatedSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnUserSessionCreatedSubscription,
		R,
		Types.OnUserSessionCreatedSubscriptionVariables
	>({ query: OnUserSessionCreated, ...options }, handler);
}
export const OnUserSessionDeleted = gql`
	subscription onUserSessionDeleted($event_id: String) {
		onUserSessionDeleted(event_id: $event_id) {
			created_at
			event_id
			id
			user {
				created_at
				event_id
				id
				profile {
					event_id
					id
					information
					language
					seen_first
					seen_last
					seen_updated
					user_email
					user_name
				}
				reset_token
				reset_token_expiration_time
				roleNames
				updated_at
				userEventId
				userProfileId
				user_email
				user_name
			}
			user_email
			user_id
			user_name
			user_table {
				created_at
				id
				is_force_mute
				is_mute
				table {
					config_overwrite
					created_at
					event_id
					group_ref
					icon
					id
					isPrivate
					max_participants
					name
					order
					updated_at
				}
				table_id
				updated_at
				user_session_id
			}
			user_table_id
		}
	}
`;

export function useOnUserSessionDeletedSubscription<R = Types.OnUserSessionDeletedSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<never, Types.OnUserSessionDeletedSubscriptionVariables>, 'query'> = {},
	handler?: Urql.SubscriptionHandlerArg<Types.OnUserSessionDeletedSubscription, R>
) {
	return Urql.useSubscription<
		Types.OnUserSessionDeletedSubscription,
		R,
		Types.OnUserSessionDeletedSubscriptionVariables
	>({ query: OnUserSessionDeleted, ...options }, handler);
}
