import { defineStore } from 'pinia';
import { RoleName } from '@/gql/types';
import { CurrentUserActionsType, CurrentUserGettersType, CurrentUserStateType } from '@/features/CurrentUser/type';

const authorizationTokenLocalStorageKey = 'authorization_token';

const getAuthorizationToken = () => {
	const authorizationToken = localStorage.getItem(authorizationTokenLocalStorageKey);
	if (authorizationToken) {
		return JSON.parse(authorizationToken);
	}
	return 'public';
};

const useCurrentUser = defineStore<string, CurrentUserStateType, CurrentUserGettersType, CurrentUserActionsType>(
	'CurrentUser',
	{
		state: () => ({
			user: null,
			isFetched: false,
			authorization_token: getAuthorizationToken(),
			isLogged: false,
			error: undefined
		}),

		getters: {
			get() {
				return this.user;
			},
			isUserAdmin() {
				return this.user?.roleNames?.some((role) => role === RoleName.Administrator);
			},
			isUserModerator() {
				return this.user?.roleNames?.some((role) => role === RoleName.Moderator);
			},
			getEventID() {
				return this.user?.event_id;
			},
			getUserID() {
				return this.user?.id;
			},
			logged() {
				return this.isLogged;
			},
			getAuthorizationToken() {
				return this.authorization_token;
			},
			getUserProfileID() {
				return this.user?.profile?.id;
			},
			getUserName() {
				return this.user?.profile?.user_name;
			}
		},

		actions: {
			setUser(user) {
				this.user = user;
				this.isFetched = true;
				this.isLogged = true;
			},
			setFetched(value) {
				this.isFetched = value;
			},
			setIsLogged(value) {
				this.isLogged = value;
			},
			setAuthorizationToken(value) {
				localStorage.setItem(authorizationTokenLocalStorageKey, JSON.stringify(value));
				this.authorization_token = value;
			}
		}
	}
);

export default useCurrentUser;
