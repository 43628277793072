export class ComponentsName {
	/* ADMIN */
	static AdminSupport = 'AdminSupport';
	static Commands = 'Commands';
	static ModeratorOps = 'ModeratorOps';
	static ModeratorPolls = 'ModeratorPolls';
	static AdminDashboard = 'AdminDashboard';
	static UsersImport = 'UsersImport';
	static UsersModify = 'UsersModify';
	static DataTable = 'DataTable';
	static AdminConfig = 'AdminConfig';
	static UserModify = 'UserModify';

	/* AUTH */
	static EventDescription = 'EventDescription';
	static SignIn = 'SignIn';
	static SignInView = 'SignInView';
	static SignInBase = 'SignInBase';
	static SignInConfirmCode = 'SignInConfirmCode';
	static SignInFirstTime = 'SignInFirstTime';
	static SignInRecovery = 'SignInRecovery';
	static SignInRecoveryView = 'SignInRecoveryView';
	static SignUp = 'SignUp';
	static SignUpView = 'SignUpView';
	static SignInPassword = 'SignInPassword';
	static SignInResetPassword = 'SignInResetPassword';
	static SignInResetPasswordView = 'SignInResetPasswordView';

	/* COMMON */
	static CloseButton = 'CloseButton';
	static HelpButton = 'HelpButton';
	static LanguageSwitcher = 'LanguageSwitcher';
	static MovingBanner = 'MovingBanner';
	static NavigationBar = 'NavigationBar';
	static ProgramSwitcher = 'ProgramSwitcher';
	static ProgramVolume = 'ProgramVolume';
	static ProgramButtons = 'ProgramButtons';
	static PlyrPlayer = 'PlyrPlayer';
	static IvsPlayer = 'IvsPlayer';
	static SidebarItem = 'SidebarItem';
	static SidebarMenu = 'SidebarMenu';
	static EmojiMenu = 'EmojiMenu';

	/* CONTROL ROOM (OPERATORS) */
	static ControlRoomStreams = 'ControlRoomStreams';
	static ControlRoomStream = 'ControlRoomStream';
	static EventManagement = 'EventManagement';
	static EventControl = 'EventControl';

	/* POLL */
	static QuestionActive = 'QuestionActive';
	static QuestionResult = 'QuestionResult';

	/* VIEWERS */
	static ProgramViewer = 'ProgramViewer';
	static ChatGlobal = 'ChatGlobal';
	static MeetViewer = 'MeetViewer';
	static PollViewer = 'PollViewer';
	static StreamViewer = 'StreamViewer';
	static TablesViewer = 'TablesViewer';
	static EventViewer = 'EventViewer';

	static ImagesViewer = 'ImagesViewer';
	static CarouselViewer = 'CarouselViewer';

	/* CHATS */

	static ChatSingle = 'ChatSingle';
	static ChatSingleModal = 'ChatSingleModal';
	static ChatSingleDrawer = 'ChatSingleDrawer';
	static ChatSingleList = 'ChatSingleList';

	/* VIEWS */
	static ExternalLinks = 'ExternalLinks';
	static ChatView = 'ChatView';
	static ControlRoom = 'ControlRoomView';
	static SSOCallback = 'SSOCallback';
	static Administrator = 'AdministratorView';
	static Authentication = 'AuthenticationView';
	static Help = 'HelpView';
	static Question = 'QuestionView';
	static Main = 'MainView';
	static NotFound = 'NotFoundView';
	static ViewLoader = 'ViewLoader';

	/* CHARTS */
	static UserPerMinuteChart = 'UserPerMinuteChart';

	/* FileStorage */
	static FileStorage = 'FileStorage';

	/* solotech carriere virtuelle */
	static JobOpenings = 'JobOpenings';
	static HRInterview = 'HRInterview';
	static SendResume = 'SendResume';
	static AboutUs = 'AboutUs';
}

export class EmitsName {
	static ChangeTheme = 'emit-change-theme';
	static UsersRetrieved = 'emit-users-retrieved';

	static VerificationCode = 'emit-verification-code';
	static FirstTimeLogin = 'emit-first-time-login';
	static ForgotPassword = 'emit-forgot-password';

	static SignUp = 'emit-register-account';
	static SignUpCancel = 'emit-register-cancel';
	static SignUpSuccess = 'emit-register-success';

	static EventDateChange = 'emit-event-date-change';

	static ToggleChat = 'emit-toggle-chat';
	static ToggleTables = 'emit-toggle-tables';
	static TogglePoll = 'emit-toggle-poll';
	static ToggleSideBarMenu = 'emit-toggle-side-bar-menu';

	static EmojiClick = 'emojiClick';

	static TableJoin = 'emit-table-join';
	static TableLeave = 'emit-table-leave';

	static SignOut = 'emit-sign-out';

	static AuthBackToSignIn = 'emit-back-to-sign-in';
	static AuthRedirect = 'emit-auth-redirect';

	static UpdateEventProgram = 'emit-update-event-program';

	static PlayerPlay = 'emit-player-play';
	static PlayerPause = 'emit-player-pause';
	static PlayerEnded = 'emit-player-ended';
	static PlayerVolumeChange = 'emit-player-volumechange';
	static PlayerError = 'emit-player-error';
	static PlayerLoaded = 'emit-player-loaded';
	static PlayerFullScreenChange = 'emit-player-fullscreenchange';
	static PlayerQualityChange = 'emit-player-qualitychange';
	static PlayerResize = 'emit-player-resize';
	static PlayerReady = 'emit-player-ready';
	static PlayerEnterFullScreen = 'emit-player-enterfullscreen';
	static PlayerExitFullScreen = 'emit-player-exitfullscreen';
	static PlayerBufferEnd = 'emit-player-bufferend';
	static PlayerAudioBlocked = 'emit-player-audioblocked';
	static PlayerPlaybackBlocked = 'emit-player-playbackblocked';
	static PlayerProgress = 'emit-player-progress';

	static ElementFullscreenChange = 'emit-fullscreen-changed';

	static ToggleDrawerChatSingle = 'emit-toggle-chat-single-drawer';
	static CloseDrawerChatSingle = 'emit-close-drawer-chat-single';
	static HandleChangeSelectedUser = 'emit-handle-change-selected-user';
	static SelectedUserForChatSingleDrawer = 'emit-selected-user-for-chat-single-drawer';
	static ToggleModalChat = 'emit-toggle-modal-chat-single';
	static ToggleChannelList = 'emit-toggle-channel-list';
	static CreateChannel = 'emit-create-channel';
	static SetContainerMessages = 'emit-set-container-messages';

	static ScrollToBottom = 'emit-scroll-to-bottom';
}
