/* Imports */
import type { Ref, ComputedRef, UnwrapRef } from 'vue';
import type { Pinia, StoreGeneric } from 'pinia';

export enum APApiCallStatus {
	pending = 'pending',
	completed = 'completed',
	errored = 'errored'
}

export enum SubscriptionStatus {
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export enum APHookName {
	before = 'before',
	after = 'after',
	error = 'error'
}

export enum APCRUDMethodName {
	get = 'get',
	find = 'find',
	create = 'create',
	update = 'update',
	delete = 'delete'
}

export enum APSubscriptionName {
	onCreate = 'onCreate',
	onUpdate = 'onUpdate',
	onDelete = 'onDelete'
}

enum APComparaisonOp {
	eq = 'eq',
	le = 'le',
	ge = 'ge',
	gt = 'gt',
	between = 'between',
	beginsWith = 'beginsWith',
	attributeExists = 'attributeExists'
}

/* Types & Interfaces */
export type Id = string;

export type APGqlOperationName = string;

export type APGenericData = Record<string, unknown>;

export interface APExtendedError extends Error {
	errors?: Error[];
	error?: Error;
}

export interface GQLObservable {
	subscribe: (params: APGenericData) => unknown;
}

interface APSoftDeletableEntry {
	deletedAt?: string;
}

type APBasicSubscriptionVariables = Record<keyof APGenericData, unknown>;

type APQueryFilter<T> = Partial<
	Record<keyof T | keyof APSoftDeletableEntry, Partial<Record<APComparaisonOp, unknown>>>
>;

type APApiCallFilter<T> = APQueryFilter<T> & {
	and?: APApiCallFilter<T>[];
	or?: APApiCallFilter<T>[];
	not?: APApiCallFilter<T> | APApiCallFilter<T>[];
};

export interface APApiCallVariables<T> extends APBasicSubscriptionVariables {
	input?: T | Partial<T>;
	filter?: APApiCallFilter<T>;
	condition?: APGenericData;
	limit?: number;
}

export interface APApiCallParams<T> extends APApiCallVariables<T> {
	params?: APGenericData;
	gqlOperationName?: APGqlOperationName;
	subscriptions?: APSubscriptionName[];
}

interface APBaseApiCallParams<T> {
	gqlOperationName: APGqlOperationName;
	variables: APApiCallVariables<T>;
}

export interface APStoredCallMetaParams<T> extends APBaseApiCallParams<T> {
	crudMethod: APCRUDMethodName;
	subscriptions?: APSubscriptionName[];
}

export interface APApiCallMetaUpdatableFields {
	status?: APApiCallStatus;
	lastFetched?: number;
}

export interface APStoredCallMeta<T> extends APStoredCallMetaParams<T>, APApiCallMetaUpdatableFields {
	status: APApiCallStatus;
	force: boolean;
}

export type APApiCallMeta<T> = Record<string, APStoredCallMeta<T>>;

export interface APApiCallMakerParams<T> extends APStoredCallMetaParams<T> {
	results?: T[];
	nextToken?: string;
}

export type APExtendedApiCallParams<T> = APApiCallParams<T> & { id?: Id };

export interface APApiCallHandlerParams<T> extends APStoredCallMetaParams<T> {
	params?: APExtendedApiCallParams<T>;
}

export type APEndpointResponse<T> = {
	value: {
		data: Record<string, T & APSoftDeletableEntry>;
	};
};

interface APApiResponseData<T> extends APGenericData {
	item?: T;
	items?: T[];
	nextToken?: string;
}

export interface APApiResponse<T> {
	data: Record<string, APApiResponseData<T>>;
}

export type APHookFunction<T> = (params: APHookCallbackParams<T>, result?: unknown) => Promise<void>;

export type APHook<T> = Record<APCRUDMethodName, APHookFunction<T>[]>;

export type APHooks<T> = Record<APHookName, APHook<T>>;

export interface APHookContext<T> {
	hook: APHookName;
	crudMethod: APCRUDMethodName;
	params?: APExtendedApiCallParams<T>;
	error?: APExtendedError;
}

export interface APHookCallbackParams<T> extends APHookContext<T> {
	model: string;
}

export interface APStoredSubscriptionParams<T> extends APBaseApiCallParams<T> {
	subscriptionName: APSubscriptionName;
	crudMethod?: APCRUDMethodName;
}

export interface APSubscription<T> extends Partial<APStoredCallMetaParams<T>> {
	unsubscribe: () => void;
}

type APStoredSubscription<T> = Record<APSubscriptionName, APSubscription<T>>;

export type APStoredSubscriptions<T> = APStoredSubscription<T> & APStoredSubscriptionParams<T>;

export interface APBaseStoreReactive<T> {
	items: Ref<UnwrapRef<Record<Id, T>>>;
	errors: Ref<Error[]>;
	calls: Ref<APApiCallMeta<T>>;
	subscriptions: Ref<UnwrapRef<APStoredSubscriptions<T>[]>>;
	pending: ComputedRef<boolean>;
	hasItems: ComputedRef<boolean>;
	hasErrors: ComputedRef<boolean>;
	hasSubscriptions: ComputedRef<boolean>;
	isPending: ComputedRef<boolean>;
	keyedById: ComputedRef<Record<string, T>>;
	data: ComputedRef<T[]>;
}

export type APStorePluginReturnType = Record<string, Ref | ComputedRef | Function>;

export interface APBaseStoreActions<T> {
	pushNewErrors: (errors: Error[]) => void;
	remove: (payload: T) => void;
	create: (data: T) => void;
	update: (payload: T) => void;
	saveCallMeta: (hash: string, data: APStoredCallMeta<T>) => void;
	updateCallMeta: (hash: string, input: { status?: APApiCallStatus; lastFetched: number }) => void;
	deleteCallMeta: (hash: string) => void;
	existingSubscription: (data: APStoredSubscriptions<T>) => APStoredSubscriptions<T> | undefined;
	saveSubscriptions: (data: APStoredSubscriptions<T> | APStoredSubscriptions<T>[]) => void;
	deleteSubscription: (data: APStoredSubscriptions<T>) => void;
	deleteSubscriptions: (data: APStoredSubscriptions<T>) => void;
}

export interface APBaseStoreStructure<T> extends APBaseStoreReactive<T>, APBaseStoreActions<T> {}

export type APStorePlugin<T> = (baseStoreStructure: APBaseStoreStructure<T>) => APStorePluginReturnType;

export interface APStoreMakerParams<T> {
	model: Id;
	primaryKey?: keyof T;
	storePlugin?: APStorePlugin<T>;
}

export interface APBaseModelConstructorOptions<T> {
	primaryKey?: keyof T;
	amplifyTableName?: string;
	storePlugin?: APStorePlugin<T>;
	hardDelete?: boolean;
	noSavedItems?: boolean;
}

export interface APModelParams<T> {
	model: string;
	hooks?: APHooks<T>;
	options?: APBaseModelConstructorOptions<T>;
}

export interface ExtendedPinia extends Pinia {
	_s: {
		get: (modelName: string) => StoreGeneric;
		values: () => Record<string, StoreGeneric>;
	};
}
