<script lang="ts" setup>
	import { computed, Ref, ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	import AdminSupportDatatableLayout from '@/custom/default/vue/components/admin/adminSupport/AdminSupportDatatableLayout.vue';
	import Column from 'primevue/column';

	import { CommandType, Table } from '@/graphql/types';
	import { useTableStore } from '@/features/Table/store';

	const emits = defineEmits(['submitCommand']);
	const { t } = useI18n();

	const loading: Ref<boolean> = ref(false);

	const tableStore = useTableStore();
	const handleClick = (type: CommandType, id: string) => {
		emits('submitCommand', type, id);
	};

	const haveParticipants = (data: Table) => !data?.users?.items.length;
	const isMute = (data: Table) => data?.users?.items.every((user) => user?.is_mute);
	const isForceMute = (data: Table) => data?.users?.items.every((user) => user?.is_force_mute);
	const participantsOnTable = computed(
		() => (data: Table) => `${data?.users?.items.length} / ${data.max_participants}`
	);
</script>

<template>
	<admin-support-datatable-layout
		:global-filter-fields="['name']"
		:loading="loading"
		:paginator="true"
		:rows="10"
		sort-field="name"
		:sort-order="1"
		:value="tableStore.getTables"
	>
		<Column :header="t('datatable.fields.table.name')" field="name" />
		<Column :header="t('datatable.fields.table.group_ref')" field="group_ref">
			<template #body="{ data, field }">
				{{ data[field].length ? data[field] : t('no_group') }}
			</template>
		</Column>
		<Column :header="t('datatable.fields.table.users')">
			<template #body="{ data }"> {{ participantsOnTable(data) }} </template>
		</Column>
		<Column :header="t('user_commands')">
			<template #body="{ data }">
				<div class="d-flex icon-row">
					<v-btn
						class="text-indigo"
						variant="outlined"
						:disabled="haveParticipants(data)"
						@click="handleClick(CommandType.REFRESH_USER, data?.id)"
					>
						<v-icon icon="mdi-refresh" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.refresh', 3)"
						>
							{{ t('command_tooltip.refresh', 3) }}
						</v-tooltip>
					</v-btn>
					<v-btn
						class="text-orange"
						variant="outlined"
						:disabled="haveParticipants(data)"
						@click="handleClick(CommandType.LOGOUT_USER, data?.id)"
					>
						<v-icon icon="mdi-logout" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.logout', 3)"
						>
							{{ t('command_tooltip.logout', 3) }}
						</v-tooltip>
					</v-btn>
				</div>
			</template>
		</Column>
		<Column :header="t('table_commands')">
			<template #body="{ data }">
				<div class="d-flex icon-row">
					<v-btn
						:class="haveParticipants(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="haveParticipants(data)"
						@click="handleClick(CommandType.KICK_USER_TABLE, data?.id)"
					>
						<v-icon icon="mdi-phone-off" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.refresh', 3)"
						>
							{{ t('command_tooltip.kick', 3) }}
						</v-tooltip>
					</v-btn>
					<v-btn
						:class="haveParticipants(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="haveParticipants(data) || isForceMute(data)"
						@click="handleClick(CommandType.MUTE_USER, data?.id)"
					>
						<v-icon :icon="isMute(data) && !isForceMute(data) ? 'mdi-microphone-off' : 'mdi-microphone'" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t(`command_tooltip.${isMute(data) && !isForceMute(data) ? 'unmute' : 'mute'}`, 3)"
						>
							{{ t(`command_tooltip.${isMute(data) && !isForceMute(data) ? 'unmute' : 'mute'}`, 3) }}
						</v-tooltip>
					</v-btn>
					<v-btn
						:class="haveParticipants(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="haveParticipants(data)"
						@click="handleClick(CommandType.FORCE_MUTE_USER, data?.id)"
					>
						<v-icon :icon="isForceMute(data) ? 'mdi-microphone-plus' : 'mdi-microphone-minus'" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t(`command_tooltip.force_${isForceMute(data) ? 'unmute' : 'mute'}`, 3)"
						>
							{{ t(`command_tooltip.force_${isForceMute(data) ? 'unmute' : 'mute'}`, 3) }}
						</v-tooltip>
					</v-btn>
				</div>
			</template>
		</Column>
	</admin-support-datatable-layout>
</template>

<i18n>
{
	"en": {
		"user_commands": "Actions",
		"table_commands": "Talk Room Actions",
		"no_group": "No Category",
		"datatable": {
			"empty": "No users found.",
			"search": "Search",
			"fields": {
				"table": {
					"name": "Table",
					"group_ref": "Category",
					"users": "Users on table"
				}
			}
		}
	},
	"fr": {
		"user_commands": "Actions",
		"table_commands": "Actions de la salle de discussion",
		"no_group": "Pas de catégorie",
		"datatable": {
			"empty": "Aucun utilisateurs trouver.",
			"search": "Rechercher",
			"fields": {
				"table": {
					"name": "Table",
					"group_ref": "Catégorie",
					"users": "Utilisateurs sur la table"
				}
			}
		}
	}
}
</i18n>

<style scoped>
	.icon-row {
		gap: 1rem;
	}
</style>
