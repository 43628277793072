import { API, graphqlOperation } from 'aws-amplify';
import crypto from 'crypto-js';
import { acceptHMRUpdate, getActivePinia, storeToRefs } from 'pinia';
import { v4 as uuid } from 'uuid';
import pluralize from 'pluralize';
import { omit, pick } from 'lodash';
import { currentEnv } from '@/composables/useApp';
import useAPUtils from '@/amplify-pinia/useAPUtils';
import useCurrentUser from '@/features/CurrentUser/store';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import * as subscriptions from '@/graphql/subscriptions';
import { APApiCallStatus, APCRUDMethodName, APHookName, APSubscriptionName } from '@/amplify-pinia/types';

import type { StoreGeneric } from 'pinia';
import type {
	APApiCallParams,
	APStoredCallMeta,
	APApiResponse,
	APBaseModelConstructorOptions,
	APEndpointResponse,
	APExtendedError,
	APHookCallbackParams,
	APHookContext,
	APHooks,
	APModelParams,
	APStoredSubscriptions,
	APStoredCallMetaParams,
	APApiCallHandlerParams,
	APApiCallVariables,
	ExtendedPinia,
	GQLObservable,
	Id,
	APApiCallMakerParams,
	APApiCallMetaUpdatableFields
} from '@/amplify-pinia/types';
import awsmobile from '@/aws-exports';

const defaultSubscriptionsSet = [APSubscriptionName.onCreate, APSubscriptionName.onUpdate, APSubscriptionName.onDelete];

const { getFormattedFilterKey, getMissingSubscriptionErrorBody, apError } = useAPUtils();

const stringify = (data: unknown) => JSON.stringify(data, null, 0);

const hash = (data: Record<string, unknown>): string => crypto.SHA256(crypto.SHA256(stringify(data))).toString();

export default class BaseModel<T> {
	model: string;
	amplifyTableName: string;
	hooks: APHooks<T>;
	options: APBaseModelConstructorOptions<T>;
	primaryKey: keyof T;

	constructor(params: APModelParams<T>) {
		const { model, hooks = {} as APHooks<T>, options = {} as APBaseModelConstructorOptions<T> } = params;
		if (!model) throw new Error('Amplify-Pinia class requires parameter "model" (string)');

		this.model = model;

		this.primaryKey = options.primaryKey ?? ('id' as keyof T);

		this.options = { ...options, hardDelete: options.hardDelete ?? true };

		this.hooks = hooks;
		this.setAPHooks(hooks);

		const { amplifyTableName, storePlugin } = this.options;

		this.amplifyTableName = amplifyTableName || this.model;

		if (!this._store) {
			const { makeStore } = useAPUtils();
			const storeDef = makeStore<T>({ model: this.amplifyTableName, primaryKey: this.primaryKey, storePlugin });
			if (import.meta.hot) {
				import.meta.hot.accept(acceptHMRUpdate(storeDef, import.meta.hot));
			}
			storeDef();
		}
	}

	get _store() {
		return (getActivePinia() as ExtendedPinia)?._s?.get(this.amplifyTableName) as unknown as StoreGeneric;
	}

	get store() {
		return storeToRefs(this._store);
	}

	private getCallHash({ crudMethod, variables, gqlOperationName }: Partial<APStoredCallMetaParams<T>>) {
		if (!crudMethod || !variables || !gqlOperationName) {
			apError({ model: this.model, code: 'getCallHash', message: 'Missing mandatory parameter' }, { mode: 'throw' });
		}
		return hash({ crudMethod, variables, gqlOperationName });
	}

	private validateOpName(crudMethod: APCRUDMethodName, opName?: string | undefined) {
		const isQuery = [APCRUDMethodName.get, APCRUDMethodName.find].includes(crudMethod);
		const gqlMethodName = crudMethod.replace('find', 'list');
		const queryNameOptions = [
			`${gqlMethodName}${this.amplifyTableName}`,
			`${gqlMethodName}${pluralize(this.amplifyTableName)}`
		];

		if (isQuery) {
			// @ts-ignore
			return opName ? queries[opName] : queryNameOptions.find((name) => !!queries[name]);
		}
		// @ts-ignore
		return opName ? mutations[opName] : queryNameOptions.find((name) => !!mutations[name]);
	}

	private updateCallMeta(callHash: string, input: APApiCallMetaUpdatableFields) {
		if (!callHash) {
			apError({ model: this.model, code: 'updateCallMeta', message: 'Missing callHash' }, { mode: 'throw' });
		}
		this._store.updateCallMeta(callHash, input);
	}

	private setCallMeta(
		callHash: string,
		{ variables, gqlOperationName, subscriptions, crudMethod, status, force }: Partial<APStoredCallMeta<T>>
	): string {
		this._store.saveCallMeta(callHash, {
			variables,
			crudMethod,
			gqlOperationName,
			status: status ?? APApiCallStatus.pending,
			force: force ?? false,
			subscriptions: subscriptions ?? []
		});
		return callHash;
	}

	private preventFromCalling(callHash: string): boolean {
		const callMeta = this.store.calls.value[callHash];

		// Cases when you clearly don't want to prevent from fetching
		if (
			callMeta?.force ||
			!callMeta?.status ||
			![APCRUDMethodName.find, APCRUDMethodName.get].includes(callMeta.gqlOperationName)
		) {
			return false;
		}

		return (
			callMeta.status === APApiCallStatus.pending ||
			(callMeta.status === APApiCallStatus.completed &&
				callMeta.subscriptions.some((key: APSubscriptionName) => !APSubscriptionName[key]))
		);
	}

	private setAPHooks(customAPHooks: APHooks<T>) {
		if (this._store === null) return {} as APHooks<T>;

		this.hooks = Object.keys(APHookName).reduce((acc, hook) => {
			const hookContent = Object.keys(APCRUDMethodName).reduce((accu, method) => {
				// @ts-ignore
				const hookMethodFns = customAPHooks[hook]?.[method] || [];
				return { ...accu, [method]: hookMethodFns };
			}, {});

			return { ...acc, [hook]: hookContent };
		}, {}) as APHooks<T>;
	}

	private async hookHandler(context: APHookContext<T>) {
		if (!this.model || !context.hook || !this.hooks) return;
		const callbacks = this.hooks[context.hook][context.crudMethod];
		if (!callbacks?.length) return;

		callbacks.map(
			async (callback: (params: APHookCallbackParams<T>) => Promise<void>) =>
				await callback({ ...context, model: this.model as string })
		);
	}

	private makeAPICall = async (data: APApiCallMakerParams<T>): Promise<T | T[] | void> => {
		const { subscriptions, crudMethod, gqlOperationName, variables, results } = data;
		const gql = { ...queries, ...mutations };
		// @ts-ignore
		const gqlOperation = gql[gqlOperationName];

		if (!gqlOperation) {
			apError({
				model: this.model,
				code: 'makeAPICall',
				message: `Operation ${gqlOperationName} does not exist, you fool!`
			});
		}

		/**********************************************************************************/
		// FIXME: Remove this non-amplify-pinia code from the core of amplify-pinia library!
		const { getAuthorizationToken } = storeToRefs(useCurrentUser());
		/**********************************************************************************/

		let res: APApiResponse<T>;

		const callHash = this.getCallHash({ crudMethod, variables, gqlOperationName });

		if (this.preventFromCalling(callHash)) {
			console.info(`AP INFO :: (${this.model}/makeAPICall) Call already executed. Will not fetch again.`);
			return [];
		}

		const callMeta = this._store.getCallMeta(callHash);

		if (callMeta) {
			this.updateCallMeta(callHash, {
				status: APApiCallStatus.pending
			});
		} else {
			this.setCallMeta(callHash, { crudMethod, gqlOperationName, variables, subscriptions });
		}

		try {
			res = (await API.graphql(
				graphqlOperation(
					gqlOperation,
					{
						...variables
					},
					awsmobile.aws_appsync_apiKey
				)
			)) as APApiResponse<T>;

			const { nextToken: responseNextToken, items, ...item } = res.data[gqlOperationName];
			const returnedItems = results ? [...results, ...(items ?? [])] : items ?? [];

			this.updateCallMeta(callHash, {
				status: APApiCallStatus.completed,
				lastFetched: new Date().valueOf()
			});

			if (responseNextToken && currentEnv().isDev) {
				console.info(`${gqlOperationName} has more data to retrieve, implement paging instead of trying to read `);
			}

			if (items) return returnedItems;
			return item as T;
		} catch (err) {
			this.updateCallMeta(callHash, { status: APApiCallStatus.errored, lastFetched: new Date().valueOf() });
			throw err;
		}
	};

	protected async apiCallHandler(data: APApiCallHandlerParams<T>) {
		if (!this.amplifyTableName) throw new Error('"amplifyTableName" is not defined in class\'s instance');
		if (!this._store) throw new Error(`No store was created for model "${this.model}"`);

		const { crudMethod, params, gqlOperationName, variables, subscriptions } = data;
		const classFctName = crudMethod as unknown as keyof typeof this;

		if (!this[classFctName]) return;

		const hookParams = {
			crudMethod,
			params: params ?? {},
			hook: APHookName.before
		} as APHookContext<T>;

		try {
			hookParams.hook = APHookName.before;
			await this.hookHandler(hookParams);

			const result = await this.makeAPICall({ crudMethod, gqlOperationName, variables, subscriptions });

			hookParams.hook = APHookName.after;
			await this.hookHandler(hookParams);

			return result;
		} catch (err) {
			apError({
				model: this.model,
				code: crudMethod,
				message: `${JSON.stringify(err)} using params ${JSON.stringify(params)}`
			});
			hookParams.hook = APHookName.error;
			hookParams.error = err as Error;
			await this.hookHandler(hookParams);
			throw err;
		}
	}

	private JSONStringConverter(str: string) {
		try {
			return JSON.parse(str);
		} catch (e) {
			return str;
		}
	}

	private JSONToObjectConversion(item: unknown): unknown {
		if (!item) return item;

		if (['number', 'boolean'].includes(typeof item)) return item;

		if (typeof item === 'string') return this.JSONStringConverter(item);

		if (Array.isArray(item)) return item.map((i) => this.JSONToObjectConversion(i));

		if (typeof item === 'object') {
			Object.keys(item).forEach((key) => {
				// @ts-ignore
				item[key] = this.JSONToObjectConversion(item[key]);
			});
			return item;
		}
		return item;
	}

	async get(id: Id, params?: APApiCallParams<T>): Promise<T> {
		const crudMethod = APCRUDMethodName.get;
		const subscriptions = params?.subscriptions ?? defaultSubscriptionsSet;
		const gqlOperationName: string = this.validateOpName(crudMethod);
		const variables = { [this.primaryKey]: id };

		const item = await this.apiCallHandler({
			params: { id, ...params },
			gqlOperationName,
			variables,
			subscriptions,
			crudMethod
		});

		this.JSONToObjectConversion(item);

		if (this._store) this._store.create(item);

		if (subscriptions) {
			await this.subscribe({
				variables: { filter: { [this.primaryKey]: { eq: id } } } as APApiCallVariables<T>,
				crudMethod,
				gqlOperationName,
				subscriptions
			});
		}

		return item as T;
	}

	async find(params: APApiCallParams<T>): Promise<T[]> {
		if (!this.amplifyTableName) {
			apError(
				{ model: this.model, code: 'find', message: '"amplifyTableName" is not defined in class\'s instance' },
				{ mode: 'throw' }
			);
		}

		if (!params) {
			apError(
				{ model: this.model, code: 'find', message: 'mandatory parameter "params" was not provided' },
				{ mode: 'throw' }
			);
		}

		const { gqlOperationName: opName, limit = 1000, subscriptions = defaultSubscriptionsSet, ...variables } = params;
		const crudMethod = APCRUDMethodName.find;
		const gqlOperationName: string = this.validateOpName(crudMethod, opName);

		// @ts-ignore
		if (!gqlOperationName) {
			apError({ model: this.model, code: 'find', message: 'Invalid "gqlOperationName" parameter' }, { mode: 'throw' });
		}

		const items = await this.apiCallHandler({
			params,
			gqlOperationName,
			variables: {
				...variables,
				filter: this.options.hardDelete
					? variables.filter
					: { ...variables.filter, deletedAt: { attributeExists: false } },
				limit
			} as APApiCallVariables<T>,
			subscriptions,
			crudMethod
		});

		if (this._store && !this.options.noSavedItems) this._store.create(items);

		if (!variables.filter) variables.filter = {};

		if (subscriptions) {
			await this.subscribe({
				variables: variables as APApiCallVariables<T>,
				crudMethod,
				gqlOperationName,
				subscriptions
			});
		}

		return items as T[];
	}

	async create(data: Partial<T>, params?: APApiCallParams<T>) {
		if (typeof data !== 'object' || Array.isArray(data) || !data) {
			apError(
				{
					model: this.model,
					code: 'create',
					message: 'Invalid "data" format provided'
				},
				{ mode: 'throw' }
			);
		}

		const crudMethod = APCRUDMethodName.create;
		const gqlOperationName: string = this.validateOpName(crudMethod);
		const condition = params?.condition;
		const subscriptions = params?.subscriptions ?? defaultSubscriptionsSet;
		const id = uuid();
		const input = { [this.primaryKey]: id, ...omit(data, ['__typename']) };
		const variables = { input, condition };

		const result = await this.apiCallHandler({
			crudMethod,
			params: { ...params, input: data, condition },
			gqlOperationName,
			subscriptions,
			variables
		});

		if (subscriptions) {
			await this.subscribe({
				variables: { filter: { [this.primaryKey]: { eq: id } }, condition } as APApiCallVariables<T>,
				crudMethod,
				gqlOperationName,
				subscriptions
			});
		}

		return result;
	}

	async update(data: Partial<T>, params?: APApiCallParams<T>) {
		if (typeof data !== 'object' || Array.isArray(data) || !data) {
			apError(
				{
					model: this.model,
					code: 'create',
					message: 'Invalid "data" format provided'
				},
				{ mode: 'throw' }
			);
		}

		const crudMethod = APCRUDMethodName.update;
		const gqlOperationName: string = this.validateOpName(crudMethod);
		const condition = params?.condition;
		const input = { ...omit(data, ['createdAt', 'updatedAt', '__typename']), [this.primaryKey]: data[this.primaryKey] };
		const variables = { condition, input };

		return await this.apiCallHandler({
			crudMethod,
			params: { ...params, input: data, condition },
			gqlOperationName,
			variables,
			subscriptions: []
		});

		// No subscriptions activated on updates
	}

	async delete(data: Partial<T>, params?: APApiCallParams<T>) {
		if (typeof data !== 'object' || Array.isArray(data) || !data) {
			apError(
				{
					model: this.model,
					code: 'delete',
					message: 'Invalid "data" format provided'
				},
				{ mode: 'throw' }
			);
		}

		const condition = params?.condition;
		const gqlOperationName: string = this.validateOpName(
			this.options.hardDelete ? APCRUDMethodName.delete : APCRUDMethodName.update
		);
		const variables = {
			condition,
			input: (this.options.hardDelete
				? { [this.primaryKey]: data[this.primaryKey] }
				: {
						...omit(data, ['createdAt', 'updatedAt', 'created_at', 'updated_at', 'created_on', 'updated_on']),
						deletedAt: new Date().toISOString(),
						[this.primaryKey]: data[this.primaryKey]
				  }) as Partial<T>
		};

		return await this.apiCallHandler({
			crudMethod: APCRUDMethodName.delete,
			params: { ...params, input: data, condition },
			gqlOperationName,
			variables,
			subscriptions: []
		});

		// No subscriptions activated on deletions
	}

	onCreateSubscriptionCallback(response: APEndpointResponse<T>, subscriptionName: string) {
		const {
			value: {
				data: { [subscriptionName]: item }
			}
		} = response;

		if (this._store || this.options.noSavedItems) this._store.create(item);
	}

	onUpdateSubscriptionCallback(response: APEndpointResponse<T>, subscriptionName: string) {
		const {
			value: {
				data: { [subscriptionName]: item }
			}
		} = response;

		if (!this._store || this.options.noSavedItems) return;
		this._store.update(item);
	}

	onDeleteSubscriptionCallback(response: APEndpointResponse<T>, subscriptionName: string) {
		const {
			value: {
				data: { [subscriptionName]: item }
			}
		} = response;

		if (!this._store || this.options.noSavedItems) return;

		item.deletedAt && !this.options.hardDelete ? this._store.update(item) : this._store.remove(item);
	}

	private async registerSubscription({
		subscription,
		subscriptionName,
		subscriptionTrigger
	}: {
		subscription: Partial<APStoredSubscriptions<T>>;
		subscriptionName: keyof typeof subscriptions;
		subscriptionTrigger: string;
	}) {
		const { getAuthorizationToken } = storeToRefs(useCurrentUser());
		const listener = (await API.graphql(
			graphqlOperation(subscriptions[subscriptionName], subscription.variables, awsmobile.aws_appsync_apiKey)
		)) as GQLObservable;

		const amplifySubscription = {
			[subscriptionTrigger]: listener.subscribe({
				next: (response: APEndpointResponse<T>) => {
					const methodName = `${subscriptionTrigger}SubscriptionCallback`;
					// @ts-ignore
					if (!this[methodName]) {
						throw new Error(`BaseModel.bindSubscription(): Method ${methodName} does not exist.`);
					}
					// @ts-ignore
					this[methodName](response, subscriptionName);
				},
				error: (error: APExtendedError) => {
					this._store.pushNewErrors(error.errors ? error.errors : [error]);
				}
			})
		};

		Object.assign(subscription, amplifySubscription);
	}

	private async bindSubscription(
		subscription: Partial<APStoredSubscriptions<T>>,
		subscriptionTrigger: APSubscriptionName,
		crudMethod: string
	): Promise<APStoredSubscriptions<T> | Partial<APStoredSubscriptions<T>> | void> {
		if (!this.model) {
			throw new Error('BaseModel.bindSubscription(): "model" cannot be null or undefined');
		}
		if (!this.amplifyTableName) {
			throw new Error('BaseModel.bindSubscription(): "amplifyTableName" cannot be null or undefined');
		}

		if (['get', 'create'].includes(crudMethod) && subscriptionTrigger === APSubscriptionName.onCreate) {
			return subscription;
		}

		if (!subscription.variables) throw new Error('No variables were passed to subscription');

		const subscriptionName = `${subscriptionTrigger}${this.amplifyTableName}` as keyof typeof subscriptions;

		const variablesKeys = Object.keys(subscription.variables);

		if (variablesKeys.includes('filter')) {
			// Since "filter" argument will always have precedence on other args, remove others args when "filter" arg exists
			subscription.variables =
				variablesKeys.length > 1 ? pick(subscription.variables, 'filter') : subscription.variables;

			return this.registerSubscription({
				subscription,
				subscriptionName,
				subscriptionTrigger
			});
		}

		const filterKeys = getFormattedFilterKey<T>(subscription).sort();

		const matches = Object.keys(subscriptions).filter(
			(key) =>
				![filterKeys.length ? `${subscriptionName}By` : subscriptionName, ...filterKeys].find(
					(str: string) => !key.includes(str)
				)
		) as Array<keyof typeof subscriptions>;

		if (matches.length === 1) {
			return this.registerSubscription({
				subscription,
				subscriptionName: matches[0],
				subscriptionTrigger
			});
		} else {
			const message = getMissingSubscriptionErrorBody<T>(
				subscription as APStoredSubscriptions<T>,
				crudMethod,
				subscriptionTrigger,
				subscriptionName,
				filterKeys,
				variablesKeys,
				this.amplifyTableName
			);
			apError({ model: this.model, message, code: 'bindSubscription' }, { mode: 'throw' });
		}
	}

	private async subscribe(data: APStoredCallMetaParams<T>) {
		const subscription: Partial<APStoredSubscriptions<T>> = {
			variables: { ...data.variables },
			gqlOperationName: data.gqlOperationName
		};
		const existingSub = this._store.existingSubscription(subscription);

		if (Array.isArray(data.subscriptions)) {
			Promise.allSettled(
				data.subscriptions.map(
					async (trigger) =>
						new Promise((resolve, reject) => {
							if (existingSub?.[trigger]) {
								reject(
									`AP WARNING: AP just prevented ${this.model}.${data.crudMethod}() method to be used in a way that would have resulted in creation of an already existing subscription for trigger "${trigger}"`
								);
							} else {
								try {
									this.bindSubscription(subscription, trigger, data.crudMethod).then((res) => resolve(res));
								} catch (error) {
									if (typeof error === 'object') reject(`AP ERROR: ${JSON.stringify({ ...error, trigger })}`);
									else reject(error);
								}
							}
						})
				)
			).then((results) => {
				results.forEach((result) => {
					if (result.status !== 'fulfilled') console.warn(result.reason);
				});
				this._store.saveSubscriptions([subscription]);
			});
		}
	}
}
