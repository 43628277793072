<script setup lang="ts">
	import { ref, watch } from 'vue';
	import { useDisplay } from 'vuetify';
	import ErrorToast from '@/features/ErrorHandler/components/ErrorToast.vue';
	import NavigationBar from '@/custom/solotech-career/vue/components/common/NavigationBar.vue';
	import MainDrawer from '@/custom/solotech-career/vue/components/drawer/MainDrawer.vue';
	import AppFooter from '@/custom/solotech-career/vue/components/common/AppFooter.vue';
	import backgroundImg from '@/custom/solotech-career/assets/background.jpg';
	const { lgAndUp } = useDisplay();

	const drawer = ref(false);

	watch(
		lgAndUp,
		(value) => {
			if (value && !drawer.value) {
				drawer.value = true;
			}
			if (!value && drawer.value) {
				drawer.value = false;
			}
		},
		{
			immediate: true
		}
	);
</script>

<template>
	<transition-group name="fade">
		<div key="onLoadedContent" class="app-background" :style="{ backgroundImage: `url(${backgroundImg})` }">
			<error-toast />
			<navigation-bar v-model:drawer="drawer" />
			<main-drawer v-model:drawer="drawer" />
			<v-main class="h-100 text-white">
				<slot />
			</v-main>
			<app-footer />
		</div>
	</transition-group>
</template>
