import { AWSGraphqlMutation, AWSGraphqlQuery, AWSSubscription } from '@/graphql';
import { onCreateUserTable, onDeleteUserTable, onUpdateUserTable } from '@/graphql/subscriptions';
import {
	OnCreateUserTableSubscription,
	OnDeleteUserTableSubscription,
	OnUpdateUserTableSubscription,
	UserTable
} from '@/graphql/types';
import { updateUserTable } from '@/graphql/mutations';
import { listUserTables } from '@/graphql/queries';

import { useTableStore } from '@/features/Table/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';

export const userTableUnsubscription = {
	onDeleteUserTableUnsubscribe: () => {},
	onCreateUserTableUnsubscribe: () => {},
	onUpdateUserTableUnsubscribe: () => {}
};

export async function subscriptionUserTable(event_id: string | null) {
	const { fetchTables } = useTableStore();
	const { updateUserTableActiveUser } = useActiveUsers();
	const updateTableStore = () => {
		fetchTables();
	};
	const error = (err: Error) => {
		console.error(err);
	};

	if (!event_id) {
		throw new Error('Cannot get event_id for table subscription');
	}

	userTableUnsubscription.onDeleteUserTableUnsubscribe = await AWSSubscription(
		onDeleteUserTable,
		{
			event_id
		},
		({
			value: {
				data: { onDeleteUserTable }
			}
		}: {
			value: { data: OnDeleteUserTableSubscription };
		}) => {
			updateTableStore();
			if (onDeleteUserTable?.userTableUserId) updateUserTableActiveUser(onDeleteUserTable?.userTableUserId, undefined);
		},
		error
	);
	userTableUnsubscription.onCreateUserTableUnsubscribe = await AWSSubscription(
		onCreateUserTable,
		{ event_id },
		({
			value: {
				data: { onCreateUserTable }
			}
		}: {
			value: { data: OnCreateUserTableSubscription };
		}) => {
			updateTableStore();
			if (onCreateUserTable?.userTableUserId)
				updateUserTableActiveUser(onCreateUserTable?.userTableUserId, onCreateUserTable as UserTable);
		},
		error
	);
	userTableUnsubscription.onUpdateUserTableUnsubscribe = await AWSSubscription(
		onUpdateUserTable,
		{ event_id },
		({
			value: {
				data: { onUpdateUserTable }
			}
		}: {
			value: { data: OnUpdateUserTableSubscription };
		}) => {
			updateTableStore();
			if (onUpdateUserTable?.userTableUserId)
				updateUserTableActiveUser(onUpdateUserTable?.userTableUserId, onUpdateUserTable as UserTable);
		},
		error
	);
}

export async function toggleMuteUser(id: string | null | undefined) {
	if (!id) throw new Error('Cannot get user_table id');
	const {
		data: {
			listUserTables: {
				items: [userTable]
			}
		}
	} = await AWSGraphqlQuery(listUserTables, { filter: { userTableUserId: { eq: id } } });

	await AWSGraphqlMutation(updateUserTable, {
		id: userTable.id,
		is_mute: !userTable.is_mute
	});
}

export async function toggleForceMuteUser(id: string | null | undefined) {
	if (!id) throw new Error('Cannot get user_table id');
	const {
		data: {
			listUserTables: {
				items: [userTable]
			}
		}
	} = await AWSGraphqlQuery(listUserTables, { filter: { userTableUserId: { eq: id } } });

	return await AWSGraphqlMutation(updateUserTable, {
		id: userTable.id,
		is_force_mute: !userTable.is_force_mute
	});
}

export function unsubscribeUserTable() {
	userTableUnsubscription.onCreateUserTableUnsubscribe();
	userTableUnsubscription.onUpdateUserTableUnsubscribe();
	userTableUnsubscription.onDeleteUserTableUnsubscribe();
}
