import { createVuetify } from 'vuetify';
// @ts-ignore
import * as components from 'vuetify/lib/components/index.mjs';
// @ts-ignore
import * as directives from 'vuetify/lib/directives/index.mjs';
// @ts-ignore
import colors from 'vuetify/lib/util/colors';
import useConfig from '@/features/Config';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/lib/styles/main.sass';

import type { Plugin } from 'vue';
import type { Config as ConfigType } from '@/features/Config/types';

export default () => {
	const {
		store: { data, currentConfig }
	} = useConfig();
	const defaultTheme = currentConfig.value?.id ? currentConfig.value.id : 'dark';
	const theme = {
		defaultTheme: defaultTheme,
		themes: {
			admin: {
				dark: true,
				colors: {
					primary: '#25307b',
					secondary: '#d11463',
					info: '#3b2eb6',
					success: '#0c9758',
					warning: '#ddc80e',
					danger: '#ea1d05'
				}
			},
			...data.value.reduce(
				(acc: Record<string, ConfigType>, config: ConfigType) => ({
					...acc,
					[config.id]: config.theme
				}),
				{}
			)
		}
	};
	return createVuetify({ components, directives, theme }) as Plugin;
};
