<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import { useDisplay } from 'vuetify';

	const { t } = useI18n();
	const { smAndDown } = useDisplay();

	const socialsMedia: {
		name: string;
		icon: string;
		link: string;
	}[] = [
		{
			name: 'Contact',
			icon: 'mdi-email',
			link: 'https://www.solotech.com/newsletter/'
		},
		{
			name: 'Linkedin',
			icon: 'mdi-linkedin',
			link: 'https://www.linkedin.com/company/solotech-inc./'
		},
		{
			name: 'Facebook',
			icon: 'mdi-facebook',
			link: 'https://www.facebook.com/Solotechinc/'
		},
		{
			name: 'Instagram',
			icon: 'mdi-instagram',
			link: 'https://www.instagram.com/solotech_inc/?hl=en'
		},
		{
			name: 'Twitter',
			icon: 'mdi-twitter',
			link: 'https://twitter.com/solotech_inc'
		},
		{
			name: 'Youtube',
			icon: 'mdi-youtube',
			link: 'https://www.youtube.com/channel/UClSCEYUoZIdYNeAxxmOD5TQ'
		}
	];
</script>

<template>
	<v-list v-if="smAndDown" density="compact" nav>
		<v-list-item
			v-for="{ name, icon, link } in socialsMedia"
			:key="name"
			:href="link"
			target="_blank"
			color="white"
			:prepend-icon="icon"
			:title="name"
		/>
	</v-list>

	<v-btn
		v-for="({ name, icon, link }, i) in socialsMedia"
		v-else
		:key="name"
		:aria-label="name"
		:href="link"
		target="_blank"
		:icon="true"
		size="small"
		:class="{ 'mr-2': i !== socialsMedia.length - 1 }"
		class="btn-small bg-white text-surface"
	>
		<v-icon>{{ icon }}</v-icon>
		<v-tooltip activator="parent" location="bottom">
			{{ name }}
		</v-tooltip>
	</v-btn>
</template>

<style scoped>
	.btn-small {
		width: 28px;
		height: 28px;
	}
</style>
