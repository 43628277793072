/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const importUsers = /* GraphQL */ `
  mutation ImportUsers($input: [String]) {
    importUsers(input: $input) {
      user_email
      event_id
      hasCreated
      __typename
    }
  }
`;
export const cleanChats = /* GraphQL */ `
  mutation CleanChats($ids: [ID]) {
    cleanChats(ids: $ids) {
      success
      message
      __typename
    }
  }
`;
export const updatePassword = /* GraphQL */ `
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const signUp = /* GraphQL */ `
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const signIn = /* GraphQL */ `
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers($input: [String]) {
    deleteUsers(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const signInGuest = /* GraphQL */ `
  mutation SignInGuest($input: SignInGuest!) {
    signInGuest(input: $input) {
      access_token
      __typename
    }
  }
`;
export const signInNoPassword = /* GraphQL */ `
  mutation SignInNoPassword($input: SignInNoPassword!) {
    signInNoPassword(input: $input) {
      access_token
      __typename
    }
  }
`;
export const createEvents = /* GraphQL */ `
  mutation CreateEvents(
    $input: CreateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    createEvents(input: $input, condition: $condition) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const updateEvents = /* GraphQL */ `
  mutation UpdateEvents(
    $input: UpdateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    updateEvents(input: $input, condition: $condition) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const deleteEvents = /* GraphQL */ `
  mutation DeleteEvents(
    $input: DeleteEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    deleteEvents(input: $input, condition: $condition) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const createEventHistory = /* GraphQL */ `
  mutation CreateEventHistory(
    $input: CreateEventHistoryInput!
    $condition: ModelEventHistoryConditionInput
  ) {
    createEventHistory(input: $input, condition: $condition) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const updateEventHistory = /* GraphQL */ `
  mutation UpdateEventHistory(
    $input: UpdateEventHistoryInput!
    $condition: ModelEventHistoryConditionInput
  ) {
    updateEventHistory(input: $input, condition: $condition) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const deleteEventHistory = /* GraphQL */ `
  mutation DeleteEventHistory(
    $input: DeleteEventHistoryInput!
    $condition: ModelEventHistoryConditionInput
  ) {
    deleteEventHistory(input: $input, condition: $condition) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const createProfiles = /* GraphQL */ `
  mutation CreateProfiles(
    $input: CreateProfilesInput!
    $condition: ModelProfilesConditionInput
  ) {
    createProfiles(input: $input, condition: $condition) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateProfiles = /* GraphQL */ `
  mutation UpdateProfiles(
    $input: UpdateProfilesInput!
    $condition: ModelProfilesConditionInput
  ) {
    updateProfiles(input: $input, condition: $condition) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteProfiles = /* GraphQL */ `
  mutation DeleteProfiles(
    $input: DeleteProfilesInput!
    $condition: ModelProfilesConditionInput
  ) {
    deleteProfiles(input: $input, condition: $condition) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createActiveUsers = /* GraphQL */ `
  mutation CreateActiveUsers(
    $input: CreateActiveUsersInput!
    $condition: ModelActiveUsersConditionInput
  ) {
    createActiveUsers(input: $input, condition: $condition) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const updateActiveUsers = /* GraphQL */ `
  mutation UpdateActiveUsers(
    $input: UpdateActiveUsersInput!
    $condition: ModelActiveUsersConditionInput
  ) {
    updateActiveUsers(input: $input, condition: $condition) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const deleteActiveUsers = /* GraphQL */ `
  mutation DeleteActiveUsers(
    $input: DeleteActiveUsersInput!
    $condition: ModelActiveUsersConditionInput
  ) {
    deleteActiveUsers(input: $input, condition: $condition) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const createPolls = /* GraphQL */ `
  mutation CreatePolls(
    $input: CreatePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    createPolls(input: $input, condition: $condition) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePolls = /* GraphQL */ `
  mutation UpdatePolls(
    $input: UpdatePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    updatePolls(input: $input, condition: $condition) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePolls = /* GraphQL */ `
  mutation DeletePolls(
    $input: DeletePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    deletePolls(input: $input, condition: $condition) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPollAnswers = /* GraphQL */ `
  mutation CreatePollAnswers(
    $input: CreatePollAnswersInput!
    $condition: ModelPollAnswersConditionInput
  ) {
    createPollAnswers(input: $input, condition: $condition) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePollAnswers = /* GraphQL */ `
  mutation UpdatePollAnswers(
    $input: UpdatePollAnswersInput!
    $condition: ModelPollAnswersConditionInput
  ) {
    updatePollAnswers(input: $input, condition: $condition) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePollAnswers = /* GraphQL */ `
  mutation DeletePollAnswers(
    $input: DeletePollAnswersInput!
    $condition: ModelPollAnswersConditionInput
  ) {
    deletePollAnswers(input: $input, condition: $condition) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChats = /* GraphQL */ `
  mutation CreateChats(
    $input: CreateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    createChats(input: $input, condition: $condition) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const updateChats = /* GraphQL */ `
  mutation UpdateChats(
    $input: UpdateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    updateChats(input: $input, condition: $condition) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const deleteChats = /* GraphQL */ `
  mutation DeleteChats(
    $input: DeleteChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    deleteChats(input: $input, condition: $condition) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const createChatsDeleteHistory = /* GraphQL */ `
  mutation CreateChatsDeleteHistory(
    $input: CreateChatsDeleteHistoryInput!
    $condition: ModelChatsDeleteHistoryConditionInput
  ) {
    createChatsDeleteHistory(input: $input, condition: $condition) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const updateChatsDeleteHistory = /* GraphQL */ `
  mutation UpdateChatsDeleteHistory(
    $input: UpdateChatsDeleteHistoryInput!
    $condition: ModelChatsDeleteHistoryConditionInput
  ) {
    updateChatsDeleteHistory(input: $input, condition: $condition) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const deleteChatsDeleteHistory = /* GraphQL */ `
  mutation DeleteChatsDeleteHistory(
    $input: DeleteChatsDeleteHistoryInput!
    $condition: ModelChatsDeleteHistoryConditionInput
  ) {
    deleteChatsDeleteHistory(input: $input, condition: $condition) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const createCommand = /* GraphQL */ `
  mutation CreateCommand(
    $input: CreateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    createCommand(input: $input, condition: $condition) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand(
    $input: UpdateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    updateCommand(input: $input, condition: $condition) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const deleteCommand = /* GraphQL */ `
  mutation DeleteCommand(
    $input: DeleteCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    deleteCommand(input: $input, condition: $condition) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const createStream = /* GraphQL */ `
  mutation CreateStream(
    $input: CreateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    createStream(input: $input, condition: $condition) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const updateStream = /* GraphQL */ `
  mutation UpdateStream(
    $input: UpdateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    updateStream(input: $input, condition: $condition) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const deleteStream = /* GraphQL */ `
  mutation DeleteStream(
    $input: DeleteStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    deleteStream(input: $input, condition: $condition) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const createBlockedUsers = /* GraphQL */ `
  mutation CreateBlockedUsers(
    $input: CreateBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    createBlockedUsers(input: $input, condition: $condition) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const updateBlockedUsers = /* GraphQL */ `
  mutation UpdateBlockedUsers(
    $input: UpdateBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    updateBlockedUsers(input: $input, condition: $condition) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const deleteBlockedUsers = /* GraphQL */ `
  mutation DeleteBlockedUsers(
    $input: DeleteBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    deleteBlockedUsers(input: $input, condition: $condition) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const createUserTable = /* GraphQL */ `
  mutation CreateUserTable(
    $input: CreateUserTableInput!
    $condition: ModelUserTableConditionInput
  ) {
    createUserTable(input: $input, condition: $condition) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const updateUserTable = /* GraphQL */ `
  mutation UpdateUserTable(
    $input: UpdateUserTableInput!
    $condition: ModelUserTableConditionInput
  ) {
    updateUserTable(input: $input, condition: $condition) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const deleteUserTable = /* GraphQL */ `
  mutation DeleteUserTable(
    $input: DeleteUserTableInput!
    $condition: ModelUserTableConditionInput
  ) {
    deleteUserTable(input: $input, condition: $condition) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const createTable = /* GraphQL */ `
  mutation CreateTable(
    $input: CreateTableInput!
    $condition: ModelTableConditionInput
  ) {
    createTable(input: $input, condition: $condition) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const updateTable = /* GraphQL */ `
  mutation UpdateTable(
    $input: UpdateTableInput!
    $condition: ModelTableConditionInput
  ) {
    updateTable(input: $input, condition: $condition) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const deleteTable = /* GraphQL */ `
  mutation DeleteTable(
    $input: DeleteTableInput!
    $condition: ModelTableConditionInput
  ) {
    deleteTable(input: $input, condition: $condition) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const createFileStorage = /* GraphQL */ `
  mutation CreateFileStorage(
    $input: CreateFileStorageInput!
    $condition: ModelFileStorageConditionInput
  ) {
    createFileStorage(input: $input, condition: $condition) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const updateFileStorage = /* GraphQL */ `
  mutation UpdateFileStorage(
    $input: UpdateFileStorageInput!
    $condition: ModelFileStorageConditionInput
  ) {
    updateFileStorage(input: $input, condition: $condition) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const deleteFileStorage = /* GraphQL */ `
  mutation DeleteFileStorage(
    $input: DeleteFileStorageInput!
    $condition: ModelFileStorageConditionInput
  ) {
    deleteFileStorage(input: $input, condition: $condition) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const createDisplayConfig = /* GraphQL */ `
  mutation CreateDisplayConfig(
    $input: CreateDisplayConfigInput!
    $condition: ModelDisplayConfigConditionInput
  ) {
    createDisplayConfig(input: $input, condition: $condition) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const updateDisplayConfig = /* GraphQL */ `
  mutation UpdateDisplayConfig(
    $input: UpdateDisplayConfigInput!
    $condition: ModelDisplayConfigConditionInput
  ) {
    updateDisplayConfig(input: $input, condition: $condition) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const deleteDisplayConfig = /* GraphQL */ `
  mutation DeleteDisplayConfig(
    $input: DeleteDisplayConfigInput!
    $condition: ModelDisplayConfigConditionInput
  ) {
    deleteDisplayConfig(input: $input, condition: $condition) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const createRolePermissions = /* GraphQL */ `
  mutation CreateRolePermissions(
    $input: CreateRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    createRolePermissions(input: $input, condition: $condition) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRolePermissions = /* GraphQL */ `
  mutation UpdateRolePermissions(
    $input: UpdateRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    updateRolePermissions(input: $input, condition: $condition) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRolePermissions = /* GraphQL */ `
  mutation DeleteRolePermissions(
    $input: DeleteRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    deleteRolePermissions(input: $input, condition: $condition) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserRoles = /* GraphQL */ `
  mutation CreateUserRoles(
    $input: CreateUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    createUserRoles(input: $input, condition: $condition) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserRoles = /* GraphQL */ `
  mutation UpdateUserRoles(
    $input: UpdateUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    updateUserRoles(input: $input, condition: $condition) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserRoles = /* GraphQL */ `
  mutation DeleteUserRoles(
    $input: DeleteUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    deleteUserRoles(input: $input, condition: $condition) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
