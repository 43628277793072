<script lang="ts" setup>
	import { useI18n } from 'vue-i18n';
	import type { ComputedRef, Ref } from 'vue';
	import { computed, ref, useAttrs } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { storeToRefs } from 'pinia';

	import AuthenticationForm from '@/custom/default/vue/components/auth/AuthenticationForm.vue';
	import { onSignInSuccess } from '@/features/CurrentUser';
	import { ComponentsName } from '@/plugins/lib/constants';
	import { ruleEmail, rulePassword } from 'lib/validations';
	import { RuleFunctions, SignInField } from 'lib/types';
	import { useSignInMutation } from '@/gql/operations';
	import useToast from '@/features/Toast';
	import { Language } from '@/gql/types';
	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import { MessageColor } from '@/features/Toast/types';
	import useCurrentEvent from '@/features/Events/store';

	const { t, locale } = useI18n();
	const attrs = useAttrs();
	const router = useRouter();
	const route = useRoute();
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const isPending: Ref<boolean> = ref(false);
	const credential: Ref<SignInField> = ref({
		user_email: '',
		password: ''
	});
	const emailValidations: ComputedRef<RuleFunctions> = computed(() => ruleEmail(locale.value as Language));
	const passwordValidations: ComputedRef<RuleFunctions> = computed(() => rulePassword(locale.value as Language));

	const signInMutation = useSignInMutation();
	const onSignIn = async () => {
		isPending.value = true;
		try {
			const result = await signInMutation.executeMutation({
				signInInput: {
					event_id: getCurrentEventId.value,
					...credential.value
				}
			});
			if (result.error) {
				isPending.value = false;
				useToast().triggerToast(t('form.error'), { color: MessageColor.error });
				throw new Error(result.error.message);
			}
			const redirection = await onSignInSuccess(result.data?.signIn, <string>route.params.eventName);
			await router.push(redirection);
		} catch (e: any) {
			isPending.value = false;
			throw new Error(e);
		}
	};
</script>

<template>
	<div class="mb-4">
		<slot name="signin-admin-header" />
	</div>
	<authentication-form
		:loading="isPending"
		data-cy="form-signin"
		autofocus
		lazy-validation
		class="access"
		@submit.prevent="onSignIn"
	>
		<input-field
			v-model="credential.user_email"
			:rules="emailValidations"
			autofocus
			data-cy="form-signin-input-user_email"
			:aria-label="t('form.email.label')"
			:label="t('form.email.label')"
			variant="underlined"
			tabindex="2"
		/>
		<input-field
			v-model="credential.password"
			:rules="passwordValidations"
			data-cy="form-signin-input-password"
			:aria-label="t('form.password.label')"
			:label="t('form.password.label')"
			type="password"
			variant="underlined"
			tabindex="3"
		/>

		<template #form-submit="{ disabled, loading }">
			<slot name="form-submit" v-bind="{ disabled, loading }">
				<v-btn :disabled="disabled" :loading="loading" class="access" color="primary" type="submit" tabindex="4" block>
					{{ t('form.submit') }}
				</v-btn>
			</slot>
		</template>
	</authentication-form>
</template>

<i18n>
{
	"en": {
		"form": {
			"email": {
				"label": "Email"
			},
			"password": {
				"label": "Password"
			},
			"submit": "Sign-in",
			"error": "Credentials errors"
		}
	},
	"fr": {
		"form": {
			"email": {
				"label": "Adresse courriel"
			},
			"password": {
				"label": "Mot de passe"
			},
			"submit": "Se Connecter",
			"error": "Email ou mot de passe incorrect"
		}
	}
}
</i18n>
