<template>
	<v-card-subtitle class="mt-6">
		{{ t('session_log_subtitle') }}
	</v-card-subtitle>
	<v-card-text class="pb-6" fluid>
		<v-row class="justify-center" no-gutters>
			<v-col cols="12">
				<div>
					<data-table
						ref="eventHistoriesDT"
						v-model:filters="filters"
						:export-filename="exportFileName"
						data-key="id"
						:loading="dataTableLoading"
						:global-filter-fields="['user_email']"
						:sort-field="'logged_in'"
						:sort-order="-1"
						:value="sessionInfoHistory"
						:paginator="true"
						:rows="10"
						:rows-per-page-options="[10, 25, 50]"
						:total-record="sessionInfoHistory.length"
						responsive-layout="scroll"
					>
						<template #empty>
							<p>No user sessions</p>
						</template>

						<template #header>
							<div class="d-flex flex-column justify-center">
								<v-text-field
									v-model="filters['global'].value"
									type="search"
									class="create-users-input"
									autocomplete="new-password"
									prepend-icon="mdi-magnify"
									variant="underlined"
									label="Filter by email address"
								/>
							</div>
						</template>
						<column field="user_email" :header="t('session_log_user_email')" :sortable="true" />
						<column field="user_name" :header="t('session_log_user_name')" :sortable="true" />
						<column field="logged_in" :header="t('session_log_logged_in')" :sortable="true">
							<template #body="slotProps">
								{{ formatDate(slotProps.data.logged_in) }}
							</template>
						</column>
						<column field="logged_out" :header="t('session_log_logged_out')" :sortable="true">
							<template #body="slotProps">
								{{ formatDate(slotProps.data.logged_out) }}
							</template>
						</column>
						<column field="session_duration" :header="t('session_log_session_time')" :sortable="true" />
						<column field="city" :header="t('session_log_city')" :sortable="true" />
						<column field="country" :header="t('session_log_country')" :sortable="true" />
					</data-table>
				</div>
				<div class="d-flex justify-center align-center text-center mt-5">
					<v-btn class="ml-4 bg-primary" size="small" prepend-icon="mdi-download" title="test" @click="downloadCSV">
						<span class="text-uppercase">{{ t('session_log_download') }}</span>
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</v-card-text>
</template>

<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import { computed, ref, Ref, watch } from 'vue';
	import { storeToRefs } from 'pinia';
	import DataTable from 'primevue/datatable';
	import Column from 'primevue/column';
	import { FilterMatchMode } from 'primevue/api';
	import { EventHistory } from '@/graphql/types';
	import { urqlClient } from '@/plugins/urql';
	import { ExportUserSessionLogs, ListUserSessionLogs, useGetEventByNameQuery } from '@/gql/operations';
	import { ExportUserSessionLogInput, UserSessionLog } from '@/gql/types';
	import prettyMilliseconds from 'pretty-ms';
	import useCurrentEvent from '@/features/Events/store';

	const sessionInfoHistory: Ref<Array<EventHistory>> = ref([]);
	const eventHistoriesDT: Ref<DataTable | null> = ref(null);
	const dataTableLoading: Ref<boolean> = ref(true);
	const filters: Ref<{
		global: {
			value: null | string;
			matchMode: string;
		};
	}> = ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });

	const { getCurrentEventId, getCurrentEventNameFromURL: getCurrentEventName } = storeToRefs(useCurrentEvent());

	const getEventByNameQuery = useGetEventByNameQuery({ variables: { event_name: getCurrentEventName.value } });
	const eventID: Ref<string | undefined> = ref(undefined);

	watch(
		() => getEventByNameQuery.data.value?.getEventByName.id,
		async (value) => {
			eventID.value = value;
			await fetchSessionLogs({ date_start: props.dateStart, date_end: props.dateEnd });
		}
	);

	const fetchSessionLogs = async (date: object | null) => {
		try {
			const fetch = await urqlClient
				.query(ListUserSessionLogs, {
					listUserSessionLogs: { event_id: eventID.value, date: date }
				})
				.toPromise();

			sessionInfoHistory.value = fetch.data.listUserSessionLogs?.user_session_logs.map((session: UserSessionLog) => ({
				...session,
				logged_in: session.logged_in,
				logged_out: session.logged_out,
				session_duration: prettyMilliseconds(session.session_duration)
			}));
			dataTableLoading.value = false;
		} catch (e) {
			console.error(`Failed to fetch user session log, reason: ${e}`);
			dataTableLoading.value = false;
		}
	};

	const props = defineProps({
		dateEnd: {
			type: Date,
			required: true
		},
		dateStart: {
			type: Date,
			required: true
		}
	});

	const { t, locale } = useI18n();
	const exportFileName = computed(() => `${getCurrentEventName.value}-session-log-${props.dateStart}-${props.dateEnd}`);

	const formatDate = (timestamp: number) =>
		new Date(timestamp).toLocaleDateString(locale.value, { hour: 'numeric', minute: 'numeric' });

	watch(
		() => [props.dateStart, props.dateEnd],
		() => fetchSessionLogs({ date_start: props.dateStart, date_end: props.dateEnd })
	);

	const downloadCSV = async () => {
		const exportUserSessionLogInput: ExportUserSessionLogInput = {
			date_start: new Date(props.dateStart),
			date_end: new Date(props.dateEnd)
		};
		const { data } = await urqlClient
			.mutation(ExportUserSessionLogs, {
				exportUserSessionLogInput
			})
			.toPromise();

		/**
		 * NOTES:
		 * 	- A blank Window is opened to start the download of the CSV file and is closed immediatly after
		 * 	  the download finishes
		 */
		const url = data.exportUserSessionLogs;
		window.open(url, '_blank');
	};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"session_log_subtitle": "Show the log information per user from the session",
		"session_log_download": "Download the logs",
		"session_log_user_email": "Email Address",
		"session_log_user_name": "Username",
		"session_log_logged_in": "Login Time",
		"session_log_logged_out": "Logout Time",
		"session_log_session_time": "Total Session Time (min)",
		"session_log_city": "City",
		"session_log_country": "Country"
	},
	"fr": {
		"session_log_subtitle": "Montre les informations des utilisateurs liées au registre par session",
		"session_log_download": "Télécharger registre",
		"session_log_user_email": "Courriel",
		"session_log_user_name": "Nom d'utilisateur",
		"session_log_logged_in": "Heure de connexion",
		"session_log_logged_out": "Heure de deconnexion",
		"session_log_session_time": "Temps total de la session",
		"session_log_city": "Ville",
		"session_log_country": "Pays"
	}
}
</i18n>
