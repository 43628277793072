<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import { Ref, ref, watch } from 'vue';
	import { ApexOptions } from 'apexcharts';
	import { VueApexChartsComponent } from 'vue3-apexcharts';

	import { urqlClient } from '@/plugins/urql';
	import { GetUserSessionCounts } from '@/gql/operations';
	import { GetUserSessionCountsQuery, GetUserSessionCountsQueryVariables } from '@/gql/types';

	const props = defineProps<{
		dateStart: Date;
		dateEnd: Date;
	}>();
	const { t, locale } = useI18n();
	const userSessionChart: Ref<VueApexChartsComponent | null> = ref(null);

	const defaultChartOption: ApexOptions = {
		chart: {
			width: '100%',
			toolbar: {
				tools: {
					download: false
				},
				export: {
					png: {
						filename: `user-session-chart-${new Date().toISOString()}`
					}
				}
			}
		},
		noData: {
			text: t('chart_no_data')
		},
		xaxis: {
			type: 'datetime',
			labels: {
				show: true,
				//@ts-ignore
				formatter: (value: string | number) => {
					if (!value) return;
					return new Date(value).toLocaleDateString(locale.value, {
						weekday: 'long',
						month: 'short',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric'
					});
				}
			}
		}
	};

	const fetchUserSessionCounts = () =>
		urqlClient
			.query<GetUserSessionCountsQuery, GetUserSessionCountsQueryVariables>(GetUserSessionCounts, {
				getUserSessionCountsInput: {
					date_start: new Date(props.dateStart).toISOString(),
					date_end: new Date(props.dateEnd).toISOString()
				}
			})
			.toPromise();
	const downloadGraph = () => userSessionChart.value?.chart?.exports.exportToPng();

	watch(
		() => [props.dateStart, props.dateEnd],
		() => {
			fetchUserSessionCounts().then(({ data }) => {
				userSessionChart.value?.updateSeries([
					{
						name: t('chart_series_name'),
						data:
							data?.getUserSessionCounts.map(({ count, timestamp }) => ({
								y: count,
								x: timestamp
							})) ?? []
					}
				]);
			});
		},
		{
			immediate: true
		}
	);
</script>

<template>
	<v-card-subtitle class="mt-6 opacity-100">
		{{ t('chart_subtitle') }}
	</v-card-subtitle>
	<div class="bg-white">
		<apexchart ref="userSessionChart" type="line" :options="defaultChartOption" :series="[]" />
	</div>
	<div v-if="false" class="mb-6 text-center">
		<v-progress-circular indeterminate :size="70" :width="7" />
	</div>
	<v-card-actions class="justify-center text-center pb-6">
		<v-btn class="bg-primary" size="small" @click="downloadGraph">
			<v-icon>mdi-download</v-icon>
			{{ t('chart_btn_download') }}
		</v-btn>
	</v-card-actions>
</template>

<i18n>
{
	"en": {
		"chart_btn_download": "Download the graph",
		"chart_granularity": "Time interval",
		"chart_subtitle": "Show the total amount of users logged in through time",
		"chart_label": "Total users",
		"chart_no_data": "No data for selected time period",
		"chart_series_name": "User Session Counts"
	},
	"fr": {
		"chart_btn_download": "Télécharger graph",
		"chart_granularity": "Intervalle de temps",
		"chart_subtitle": "Montre le nombre total des utilisateurs actifs au fil du temps",
		"chart_label": "Utilisateurs totaux",
		"chart_no_data": "Pas de données pour la période de temps sélectionnée",
		"chart_series_name": "Nombre de sessions d'utilisateurs"
	}
}
</i18n>
