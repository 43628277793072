import { devtoolsExchange } from '@urql/devtools';
import { CombinedError, createClient, defaultExchanges, makeOperation, subscriptionExchange } from '@urql/vue';
import { ClientOptions } from '@urql/core/dist/types/client';
import { authExchange } from '@urql/exchange-auth';
import useApp from '@/composables/useApp';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { createClient as createWSClient } from 'graphql-ws';
import { uuidv4 } from '../../amplify/backend/function/appgalawebutil/lib/nodejs/utils';

type AuthState = {
	token?: string;
};
const auth = authExchange<AuthState>({
	willAuthError: ({ authState }) => !authState,
	didAuthError: ({ error }: { error: CombinedError }) =>
		error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN'),
	addAuthToOperation: ({ authState, operation }) => {
		if (!authState || !authState.token) {
			return operation;
		}
		const fetchOptions =
			typeof operation.context.fetchOptions === 'function'
				? operation.context.fetchOptions()
				: operation.context.fetchOptions || {};

		return makeOperation(operation.kind, operation, {
			...operation.context,
			fetchOptions: {
				...fetchOptions,
				headers: {
					...fetchOptions.headers,
					Authorization: `Bearer ${JSON.parse(authState.token)}`
				}
			}
		});
	},
	getAuth: async ({ authState }) => {
		if (!authState) {
			const token = localStorage.getItem('authorization_token');
			if (!token) {
				return null;
			}
			return { token };
		}
		const { eventNameFromUrl, onSignOut } = useApp();
		await onSignOut(eventNameFromUrl());
		return null;
	}
});

const getConnectionId = () => {
	const connectionId = sessionStorage.getItem('connection_id');

	if (!connectionId) {
		const newConnectionId = uuidv4();
		sessionStorage.setItem('connection_id', newConnectionId);
		return newConnectionId;
	}

	return connectionId;
};

export const wsClient = createWSClient({
	url: `${import.meta.env.VITE_WS_ENDPOINT}/graphql`,
	connectionParams: () => ({
		// @ts-ignore
		authToken: JSON.parse(localStorage.getItem('authorization_token')),
		connectionId: getConnectionId()
	})
});

const urqlConfig: ClientOptions = {
	url: `${import.meta.env.VITE_API_ENDPOINT}/graphql`,
	requestPolicy: 'cache-and-network',
	exchanges: [
		auth,
		multipartFetchExchange,
		devtoolsExchange,
		subscriptionExchange({
			forwardSubscription(request) {
				const input = { ...request, query: request.query || '' };
				return {
					subscribe: (sink) => {
						const unsubscribe = wsClient.subscribe(input, sink);
						return { unsubscribe };
					}
				};
			}
		}),
		...defaultExchanges
	]
};

const urqlClient = createClient(urqlConfig);

export { urqlClient };
