<script lang="ts" setup>
	import { API } from 'aws-amplify';
	import { useRoute, useRouter } from 'vue-router';
	import { storeToRefs } from 'pinia';
	import { defineComponent } from 'vue';

	import useApp from '@/composables/useApp';
	import HelpButton from '@/custom/default/vue/components/common/HelpButton.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import SignInDefault from '@/custom/default/vue/components/auth/Signin/SignInDefault.vue';
	import SignInSSO from '@/custom/default/vue/components/auth/Signin/SignInSSO.vue';
	import AuthenticationCard from '@/custom/default/vue/components/auth/AuthenticationCard.vue';
	import useAuthenticationView from '@/composables/useAuthenticationView';
	import DividerText from '@/custom/default/vue/components/common/DividerText.vue';
	import { useI18n } from 'vue-i18n';
	import useCurrentEvent from '@/features/Events/store';

	defineComponent({
		name: ComponentsName.SignIn
	});

	const router = useRouter();
	const route = useRoute();
	const { t } = useI18n();

	const { getCurrentEventId } = storeToRefs(useCurrentEvent());
	const { resolver } = useApp();

	const { logoSSO, authConfig } = useAuthenticationView();

	const signInProvider = async () => {
		const restGatewayUrl = await API.endpoint('appgalawebGateway');
		const SSOUrl = `${restGatewayUrl}/sso/signin?eventName=${route.params.eventName}&eventId=${getCurrentEventId.value}`;
		window.location.assign(SSOUrl);
	};
</script>

<template>
	<AuthenticationCard>
		<template v-if="!authConfig.noSSO" #auth-card-content-header>
			<SignInSSO :append-logo="logoSSO" block color="primary" size="large" @click="signInProvider" />
			<divider-text :text="t('auth_divider_text')" />
		</template>

		<template #auth-card-method>
			<SignInDefault />
		</template>

		<template #auth-card-content-footer>
			<HelpButton btn-class-name="w-50" class="bg-primary" class-card="bg-secondary" language-switcher />
		</template>
	</AuthenticationCard>
</template>

<style scoped>
	:deep(.v-divider) {
		opacity: 0.5;
		margin: 1rem 0;
	}
</style>

<i18n>
{
	"en": {
		"auth_divider_text": "OR"
	},
	"fr": {
		"auth_divider_text": "OU"
	}
}
</i18n>
