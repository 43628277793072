<script lang="ts" setup>
	import { useI18n } from 'vue-i18n';
	import useApp from '@/composables/useApp';
	import { itemJobOpening } from '@/custom/solotech-carriere-virtuelle/vue/components/jobOpening/types';
	import { ref } from 'vue';

	withDefaults(
		defineProps<{
			title: string;
			items?: itemJobOpening[];
			minHeight?: string;
		}>(),
		{
			title: '',
			items: () => [],
			minHeight: 'auto'
		}
	);

	const { t } = useI18n();
	const { resolver } = useApp();

	const componentRef = ref<HTMLElement | null>(null);

	defineExpose({
		componentRef
	});
</script>

<template>
	<section ref="componentRef" class="d-flex flex-column h-100 min-height pb-2 pt-4 rounded bg-secondary">
		<h1 class="subtitle text-caption py-1 px-2 mb-3 bg-white text-secondary">{{ t(`${title}_title`) }}</h1>
		<ul class="list d-flex flex-column flex-grow-1 px-3">
			<li v-for="{ img, content, number } in items" :key="number" class="d-flex">
				<div class="mr-5">
					<div class="list-img">
						<v-img v-if="img" height="56px" width="56px" :src="resolver.getImageFromPath(`tables/${img}`)" alt="" />
					</div>
					<div v-if="img" class="text-caption mt-2 text-center">#{{ number }}</div>
				</div>
				<template v-if="Array.isArray(content)">
					<div>
						<p v-for="(text, index) in content" :key="index" class="mb-4">{{ t(text) }}</p>
					</div>
				</template>
				<p v-else>{{ t(content) }}</p>
			</li>
		</ul>
	</section>
</template>

<style scoped scss>
	.subtitle {
		margin-right: 20%;
		font-weight: 800;
		border-radius: 0 20px 20px 0;
	}
	.list {
		list-style: none;
		gap: 12px;
	}
	.list-img {
		max-width: 56px;
		height: 56px;
		width: 56px;
	}
	.min-height {
		min-height: v-bind(minHeight);
	}
</style>
