<script lang="ts" setup>
	import { useI18n } from 'vue-i18n';
	import footerImg from '@/custom/solotech-career/assets/logoFooter.png';

	const { t } = useI18n();
</script>
<template>
	<v-footer app fixed class="footer d-flex align-center py-1 pl-1">
		<v-img class="logo-footer mr-2" width="19" height="19" :src="footerImg" alt="" />
		<p class="text-caption">{{ t('footer-copyright') }}</p>
	</v-footer>
</template>

<style scoped>
	.footer {
		background: rgba(37, 22, 73, 0.43);
		backdrop-filter: blur(8px);
	}
	.logo-footer {
		max-width: 19px;
	}
</style>

<i18n>
{
	"en": {
		"footer-copyright": "© 2023 Solotech, All rights reserved."
	},
	"fr": {
		"footer-copyright": "© 2023 Solotech, Tous droits réservés."
	}
}
</i18n>
