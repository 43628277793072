import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import useConfig from '@/features/Config';
import useApp from '@/composables/useApp';
import { ComponentsName } from '@/plugins/lib/constants';

import type { Ref, ComputedRef } from 'vue';
import type { AuthConfig } from '@/features/Config/types';
import useCurrentEvent from '@/features/Events/store';

export default (): {
	eventFromDate: Ref<string | undefined>;
	eventToDate: Ref<string | undefined>;
	defaultImgLogin: Ref<string | undefined>;
	defaultImgChrome: Ref<string | undefined>;
	defaultImgFirefox: Ref<string | undefined>;
	defaultImgEdge: Ref<string | undefined>;
	logoBottom: Ref<string | undefined>;
	headerLogo: Ref<string | undefined>;
	logoSSO: Ref<string | undefined>;
	authConfig: ComputedRef<Partial<AuthConfig>>;
} => {
	defineComponent({ name: ComponentsName.Authentication });
	const { resolver } = useApp();

	const { getCurrentEventDateStart, getCurrentEventDateEnd } = storeToRefs(useCurrentEvent());

	const {
		store: { currentConfig }
	} = useConfig();

	const defaultImgLogin = ref<string>();
	const defaultImgChrome = ref<string>();
	const defaultImgFirefox = ref<string>();
	const defaultImgEdge = ref<string>();
	const logoBottom = ref<string>();
	const headerLogo = ref<string>();
	const logoSSO = ref<string>();

	const authConfig = computed<Partial<AuthConfig>>(() => currentConfig.value.authentication ?? {});

	onBeforeMount(() => {
		const { authentication } = currentConfig.value;

		defaultImgLogin.value = resolver.getImageFromPath(authentication.background ?? resolver.DEFAULT_IMG_LOGIN);
		defaultImgChrome.value = resolver.getImageFromPath(resolver.DEFAULT_IMG_CHROME_ICON);
		defaultImgFirefox.value = resolver.getImageFromPath(resolver.DEFAULT_IMG_FIREFOX_ICON);
		defaultImgEdge.value = resolver.getImageFromPath(resolver.DEFAULT_IMG_EDGE_ICON);
		logoBottom.value = resolver.getImageFromPath(resolver.DEFAULT_LOGO_BOTTOM);
		headerLogo.value = resolver.getImageFromPath(resolver.DEFAULT_HEADER_LOGO);
		logoSSO.value = resolver.getImageFromPath(authConfig.value?.logoSSO ?? resolver.DEFAULT_IMG_LOGO_SSO);
	});

	return {
		authConfig,
		eventFromDate: getCurrentEventDateEnd,
		eventToDate: getCurrentEventDateStart,
		defaultImgLogin,
		defaultImgChrome,
		defaultImgFirefox,
		defaultImgEdge,
		logoBottom,
		headerLogo,
		logoSSO
	};
};
