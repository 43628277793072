import { defineStore, storeToRefs } from 'pinia';
import { v4 as uuidV4 } from 'uuid';
import { MessageColor } from './types';

import type { Message, MessageOptions } from './types';

const storeDefinition = defineStore('Toast', {
	state: (): {
		messages: Message[];
	} => ({
		messages: []
	}),

	getters: {
		toastMessage(): Message {
			return this.messages[0];
		},
		isShowing(): boolean {
			return !!this.toastMessage;
		},
		showConfirm(): boolean {
			return typeof this.toastMessage?.confirm === 'function';
		}
	},

	actions: {
		deleteMessage(): void {
			this.messages = this.messages.filter((msg) => msg.id !== this.toastMessage.id);
		},
		triggerToast(text: string, opts?: MessageOptions) {
			const id = uuidV4();
			const message = {
				id,
				createdAt: new Date().valueOf(),
				...opts,
				text,
				color: opts?.color ?? MessageColor.primary,
				closable: opts?.cancel === undefined,
				close: this.deleteMessage,
				cancel:
					typeof opts?.cancel === 'function'
						? () => {
								if (opts?.cancel) opts.cancel();
								this.deleteMessage();
						  }
						: undefined,
				confirm:
					typeof opts?.confirm === 'function'
						? async () => {
								if (opts?.confirm) await opts.confirm();
								this.deleteMessage();
						  }
						: undefined
			};
			this.messages = [message, ...this.messages];
			if (typeof opts?.confirm !== 'function') {
				setTimeout(() => {
					this.deleteMessage();
				}, 10000);
			}
		}
	}
});

export default () => {
	const def = storeDefinition();

	return {
		...storeToRefs(def),
		deleteMessage: def.deleteMessage,
		triggerToast: def.triggerToast
	};
};
