<script lang="ts" setup>
	import { computed, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useVModel } from '@vueuse/core';
	import { ImportUsersDataBase } from './types';
	import { MessageColor } from '@/features/Toast/types';
	import useToast from '@/features/Toast';
	import { useDeleteUsersMutation } from '@/gql/operations';
	import { DeleteUsersInput } from '@/gql/types';

	const props = defineProps<{
		isDialogDeleteUsers: boolean;
		usersSelected: ImportUsersDataBase[];
		isLoading: boolean;
		users: ImportUsersDataBase[];
	}>();
	const emits = defineEmits(['update:isDialogDeleteUsers', 'update:isLoading', 'update:usersSelected', 'update:users']);

	const { t } = useI18n();
	const { triggerToast } = useToast();

	const isDialogDeleteUsersVisibleVModel = useVModel(props, 'isDialogDeleteUsers', emits);
	const usersSelectedVModel = useVModel(props, 'usersSelected', emits);
	const isLoadingVModel = useVModel(props, 'isLoading', emits);
	const usersVModel = useVModel(props, 'users', emits);

	const refBtnCancel = ref<HTMLButtonElement | null>(null);

	watch(
		[isDialogDeleteUsersVisibleVModel, refBtnCancel],
		(newData, oldData) => {
			const oldBtnCancel = oldData[1];
			const isDialogVisible = newData[0];
			const BtnCancel = newData[1];
			if (isDialogVisible && BtnCancel && !oldBtnCancel) {
				//@ts-ignore
				refBtnCancel.value?.$el?.focus();
			}
		},
		{ immediate: true }
	);

	const deleteUsersMutation = useDeleteUsersMutation();

	const usersSelectedCount = computed(() => usersSelectedVModel.value.length);

	async function deleteUsers() {
		isDialogDeleteUsersVisibleVModel.value = false;
		isLoadingVModel.value = true;

		const usersRegistered = usersSelectedVModel.value.filter(({ status }) => status !== t('labels.to_be_imported'));
		const usersNotRegistered = usersSelectedVModel.value.filter(({ status }) => status === t('labels.to_be_imported'));

		if (usersRegistered.length > 0) {
			await deleteUsersRegisteredMutation(usersRegistered);
		}

		if (usersNotRegistered.length > 0) {
			usersVModel.value = usersVModel.value.filter(
				(user) => !usersNotRegistered.some((userNotRegistered) => userNotRegistered.id === user.id)
			);
		}
		usersSelectedVModel.value = [];
		isLoadingVModel.value = false;
	}

	async function deleteUsersRegisteredMutation(usersRegistered: ImportUsersDataBase[]) {
		const deleteUsersInput: DeleteUsersInput[] = usersRegistered.map((user) => ({
			userId: user.id
		}));

		try {
			const data = await deleteUsersMutation.executeMutation({ deleteUsersInput });
			console.log(data);
			if (data.error) {
				triggerToast(t('users_deletion_success_delete'), { color: MessageColor.error });
			}
			const deleteUsers = data.data?.deleteUsers || [];
			const userToDelete = deleteUsers.filter((user) => user.success);
			if (userToDelete.length < 1) {
				triggerToast(t('users_deletion_success_delete'), { color: MessageColor.error });
				return;
			}
			if (userToDelete.length !== deleteUsersInput.length) {
				deleteUserdRef(userToDelete);
				triggerToast(t('users_deletion_partial_delete'), { color: MessageColor.warning });
				return;
			}

			deleteUserdRef(userToDelete);
			triggerToast(t('users_deletion_success_delete'), { color: MessageColor.success });
		} catch (e) {
			triggerToast(t('toast.failed_delete'), { color: MessageColor.error });
		}
	}

	function deleteUserdRef(userToDelete: { userId: string }[]) {
		usersVModel.value = usersVModel.value.filter(
			(user) => !userToDelete.find((userRegistered) => userRegistered.userId === user.id)
		);
	}
</script>

<template>
	<v-dialog
		v-model="isDialogDeleteUsersVisibleVModel"
		role="alertdialog"
		aria-labelledby="dialog-label"
		aria-describedby="dialog-description"
		data-cy="dialog-delete-users"
	>
		<v-card color="error">
			<v-card-title class="d-flex align-baseline justify-space-between">
				<h2 id="dialog-label" class="text-h6 font-weight-bold mr-2">{{ t('warning') }}</h2>
				<v-btn
					icon="mdi-close"
					size="small"
					variant="text"
					:aria-label="t('actions.close')"
					@click="isDialogDeleteUsersVisibleVModel = false"
				/>
			</v-card-title>
			<v-card-text>
				<p id="dialog-description">{{ t('users_deletion_description', { count: usersSelectedCount }) }}</p>
			</v-card-text>
			<v-card-actions>
				<div class="ml-auto">
					<v-btn
						ref="refBtnCancel"
						class="bg-indigo-darken-2 mr-2"
						@click="isDialogDeleteUsersVisibleVModel = !isDialogDeleteUsersVisibleVModel"
					>
						{{ t('actions.cancel') }}
					</v-btn>
					<v-btn class="bg-pink-darken-4" data-cy="btn-confirm-dialog-delete-user" @click="deleteUsers">{{
						t('actions.confirm')
					}}</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<i18n>
{
	"fr": {
		"warning": "Attention",
		"users_deletion_description": "Are you sure you want to delete {count} user ? | Are you sure you want to delete {count} selected users ?",
		"users_deletion_success_delete": "User deleted | Users deleted",
		"users_deletion_failed_delete": "Failed to delete user | Failed to delete users",
		"users_deletion_partial_delete": "Some users have not been deleted"
	},
	"en": {
		"warning": "Warning",
		"users_deletion_description": "Êtes-vous sûr de vouloir supprimer {count} utilisateur ? | Êtes-vous sûr de vouloir supprimer les utilisateurs {count} sélectionnés ?",
		"users_deletion_failed_delete": "Échec de la suppression de l'utilisateur | Échec de la suppression des utilisateurs",
		"users_deletion_success_delete": "Utilisateur supprimé | Utilisateurs supprimés",
		"users_deletion_partial_delete": "Certains utilisateurs n'ont pas été supprimés"
	}
}
</i18n>
