import { defineStore } from 'pinia';
import useCurrentUser from '@/features/CurrentUser/store';
import { AWSGraphqlMutation, AWSGraphqlQuery } from '@/graphql';
import { listBlockedUsers } from '@/graphql/queries';
import { ActiveUsers, Profiles, UserTable } from '@/graphql/types';
import orderBy from 'lodash/orderBy';
import { createBlockedUsers, deleteBlockedUsers } from '@/graphql/mutations';
import { ActiveUserActions, ActiveUserGetters, ActiveUserState } from '@/features/ActiveUsers/type';
import omit from 'lodash/omit';
import { pipe, subscribe } from 'wonka';

import {
	ListUserSessions,
	OnUserSessionCreated,
	OnUserSessionDeleted,
	UserSessionJoinTable,
	UserSessionLeaveTable
} from '@/gql/operations';
import { urqlClient } from '@/plugins/urql';
import { UserSession } from '@/gql/types';
import useCurrentEvent from '@/features/Events/store';

export const useActiveUsers = defineStore<string, ActiveUserState, ActiveUserGetters, ActiveUserActions>('ActiveUser', {
	state: () => ({
		activeUsers: [],
		activeUsersBlocked: [],
		nextPage: null,
		searchTerm: null,
		subscriptions: {
			onUserSessionCreatedSubscription: () => {},
			onUserSessionDeletedSubscription: () => {}
		}
	}),
	getters: {
		getActiveUsersProfiles(state) {
			const sessionId = localStorage.getItem('session_id');
			const profiles = state.activeUsers
				.filter(({ session_id }) => session_id !== sessionId)
				.map(({ profile }) => profile);
			// @BUG: The type is forced because the profile can be null or undefined
			// and in the case where we have to retrieve them this should not be the case
			return orderBy(profiles, ['user_name'], ['asc']) as Profiles[];
		},
		getCurrentActiveUser(state) {
			const sessionId = localStorage.getItem('session_id');
			return state.activeUsers.find(({ session_id }) => session_id === sessionId);
		},
		getActiveUser: (state) => (userId: string) => state.activeUsers.find(({ user_id }) => user_id === userId),
		getActiveUserBySessionId: (state) => (sessionId: string) =>
			state.activeUsers.find(({ session_id }) => session_id === sessionId),
		getActiveUserByProfileId: (state) => (profileId: string) => {
			const userProfile = state.activeUsers.find(({ profile }) => profile?.id === profileId)?.profile;
			if (!userProfile) {
				return null;
			}
			return userProfile;
		},
		hasNextToken(state) {
			return !!state.nextPage;
		},
		searchActiveUsersByUsername(state) {
			if (!state.searchTerm) {
				return this.getActiveUsersProfiles;
			}
			return orderBy(
				this.getActiveUsersProfiles.filter(
					(profile) => state.searchTerm && profile?.user_name.toLowerCase().includes(state.searchTerm.toLowerCase())
				),
				['user_name'],
				['asc']
			);
		},
		activeUserIsBlocked: (state) => (userId) =>
			!!state.activeUsersBlocked.find(({ user_id_from }) => user_id_from === userId)
	},
	actions: {
		async fetchActiveUsers(limit = 100, nextToken?: string) {
			const { getCurrentEventId } = useCurrentEvent();

			const list = await urqlClient
				.query(ListUserSessions, { listUserSessionsInput: { event_id: getCurrentEventId } })
				.toPromise()
				.catch((error) => {
					throw Error(`Cannot retrieve 'ActiveUsersList'. ${error}`);
				});

			this.setActiveUsers(
				//@ts-ignore
				list.data.listUserSessions.user_sessions.map((session) => {
					session.session_id = session.id;
					session.profile = session.user.profile;
					return session;
				})
			);
		},
		setTableToCurrentActiveUser: function (sessionId, userTableId = null) {
			if (!sessionId) return;

			const promise = userTableId
				? urqlClient
						.mutation(UserSessionJoinTable, { userSessionJoinTableInput: { user_table_id: userTableId } })
						.toPromise()
				: urqlClient.mutation(UserSessionLeaveTable, {}).toPromise();

			promise
				.then((result) => {
					const updatedUserSession: UserSession = userTableId
						? result.data.userSessionJoinTable
						: result.data.userSessionLeaveTable;
					const updatedActiveUser = {
						...updatedUserSession,
						session_id: updatedUserSession.id,
						profile: updatedUserSession.user?.profile
					} as unknown as ActiveUsers;
					const updateActiveUserIndex = this.activeUsers.findIndex(
						(user) => user.session_id === updatedActiveUser.session_id
					);
					this.activeUsers.splice(updateActiveUserIndex, 1, updatedActiveUser);
				})
				.catch((err) => {
					throw Error(`Unable to assign table for current user. ${JSON.stringify(err)}`);
				});
		},
		setActiveUsers(payload: ActiveUsers | ActiveUsers[]) {
			if (!Array.isArray(payload)) {
				this.activeUsers = [...this.activeUsers, payload];
				return;
			}
			this.activeUsers = [...this.activeUsers, ...payload];
		},
		updateUserTableActiveUser(sessionId: string, userTable?: UserTable) {
			const activeUserIndex = this.activeUsers.findIndex((activeUser) => activeUser.session_id === sessionId);
			if (activeUserIndex >= 0) {
				this.activeUsers[activeUserIndex].user_table = userTable;
			}
		},
		removeActiveUser(sessionId: string) {
			this.activeUsers = this.activeUsers.filter((item: ActiveUsers) => item.session_id !== sessionId);
		},
		loadMoreActiveUser() {
			if (this.nextPage) {
				this.fetchActiveUsers(100, this.nextPage);
			}
		},
		fetchBlockedUsers(limit = 100, nextToken?: string) {
			const { getUserID } = useCurrentUser();
			AWSGraphqlQuery(listBlockedUsers, { limit, filter: { user_id_to: { eq: getUserID } }, nextToken })
				.then(
					({
						data: {
							listBlockedUsers: { items }
						}
					}) => {
						this.setBlockedUsers(items);
					}
				)
				.catch((error) => {
					throw Error(`Cannot retrieve 'BlockedUsersList'. ${error}`);
				});
		},
		setBlockedUsers(payload) {
			if (!Array.isArray(payload)) {
				this.activeUsersBlocked = [...this.activeUsersBlocked, payload];
				return;
			}
			this.activeUsersBlocked = [...this.activeUsersBlocked, ...payload];
		},
		removeBlockedUser(payload) {
			this.activeUsersBlocked = this.activeUsersBlocked.filter(({ user_id_from }) => user_id_from !== payload);
		},
		blockActiveUser(user_id_from: string | null) {
			if (!this.getCurrentActiveUser) throw Error('CurrentActiveUser is not available');
			if (!user_id_from) throw Error("User id doesn't provided");
			if (this.activeUserIsBlocked(user_id_from)) {
				this.unblockActiveUser(user_id_from);
				return;
			}
			const { event_id, user_id: user_id_to } = this.getCurrentActiveUser;
			AWSGraphqlMutation(createBlockedUsers, { event_id, user_id_to, user_id_from })
				.then(({ data: { createBlockedUsers } }) => {
					this.setBlockedUsers(createBlockedUsers);
				})
				.catch((err) => console.error(err));
		},
		unblockActiveUser(userId: string) {
			const blockedUser = this.activeUsersBlocked.find(({ user_id_from }) => user_id_from === userId);
			if (!blockedUser) {
				throw Error('Unable to find this blocked user');
			}
			AWSGraphqlMutation(deleteBlockedUsers, { id: blockedUser.id })
				.then(() => {
					this.removeBlockedUser(userId);
				})
				.catch((err) => {
					throw Error(err);
				});
		},
		async subscribe() {
			const { getEventID } = useCurrentUser();
			const self = this;
			try {
				const { unsubscribe } = pipe(
					urqlClient.subscription(OnUserSessionCreated, {
						event_id: getEventID
					}),
					subscribe((result) => {
						if (result?.data) {
							const session = result.data.onUserSessionCreated;
							session.session_id = session.id;
							session.profile = session.user.profile;
							self.setActiveUsers(session as ActiveUsers);
						}
					})
				);

				this.subscriptions.onUserSessionCreatedSubscription = unsubscribe;
			} catch (e) {
				console.error('OnUserSessionCreated subscription failed, reason:', e);
			}

			try {
				const { unsubscribe } = pipe(
					urqlClient.subscription(OnUserSessionDeleted, {
						event_id: getEventID
					}),
					subscribe((result) => {
						if (result?.data) {
							const session = result?.data.onUserSessionDeleted;
							self.removeActiveUser(session.id);
						}
					})
				);
				this.subscriptions.onUserSessionDeletedSubscription = unsubscribe;
			} catch (e) {
				console.error('OnUserSessionDeleted subscription failed, reason:', e);
			}
		},
		unsubscribe() {
			this.subscriptions.onUserSessionCreatedSubscription();
			this.subscriptions.onUserSessionDeletedSubscription();
		}
	}
});
