import { computed, nextTick, ref, Ref, watch } from 'vue';
import { useScroll } from '@vueuse/core';

const getMessageContainerRefValue = (messageContainerRef: Ref) =>
	messageContainerRef?.value?.$el || messageContainerRef?.value;

export default (scrollableElementRef: Ref, messages: Ref) => {
	const { y } = useScroll(scrollableElementRef, { throttle: 300 });
	const hasNoScroll = ref(true);

	watch(
		messages,
		async () => {
			await nextTick();
			const messageContainerRefValue = getMessageContainerRefValue(scrollableElementRef);
			const clientHeight = messageContainerRefValue?.clientHeight || 0;
			const scrollHeight = messageContainerRefValue?.scrollHeight || 0;
			hasNoScroll.value = scrollHeight <= clientHeight;
		},
		{ immediate: true }
	);

	const scrollIsBottom = computed(
		() =>
			(getMessageContainerRefValue(scrollableElementRef)?.scrollHeight || 0) -
				(getMessageContainerRefValue(scrollableElementRef)?.clientHeight || 0) -
				40 <=
			y.value
	);

	const scrollToBottom = async () => {
		await nextTick();
		const messageContainerRefValue = getMessageContainerRefValue(scrollableElementRef);
		if (messageContainerRefValue?.scrollHeight) {
			messageContainerRefValue.scrollTop = messageContainerRefValue.scrollHeight;
		}
	};

	return { scrollToBottom, scrollIsBottom, hasNoScroll };
};
