<script setup lang="ts">
	import type { Ref } from 'vue';
	import { computed, nextTick, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { v4 as uuidV4 } from 'uuid';
	import { FilterMatchMode } from 'primevue/api';
	import { storeToRefs } from 'pinia';
	import DataTable, { DataTableRowEditSaveEvent } from 'primevue/datatable';
	import Column from 'primevue/column';
	import CsvImport from '@/custom/default/vue/components/admin/usersManagement/CsvImportUsers.vue';
	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import SuccessAndErrors from '@/custom/default/vue/components/admin/usersManagement/SuccessAndErrors.vue';

	import useDateFormatter from '@/composables/useDateFormatter';
	import { ImportUsersDataBase } from './usersManagement/types';
	import { User } from '@/graphql/types';
	import useCurrentUser from '@/features/CurrentUser/store';
	import useUserValidation from '@/composables/useUserValidation';
	import DialogImportUsers from '@/custom/default/vue/components/admin/usersManagement/DialogImportUsers.vue';
	import useToast from '@/features/Toast';
	import { MessageColor } from '@/features/Toast/types';
	import DialogDeleteUsers from '@/custom/default/vue/components/admin/usersManagement/DialogDeleteUsers.vue';
	import { useActiveUsers } from '@/features/ActiveUsers/store';
	import { ListUsers, useGetEventByNameQuery, useImportUsersMutation, useUpdateUserMutation } from '@/gql/operations';
	import { Language, RoleName } from '@/gql/types';
	import { urqlClient } from '@/plugins/urql';
	import useCurrentEvent from '@/features/Events/store';

	const { t, locale } = useI18n();
	const { getCurrentEventId, getCurrentEventNameFromURL: getCurrentEventName } = storeToRefs(useCurrentEvent());

	const { isUserAdmin } = storeToRefs(useCurrentUser());

	const errors: Ref<Record<string, Record<string, { messages: string[]; isValid: boolean }>>> = ref({});
	const roleItems = ref([...(Object.values(RoleName) as Array<RoleName>).map((value) => value)]);
	const isVisibleOverlay: Ref<boolean> = ref(false);

	const users = ref<any>([]);
	const isLoading = ref(true);
	const usersSelected = ref<ImportUsersDataBase[]>([]);
	const successUsersImported = ref<ImportUsersDataBase[]>([]);
	const errorsUsersImported = ref<ImportUsersDataBase[]>([]);
	const isDialogCsvImportVisible = ref(false);
	const isDialogDeleteUsersVisible = ref(false);
	const editingRows = ref([]);

	const { userEmailValidation, userNameValidation, userPasswordValidation, validation } = useUserValidation(
		users,
		errors
	);
	const { commonDateFormat } = useDateFormatter();
	const { triggerToast } = useToast();
	const { getActiveUser } = storeToRefs(useActiveUsers());
	const getEventByNameQuery = useGetEventByNameQuery({ variables: { event_name: getCurrentEventName.value } });
	const eventID: Ref<string | undefined> = ref(undefined);
	const needCount: Ref<boolean | undefined> = ref(true);
	const usersCount = ref(0);
	const lastEvaluatedKey: Ref<string | undefined> = ref(undefined);
	const listUsers = ref({});
	const first = ref(5);
	const currentPage = ref(5);

	watch(
		() => getEventByNameQuery.data.value?.getEventByName.id,
		async (value) => {
			eventID.value = value;
			await initialFetchUsers();
		}
	);

	const initialFetchUsers = async () => {
		const fetch = await urqlClient
			.query(ListUsers, {
				list: { event_id: eventID.value, count: needCount.value, limit: 50 }
			})
			.toPromise();
		onFetchUsers(fetch.data.listUsers, true);
		needCount.value = false;
	};

	const onFetchUsers = (value: any, reset = false) => {
		if (value.count) {
			usersCount.value = value?.count;
		}
		lastEvaluatedKey.value = value?.last_evaluated_key || null;

		const newUsers =
			value?.users?.map((u: User) => ({
				...u,
				isValid: true,
				role: (u?.roleNames?.[0] as unknown as RoleName) || RoleName.User,
				seen_last: u?.profile?.seen_last,
				user_name: u?.profile?.user_name,
				profileID: u?.profile?.id,
				status: t('labels.offline'),
				password: 'N/A'
			})) || [];

		isLoading.value = false;

		if (reset) {
			return (users.value = newUsers);
		}
		users.value = [...users.value, ...newUsers];
	};
	defineProps<{ title: string }>();

	const importUsersMutation = useImportUsersMutation();
	const updateUsersMutation = useUpdateUserMutation();

	const countUserReadyToImport = computed(() => {
		const validUser = usersSelected.value.filter(
			({ isValid, status }) => isValid && status === t('labels.to_be_imported')
		);
		return validUser.length;
	});

	const dataFilter: Ref<Record<string, { value: any | null; matchMode: string }>> = ref({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		isValid: { value: null, matchMode: FilterMatchMode.EQUALS }
	});

	const createUser = async () => {
		const newUser: ImportUsersDataBase = {
			id: uuidV4(),
			user_name: '',
			user_email: '',
			password: '',
			role: RoleName.User,
			seen_last: 'N/A',
			status: t('labels.to_be_imported'),
			isValid: false
		};

		newUser.isValid = validation(newUser);

		users.value = [...users.value, newUser];
		await nextTick();
		(document.querySelector('.p-paginator-last') as HTMLButtonElement)?.click();
	};

	const saveUser = async (data: ImportUsersDataBase) => {
		const isValid = validation(data);
		if (!isValid) {
			const userIndex = users.value.findIndex(({ id }: { id: string }) => id === data.id);
			users.value[userIndex] = { ...data, isValid };
			return triggerToast(t('error_import_user'), { color: MessageColor.error });
		}
		usersSelected.value = [data];
		await nextTick();
		await saveSelectedUsers();
	};

	const saveSelectedUsers = async () => {
		isLoading.value = true;
		const selectedUsersReadyToImport =
			usersSelected.value.length > 0
				? usersSelected.value
				: users.value.filter(({ status }: { status: string }) => status === t('labels.to_be_imported'));
		const validUsers = selectedUsersReadyToImport.filter(({ isValid }: { isValid: boolean }) => isValid);

		const importUsersInput = validUsers.map(
			({
				user_email,
				user_name,
				password,
				role
			}: {
				user_email: string;
				user_name: string;
				password: string;
				role: string;
			}) => ({
				user_email: user_email.toLowerCase(),
				user_name,
				password,
				roleNames: isUserAdmin.value ? [role] : [RoleName.User],
				language: locale.value === 'fr' ? Language.Fr : Language.En,
				event_id: getCurrentEventId.value
			})
		);

		try {
			const result = await importUsersMutation.executeMutation({
				importUsersInput
			});

			const importUsers = result?.data?.importUsers || [];

			if (result.error) {
				triggerToast(t('toast.failed_import', selectedUsersReadyToImport), { color: MessageColor.error });
				return;
			}

			const usersCreated = importUsers.filter(({ hasCreated }) => hasCreated);
			const usersCreatedEmails = usersCreated.map((user) => user?.user?.user_email);
			const usersNotCreated = importUsers.filter(({ hasCreated }) => !hasCreated).map((user) => user?.user?.user_email);

			errorsUsersImported.value = users.value.filter(({ user_email }: User) =>
				usersNotCreated.includes(user_email.toLowerCase())
			);

			successUsersImported.value = users.value.filter(({ user_email }: User) =>
				usersCreatedEmails.includes(user_email.toLowerCase())
			);

			usersCreated.forEach((userCreated) => {
				const userIndex = users.value.findIndex((user: User) => user.user_email === userCreated.user?.user_email);

				users.value[userIndex] = {
					...users.value[userIndex],
					id: userCreated?.user?.id || '',
					status: t('labels.offline'),
					password: 'N/A',
					isValid: true
				};
			});
		} catch (e) {
			console.error('import user ', e);
		} finally {
			isLoading.value = false;
			isVisibleOverlay.value = false;
		}

		isLoading.value = false;
		isVisibleOverlay.value = false;
	};

	const onCellEditComplete = async (event: DataTableRowEditSaveEvent) => {
		isLoading.value = true;
		const { index, newData, data } = event;
		const isValid = validation(newData);
		if (data.status === t('labels.to_be_imported')) {
			updateUserRef(newData, isValid);
			return (isLoading.value = false);
		}
		if (!isValid) {
			triggerToast(t('toast.failed_update'), { color: MessageColor.error });
			return (isLoading.value = false);
		}
		await updateUserServer(newData);
		isLoading.value = false;
	};

	const updateUserRef = (newData: ImportUsersDataBase, isValid: boolean, status = t('labels.to_be_imported')) => {
		const userIndex = users.value.findIndex((user: User) => user.id === newData.id);
		users.value[userIndex] = {
			...newData,
			status,
			isValid
		};
		triggerToast(t('toast.success_update'), { color: MessageColor.success });
	};

	const updateUserServer = async (newData: ImportUsersDataBase) => {
		try {
			await updateUsersMutation.executeMutation({
				updateUserInput: {
					id: newData.id,
					user_email: newData.user_email,
					roleNames: [newData.role] as unknown as RoleName[],
					user_name: newData.user_name
				}
			});
			updateUserRef(newData, true, t('labels.offline'));
			triggerToast(t('toast.success_update'), { color: MessageColor.success });
		} catch (e) {
			console.error("failed to update user's data", e);
			triggerToast(t('toast.failed_update'), { color: MessageColor.error });
		}
	};

	const deleteUser = (user: ImportUsersDataBase) => {
		isDialogDeleteUsersVisible.value = true;
		usersSelected.value = [user];
	};

	const onPaginate = async (e: any) => {
		if (e.page + 1 === e.pageCount && users.value.length < usersCount.value) {
			const fetch = await urqlClient
				.query(ListUsers, {
					list: { event_id: eventID.value, count: false, limit: 50, last_evaluated_key: lastEvaluatedKey.value }
				})
				.toPromise();
			onFetchUsers(fetch.data.listUsers);
		}
	};

	const onSearch = async (e: any) => {
		isLoading.value = true;
		if (e.target.value === '') {
			return initialFetchUsers();
		}
		const fetch = await urqlClient
			.query(ListUsers, {
				list: {
					event_id: eventID.value,
					count: false,
					user_email_begins_with: e.target.value
				}
			})
			.toPromise();

		onFetchUsers(fetch.data.listUsers, true);
	};

	const rowClass = (data: any): string => (!data.isValid ? 'user-management-row-warning' : '');
</script>

<template>
	<v-card class="mb-8 user-management">
		<v-card-title class="bg-secondary">
			<h1 class="text-h6 font-weight-bold text-uppercase">{{ t('control_room_users') }}</h1>
		</v-card-title>
		<v-card-text>
			<CsvImport
				v-model:users="users"
				v-model:is-visible="isDialogCsvImportVisible"
				v-model:errors="errors"
				@emit-validation="validation"
			/>
			<DataTable
				v-model:selection="usersSelected"
				v-model:filters="dataFilter"
				v-model:editing-rows="editingRows"
				v-model:page="currentPage"
				paginator
				:total-records="usersCount"
				:rows="10"
				edit-mode="row"
				data-key="id"
				:row-class="rowClass"
				:global-filter-fields="['user_name', 'user_email', 'role', 'password']"
				:value="users"
				:loading="isLoading"
				responsive-layout="scroll"
				@page="onPaginate"
				@row-edit-save="onCellEditComplete"
			>
				<template #empty>
					<p>{{ t('datatable_empty_message') }}</p>
				</template>

				<template #header>
					<div class="d-flex flex-wrap justify-space-between align-baseline">
						<v-text-field
							data-cy="datatable-input-search"
							type="search"
							class="create-users-input mr-4"
							autocomplete="off"
							prepend-icon="mdi-magnify"
							variant="underlined"
							:label="t('labels.search')"
							@input="onSearch"
						/>
						<div class="row-header-btn d-flex flex-wrap">
							<v-btn
								prepend-icon="mdi-plus"
								data-cy="btn-import-users-csv"
								color="white"
								@click="isDialogCsvImportVisible = true"
							>
								{{ t('labels.add_user_from_csv') }}
							</v-btn>
							<v-btn data-cy="datatable-actions-add-user" prepend-icon="mdi-plus" color="white" @click="createUser">
								{{ t('labels.add_user') }}
							</v-btn>
						</div>
					</div>
				</template>

				<Column selection-mode="multiple" />
				<Column :header="t('labels.username')" field="user_name" sortable>
					<template #body="{ data, field }">
						<div
							data-cy="datatable-column-username"
							:class="{ 'text-error': !data.isValid }"
							class="d-flex flex-column"
						>
							<p>{{ data[field] || t('placeholders.username') }}</p>
							<ul v-if="errors?.[data.id]?.[field]?.messages">
								<li v-for="(message, index) in errors[data.id][field].messages" :key="index">{{ message }}</li>
							</ul>
						</div>
					</template>
					<template #editor="{ data, field }">
						<input-field
							v-model="data[field]"
							data-cy="datatable-column-username-input"
							autofocus
							:label="t('labels.username')"
							:type="'text'"
							:rules="userNameValidation"
						/>
					</template>
				</Column>
				<Column :header="t('labels.email')" field="user_email" sortable>
					<template #body="{ data, field }">
						<div data-cy="datatable-column-email" :class="{ 'text-error': !data.isValid }" class="d-flex flex-column">
							<p>{{ data[field] || t('placeholders.email') }}</p>
							<ul v-if="errors?.[data.id]?.[field]?.messages" class="mt-2">
								<li v-for="(message, index) in errors?.[data.id]?.[field]?.messages" :key="index">{{ message }}</li>
							</ul>
						</div>
					</template>
					<template #editor="{ data, field }">
						<input-field
							v-model="data[field]"
							data-cy="datatable-column-email-input"
							:label="t('labels.email')"
							:type="'email'"
							:rules="userEmailValidation"
						/>
					</template>
				</Column>
				<Column :header="t('labels.seen_last')" field="seen_last">
					<template #body="{ field, data }">
						<p v-if="data[field] === 'N/A'" data-cy="datatable-column-seen-last">
							{{ data[field] }}
						</p>
						<p v-else data-cy="datatable-column-seen-last">{{ commonDateFormat(data[field]) }}</p>
					</template>
				</Column>
				<Column :header="t('labels.password')" field="password" sortable>
					<template #body="{ data, field }">
						<div
							data-cy="datatable-column-password"
							:class="{ 'text-error': !data.isValid }"
							class="d-flex flex-column"
						>
							<p>{{ data[field] || t('placeholders.password') }}</p>
							<ul v-if="errors?.[data.id]?.[field]?.messages" class="mt-2">
								<li v-for="(message, index) in errors[data.id][field].messages" :key="index">{{ message }}</li>
							</ul>
						</div>
					</template>
					<template #editor="{ data, field }">
						<p v-if="data[field] === 'N/A'">{{ data[field] || t('placeholders.password') }}</p>
						<input-field
							v-else
							v-model="data[field]"
							data-cy="datatable-column-password-input"
							:label="t('labels.password')"
							:type="'password'"
							:rules="userPasswordValidation"
							:autocomplete="'new-password'"
						/>
					</template>
				</Column>
				<Column v-if="isUserAdmin" :header="t('labels.role')" field="role" sortable>
					<template #body="{ data, field }">
						<div data-cy="datatable-column-role" :class="{ 'text-error': !data.isValid }" class="d-flex flex-column">
							<p>{{ data[field] || t('placeholders.role') }}</p>
							<ul v-if="errors?.[data.id]?.[field]?.messages" class="mt-2">
								<li v-for="(message, index) in errors[data.id][field].messages" :key="index">{{ message }}</li>
							</ul>
						</div>
					</template>
					<template #editor="{ data, field }">
						<v-select
							v-model="data[field]"
							data-cy="datatable-column-role-select"
							:label="t('labels.role')"
							variant="underlined"
							:items="roleItems"
						/>
					</template>
				</Column>
				<Column :header="t('labels.status')" field="status" sortable>
					<template #body="{ data, field }">
						<div>
							<p v-if="data[field] === t('labels.to_be_imported')" data-cy="datatable-column-status">
								{{ data[field] }}
							</p>
							<p v-else data-cy="datatable-column-status">
								{{ getActiveUser(data.id) ? t('labels.online') : t('labels.offline') }}
							</p>
						</div>
					</template>
				</Column>
				<Column row-editor body-style="text-align:center" :header="t('labels.actions')">
					<template #body="{ data, editorInitCallback }">
						<div class="d-flex row-header-btn">
							<v-btn
								v-if="data.status === t('labels.to_be_imported')"
								variant="outlined"
								size="small"
								color="success"
								data-cy="datatable-btn-import"
								:aria-label="t('labels.import_user')"
								@click="saveUser(data)"
							>
								<v-icon>mdi-tray-arrow-down</v-icon>
								<v-tooltip activator="parent">
									{{ t('labels.import_user') }}
								</v-tooltip>
							</v-btn>
							<v-btn
								variant="outlined"
								size="small"
								data-cy="datatable-btn-edit"
								:aria-label="t('labels.edit')"
								@click="editorInitCallback"
							>
								<v-icon>mdi-pencil</v-icon>
								<v-tooltip activator="parent">
									{{ t('labels.edit') }}
								</v-tooltip>
							</v-btn>
							<v-btn
								variant="outlined"
								data-cy="datatable-actions-delete-user"
								size="small"
								color="error"
								:aria-label="t('labels.delete')"
								@click="deleteUser(data)"
							>
								<v-icon plain>mdi-delete</v-icon>
								<v-tooltip activator="parent">
									{{ t('labels.delete') }}
								</v-tooltip>
							</v-btn>
						</div>
					</template>
					<template #editor="{ editorSaveCallback, editorCancelCallback }">
						<div class="d-flex row-header-btn">
							<v-btn
								variant="outlined"
								size="small"
								data-cy="datatable-btn-save"
								:aria-label="t('labels.save')"
								@click="editorSaveCallback"
							>
								<v-icon>mdi-check</v-icon>
								<v-tooltip activator="parent">
									{{ t('labels.save') }}
								</v-tooltip>
							</v-btn>
							<v-btn variant="outlined" size="small" :aria-label="t('labels.cancel')" @click="editorCancelCallback">
								<v-icon>mdi-close</v-icon>
								<v-tooltip activator="parent">
									{{ t('labels.cancel') }}
								</v-tooltip>
							</v-btn>
						</div>
					</template>
				</Column>
				<template #footer>
					<div class="d-flex">
						<v-btn
							:disabled="isLoading || usersSelected.length < 1"
							:aria-disabled="isLoading || usersSelected.length < 1"
							class="mr-2 bg-error"
							prepend-icon="mdi-delete"
							data-cy="datatable-footer-actions-delete-users"
							@click="isDialogDeleteUsersVisible = true"
						>
							{{ t('labels.delete') }}
						</v-btn>
						<v-btn
							data-cy="datatable-footer-actions-import-users"
							color="primary"
							prepend-icon="mdi-tray-arrow-down"
							:disabled="isLoading || countUserReadyToImport === 0"
							:aria-disabled="isLoading || countUserReadyToImport === 0"
							@click="isVisibleOverlay = true"
						>
							{{ t('actions.import') }}
						</v-btn>
					</div>
				</template>
			</DataTable>

			<DialogImportUsers
				v-model:is-visible-overlay="isVisibleOverlay"
				:users="users"
				:users-selected="usersSelected"
				@save-selected-users="saveSelectedUsers"
			/>
			<DialogDeleteUsers
				v-model:isDialogDeleteUsers="isDialogDeleteUsersVisible"
				v-model:users-selected="usersSelected"
				v-model:is-loading="isLoading"
				v-model:users="users"
			/>

			<div v-if="successUsersImported.length || errorsUsersImported.length" class="mt-6">
				<success-and-errors
					:errors-users-imported="errorsUsersImported"
					:success-users-imported="successUsersImported"
				/>
			</div>
		</v-card-text>
	</v-card>
</template>

<style>
	.user-management-row-warning {
		background-color: #ffcf82 !important;
	}
</style>

<style scoped>
	.create-users-input {
		max-width: 25rem;
		min-width: 18rem;
	}
	.row-header-btn {
		gap: 1rem;
	}
</style>

<i18n>
{
	"en": {
		"csv_title": "Import from csv file",
		"csv_import_title": "List users to import",
		"datatable_empty_message": "No users to import",
		"export_message": "You want to import {count} user ? | You want to import {count} users ? ",
		"labels": {
			"username": "Username",
			"email": "Email",
			"password": "Password",
			"role": "Role",
			"tristate": "Toggle validate user",
			"search": "Search by email",
			"isValid": "Valid Entries",
			"add_user_from_csv": "Add user (from csv)",
			"add_user": "Add user",
			"seen_last": "Seen last",
			"import_user": "Import user",
			"delete": "Delete",
			"online": "Online",
			"offline": "Offline",
			"status": "Status",
			"to_be_imported": "To be imported",
			"edit": "Edit",
			"save": "Save",
			"cancel": "Cancel",
			"actions": "Actions"
		},
		"placeholders": {
			"username": "Enter username",
			"email": "Enter user email",
			"password": "Enter user password",
			"role": "Choose user role"
		},
		"actions": {
			"cancel": "Cancel",
			"register": "Register",
			"import": "Import",
			"confirm": "Confirm",
			"close": "close"
		},
		"toast": {
			"failed_update": "Failed to update user, please fix errors",
			"success_update": "User updated",
			"failed_import": "Failed to import user | Failed to import users"
		},
		"error_email": "Email address already used",
		"error_import_user": "There are errors in the user data, please fix them before importing"
	},
	"fr": {
		"csv_title": "Importer depuis un csv",
		"csv_import_title": "Liste d'utilisateurs à importer",
		"datatable_empty_message": "Aucun utilisateur à importer",
		"export_message": "Voulez-vous importer {count} utilisateur ? | Voulez-vous importer {count} utilisateurs ?",
		"labels": {
			"username": "Nom d'utilisateur",
			"email": "Courriel",
			"password": "Mot de passe",
			"role": "Rôle",
			"tristate": "Basculer la validation de l'utilisateur",
			"search": "Rechercher par courriel",
			"isValid": "Entrée valide",
			"add_user_from_csv": "Ajouter des utilisateurs (depuis un csv)",
			"add_user": "Ajouter un utilisateur",
			"seen_last": "dernière connexion",
			"import_user": "utilisateur importé",
			"online": "En ligne",
			"offline": "Hors ligne",
			"to_be_imported": "à importer",
			"edit": "Modifier",
			"delete": "Supprimer",
			"status": "Statut",
			"save": "Enregistrer",
			"cancel": "Annuler",
			"actions": "Actions"
		},
		"placeholders": {
			"username": "Entrer un nom d'utilisateur",
			"email": "Entrer un courriel",
			"password": "Entrer un mot de passe",
			"role": "Choisir un rôle"
		},
		"actions": {
			"cancel": "Annuler",
			"register": "Enregistrer",
			"import": "Importer",
			"confirm": "Confirmer",
			"close": "fermer"
		},
		"toast": {
			"success_delete": "Utilisateur supprimé avec succès | Utilisateurs supprimés avec succès",
			"success_update": "Utilisateur mis à jour avec succès",
			"failed_update": "Échec de la mise à jour de l'utilisateur, veuillez fixer les erreurs",
			"failed_import": "Échec de l'importation de l'utilisateur | Échec de l'importation des utilisateurs"
		},
		"error_email": "Adresse e-mail déjà utilisée",
		"error_import_user": "Il y a des erreurs dans les données de l'utilisateur, veuillez les corriger avant de l'importer"
	}
}
</i18n>
