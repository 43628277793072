// This is an auto generated file. This will be overwritten

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	Color: any;
	DateTime: any;
	DomainName: any;
	Email: any;
	FolderReference: any;
	JSON: any;
	URL: any;
	UUID: any;
};

export type AddAuthMethodInput = {
	/** Sign in Configuration */
	signInConfig?: InputMaybe<SignInConfigInput>;
	/** Sign in no password Configuration */
	signInNoPasswordConfig?: InputMaybe<SignInNoPasswordConfigInput>;
	/** Sign up Configuration */
	signUpConfig?: InputMaybe<SignUpConfigInput>;
	/** Sign up no password Configuration */
	signUpNoPasswordConfig?: InputMaybe<SignUpNoPasswordConfigInput>;
	/** SSO Configuration */
	ssoConfig?: InputMaybe<SsoConfigInput>;
};

export type AddClosedCaptionInput = {
	/** StreamText CC Input */
	STREAMTEXT?: InputMaybe<StreamTextCcInput>;
	/** Wordly CC Input */
	WORDLY?: InputMaybe<WordlyCcInput>;
};

export type AddConferencesToUserInput = {
	/** Conference IDs to add */
	conference_ids: Array<Scalars['UUID']>;
	/** Conference User ID */
	user_id: Scalars['UUID'];
};

export type AddGeoRestrictedAreaInput = {
	/** Sign up no password Configuration */
	circle?: InputMaybe<CircleAreaInput>;
	/** Restriction type */
	restriction_type: RestrictionType;
};

export type AddSocialLinkToEventInput = {
	/** Description of the link */
	name: Scalars['String'];
	/** Order of the link */
	order?: InputMaybe<Scalars['Float']>;
	/** Auth Method Type */
	type: SocialLinkType;
	/** URL of the link */
	url: Scalars['URL'];
};

export type AddSsoClientInput = {
	/** Provider Details */
	provider_details: ProviderDetailsInput;
	/** Provider Type */
	provider_type: SsoProviderType;
	/** Title */
	title: Scalars['String'];
};

export type AddUsersToChannelInput = {
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Channel User IDs to add */
	user_ids: Array<Scalars['UUID']>;
};

export type AddUsersToConferenceInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
	/** Conference User IDs to add */
	user_ids: Array<Scalars['UUID']>;
};

export type AssignPublicChannelInput = {
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Associated Conference ID */
	conference_id?: InputMaybe<Scalars['UUID']>;
	/** Associated Talkroom ID */
	talkroom_id?: InputMaybe<Scalars['UUID']>;
};

export type AuthMethod = {
	/** Type */
	type: AuthMethodType;
};

export enum AuthMethodType {
	SignIn = 'SIGN_IN',
	SignInNoPassword = 'SIGN_IN_NO_PASSWORD',
	SignUp = 'SIGN_UP',
	SignUpNoPassword = 'SIGN_UP_NO_PASSWORD',
	Sso = 'SSO'
}

export type BackgroundConfig = {
	__typename?: 'BackgroundConfig';
	/** Default background color */
	default?: Maybe<Scalars['Color']>;
	/** Paper Color */
	paper?: Maybe<Scalars['Color']>;
};

export type BackgroundConfigInput = {
	/** Default background color */
	default?: InputMaybe<Scalars['Color']>;
	/** Paper Color */
	paper?: InputMaybe<Scalars['Color']>;
};

export type ChangeQuestionStatusInput = {
	/** Question ID */
	question_id: Scalars['UUID'];
	/** Status */
	status: QuestionStatus;
};

export type Channel = {
	__typename?: 'Channel';
	/** Associated Conference ID */
	conference_id?: Maybe<Scalars['UUID']>;
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Messages */
	messages: Array<Message>;
	/** Moderation mode */
	moderation?: Maybe<ModerationMode>;
	/** Name */
	name: Scalars['String'];
	/** Associated Talkroom ID */
	talkroom_id?: Maybe<Scalars['UUID']>;
	/** Channel type */
	type: ChannelType;
	/** Users */
	users: Array<ChannelUser>;
};

export enum ChannelType {
	Private = 'PRIVATE',
	Public = 'PUBLIC'
}

export type ChannelUser = {
	__typename?: 'ChannelUser';
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** User */
	user?: Maybe<User>;
	/** Associated User ID */
	user_id: Scalars['UUID'];
};

export type CircleAreaInput = {
	/** Position of the center of the circle */
	center: PositionInput;
	/** Geographic area name */
	name: Scalars['String'];
	/** radius of the circle in kilometers */
	radius: Scalars['Float'];
	/** Geographic area type */
	type?: GeographicAreaType;
};

export type ClosedCaption = {
	/** Id of the closedCaption */
	id: Scalars['String'];
	/** Closed Caption provider type */
	type: ClosedCaptionProvider;
};

export enum ClosedCaptionProvider {
	Streamtext = 'STREAMTEXT',
	Wordly = 'WORDLY'
}

export type CollaborativeBroadcastConfiguration = {
	__typename?: 'CollaborativeBroadcastConfiguration';
	/** Backstages */
	backstages?: Maybe<Array<Talkroom>>;
	/** Broadcast status */
	broadcast_status?: Maybe<CollaborativeBroadcastStatus>;
	/** Associated Stream ID */
	broadcast_stream_id?: Maybe<Scalars['UUID']>;
	/** Unique identifier */
	conference_id: Scalars['UUID'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Tells if enabled or not */
	is_enabled: Scalars['Boolean'];
	/** Layout type */
	layout: LayoutType;
	/** Stage */
	stage?: Maybe<Talkroom>;
};

export type CollaborativeBroadcastCredential = {
	__typename?: 'CollaborativeBroadcastCredential';
	/** Backstage Credentials */
	backstage: TalkroomCredential;
	/** Conference ID */
	conference_id: Scalars['UUID'];
	/** Event ID */
	event_id: Scalars['UUID'];
	/** Stage Credentials */
	stage: TalkroomCredential;
	/** User ID */
	user_id: Scalars['UUID'];
};

export enum CollaborativeBroadcastStatus {
	Active = 'ACTIVE',
	Complete = 'COMPLETE',
	Ending = 'ENDING',
	Failed = 'FAILED',
	Starting = 'STARTING'
}

export type Command = {
	__typename?: 'Command';
	/** Associated Event Id */
	event_id: Scalars['UUID'];
	/** Unique Identifier */
	id: Scalars['UUID'];
	/** Force command execution */
	is_forced: Scalars['Boolean'];
	/** Define name of command */
	name: CommandName;
	/** Receiver id */
	receiver_id: Scalars['UUID'];
	/** Define type of command */
	type: CommandType;
};

export type CommandHistory = {
	__typename?: 'CommandHistory';
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Associated Event Id */
	event_id: Scalars['UUID'];
	/** Unique Identifier */
	id: Scalars['UUID'];
	/** Force command execution */
	is_forced: Scalars['Boolean'];
	/** Define name of command */
	name: CommandName;
	/** Recipient name */
	recipient_name: Scalars['String'];
	/** Sender name */
	sender_name: Scalars['String'];
	/** Timestamp of creation date */
	timestamp: Scalars['Float'];
	/** Define type of command */
	type: CommandType;
};

export type CommandHistoryList = {
	__typename?: 'CommandHistoryList';
	/** List of Command History */
	commandHistory: Array<CommandHistory>;
	/** Total count */
	count?: Maybe<Scalars['Int']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
};

/** Define name of command */
export enum CommandName {
	/** Command applied to [EVENT|USER] : Log out user(s) */
	Logout = 'LOGOUT',
	/** Command applied to [EVENT|USER] : reload page user(s) */
	Refresh = 'REFRESH'
}

/** Define type of command */
export enum CommandType {
	Conference = 'CONFERENCE',
	/** Command target event */
	Event = 'EVENT',
	/** Command target user */
	User = 'USER'
}

export type Conference = {
	__typename?: 'Conference';
	/** Channel ID */
	channel_id?: Maybe<Scalars['UUID']>;
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** End date */
	date_end: Scalars['DateTime'];
	/** Start date */
	date_start: Scalars['DateTime'];
	/** Description */
	description: Scalars['String'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** FileStorage linked to the conference */
	file_storage: FileStorage;
	/** Frames linked to the conference */
	frames: Array<Frame>;
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Name */
	name: Scalars['String'];
	/** QuestionPool linked to the conference */
	question_pool?: Maybe<QuestionPool>;
	/** Streams linked to the conference */
	streams: Array<Stream>;
	/** Talkrooms linked to the conference */
	talkrooms: Array<Talkroom>;
	/** Type */
	type: ConferenceType;
};

export enum ConferenceType {
	Private = 'PRIVATE',
	Public = 'PUBLIC'
}

export type CreateConferenceInput = {
	/** End date */
	date_end: Scalars['DateTime'];
	/** Start date */
	date_start: Scalars['DateTime'];
	/** Description */
	description: Scalars['String'];
	/** Name */
	name: Scalars['String'];
	/** Type */
	type: ConferenceType;
};

export type CreateEventInput = {
	/** End date */
	date_end: Scalars['DateTime'];
	/** Start date */
	date_start: Scalars['DateTime'];
	/**
	 * Name,
	 *     allowed values are letters, numbers and "-_."
	 */
	name: Scalars['String'];
	/** Type */
	type?: EventType;
};

export type CreateFileInput = {
	/** File content type */
	content_type: Scalars['String'];
	/** File name */
	name: Scalars['String'];
	/** Parent Folder id */
	parent_folder_id: Scalars['FolderReference'];
};

export type CreateFolderInput = {
	/** Folder name */
	name: Scalars['String'];
	/** Parent Folder id */
	parent_folder_id: Scalars['FolderReference'];
};

export type CreateFrameInput = {
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Type of Frame */
	type: FrameType;
	/** Url */
	url: Scalars['URL'];
};

export type CreateLinkInput = {
	/** Link name */
	name: Scalars['String'];
	/** Parent Folder id */
	parent_folder_id: Scalars['FolderReference'];
	/** Link url */
	url: Scalars['URL'];
};

export type CreatePrivateChannelInput = {
	/** Name */
	name: Scalars['String'];
	/** Channel User IDs */
	user_ids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type CreatePublicChannelInput = {
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Define what moderation type you want for the channel */
	moderation?: ModerationMode;
	/** Name */
	name: Scalars['String'];
};

export type CreateQuestionPoolInput = {
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Description */
	description?: InputMaybe<Scalars['String']>;
	/** Name */
	name: Scalars['String'];
};

export type CreateStreamInput = {
	/** Closed Caption provider */
	closed_caption_provider?: InputMaybe<ClosedCaptionProvider>;
	/** Closed Captions */
	closed_captions?: Array<AddClosedCaptionInput>;
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Associated Conference ID */
	is_private?: InputMaybe<Scalars['Boolean']>;
	/** Name */
	name: Scalars['String'];
	/** Order */
	order: Scalars['Int'];
	/** Provider */
	provider: StreamProvider;
	/** Type */
	type?: InputMaybe<StreamType>;
	/** URL */
	url?: InputMaybe<Scalars['URL']>;
};

export type CreateTalkroomInput = {
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Max participants allowed */
	max_participants?: Scalars['Int'];
	/** Name */
	name: Scalars['String'];
	/** Order of the talkroom in the list */
	order?: Scalars['Int'];
	/** Number of seats reserved for administrators and moderators */
	reserved_seats?: Scalars['Int'];
	/** Type of talkroom */
	type?: TalkroomType;
};

export type DeleteMessageInput = {
	/** Unique identifier */
	id: Scalars['UUID'];
};

export type DeleteQuestionPoolInput = {
	/** ID of the question pool */
	question_pool_id: Scalars['UUID'];
};

export type DeleteUserInput = {
	/** user id */
	id: Scalars['UUID'];
};

export type DeleteUsers = {
	__typename?: 'DeleteUsers';
	/** message of delete users */
	message?: Maybe<Scalars['String']>;
	/** result status of delete users */
	success: Scalars['Boolean'];
	/** user id */
	userId: Scalars['String'];
};

export type DeleteUsersInput = {
	/** id of the user to delete */
	userId: Scalars['String'];
};

export type DeletionStatus = {
	__typename?: 'DeletionStatus';
	/** Unique Identifier of deleted Entity */
	id: Scalars['UUID'];
	/** Tells if operation was successful or not */
	success: Scalars['Boolean'];
};

export type DuplicateConferenceInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type DuplicateEventInput = {
	/** Event ID */
	event_id: Scalars['UUID'];
};

export enum Event_State {
	Locked = 'LOCKED',
	Open = 'OPEN'
}

export type EditQuestionInput = {
	/** Content */
	content: Scalars['String'];
	/** Question ID */
	question_id: Scalars['UUID'];
};

export type EmailFieldConfig = {
	__typename?: 'EmailFieldConfig';
	/** List of authorized domains */
	authorized_domains?: Maybe<Array<Scalars['DomainName']>>;
	/** Field is required */
	is_required?: Maybe<Scalars['Boolean']>;
	/** HTML Validation schema */
	validation_schema: Scalars['String'];
};

export type EmailFieldConfigInput = {
	/** List of authorized domains */
	authorized_domains?: Array<Scalars['DomainName']>;
	/** Field is required */
	is_required?: Scalars['Boolean'];
};

export enum Emoji {
	ClappingHands = 'ClappingHands',
	GrimacingFace = 'GrimacingFace',
	RedHeart = 'RedHeart',
	ThumbsUp = 'ThumbsUp'
}

export type Event = {
	__typename?: 'Event';
	/** Event created at */
	created_at: Scalars['String'];
	/** Event date end */
	date_end: Scalars['String'];
	/** Event date start */
	date_start: Scalars['String'];
	/** Event status (LOCKED/OPEN) */
	event_state: Event_State;
	/** Event guest access is allowed */
	guestAccess: Scalars['Boolean'];
	/** Check if event has password */
	hasEventPassword?: Maybe<Scalars['Boolean']>;
	/** Primary key */
	id: Scalars['String'];
	/** Event name */
	name: Scalars['String'];
	/** Event no password access is allowed */
	noPassword: Scalars['Boolean'];
	/** Social Medias */
	social_links: Array<SocialLink>;
	/** Event updated at */
	updated_at: Scalars['String'];
};

export type EventConfig = {
	__typename?: 'EventConfig';
	/** List of available Auth Methods */
	auth_methods: Array<AuthMethod>;
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Defines if an event is geo restricted */
	is_geo_restricted: Scalars['Boolean'];
	/** Theme configuartion of Event */
	theme: EventConfigTheme;
	/** Type */
	type: EventType;
};

export type EventConfigTheme = {
	__typename?: 'EventConfigTheme';
	/** Background Theme Color Config */
	background: BackgroundConfig;
	/** Contrast Threshold */
	contrastThreshold: Scalars['Float'];
	/** Primary Theme Color Config */
	primary: ThemeColorConfig;
	/** Secondary Theme Color Config */
	secondary: ThemeColorConfig;
	/** Theme Offset Config */
	tonalOffset: ThemeOffsetConfig;
};

export type EventList = {
	__typename?: 'EventList';
	/** Total count */
	count?: Maybe<Scalars['Int']>;
	/** List of Events */
	events: Array<Event>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
};

export enum EventType {
	MonoConference = 'MONO_CONFERENCE',
	MultiConferences = 'MULTI_CONFERENCES'
}

export type EventWithConfig = {
	__typename?: 'EventWithConfig';
	/** Event */
	event: Event;
	/** Event configuration */
	eventConfig: EventConfig;
};

export type ExportConferenceParticipantLogsInput = {
	/** Conference ID */
	conference_id?: InputMaybe<Scalars['UUID']>;
	/** User ID */
	user_id?: InputMaybe<Scalars['UUID']>;
};

export type ExportUserSessionLogInput = {
	/** date_end for list user session logs */
	date_end: Scalars['DateTime'];
	/** date_start for list user session logs */
	date_start: Scalars['DateTime'];
};

export type File = {
	__typename?: 'File';
	/** File mimetype */
	content_type: Scalars['String'];
	/** File create date */
	created_at: Scalars['DateTime'];
	/** Event id */
	event_id: Scalars['UUID'];
	/** Primary key */
	id: Scalars['UUID'];
	/** File name */
	name: Scalars['String'];
	/** Owner of the file */
	owner?: Maybe<User>;
	/** Owner's user id */
	owner_id: Scalars['UUID'];
	/** Parent folder id */
	parent_folder_id: Scalars['FolderReference'];
	/** File update date */
	updated_at: Scalars['DateTime'];
	/** File url */
	url: Scalars['URL'];
};

export type FileCreated = {
	__typename?: 'FileCreated';
	/** created file */
	file: File;
	/** Url to upload the file */
	upload_url: Scalars['String'];
};

/** Supported file name for Event Builder uploads */
export enum FileName {
	Background = 'background',
	BackgroundSignin = 'background_signin',
	LogoHeader = 'logo_header',
	LogoSignin = 'logo_signin'
}

export type FileStorage = {
	__typename?: 'FileStorage';
	/** Files */
	files: Array<File>;
	/** Folders */
	folders: Array<Folder>;
	/** Links */
	links: Array<Link>;
};

export type Folder = {
	__typename?: 'Folder';
	/** Folder create date */
	created_at: Scalars['DateTime'];
	/** Event id */
	event_id: Scalars['UUID'];
	/** Files */
	files: Array<File>;
	/** Folders */
	folders: Array<Folder>;
	/** Primary key */
	id: Scalars['UUID'];
	/** Links */
	links: Array<Link>;
	/** Folder name */
	name: Scalars['String'];
	/** Id of the parent folder or any of the FolderType values */
	parent_folder_id: Scalars['FolderReference'];
	/** Folder path */
	path: Scalars['String'];
	/** Folder update date */
	updated_at: Scalars['DateTime'];
};

export type Frame = {
	__typename?: 'Frame';
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Type of Frame */
	type: FrameType;
	/** Url */
	url: Scalars['URL'];
};

export enum FrameType {
	Mentimeter = 'MENTIMETER',
	Slido = 'SLIDO'
}

export enum GeographicAreaType {
	Circle = 'CIRCLE'
}

export type Geolocation = {
	__typename?: 'Geolocation';
	/** Date when the position was created */
	created_at: Scalars['DateTime'];
	/** If of the event */
	event_id: Scalars['UUID'];
	/** Geolocation coordinates */
	position: Position;
};

export type GetFileStorageInput = {
	/** Parent Folder id */
	parent_folder_id?: InputMaybe<Scalars['FolderReference']>;
};

export type GetUserInput = {
	/** user id */
	id: Scalars['UUID'];
};

export type GetUserSessionCountsInput = {
	/** End Date */
	date_end: Scalars['DateTime'];
	/** Start Date */
	date_start: Scalars['DateTime'];
};

export type GetUserSessionInput = {
	/** Unique identifier */
	id: Scalars['UUID'];
};

export type IvsStreamInfo = StreamProviderInfo & {
	__typename?: 'IVSStreamInfo';
	/** Arn */
	arn: Scalars['String'];
	/** Ingest Server */
	ingest_server: Scalars['URL'];
	/** Playback URL */
	playback_url: Scalars['URL'];
	/** Type */
	status?: Maybe<StreamStatus>;
	/** Stream key */
	stream_key?: Maybe<Scalars['String']>;
	/** Type */
	type: StreamProvider;
};

export type ImportUser = {
	__typename?: 'ImportUser';
	/** has created */
	hasCreated: Scalars['Boolean'];
	/** reason */
	reason?: Maybe<Scalars['String']>;
	/** User profile */
	user?: Maybe<User>;
	/** user email */
	user_email?: Maybe<Scalars['String']>;
};

export type ImportUsersInput = {
	/** Event id */
	event_id?: InputMaybe<Scalars['UUID']>;
	/** language */
	language?: InputMaybe<Language>;
	/** User Password */
	password: Scalars['String'];
	/** role names */
	roleNames: Array<RoleName>;
	/** User email */
	user_email: Scalars['String'];
	/** User name */
	user_name: Scalars['String'];
};

export type InviteUserToCollaborativeBroadcastInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
	/** Invited User ID */
	user_id: Scalars['UUID'];
};

export type JoinCollaborativeBroadcastInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type KickoutTalkroomParticipantInput = {
	/** Participant ID */
	participant_id: Scalars['UUID'];
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

/** Supported Language */
export enum Language {
	En = 'en',
	Fr = 'fr'
}

export enum LayoutType {
	Grid = 'GRID',
	SingleSpeaker = 'SINGLE_SPEAKER',
	Speaker = 'SPEAKER'
}

export type Link = {
	__typename?: 'Link';
	/** Link create date */
	created_at: Scalars['DateTime'];
	/** Event id */
	event_id: Scalars['UUID'];
	/** Primary key */
	id: Scalars['UUID'];
	/** Link name */
	name: Scalars['String'];
	/** Owner of the link */
	owner?: Maybe<User>;
	/** Owner's user id */
	owner_id: Scalars['UUID'];
	/** Parent folder id */
	parent_folder_id: Scalars['FolderReference'];
	/** Link update date */
	updated_at: Scalars['DateTime'];
	/** URL of the link */
	url: Scalars['URL'];
};

export type ListCommandHistoryInput = {
	/** Pagination last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
};

export type ListFilesInput = {
	/** Parent Folder id */
	parent_folder_id?: InputMaybe<Scalars['FolderReference']>;
};

export type ListFoldersInput = {
	/** Parent folder id */
	parent_folder_id?: InputMaybe<Scalars['FolderReference']>;
};

export type ListGeolocationsInput = {
	/** End date */
	date_end: Scalars['DateTime'];
	/** Start date */
	date_start: Scalars['DateTime'];
};

export type ListLinksInput = {
	/** Parent folder id */
	parent_folder_id?: InputMaybe<Scalars['FolderReference']>;
};

export type ListMessagesInput = {
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Pagination last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
};

export type ListPaginationInputArgsInput = {
	/** Pagination last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
};

export type ListQuestionsInput = {
	/** Pagination last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
	/** ID of the question pool */
	question_pool_id: Scalars['UUID'];
	/** Expected question status filter */
	question_status?: InputMaybe<QuestionStatus>;
};

export type ListUserSessionLogsArgs = {
	/** date for filter list user sessions logs by date */
	date?: InputMaybe<ListUserSessionLogsDate>;
	/** Associated Event ID */
	event_id?: InputMaybe<Scalars['UUID']>;
	/** last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
};

export type ListUserSessionLogsDate = {
	/** date_end for list user session logs */
	date_end: Scalars['DateTime'];
	/** date_start for list user session logs */
	date_start: Scalars['DateTime'];
};

export type ListUserSessionsInput = {
	/** Associated Event ID */
	event_id?: InputMaybe<Scalars['UUID']>;
	/** Pagination last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** Paginiation limit */
	limit?: InputMaybe<Scalars['Int']>;
	/** user email begins_with some string */
	user_email_begins_with?: InputMaybe<Scalars['String']>;
};

export type ListUsersArgs = {
	/** total users in db (by event) */
	count?: InputMaybe<Scalars['Boolean']>;
	/** event id */
	event_id?: InputMaybe<Scalars['UUID']>;
	/** last evaluated key */
	last_evaluated_key?: InputMaybe<Scalars['String']>;
	/** limit */
	limit?: InputMaybe<Scalars['Float']>;
	/** user email begins_with some string */
	user_email_begins_with?: InputMaybe<Scalars['String']>;
};

export type LockTalkroomInput = {
	/** New lock status */
	is_locked: Scalars['Boolean'];
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type Message = {
	__typename?: 'Message';
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Conference ID, this field is only defined for public conferences */
	conference_id?: Maybe<Scalars['UUID']>;
	/** Content of message */
	content: Scalars['String'];
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Deletion date */
	deleted_at?: Maybe<Scalars['DateTime']>;
	/** Event ID */
	event_id: Scalars['UUID'];
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Sender */
	sender: MessageSender;
	/** Status of the message only delcared for public channels */
	status?: Maybe<MessageStatus>;
};

export type MessageDeleted = {
	__typename?: 'MessageDeleted';
	/** Associated Channel ID */
	channel_id: Scalars['UUID'];
	/** Associated Conference ID for messages from public channels */
	conference_id: Scalars['UUID'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Message ID */
	id: Scalars['UUID'];
};

export type MessageList = {
	__typename?: 'MessageList';
	/** Total count */
	count?: Maybe<Scalars['Int']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
	/** List of Messages */
	messages: Array<Message>;
};

export type MessageSender = {
	__typename?: 'MessageSender';
	/** Sender ID */
	user_id: Scalars['UUID'];
	/** Sender Name */
	user_name: Scalars['String'];
};

export enum MessageStatus {
	Deleted = 'DELETED',
	InReview = 'IN_REVIEW',
	RequiresVerification = 'REQUIRES_VERIFICATION',
	Validated = 'VALIDATED'
}

export enum ModerationMode {
	AiModerated = 'AI_MODERATED',
	FullyModerated = 'FULLY_MODERATED',
	HumanModerated = 'HUMAN_MODERATED',
	NonModerated = 'NON_MODERATED'
}

export type Mutation = {
	__typename?: 'Mutation';
	/** Adds a Auth Method to an Event Config */
	addAuthMethodsToEventConfig: EventConfig;
	/** Adds an SSO Client to the SSO Auth Method of the Event Config */
	addClientToSsoAuthMethod: EventConfig;
	/** Add closed captions to Stream */
	addClosedCaptionToStream: Stream;
	/** Add multiple Conferences to a User */
	addConferencesToUser: Scalars['Boolean'];
	/** Add a geo restricted area */
	addGeoRestrictedArea: EventConfig;
	addSocialLinkToEvent: Event;
	/** Adds users to a Private Channel */
	addUsersToChannel: Channel;
	/** Add multiple Users to a Conference */
	addUsersToConference: Conference;
	/** Assigns a Public Channel */
	assignPublicChannel: Channel;
	/** Changes the status of a Question */
	changeQuestionStatus: Question;
	/** Creates a Conference */
	createConference: Conference;
	/** Creates an Event with default EventConfig */
	createEvent: EventWithConfig;
	createFile: FileCreated;
	createFolder: Folder;
	/** Creates a Frame */
	createFrame: Frame;
	createLink: Link;
	/** Creates a Private Channel */
	createPrivateChannel: Channel;
	/** Creates a Public Channel */
	createPublicChannel: Channel;
	/** Creates a QuestionPool */
	createQuestionPool: QuestionPool;
	/** Creates a Stream */
	createStream: Stream;
	/** Creates a Talkroom */
	createTalkroom: Talkroom;
	createUsers: Array<ImportUser>;
	/** Deletes a Conference */
	deleteConference: DeletionStatus;
	deleteFiles: Array<DeletionStatus>;
	deleteFolder: DeletionStatus;
	/** Deletes a Frame */
	deleteFrame: DeletionStatus;
	deleteLinks: Array<DeletionStatus>;
	/** Deletes a Message */
	deleteMessage: DeletionStatus;
	/** Creates a Public Channel */
	deletePublicChannel: DeletionStatus;
	/** Deletes a QuestionPool */
	deleteQuestionPool: DeletionStatus;
	/** Deletes a Stream */
	deleteStream: DeletionStatus;
	/** Deletes a Talkroom */
	deleteTalkroom: DeletionStatus;
	deleteUser: DeletionStatus;
	deleteUsers: Array<DeleteUsers>;
	/** Duplicates a Conference */
	duplicateConference: Conference;
	/** Duplicates an Event with the assoicated Event Configs */
	duplicateEvent: EventWithConfig;
	/** Edits a Question */
	editQuestion: Question;
	/** Removes a geo restricted area */
	enableGeoRestriction: EventConfig;
	/** Exports Conference Participation Logs by sending a Presigned URL */
	exportConferenceParticipationLogs: Scalars['String'];
	exportUserSessionLogs: Scalars['String'];
	importUsers: Array<ImportUser>;
	/** Invites a User to a Collaborative Broadcast */
	inviteUserToCollaborativeBroadcast: CollaborativeBroadcastCredential;
	/** Joins a Collaborative Broadcast */
	joinCollaborativeBroadcast: CollaborativeBroadcastCredential;
	/** Joins a Talkroom and returns a Talkroom Token */
	joinTalkroom: Scalars['String'];
	/** Kicks out a participant in Talkroom */
	kickoutTalkroomParticipant: Scalars['Boolean'];
	/** Leave a conference */
	leaveConference: Scalars['Boolean'];
	/** Toggle Talkroom lock status */
	lockTalkroom: Talkroom;
	/** Mutes all participants in Talkroom */
	muteAllTalkroomParticipants: Scalars['Boolean'];
	/** Mutes a participant in Talkroom */
	muteTalkroomParticipant: Scalars['Boolean'];
	orderSocialLinksOnEvent: Event;
	/** Orders Streams */
	orderStreams: Status;
	/** Orders Talkrooms */
	orderTalkrooms: Status;
	/** Removes a Auth Method from an Event Config */
	removeAuthMethodFromEventConfig: EventConfig;
	/** Removes a client from SSO Auth Method */
	removeClientFromSsoAuthMethod: EventConfig;
	/** Remove a closed caption from a stream */
	removeClosedCaptionFromStream: Stream;
	/** Remove multiple Conferences from a User */
	removeConferencesFromUser: Scalars['Boolean'];
	/** Removes a geo restricted area */
	removeGeoRestrictedArea: EventConfig;
	removeSocialLinkFromEvent: Event;
	/** Remove multiple Users from a Conference */
	removeUsersFromConference: Conference;
	resetPassword: Status;
	/** Sends a Conference Command */
	sendConferenceCommand: CommandHistory;
	/** Sends a Event Command */
	sendEventCommand: CommandHistory;
	/** Sends a Message */
	sendMessage: Message;
	/** Send a Question */
	sendQuestion: Question;
	/** Sends a Talkroom Reaction */
	sendTalkroomReaction: TalkroomReaction;
	/** Sends a User Command */
	sendUserCommand: CommandHistory;
	signIn: SignInResult;
	/** Sign In No Password Auth Method */
	signInNoPassword: SignInResult;
	signUp: Status;
	signUpNoPassword: SignInResult;
	/** Starts a Collaborative Broadcast */
	startCollaborativeBroadcast: CollaborativeBroadcastConfiguration;
	/** Stops a Collaborative Broadcast */
	stopCollaborativeBroadcast: CollaborativeBroadcastConfiguration;
	/** Toggles an highlighted Participant in a Talkroom */
	toggleHighlightedParticipantInTalkroom: Talkroom;
	/** Toggles highlighted Question */
	toggleHighlightedQuestion: QuestionPool;
	/** Toggles a Raised Hand in a Talkroom */
	toggleRaisedHandInTalkroom: Talkroom;
	/** Assigns a Public Channel */
	unassignPublicChannel: Channel;
	/** Updates a Conference */
	updateConference: Conference;
	updateEvent: Event;
	/** Updates the Theme of an Event Config */
	updateEventConfigTheme: EventConfig;
	updatePassword: Status;
	/** Updates a Public Channel */
	updatePublicChannel: Channel;
	/** Update a QuestionPool */
	updateQuestionPool: QuestionPool;
	/** Updates a Stream */
	updateStream: Stream;
	updateUser: User;
	/** Updates user session geolocation */
	updateUserSessionGeolocation: Geolocation;
	/** Creates or updates a CollaborativeBroadcastConfiguration */
	upsertCollaborativeBroadcastConfiguration: CollaborativeBroadcastConfiguration;
	userSessionJoinTable: UserSession;
	userSessionLeaveTable: UserSession;
	/** Validate a Message */
	validateMessage: Scalars['Boolean'];
};

export type MutationAddAuthMethodsToEventConfigArgs = {
	addAuthMethodInput: AddAuthMethodInput;
	event_id: Scalars['UUID'];
};

export type MutationAddClientToSsoAuthMethodArgs = {
	addSsoClientInput: AddSsoClientInput;
	event_id: Scalars['UUID'];
};

export type MutationAddClosedCaptionToStreamArgs = {
	addClosedCaptionInput: AddClosedCaptionInput;
	stream_id: Scalars['UUID'];
};

export type MutationAddConferencesToUserArgs = {
	addConferencesToUserInput: AddConferencesToUserInput;
};

export type MutationAddGeoRestrictedAreaArgs = {
	AddGeoRestrictedAreaInput: AddGeoRestrictedAreaInput;
};

export type MutationAddSocialLinkToEventArgs = {
	addSocialLinkToEventInput: AddSocialLinkToEventInput;
};

export type MutationAddUsersToChannelArgs = {
	addUsersToChannelInput: AddUsersToChannelInput;
};

export type MutationAddUsersToConferenceArgs = {
	addUsersToConferenceInput: AddUsersToConferenceInput;
};

export type MutationAssignPublicChannelArgs = {
	assignPublicChannelInput: AssignPublicChannelInput;
};

export type MutationChangeQuestionStatusArgs = {
	changeQuestionStatusInput: ChangeQuestionStatusInput;
};

export type MutationCreateConferenceArgs = {
	createConferenceInput: CreateConferenceInput;
};

export type MutationCreateEventArgs = {
	createEventInput: CreateEventInput;
};

export type MutationCreateFileArgs = {
	createFileInput: CreateFileInput;
};

export type MutationCreateFolderArgs = {
	createFolderInput: CreateFolderInput;
};

export type MutationCreateFrameArgs = {
	createFrameInput: CreateFrameInput;
};

export type MutationCreateLinkArgs = {
	createLinkInput: CreateLinkInput;
};

export type MutationCreatePrivateChannelArgs = {
	createPrivateChannelInput: CreatePrivateChannelInput;
};

export type MutationCreatePublicChannelArgs = {
	createPublicChannelInput: CreatePublicChannelInput;
};

export type MutationCreateQuestionPoolArgs = {
	createQuestionPoolInput: CreateQuestionPoolInput;
};

export type MutationCreateStreamArgs = {
	createStreamInput: CreateStreamInput;
};

export type MutationCreateTalkroomArgs = {
	createTalkroomInput: CreateTalkroomInput;
};

export type MutationCreateUsersArgs = {
	event_id: Scalars['String'];
	importUsersInput: Array<ImportUsersInput>;
};

export type MutationDeleteConferenceArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteFilesArgs = {
	ids: Array<Scalars['UUID']>;
};

export type MutationDeleteFolderArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteFrameArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteLinksArgs = {
	ids: Array<Scalars['UUID']>;
};

export type MutationDeleteMessageArgs = {
	deleteMessageInput: DeleteMessageInput;
};

export type MutationDeletePublicChannelArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteQuestionPoolArgs = {
	deleteQuestionPoolInput: DeleteQuestionPoolInput;
};

export type MutationDeleteStreamArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteTalkroomArgs = {
	id: Scalars['UUID'];
};

export type MutationDeleteUserArgs = {
	deleteUserInput: DeleteUserInput;
};

export type MutationDeleteUsersArgs = {
	deleteUsersInput: Array<DeleteUsersInput>;
};

export type MutationDuplicateConferenceArgs = {
	duplicateConferenceInput: DuplicateConferenceInput;
};

export type MutationDuplicateEventArgs = {
	duplicateEventInput: DuplicateEventInput;
};

export type MutationEditQuestionArgs = {
	editQuestionInput: EditQuestionInput;
};

export type MutationEnableGeoRestrictionArgs = {
	is_enabled: Scalars['Boolean'];
};

export type MutationExportConferenceParticipationLogsArgs = {
	exportConferenceParticipantLogsInput: ExportConferenceParticipantLogsInput;
};

export type MutationExportUserSessionLogsArgs = {
	exportUserSessionLogInput: ExportUserSessionLogInput;
};

export type MutationImportUsersArgs = {
	importUsersInput: Array<ImportUsersInput>;
};

export type MutationInviteUserToCollaborativeBroadcastArgs = {
	inviteUserToCollaborativeBroadcastInput: InviteUserToCollaborativeBroadcastInput;
};

export type MutationJoinCollaborativeBroadcastArgs = {
	joinCollaborativeBroadcastInput: JoinCollaborativeBroadcastInput;
};

export type MutationJoinTalkroomArgs = {
	id: Scalars['UUID'];
};

export type MutationKickoutTalkroomParticipantArgs = {
	kickoutTalkroomParticipantInput: KickoutTalkroomParticipantInput;
};

export type MutationLeaveConferenceArgs = {
	connection_id: Scalars['UUID'];
};

export type MutationLockTalkroomArgs = {
	lockTalkroomInput: LockTalkroomInput;
};

export type MutationMuteAllTalkroomParticipantsArgs = {
	talkroom_id: Scalars['UUID'];
};

export type MutationMuteTalkroomParticipantArgs = {
	muteTalkroomParticipantInput: MuteTalkroomParticipantInput;
};

export type MutationOrderSocialLinksOnEventArgs = {
	orderSocialLinksInput: OrderSocialLinksInput;
};

export type MutationOrderStreamsArgs = {
	orderStreamsInput: OrderStreamsInput;
};

export type MutationOrderTalkroomsArgs = {
	orderTalkroomsInput: OrderTalkroomsInput;
};

export type MutationRemoveAuthMethodFromEventConfigArgs = {
	auth_method_type: AuthMethodType;
	event_id: Scalars['UUID'];
};

export type MutationRemoveClientFromSsoAuthMethodArgs = {
	event_id: Scalars['UUID'];
	id: Scalars['UUID'];
};

export type MutationRemoveClosedCaptionFromStreamArgs = {
	closed_caption_id: Scalars['String'];
	stream_id: Scalars['UUID'];
};

export type MutationRemoveConferencesFromUserArgs = {
	removeConferencesFromUserInput: RemoveConferencesFromUserInput;
};

export type MutationRemoveGeoRestrictedAreaArgs = {
	id: Scalars['UUID'];
};

export type MutationRemoveSocialLinkFromEventArgs = {
	social_link_id: Scalars['UUID'];
};

export type MutationRemoveUsersFromConferenceArgs = {
	removeUsersFromConferenceInput: RemoveUsersFromConferenceInput;
};

export type MutationResetPasswordArgs = {
	resetPasswordInput: ResetPasswordInput;
};

export type MutationSendConferenceCommandArgs = {
	sendUserCommandInput: SendUserCommandInput;
};

export type MutationSendEventCommandArgs = {
	sendEventCommandInput: SendEventCommandInput;
};

export type MutationSendMessageArgs = {
	sendMessageInput: SendMessageInput;
};

export type MutationSendQuestionArgs = {
	sendQuestionInput: SendQuestionInput;
};

export type MutationSendTalkroomReactionArgs = {
	sendTalkroomReactionInput: SendTalkroomReactionInput;
};

export type MutationSendUserCommandArgs = {
	sendUserCommandInput: SendUserCommandInput;
};

export type MutationSignInArgs = {
	signInInput: SignInInput;
};

export type MutationSignInNoPasswordArgs = {
	signInNoPasswordInput: SignInNoPasswordInput;
};

export type MutationSignUpArgs = {
	signUpInput: SignUpInput;
};

export type MutationSignUpNoPasswordArgs = {
	signUpNoPasswordInput: SignUpNoPasswordInput;
};

export type MutationStartCollaborativeBroadcastArgs = {
	startCollaborativeBroadcastInput: StartCollaborativeBroadcastInput;
};

export type MutationToggleHighlightedParticipantInTalkroomArgs = {
	toggleHighlightedParticipantInput: ToggleHighlightedParticipantInput;
};

export type MutationToggleHighlightedQuestionArgs = {
	toggleHighlightedQuestionInput: ToggleHighlightedQuestionInput;
};

export type MutationToggleRaisedHandInTalkroomArgs = {
	toggleRaisedHandInput: ToggleRaisedHandInput;
};

export type MutationUnassignPublicChannelArgs = {
	unassignPublicChannelInput: UnassignPublicChannelInput;
};

export type MutationUpdateConferenceArgs = {
	id: Scalars['UUID'];
	updateConferenceInput: UpdateConferenceInput;
};

export type MutationUpdateEventArgs = {
	updateEventInput: UpdateEventInput;
};

export type MutationUpdateEventConfigThemeArgs = {
	event_id: Scalars['UUID'];
	updateEventConfigThemeInput: UpdateEventConfigThemeInput;
};

export type MutationUpdatePasswordArgs = {
	updatePasswordInput: UpdatePasswordInput;
};

export type MutationUpdatePublicChannelArgs = {
	id: Scalars['UUID'];
	updatePublicChannelInput: UpdatePublicChannelInput;
};

export type MutationUpdateQuestionPoolArgs = {
	question_pool_id: Scalars['UUID'];
	updateQuestionPoolInput: UpdateQuestionPoolInput;
};

export type MutationUpdateStreamArgs = {
	id: Scalars['UUID'];
	updateStreamInput: UpdateStreamInput;
};

export type MutationUpdateUserArgs = {
	updateUserInput: UpdateUserInput;
};

export type MutationUpdateUserSessionGeolocationArgs = {
	updateUserSessionGeolocationInput: UpdateUserSessionGeolocationInput;
};

export type MutationUpsertCollaborativeBroadcastConfigurationArgs = {
	conference_id: Scalars['String'];
	upsertCollaborativeBroadcastConfigurationInput: UpsertCollaborativeBroadcastConfigurationInput;
};

export type MutationUserSessionJoinTableArgs = {
	userSessionJoinTableInput: UserSessionJoinTableInput;
};

export type MutationValidateMessageArgs = {
	message_id: Scalars['UUID'];
};

export type MuteTalkroomParticipantInput = {
	/** Participant ID */
	participant_id: Scalars['UUID'];
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type OnCollaborativeBroadcastConfigurationUpdatedInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type OnCollaborativeBroadcastInviteSentInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type OnQuestionPoolUpdatedInput = {
	/** ID of the question pool */
	question_pool_id: Scalars['UUID'];
};

export type OnQuestionUpdatedInput = {
	/** Question Pool ID */
	question_pool_id: Scalars['UUID'];
	/** Question Pool ID */
	status?: InputMaybe<QuestionStatus>;
};

export type OnTalkroomParticipantJoinedInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type OnTalkroomParticipantLeftInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type OnTalkroomReactionSentInput = {
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type OnTalkroomUpdatedInput = {
	/** Conference ID */
	conference_id?: InputMaybe<Scalars['UUID']>;
	/** Talkroom ID */
	talkroom_id?: InputMaybe<Scalars['UUID']>;
};

export type OpenIdProviderDetailsInput = {
	/** Authorized Request Method */
	attributes_request_method: Scalars['String'];
	/** Authorize Scope */
	authorize_scopes: Scalars['String'];
	/** Client ID */
	client_id: Scalars['String'];
	/** Client Secret */
	client_secret: Scalars['String'];
	/** OPen ID Issuer */
	oidc_issuer: Scalars['String'];
};

export type OrderSocialLinksInput = {
	/** Array object that each contains a social link ID and its order */
	social_link_orders: Array<SocialLinkOrderInput>;
};

export type OrderStreamsInput = {
	/** Array object that each contains a Stream ID and the order of that same Stream */
	stream_orders: Array<StreamOrderInput>;
};

export type OrderTalkroomsInput = {
	/** Array object that each contains a Talkroom ID and the order of that same Talkroom */
	talkroom_orders: Array<TalkroomOrderInput>;
};

export type PasswordFieldConfig = {
	__typename?: 'PasswordFieldConfig';
	/** Field is required */
	is_required?: Maybe<Scalars['Boolean']>;
	/** HTML Validation schema */
	validation_schema: Scalars['String'];
};

export type Position = {
	__typename?: 'Position';
	/** Latitude coordinate */
	latitude: Scalars['Float'];
	/** Longitude coordinate */
	longitude: Scalars['Float'];
};

export type PositionInput = {
	/** Position latitude */
	latitude: Scalars['Float'];
	/** Position longitude */
	longitude: Scalars['Float'];
};

export type Profile = {
	__typename?: 'Profile';
	/** Associated Event ID */
	event_id: Scalars['String'];
	/** Unique identifier */
	id: Scalars['String'];
	/** Additional information by event */
	information?: Maybe<Scalars['JSON']>;
	/** Selected Language */
	language: Scalars['String'];
	/** Example field (placeholder) */
	seen_first?: Maybe<Scalars['DateTime']>;
	/** Example field (placeholder) */
	seen_last?: Maybe<Scalars['DateTime']>;
	/** Example field (placeholder) */
	seen_updated?: Maybe<Scalars['DateTime']>;
	/** Email of User */
	user_email: Scalars['String'];
	/** Name of User */
	user_name: Scalars['String'];
};

export type ProviderDetailsInput = {
	/** Open ID Provider Details */
	OIDC?: InputMaybe<OpenIdProviderDetailsInput>;
	/** SAML Provider Details */
	SAML?: InputMaybe<SamlProviderDetailsInput>;
};

export type Query = {
	__typename?: 'Query';
	/** Gets a Channel by ID */
	getChannel: Channel;
	/** Gets a CollaborativeBroadcastConfiguration by Conference ID */
	getCollaborativeBroadcastConfiguration: CollaborativeBroadcastConfiguration;
	/** Gets a Conference by ID and validates if current User is allowed to interact with it */
	getConference: Conference;
	getCurrentUser: User;
	getEventByName: Event;
	/** Gets a presigned url to upload a file to an Event */
	getEventUploadPresignedUrl: Scalars['String'];
	getEventWithConfigByName: EventWithConfig;
	getFile: File;
	getFileStorage: FileStorage;
	getLink: Link;
	/** Get a Question */
	getQuestion: Question;
	/** Get a QuestionPool */
	getQuestionPool: QuestionPool;
	/** Gets a Stream by ID */
	getStream: Stream;
	/** Gets Stream Provider information */
	getStreamProviderInfo: StreamProviderInfo;
	getUser: User;
	getUserSession: UserSession;
	/** Gets the count */
	getUserSessionCount: Scalars['Float'];
	/** Gets history of all UserSessionCounts */
	getUserSessionCounts: Array<UserSessionCount>;
	/** List of command history for user's current event  */
	listCommandHistory: CommandHistoryList;
	/** Gets all Conferences by Event ID */
	listConferences: Array<Conference>;
	/** Lists all Events */
	listEvents: EventList;
	listFiles: Array<File>;
	listFolders: Array<Folder>;
	/** List Geolocations by Event ID and Date Range */
	listGeolocations: Array<Geolocation>;
	listLinks: Array<Link>;
	listMessages: MessageList;
	listModeratedMessages: MessageList;
	/** Lists Public Channel by Conference ID */
	listPublicChannels: Array<Channel>;
	/** Lists all public Streams by Conference ID */
	listPublicStreams: Array<Stream>;
	/** List Questions */
	listQuestions: QuestionList;
	/** Lists all Streams by Conference ID */
	listStreams: Array<Stream>;
	/** Gets all Talkrooms */
	listTalkrooms: Array<Talkroom>;
	/** Gets all Conferences by Event ID */
	listUserAssignedConferences: Array<Conference>;
	/** Lists all Private Channels of a User */
	listUserChannels: UserChannels;
	listUserSessionLogs: UserSessionLogsList;
	listUserSessions: UserSessionList;
	listUsers: UserList;
};

export type QueryGetChannelArgs = {
	id: Scalars['UUID'];
};

export type QueryGetCollaborativeBroadcastConfigurationArgs = {
	conference_id: Scalars['UUID'];
};

export type QueryGetConferenceArgs = {
	id: Scalars['UUID'];
};

export type QueryGetCurrentUserArgs = {
	access_token?: InputMaybe<Scalars['String']>;
};

export type QueryGetEventByNameArgs = {
	event_name: Scalars['String'];
};

export type QueryGetEventUploadPresignedUrlArgs = {
	file_name: FileName;
};

export type QueryGetEventWithConfigByNameArgs = {
	event_name: Scalars['String'];
};

export type QueryGetFileArgs = {
	id: Scalars['UUID'];
};

export type QueryGetFileStorageArgs = {
	getFileStorageInput?: InputMaybe<GetFileStorageInput>;
};

export type QueryGetLinkArgs = {
	id: Scalars['UUID'];
};

export type QueryGetQuestionArgs = {
	question_id: Scalars['UUID'];
};

export type QueryGetQuestionPoolArgs = {
	question_pool_id: Scalars['UUID'];
};

export type QueryGetStreamArgs = {
	id: Scalars['UUID'];
};

export type QueryGetStreamProviderInfoArgs = {
	id: Scalars['UUID'];
};

export type QueryGetUserArgs = {
	getUserInput: GetUserInput;
};

export type QueryGetUserSessionArgs = {
	getUserSessionInput: GetUserSessionInput;
};

export type QueryGetUserSessionCountsArgs = {
	getUserSessionCountsInput: GetUserSessionCountsInput;
};

export type QueryListCommandHistoryArgs = {
	listCommandHistoryInput: ListCommandHistoryInput;
};

export type QueryListEventsArgs = {
	listPaginationInputArgsInput: ListPaginationInputArgsInput;
};

export type QueryListFilesArgs = {
	listFilesInput: ListFilesInput;
};

export type QueryListFoldersArgs = {
	listFoldersInput: ListFoldersInput;
};

export type QueryListGeolocationsArgs = {
	listGeolocationsInput: ListGeolocationsInput;
};

export type QueryListLinksArgs = {
	listLinksInput: ListLinksInput;
};

export type QueryListMessagesArgs = {
	listMessagesInput: ListMessagesInput;
};

export type QueryListModeratedMessagesArgs = {
	listMessagesInput: ListMessagesInput;
};

export type QueryListPublicChannelsArgs = {
	conference_id: Scalars['UUID'];
};

export type QueryListPublicStreamsArgs = {
	conference_id: Scalars['UUID'];
};

export type QueryListQuestionsArgs = {
	listQuestionsInput: ListQuestionsInput;
};

export type QueryListStreamsArgs = {
	conference_id: Scalars['UUID'];
};

export type QueryListTalkroomsArgs = {
	conference_id: Scalars['UUID'];
};

export type QueryListUserAssignedConferencesArgs = {
	user_id: Scalars['UUID'];
};

export type QueryListUserSessionLogsArgs = {
	listUserSessionLogs: ListUserSessionLogsArgs;
};

export type QueryListUserSessionsArgs = {
	listUserSessionsInput: ListUserSessionsInput;
};

export type QueryListUsersArgs = {
	list: ListUsersArgs;
};

export type Question = {
	__typename?: 'Question';
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Question content */
	content: Scalars['String'];
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Date of deletion */
	deleted_at?: Maybe<Scalars['DateTime']>;
	/** Who edited the question */
	edited_by?: Maybe<QuestionSender>;
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Old status defined when received from onQuestionUpdated subscription */
	old_status?: Maybe<QuestionStatus>;
	/** Associated QuestionPool ID */
	question_pool_id: Scalars['UUID'];
	/** Information about who sent the question */
	sender: QuestionSender;
	/** Status */
	status: QuestionStatus;
};

export type QuestionList = {
	__typename?: 'QuestionList';
	/** Total count */
	count?: Maybe<Scalars['Int']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
	/** List of questionss */
	questions: Array<Question>;
};

export type QuestionPool = {
	__typename?: 'QuestionPool';
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Description */
	description?: Maybe<Scalars['String']>;
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Id of the highlighted question */
	highlighted_question_id?: Maybe<Scalars['UUID']>;
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Name */
	name: Scalars['String'];
};

export type QuestionSender = {
	__typename?: 'QuestionSender';
	/** Email of the user */
	user_email: Scalars['Email'];
	/** User ID */
	user_id: Scalars['UUID'];
	/** User name */
	user_name: Scalars['String'];
};

export enum QuestionStatus {
	Answered = 'ANSWERED',
	Deleted = 'DELETED',
	InReview = 'IN_REVIEW',
	Validated = 'VALIDATED'
}

export type RemoveConferencesFromUserInput = {
	/** Conference IDs to add */
	conference_ids: Array<Scalars['UUID']>;
	/** Conference User ID */
	user_id: Scalars['UUID'];
};

export type RemoveUsersFromConferenceInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
	/** Conference User IDs to add */
	user_ids: Array<Scalars['UUID']>;
};

export type ResetPasswordInput = {
	/** Event id */
	event_id: Scalars['UUID'];
	/** Selected Language */
	language?: Language;
	/** User email */
	user_email: Scalars['String'];
};

export enum RestrictionType {
	BlackList = 'BLACK_LIST',
	WhiteList = 'WHITE_LIST'
}

export enum RoleName {
	Administrator = 'ADMINISTRATOR',
	Host = 'HOST',
	Moderator = 'MODERATOR',
	User = 'USER'
}

export type SsoConfig = AuthMethod & {
	__typename?: 'SSOConfig';
	/** List of all SSO Clients */
	clients?: Maybe<Array<SsoClient>>;
	/** Type */
	type: AuthMethodType;
};

export type SsoConfigInput = {
	/** Provider Details */
	provider_details: ProviderDetailsInput;
	/** Provider Type */
	provider_type: SsoProviderType;
	/** Title */
	title: Scalars['String'];
	/** Auth Method Type */
	type?: AuthMethodType;
};

export type SamlProviderDetailsInput = {
	/** Metadata URL */
	MetadataURL: Scalars['URL'];
};

export type SendEventCommandInput = {
	/** Force command execution */
	is_forced?: Scalars['Boolean'];
	/** Name of command */
	name: CommandName;
};

export type SendMessageInput = {
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Content of message */
	content: Scalars['String'];
};

export type SendQuestionInput = {
	/** Content of the question */
	content: Scalars['String'];
	/** ID of the question pool */
	question_pool_id: Scalars['UUID'];
};

export type SendTalkroomReactionInput = {
	/** Content (Thumbs Up, Clapping Hands) */
	content: Emoji;
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type SendUserCommandInput = {
	/** Name of command */
	name: CommandName;
	/** Receiver Id */
	user_id: Scalars['UUID'];
};

export type SignInConfig = AuthMethod & {
	__typename?: 'SignInConfig';
	/** Type */
	type: AuthMethodType;
};

export type SignInConfigInput = {
	/** Auth Method Type */
	type?: AuthMethodType;
};

export type SignInInput = {
	/** Event id */
	event_id: Scalars['UUID'];
	/** User Password */
	password: Scalars['String'];
	/** User Position */
	position?: InputMaybe<PositionInput>;
	/** User email */
	user_email: Scalars['String'];
};

export type SignInNoPasswordConfig = AuthMethod & {
	__typename?: 'SignInNoPasswordConfig';
	/** Event Password Field */
	field_event_password?: Maybe<PasswordFieldConfig>;
	/** Type */
	type: AuthMethodType;
};

export type SignInNoPasswordConfigInput = {
	/** Event Password */
	event_password?: InputMaybe<Scalars['String']>;
	/** Auth Method Type */
	type?: AuthMethodType;
};

export type SignInNoPasswordInput = {
	/** Event ID */
	event_id: Scalars['UUID'];
	/** Event Password */
	event_password?: InputMaybe<Scalars['String']>;
	/** User Position */
	position?: InputMaybe<PositionInput>;
	/** User email */
	user_email: Scalars['Email'];
};

export type SignInResult = {
	__typename?: 'SignInResult';
	/** Access Token */
	access_token: Scalars['String'];
	/** Session ID */
	session_id: Scalars['UUID'];
	/** User */
	user?: Maybe<User>;
};

export type SignUpConfig = AuthMethod & {
	__typename?: 'SignUpConfig';
	/** Type */
	type: AuthMethodType;
};

export type SignUpConfigInput = {
	/** Auth Method Type */
	type?: AuthMethodType;
};

export type SignUpInput = {
	/** Event id */
	event_id: Scalars['UUID'];
	/** User selected language on sign up */
	language: Language;
	/** User Password */
	password: Scalars['String'];
	/** Password confirmation */
	password_confirm: Scalars['String'];
	/** User Position */
	position?: InputMaybe<PositionInput>;
	/** User email */
	user_email: Scalars['String'];
	/** User name */
	user_name: Scalars['String'];
};

export type SignUpNoPasswordConfig = AuthMethod & {
	__typename?: 'SignUpNoPasswordConfig';
	/** Event Password Field */
	field_event_password?: Maybe<PasswordFieldConfig>;
	/** User Email Field */
	field_user_email?: Maybe<EmailFieldConfig>;
	/** User Name Field */
	field_user_name?: Maybe<TextFieldConfig>;
	/** Type */
	type: AuthMethodType;
};

export type SignUpNoPasswordConfigInput = {
	/** Event Password */
	event_password?: InputMaybe<Scalars['String']>;
	/** User Email type field config */
	field_user_email?: InputMaybe<EmailFieldConfigInput>;
	/** User Name type field config */
	field_user_name?: InputMaybe<TextFieldConfigInput>;
	/** Auth Method Type */
	type?: AuthMethodType;
};

export type SignUpNoPasswordInput = {
	/** Event ID */
	event_id: Scalars['UUID'];
	/** Event Password */
	event_password?: InputMaybe<Scalars['String']>;
	/** Additional information by event */
	information?: InputMaybe<Scalars['JSON']>;
	/** Legacy password only for v1 purposes */
	password?: InputMaybe<Scalars['String']>;
	/** User Position */
	position?: InputMaybe<PositionInput>;
	/** User Email */
	user_email?: InputMaybe<Scalars['Email']>;
	/** User Name */
	user_name?: InputMaybe<Scalars['String']>;
};

export type SocialLink = {
	__typename?: 'SocialLink';
	/** Id of the link */
	id: Scalars['UUID'];
	/** Description of the link */
	name: Scalars['String'];
	/** Order of the link */
	order?: Maybe<Scalars['Float']>;
	/** Type of social link */
	type: SocialLinkType;
	/** URL of the link */
	url: Scalars['URL'];
};

export type SocialLinkOrderInput = {
	/** Order */
	order: Scalars['Float'];
	/** Associated social link ID */
	social_link_id: Scalars['UUID'];
};

export enum SocialLinkType {
	Facebook = 'FACEBOOK',
	Instagram = 'INSTAGRAM',
	Linkedin = 'LINKEDIN',
	X = 'X',
	Youtube = 'YOUTUBE'
}

export type SsoClient = {
	__typename?: 'SsoClient';
	/** Unique Identifier */
	id: Scalars['String'];
	/** Title */
	title: Scalars['String'];
};

export enum SsoProviderType {
	Facebook = 'Facebook',
	Google = 'Google',
	LoginWithAmazon = 'LoginWithAmazon',
	Oidc = 'OIDC',
	Saml = 'SAML',
	SignInWithApple = 'SignInWithApple'
}

export type StartCollaborativeBroadcastInput = {
	/** Conference ID */
	conference_id: Scalars['UUID'];
};

export type Status = {
	__typename?: 'Status';
	/** Tells if operation was successful or not */
	success: Scalars['Boolean'];
};

export type Stream = {
	__typename?: 'Stream';
	/** Closed Caption provider */
	closed_caption_provider?: Maybe<ClosedCaptionProvider>;
	/** List of related closed captions */
	closed_captions: Array<ClosedCaption>;
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Is the stream private to the public */
	is_private: Scalars['Boolean'];
	/** Name */
	name: Scalars['String'];
	/** Order */
	order?: Maybe<Scalars['Int']>;
	/** Provider */
	provider: StreamProvider;
	/** Status of the stream only available for IVS streams */
	status: StreamStatus;
	/** Type */
	type: StreamType;
	/** URL */
	url: Scalars['URL'];
};

export type StreamOrderInput = {
	/** Order */
	order: Scalars['Int'];
	/** Associated Stream ID */
	stream_id: Scalars['UUID'];
};

export enum StreamProvider {
	Ivs = 'IVS',
	IvsUnmanaged = 'IVS_UNMANAGED',
	Vimeo = 'VIMEO',
	Youtube = 'YOUTUBE'
}

export type StreamProviderInfo = {
	/** Type */
	type: StreamProvider;
};

export enum StreamStatus {
	Ended = 'ENDED',
	Failed = 'FAILED',
	Started = 'STARTED',
	Unknown = 'UNKNOWN'
}

export type StreamTextCc = ClosedCaption & {
	__typename?: 'StreamTextCC';
	/** Language as the id of the closedCaption */
	id: Scalars['String'];
	/** CC Language */
	language: Language;
	/** Closed Caption provider type */
	type: ClosedCaptionProvider;
	/** StreamText URL */
	url: Scalars['URL'];
};

export type StreamTextCcInput = {
	/** language */
	language: Language;
	/** Stream text provider type */
	type?: ClosedCaptionProvider;
	/** Stream text iFrame URL */
	url: Scalars['URL'];
};

export enum StreamType {
	Live = 'LIVE',
	Replay = 'REPLAY'
}

export type Subscription = {
	__typename?: 'Subscription';
	/** Join a conference, this subscription does not return any information */
	joinConference: Scalars['Boolean'];
	onChannelCreated: Channel;
	onChannelUpdated: Channel;
	onCollaborativeBroadcastConfigurationUpdated: CollaborativeBroadcastConfiguration;
	onCollaborativeBroadcastInviteSent: CollaborativeBroadcastCredential;
	onConferenceCommandSent: Command;
	onEventCommandSent: Command;
	onEventUpdated: Event;
	onMessageCreated: Message;
	onMessageDeleted: MessageDeleted;
	onMessageModerated: Message;
	onQuestionPoolUpdated: QuestionPool;
	onQuestionUpdated: Question;
	onStreamCreated: Stream;
	onStreamDeleted: Stream;
	onStreamUpdated: Stream;
	onTalkroomParticipantJoined: TalkroomParticipantJoined;
	onTalkroomParticipantLeft: TalkroomParticipantLeft;
	onTalkroomReactionSent: TalkroomReaction;
	onTalkroomUpdated: Talkroom;
	onUserCommandSent: Command;
	onUserSessionCreated: UserSession;
	onUserSessionDeleted: UserSession;
};

export type SubscriptionJoinConferenceArgs = {
	conference_id: Scalars['UUID'];
};

export type SubscriptionOnCollaborativeBroadcastConfigurationUpdatedArgs = {
	onCollaborativeBroadcastConfigurationUpdatedInput: OnCollaborativeBroadcastConfigurationUpdatedInput;
};

export type SubscriptionOnCollaborativeBroadcastInviteSentArgs = {
	onCollaborativeBroadcastInviteSentInput: OnCollaborativeBroadcastInviteSentInput;
};

export type SubscriptionOnConferenceCommandSentArgs = {
	conference_id: Scalars['UUID'];
};

export type SubscriptionOnMessageCreatedArgs = {
	channel_id?: InputMaybe<Scalars['UUID']>;
	conference_id?: InputMaybe<Scalars['UUID']>;
};

export type SubscriptionOnMessageDeletedArgs = {
	channel_id?: InputMaybe<Scalars['UUID']>;
	conference_id?: InputMaybe<Scalars['UUID']>;
};

export type SubscriptionOnMessageModeratedArgs = {
	channel_id: Scalars['UUID'];
};

export type SubscriptionOnQuestionPoolUpdatedArgs = {
	onQuestionPoolUpdatedInput: OnQuestionPoolUpdatedInput;
};

export type SubscriptionOnQuestionUpdatedArgs = {
	onQuestionUpdatedInput: OnQuestionUpdatedInput;
};

export type SubscriptionOnStreamCreatedArgs = {
	conference_id: Scalars['UUID'];
};

export type SubscriptionOnStreamDeletedArgs = {
	conference_id: Scalars['UUID'];
};

export type SubscriptionOnStreamUpdatedArgs = {
	conference_id: Scalars['UUID'];
};

export type SubscriptionOnTalkroomParticipantJoinedArgs = {
	onTalkroomParticipantJoinedInput: OnTalkroomParticipantJoinedInput;
};

export type SubscriptionOnTalkroomParticipantLeftArgs = {
	onTalkroomParticipantLeftInput: OnTalkroomParticipantLeftInput;
};

export type SubscriptionOnTalkroomReactionSentArgs = {
	onTalkroomReactionSentInput: OnTalkroomReactionSentInput;
};

export type SubscriptionOnTalkroomUpdatedArgs = {
	onTalkroomUpdatedInput: OnTalkroomUpdatedInput;
};

export type SubscriptionOnUserSessionCreatedArgs = {
	event_id?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUserSessionDeletedArgs = {
	event_id?: InputMaybe<Scalars['String']>;
};

export type Table = {
	__typename?: 'Table';
	/** Specific configs of current Table */
	config_overwrite: Scalars['JSON'];
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Table group reference */
	group_ref?: Maybe<Scalars['String']>;
	/** Icon of current Table */
	icon?: Maybe<Scalars['String']>;
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Is the table visible only to administrators and moderators */
	isPrivate?: Maybe<Scalars['Boolean']>;
	/** Max number of participants allowed */
	max_participants: Scalars['Int'];
	/** Name of current Table */
	name: Scalars['String'];
	/** Table order */
	order: Scalars['Int'];
	/** Date of last update */
	updated_at: Scalars['DateTime'];
};

export type Talkroom = {
	__typename?: 'Talkroom';
	/** Associated Channel ID */
	channel_id?: Maybe<Scalars['UUID']>;
	/** Associated Conference ID */
	conference_id: Scalars['UUID'];
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Highlighted Participant ID */
	highlighted_participant_id?: Maybe<Scalars['UUID']>;
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Talkroom lock status */
	is_locked: Scalars['Boolean'];
	/** Max participants allowed */
	max_participants: Scalars['Int'];
	/** Name (Different from name in Livekit) */
	name: Scalars['String'];
	/** Creation date */
	order: Scalars['Float'];
	/** Presence in the talkroom */
	presence?: Maybe<TalkroomPresence>;
	/** Raised hands in the talkroom */
	raised_hands?: Maybe<Array<TalkroomRaisedHand>>;
	/** Number of seats reserved for administrators and moderators */
	reserved_seats: Scalars['Int'];
	/** Creation date */
	type: TalkroomType;
};

export type TalkroomCredential = {
	__typename?: 'TalkroomCredential';
	/** Talkroom */
	talkroom: Talkroom;
	/** Talkroom Token */
	talkroom_token: Scalars['String'];
	/** User ID */
	user_id: Scalars['UUID'];
};

export type TalkroomOrderInput = {
	/** Order */
	order: Scalars['Int'];
	/** Associated talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type TalkroomParticipant = {
	__typename?: 'TalkroomParticipant';
	/** User identfier reference */
	user_id: Scalars['UUID'];
	/** User name */
	user_name: Scalars['String'];
};

export type TalkroomParticipantJoined = {
	__typename?: 'TalkroomParticipantJoined';
	/** Conference identifier reference */
	conference_id: Scalars['UUID'];
	/** Event identifier reference */
	event_id: Scalars['UUID'];
	/** Participant who joined the talkroom */
	participant: TalkroomParticipant;
	/** Talkroom identifier reference */
	talkroom_id: Scalars['UUID'];
};

export type TalkroomParticipantLeft = {
	__typename?: 'TalkroomParticipantLeft';
	/** Conference identifier reference */
	conference_id: Scalars['UUID'];
	/** Event identifier reference */
	event_id: Scalars['UUID'];
	/** Participant who left the talkroom */
	participant: TalkroomParticipant;
	/** Talkroom identifier reference */
	talkroom_id: Scalars['UUID'];
};

export type TalkroomPresence = {
	__typename?: 'TalkroomPresence';
	/** Total number of participants in the talkroom */
	participant_number: Scalars['Float'];
	/** Participants in the talkroom */
	participants: Array<TalkroomParticipant>;
};

export type TalkroomRaisedHand = {
	__typename?: 'TalkroomRaisedHand';
	/** Participant */
	participant: TalkroomParticipant;
	/** Hand raising date */
	raised_at: Scalars['DateTime'];
};

export type TalkroomReaction = {
	__typename?: 'TalkroomReaction';
	/** Content (Thumbs Up, Clapping Hands) */
	content: Emoji;
	/** Creation date */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Sender */
	sender: TalkroomParticipant;
	/** Associated Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export enum TalkroomType {
	Backstage = 'BACKSTAGE',
	Meet = 'MEET',
	Stage = 'STAGE'
}

export type TextFieldConfig = {
	__typename?: 'TextFieldConfig';
	/** Field is required */
	is_required?: Maybe<Scalars['Boolean']>;
	/** HTML Validation schema */
	validation_schema: Scalars['String'];
};

export type TextFieldConfigInput = {
	/** Field is required */
	is_required?: Scalars['Boolean'];
};

export type ThemeColorConfig = {
	__typename?: 'ThemeColorConfig';
	/** Contrast Text */
	contrastText?: Maybe<Scalars['Color']>;
	/** Dark Color */
	dark?: Maybe<Scalars['Color']>;
	/** Light Color */
	light?: Maybe<Scalars['Color']>;
	/** Main Color */
	main: Scalars['Color'];
};

export type ThemeColorConfigInput = {
	/** Contrast Text */
	contrastText?: InputMaybe<Scalars['Color']>;
	/** Dark Color */
	dark?: InputMaybe<Scalars['Color']>;
	/** Light Color */
	light?: InputMaybe<Scalars['Color']>;
	/** Main Color */
	main: Scalars['Color'];
};

export type ThemeOffsetConfig = {
	__typename?: 'ThemeOffsetConfig';
	/** Dark Color Offset */
	dark: Scalars['Float'];
	/** Light Color Offset */
	light: Scalars['Float'];
};

export type ThemeOffsetConfigInput = {
	/** Dark Color Offset */
	dark: Scalars['Float'];
	/** Light Color Offset */
	light: Scalars['Float'];
};

export type ToggleHighlightedParticipantInput = {
	/** Participant ID */
	participant_id?: InputMaybe<Scalars['UUID']>;
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type ToggleHighlightedQuestionInput = {
	/** Question ID */
	question_id?: InputMaybe<Scalars['UUID']>;
	/** Question Pool ID */
	question_pool_id: Scalars['UUID'];
};

export type ToggleRaisedHandInput = {
	/** Raising Hand Indicator */
	is_raised: Scalars['Boolean'];
	/** Talkroom ID */
	talkroom_id: Scalars['UUID'];
};

export type UnassignPublicChannelInput = {
	/** Channel ID */
	channel_id: Scalars['UUID'];
	/** Associated Conference ID */
	conference_id?: InputMaybe<Scalars['UUID']>;
	/** Associated Talkroom ID */
	talkroom_id?: InputMaybe<Scalars['UUID']>;
};

export type UpdateConferenceInput = {
	/** End date */
	date_end?: InputMaybe<Scalars['DateTime']>;
	/** Start date */
	date_start?: InputMaybe<Scalars['DateTime']>;
	/** Description */
	description?: InputMaybe<Scalars['String']>;
	/** Name */
	name?: InputMaybe<Scalars['String']>;
	/** Type */
	type?: InputMaybe<ConferenceType>;
};

export type UpdateEventConfigThemeInput = {
	/** Background Color Config */
	background?: InputMaybe<BackgroundConfigInput>;
	/** Contrast Threshold */
	contrastThreshold?: InputMaybe<Scalars['Float']>;
	/** Primary Theme Color Config */
	primary?: InputMaybe<ThemeColorConfigInput>;
	/** Secondary Theme Color Config */
	secondary?: InputMaybe<ThemeColorConfigInput>;
	/** Theme Offset Config */
	tonalOffset?: InputMaybe<ThemeOffsetConfigInput>;
};

export type UpdateEventInput = {
	/** End date of event */
	date_end?: InputMaybe<Scalars['DateTime']>;
	/** Start date of event */
	date_start?: InputMaybe<Scalars['DateTime']>;
	/** Define state of event OPEN or LOCKED */
	event_state?: InputMaybe<Event_State>;
	id?: InputMaybe<Scalars['UUID']>;
	/**
	 * Event name,
	 *     allowed values are letters, numbers and "-_."
	 */
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	/** Type */
	type?: InputMaybe<EventType>;
};

export type UpdatePasswordInput = {
	/** Event id */
	event_id: Scalars['UUID'];
	/** Selected Language */
	language?: Language;
	/** User new password */
	password: Scalars['String'];
	/** User password confirm */
	password_confirm: Scalars['String'];
	/** User reset token */
	reset_token: Scalars['UUID'];
	/** User email */
	user_email: Scalars['String'];
};

export type UpdatePublicChannelInput = {
	/** Define what moderation type you want for the channel */
	moderation?: InputMaybe<ModerationMode>;
	/** Name */
	name?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionPoolInput = {
	/** Description */
	description?: InputMaybe<Scalars['String']>;
	/** Name */
	name?: InputMaybe<Scalars['String']>;
};

export type UpdateStreamInput = {
	/** Closed Caption Provider */
	closed_caption_provider?: InputMaybe<ClosedCaptionProvider>;
	/** Is Stream private */
	is_private?: InputMaybe<Scalars['Boolean']>;
	/** Name */
	name?: InputMaybe<Scalars['String']>;
	/** Provider, it's not possible to change provider to or from IVS */
	provider?: InputMaybe<StreamProvider>;
	/** Type */
	type?: InputMaybe<StreamType>;
	/** URL, it's not possible to update url for IVS Streams */
	url?: InputMaybe<Scalars['URL']>;
};

export type UpdateUserInput = {
	id: Scalars['UUID'];
	roleNames: Array<RoleName>;
	user_email: Scalars['String'];
	user_name: Scalars['String'];
};

export type UpdateUserSessionGeolocationInput = {
	/** Position latitude */
	latitude: Scalars['Float'];
	/** Position longitude */
	longitude: Scalars['Float'];
};

export type UpsertCollaborativeBroadcastConfigurationInput = {
	/** Associated stream id */
	broadcast_stream_id?: InputMaybe<Scalars['UUID']>;
	/** Tells if is enabled or not */
	is_enabled?: InputMaybe<Scalars['Boolean']>;
	/** Layout type */
	layout?: InputMaybe<LayoutType>;
};

export type User = {
	__typename?: 'User';
	/** Date of creation of the user */
	created_at: Scalars['DateTime'];
	/** Event Identifier */
	event_id: Scalars['UUID'];
	/** User Identifier */
	id: Scalars['UUID'];
	/** User profile */
	profile?: Maybe<Profile>;
	/** User profile id */
	reset_token?: Maybe<Scalars['UUID']>;
	/** User profile id */
	reset_token_expiration_time?: Maybe<Scalars['Float']>;
	/** User roles */
	roleNames: Array<RoleName>;
	/** Example field (placeholder) */
	updated_at: Scalars['DateTime'];
	/** User event id */
	userEventId?: Maybe<Scalars['UUID']>;
	/** User profile id */
	userProfileId?: Maybe<Scalars['UUID']>;
	/** User Email */
	user_email: Scalars['String'];
	/** User name */
	user_name?: Maybe<Scalars['String']>;
};

export type UserChannels = {
	__typename?: 'UserChannels';
	/** User Channels */
	channels: Array<Channel>;
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Associated User ID */
	user_id: Scalars['UUID'];
};

export type UserList = {
	__typename?: 'UserList';
	/** Total users count by event */
	count?: Maybe<Scalars['Float']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
	/** List of Users */
	users: Array<User>;
};

export type UserSession = {
	__typename?: 'UserSession';
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Associated user */
	user?: Maybe<User>;
	/** User email */
	user_email: Scalars['String'];
	/** Associated User ID */
	user_id: Scalars['UUID'];
	/** User name */
	user_name: Scalars['String'];
	/** Associated UserTable Entity */
	user_table?: Maybe<UserTable>;
	/** Associated TableUser ID */
	user_table_id?: Maybe<Scalars['UUID']>;
};

export type UserSessionCount = {
	__typename?: 'UserSessionCount';
	/** Average Count Number */
	avg_count?: Maybe<Scalars['Int']>;
	/** Deprecated Count Number */
	count?: Maybe<Scalars['Int']>;
	/** Creation Date */
	created_at: Scalars['DateTime'];
	/** Max Count Number */
	max_count?: Maybe<Scalars['Int']>;
	/** Min Count Number */
	min_count?: Maybe<Scalars['Int']>;
	/** Creation Date formated as a timestamp */
	timestamp?: Maybe<Scalars['Float']>;
};

export type UserSessionJoinTableInput = {
	/** id representing the userSession connection to a Table */
	user_table_id: Scalars['UUID'];
};

export type UserSessionList = {
	__typename?: 'UserSessionList';
	/** Total User Sessions count by event ID */
	count?: Maybe<Scalars['Int']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
	/** List of User Sessions */
	user_sessions: Array<UserSession>;
};

export type UserSessionLog = {
	__typename?: 'UserSessionLog';
	/** User session login city */
	city?: Maybe<Scalars['String']>;
	/** User session login country */
	country?: Maybe<Scalars['String']>;
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Associated Event ID */
	event_id: Scalars['UUID'];
	/** Unique identfier */
	id: Scalars['UUID'];
	/** Timestamp of login date */
	logged_in: Scalars['Float'];
	/** Timestamp of logout date */
	logged_out: Scalars['Float'];
	/** Timestamp of session duration */
	session_duration: Scalars['Int'];
	/** Email of User */
	user_email: Scalars['String'];
	/** Name of User */
	user_name: Scalars['String'];
};

export type UserSessionLogsList = {
	__typename?: 'UserSessionLogsList';
	/** Total user session logs count by event ID */
	count?: Maybe<Scalars['Int']>;
	/** Last evaluated key */
	last_evaluated_key?: Maybe<Scalars['String']>;
	/** List of User Session Logs */
	user_session_logs: Array<UserSessionLog>;
};

export type UserTable = {
	__typename?: 'UserTable';
	/** Date of creation */
	created_at: Scalars['DateTime'];
	/** Unique identifier */
	id: Scalars['UUID'];
	/** Is user forced to be muted on the table */
	is_force_mute?: Maybe<Scalars['Boolean']>;
	/** Is user muted on the table */
	is_mute?: Maybe<Scalars['Boolean']>;
	/** Associated Table Entity */
	table: Table;
	/** Associated Table ID */
	table_id: Scalars['UUID'];
	/** Date of last update */
	updated_at: Scalars['DateTime'];
	/** Associated User Session ID */
	user_session_id: Scalars['UUID'];
};

export type WordlyCc = ClosedCaption & {
	__typename?: 'WordlyCC';
	/** Id of Wordly closedCaption */
	id: Scalars['String'];
	/** Closed Caption provider type */
	type: ClosedCaptionProvider;
	/** Wordly iFrame URL */
	url: Scalars['URL'];
};

export type WordlyCcInput = {
	/** Wordly provider type */
	type?: ClosedCaptionProvider;
	/** Wordly iFrame URL */
	url: Scalars['URL'];
};

export type AddAuthMethodsToEventConfigMutationVariables = Exact<{
	addAuthMethodInput: AddAuthMethodInput;
	event_id: Scalars['UUID'];
}>;

export type AddAuthMethodsToEventConfigMutation = {
	__typename?: 'Mutation';
	addAuthMethodsToEventConfig: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type AddClientToSsoAuthMethodMutationVariables = Exact<{
	addSsoClientInput: AddSsoClientInput;
	event_id: Scalars['UUID'];
}>;

export type AddClientToSsoAuthMethodMutation = {
	__typename?: 'Mutation';
	addClientToSsoAuthMethod: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type AddClosedCaptionToStreamMutationVariables = Exact<{
	addClosedCaptionInput: AddClosedCaptionInput;
	stream_id: Scalars['UUID'];
}>;

export type AddClosedCaptionToStreamMutation = {
	__typename?: 'Mutation';
	addClosedCaptionToStream: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type AddConferencesToUserMutationVariables = Exact<{
	addConferencesToUserInput: AddConferencesToUserInput;
}>;

export type AddConferencesToUserMutation = { __typename?: 'Mutation'; addConferencesToUser: boolean };

export type AddGeoRestrictedAreaMutationVariables = Exact<{
	AddGeoRestrictedAreaInput: AddGeoRestrictedAreaInput;
}>;

export type AddGeoRestrictedAreaMutation = {
	__typename?: 'Mutation';
	addGeoRestrictedArea: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type AddSocialLinkToEventMutationVariables = Exact<{
	addSocialLinkToEventInput: AddSocialLinkToEventInput;
}>;

export type AddSocialLinkToEventMutation = {
	__typename?: 'Mutation';
	addSocialLinkToEvent: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type AddUsersToChannelMutationVariables = Exact<{
	addUsersToChannelInput: AddUsersToChannelInput;
}>;

export type AddUsersToChannelMutation = {
	__typename?: 'Mutation';
	addUsersToChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type AddUsersToConferenceMutationVariables = Exact<{
	addUsersToConferenceInput: AddUsersToConferenceInput;
}>;

export type AddUsersToConferenceMutation = {
	__typename?: 'Mutation';
	addUsersToConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type AssignPublicChannelMutationVariables = Exact<{
	assignPublicChannelInput: AssignPublicChannelInput;
}>;

export type AssignPublicChannelMutation = {
	__typename?: 'Mutation';
	assignPublicChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type ChangeQuestionStatusMutationVariables = Exact<{
	changeQuestionStatusInput: ChangeQuestionStatusInput;
}>;

export type ChangeQuestionStatusMutation = {
	__typename?: 'Mutation';
	changeQuestionStatus: {
		__typename?: 'Question';
		conference_id: any;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		old_status?: QuestionStatus | null;
		question_pool_id: any;
		status: QuestionStatus;
		edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
		sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
	};
};

export type CreateConferenceMutationVariables = Exact<{
	createConferenceInput: CreateConferenceInput;
}>;

export type CreateConferenceMutation = {
	__typename?: 'Mutation';
	createConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type CreateEventMutationVariables = Exact<{
	createEventInput: CreateEventInput;
}>;

export type CreateEventMutation = {
	__typename?: 'Mutation';
	createEvent: {
		__typename?: 'EventWithConfig';
		event: {
			__typename?: 'Event';
			created_at: string;
			date_end: string;
			date_start: string;
			event_state: Event_State;
			guestAccess: boolean;
			hasEventPassword?: boolean | null;
			id: string;
			name: string;
			noPassword: boolean;
			updated_at: string;
			social_links: Array<{
				__typename?: 'SocialLink';
				id: any;
				name: string;
				order?: number | null;
				type: SocialLinkType;
				url: any;
			}>;
		};
		eventConfig: {
			__typename?: 'EventConfig';
			event_id: any;
			is_geo_restricted: boolean;
			type: EventType;
			auth_methods: Array<
				| { __typename?: 'SSOConfig'; type: AuthMethodType }
				| { __typename?: 'SignInConfig'; type: AuthMethodType }
				| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
			>;
			theme: {
				__typename?: 'EventConfigTheme';
				contrastThreshold: number;
				background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
				primary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				secondary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
			};
		};
	};
};

export type CreateFileMutationVariables = Exact<{
	createFileInput: CreateFileInput;
}>;

export type CreateFileMutation = {
	__typename?: 'Mutation';
	createFile: {
		__typename?: 'FileCreated';
		upload_url: string;
		file: {
			__typename?: 'File';
			content_type: string;
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
			owner?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		};
	};
};

export type CreateFolderMutationVariables = Exact<{
	createFolderInput: CreateFolderInput;
}>;

export type CreateFolderMutation = {
	__typename?: 'Mutation';
	createFolder: {
		__typename?: 'Folder';
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		parent_folder_id: any;
		path: string;
		updated_at: any;
		files: Array<{
			__typename?: 'File';
			content_type: string;
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
			owner?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
		folders: Array<{
			__typename?: 'Folder';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			parent_folder_id: any;
			path: string;
			updated_at: any;
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				links: Array<{
					__typename?: 'Link';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
					owner?: {
						__typename?: 'User';
						created_at: any;
						event_id: any;
						id: any;
						reset_token?: any | null;
						reset_token_expiration_time?: number | null;
						roleNames: Array<RoleName>;
						updated_at: any;
						userEventId?: any | null;
						userProfileId?: any | null;
						user_email: string;
						user_name?: string | null;
					} | null;
				}>;
			}>;
		}>;
		links: Array<{
			__typename?: 'Link';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
		}>;
	};
};

export type CreateFrameMutationVariables = Exact<{
	createFrameInput: CreateFrameInput;
}>;

export type CreateFrameMutation = {
	__typename?: 'Mutation';
	createFrame: {
		__typename?: 'Frame';
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		type: FrameType;
		url: any;
	};
};

export type CreateLinkMutationVariables = Exact<{
	createLinkInput: CreateLinkInput;
}>;

export type CreateLinkMutation = {
	__typename?: 'Mutation';
	createLink: {
		__typename?: 'Link';
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		owner_id: any;
		parent_folder_id: any;
		updated_at: any;
		url: any;
		owner?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type CreatePrivateChannelMutationVariables = Exact<{
	createPrivateChannelInput: CreatePrivateChannelInput;
}>;

export type CreatePrivateChannelMutation = {
	__typename?: 'Mutation';
	createPrivateChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type CreatePublicChannelMutationVariables = Exact<{
	createPublicChannelInput: CreatePublicChannelInput;
}>;

export type CreatePublicChannelMutation = {
	__typename?: 'Mutation';
	createPublicChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type CreateQuestionPoolMutationVariables = Exact<{
	createQuestionPoolInput: CreateQuestionPoolInput;
}>;

export type CreateQuestionPoolMutation = {
	__typename?: 'Mutation';
	createQuestionPool: {
		__typename?: 'QuestionPool';
		conference_id: any;
		created_at: any;
		description?: string | null;
		event_id: any;
		highlighted_question_id?: any | null;
		id: any;
		name: string;
	};
};

export type CreateStreamMutationVariables = Exact<{
	createStreamInput: CreateStreamInput;
}>;

export type CreateStreamMutation = {
	__typename?: 'Mutation';
	createStream: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type CreateTalkroomMutationVariables = Exact<{
	createTalkroomInput: CreateTalkroomInput;
}>;

export type CreateTalkroomMutation = {
	__typename?: 'Mutation';
	createTalkroom: {
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	};
};

export type CreateUsersMutationVariables = Exact<{
	event_id: Scalars['String'];
	importUsersInput: Array<ImportUsersInput> | ImportUsersInput;
}>;

export type CreateUsersMutation = {
	__typename?: 'Mutation';
	createUsers: Array<{
		__typename?: 'ImportUser';
		hasCreated: boolean;
		reason?: string | null;
		user_email?: string | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	}>;
};

export type DeleteConferenceMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeleteConferenceMutation = {
	__typename?: 'Mutation';
	deleteConference: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteFilesMutationVariables = Exact<{
	ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteFilesMutation = {
	__typename?: 'Mutation';
	deleteFiles: Array<{ __typename?: 'DeletionStatus'; id: any; success: boolean }>;
};

export type DeleteFolderMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeleteFolderMutation = {
	__typename?: 'Mutation';
	deleteFolder: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteFrameMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeleteFrameMutation = {
	__typename?: 'Mutation';
	deleteFrame: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteLinksMutationVariables = Exact<{
	ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteLinksMutation = {
	__typename?: 'Mutation';
	deleteLinks: Array<{ __typename?: 'DeletionStatus'; id: any; success: boolean }>;
};

export type DeleteMessageMutationVariables = Exact<{
	deleteMessageInput: DeleteMessageInput;
}>;

export type DeleteMessageMutation = {
	__typename?: 'Mutation';
	deleteMessage: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeletePublicChannelMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeletePublicChannelMutation = {
	__typename?: 'Mutation';
	deletePublicChannel: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteQuestionPoolMutationVariables = Exact<{
	deleteQuestionPoolInput: DeleteQuestionPoolInput;
}>;

export type DeleteQuestionPoolMutation = {
	__typename?: 'Mutation';
	deleteQuestionPool: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteStreamMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeleteStreamMutation = {
	__typename?: 'Mutation';
	deleteStream: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteTalkroomMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type DeleteTalkroomMutation = {
	__typename?: 'Mutation';
	deleteTalkroom: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteUserMutationVariables = Exact<{
	deleteUserInput: DeleteUserInput;
}>;

export type DeleteUserMutation = {
	__typename?: 'Mutation';
	deleteUser: { __typename?: 'DeletionStatus'; id: any; success: boolean };
};

export type DeleteUsersMutationVariables = Exact<{
	deleteUsersInput: Array<DeleteUsersInput> | DeleteUsersInput;
}>;

export type DeleteUsersMutation = {
	__typename?: 'Mutation';
	deleteUsers: Array<{ __typename?: 'DeleteUsers'; message?: string | null; success: boolean; userId: string }>;
};

export type DuplicateConferenceMutationVariables = Exact<{
	duplicateConferenceInput: DuplicateConferenceInput;
}>;

export type DuplicateConferenceMutation = {
	__typename?: 'Mutation';
	duplicateConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type DuplicateEventMutationVariables = Exact<{
	duplicateEventInput: DuplicateEventInput;
}>;

export type DuplicateEventMutation = {
	__typename?: 'Mutation';
	duplicateEvent: {
		__typename?: 'EventWithConfig';
		event: {
			__typename?: 'Event';
			created_at: string;
			date_end: string;
			date_start: string;
			event_state: Event_State;
			guestAccess: boolean;
			hasEventPassword?: boolean | null;
			id: string;
			name: string;
			noPassword: boolean;
			updated_at: string;
			social_links: Array<{
				__typename?: 'SocialLink';
				id: any;
				name: string;
				order?: number | null;
				type: SocialLinkType;
				url: any;
			}>;
		};
		eventConfig: {
			__typename?: 'EventConfig';
			event_id: any;
			is_geo_restricted: boolean;
			type: EventType;
			auth_methods: Array<
				| { __typename?: 'SSOConfig'; type: AuthMethodType }
				| { __typename?: 'SignInConfig'; type: AuthMethodType }
				| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
			>;
			theme: {
				__typename?: 'EventConfigTheme';
				contrastThreshold: number;
				background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
				primary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				secondary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
			};
		};
	};
};

export type EditQuestionMutationVariables = Exact<{
	editQuestionInput: EditQuestionInput;
}>;

export type EditQuestionMutation = {
	__typename?: 'Mutation';
	editQuestion: {
		__typename?: 'Question';
		conference_id: any;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		old_status?: QuestionStatus | null;
		question_pool_id: any;
		status: QuestionStatus;
		edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
		sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
	};
};

export type EnableGeoRestrictionMutationVariables = Exact<{
	is_enabled: Scalars['Boolean'];
}>;

export type EnableGeoRestrictionMutation = {
	__typename?: 'Mutation';
	enableGeoRestriction: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type ExportConferenceParticipationLogsMutationVariables = Exact<{
	exportConferenceParticipantLogsInput: ExportConferenceParticipantLogsInput;
}>;

export type ExportConferenceParticipationLogsMutation = {
	__typename?: 'Mutation';
	exportConferenceParticipationLogs: string;
};

export type ExportUserSessionLogsMutationVariables = Exact<{
	exportUserSessionLogInput: ExportUserSessionLogInput;
}>;

export type ExportUserSessionLogsMutation = { __typename?: 'Mutation'; exportUserSessionLogs: string };

export type ImportUsersMutationVariables = Exact<{
	importUsersInput: Array<ImportUsersInput> | ImportUsersInput;
}>;

export type ImportUsersMutation = {
	__typename?: 'Mutation';
	importUsers: Array<{
		__typename?: 'ImportUser';
		hasCreated: boolean;
		reason?: string | null;
		user_email?: string | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	}>;
};

export type InviteUserToCollaborativeBroadcastMutationVariables = Exact<{
	inviteUserToCollaborativeBroadcastInput: InviteUserToCollaborativeBroadcastInput;
}>;

export type InviteUserToCollaborativeBroadcastMutation = {
	__typename?: 'Mutation';
	inviteUserToCollaborativeBroadcast: {
		__typename?: 'CollaborativeBroadcastCredential';
		conference_id: any;
		event_id: any;
		user_id: any;
		backstage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
				presence?: {
					__typename?: 'TalkroomPresence';
					participant_number: number;
					participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
				} | null;
				raised_hands?: Array<{
					__typename?: 'TalkroomRaisedHand';
					raised_at: any;
					participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
				}> | null;
			};
		};
		stage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
			};
		};
	};
};

export type JoinCollaborativeBroadcastMutationVariables = Exact<{
	joinCollaborativeBroadcastInput: JoinCollaborativeBroadcastInput;
}>;

export type JoinCollaborativeBroadcastMutation = {
	__typename?: 'Mutation';
	joinCollaborativeBroadcast: {
		__typename?: 'CollaborativeBroadcastCredential';
		conference_id: any;
		event_id: any;
		user_id: any;
		backstage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
				presence?: {
					__typename?: 'TalkroomPresence';
					participant_number: number;
					participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
				} | null;
				raised_hands?: Array<{
					__typename?: 'TalkroomRaisedHand';
					raised_at: any;
					participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
				}> | null;
			};
		};
		stage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
			};
		};
	};
};

export type JoinTalkroomMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type JoinTalkroomMutation = { __typename?: 'Mutation'; joinTalkroom: string };

export type KickoutTalkroomParticipantMutationVariables = Exact<{
	kickoutTalkroomParticipantInput: KickoutTalkroomParticipantInput;
}>;

export type KickoutTalkroomParticipantMutation = { __typename?: 'Mutation'; kickoutTalkroomParticipant: boolean };

export type LeaveConferenceMutationVariables = Exact<{
	connection_id: Scalars['UUID'];
}>;

export type LeaveConferenceMutation = { __typename?: 'Mutation'; leaveConference: boolean };

export type LockTalkroomMutationVariables = Exact<{
	lockTalkroomInput: LockTalkroomInput;
}>;

export type LockTalkroomMutation = {
	__typename?: 'Mutation';
	lockTalkroom: {
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	};
};

export type MuteAllTalkroomParticipantsMutationVariables = Exact<{
	talkroom_id: Scalars['UUID'];
}>;

export type MuteAllTalkroomParticipantsMutation = { __typename?: 'Mutation'; muteAllTalkroomParticipants: boolean };

export type MuteTalkroomParticipantMutationVariables = Exact<{
	muteTalkroomParticipantInput: MuteTalkroomParticipantInput;
}>;

export type MuteTalkroomParticipantMutation = { __typename?: 'Mutation'; muteTalkroomParticipant: boolean };

export type OrderSocialLinksOnEventMutationVariables = Exact<{
	orderSocialLinksInput: OrderSocialLinksInput;
}>;

export type OrderSocialLinksOnEventMutation = {
	__typename?: 'Mutation';
	orderSocialLinksOnEvent: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type OrderStreamsMutationVariables = Exact<{
	orderStreamsInput: OrderStreamsInput;
}>;

export type OrderStreamsMutation = {
	__typename?: 'Mutation';
	orderStreams: { __typename?: 'Status'; success: boolean };
};

export type OrderTalkroomsMutationVariables = Exact<{
	orderTalkroomsInput: OrderTalkroomsInput;
}>;

export type OrderTalkroomsMutation = {
	__typename?: 'Mutation';
	orderTalkrooms: { __typename?: 'Status'; success: boolean };
};

export type RemoveAuthMethodFromEventConfigMutationVariables = Exact<{
	auth_method_type: AuthMethodType;
	event_id: Scalars['UUID'];
}>;

export type RemoveAuthMethodFromEventConfigMutation = {
	__typename?: 'Mutation';
	removeAuthMethodFromEventConfig: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type RemoveClientFromSsoAuthMethodMutationVariables = Exact<{
	event_id: Scalars['UUID'];
	id: Scalars['UUID'];
}>;

export type RemoveClientFromSsoAuthMethodMutation = {
	__typename?: 'Mutation';
	removeClientFromSsoAuthMethod: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type RemoveClosedCaptionFromStreamMutationVariables = Exact<{
	closed_caption_id: Scalars['String'];
	stream_id: Scalars['UUID'];
}>;

export type RemoveClosedCaptionFromStreamMutation = {
	__typename?: 'Mutation';
	removeClosedCaptionFromStream: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type RemoveConferencesFromUserMutationVariables = Exact<{
	removeConferencesFromUserInput: RemoveConferencesFromUserInput;
}>;

export type RemoveConferencesFromUserMutation = { __typename?: 'Mutation'; removeConferencesFromUser: boolean };

export type RemoveGeoRestrictedAreaMutationVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type RemoveGeoRestrictedAreaMutation = {
	__typename?: 'Mutation';
	removeGeoRestrictedArea: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type RemoveSocialLinkFromEventMutationVariables = Exact<{
	social_link_id: Scalars['UUID'];
}>;

export type RemoveSocialLinkFromEventMutation = {
	__typename?: 'Mutation';
	removeSocialLinkFromEvent: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type RemoveUsersFromConferenceMutationVariables = Exact<{
	removeUsersFromConferenceInput: RemoveUsersFromConferenceInput;
}>;

export type RemoveUsersFromConferenceMutation = {
	__typename?: 'Mutation';
	removeUsersFromConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type ResetPasswordMutationVariables = Exact<{
	resetPasswordInput: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
	__typename?: 'Mutation';
	resetPassword: { __typename?: 'Status'; success: boolean };
};

export type SendConferenceCommandMutationVariables = Exact<{
	sendUserCommandInput: SendUserCommandInput;
}>;

export type SendConferenceCommandMutation = {
	__typename?: 'Mutation';
	sendConferenceCommand: {
		__typename?: 'CommandHistory';
		created_at: any;
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		recipient_name: string;
		sender_name: string;
		timestamp: number;
		type: CommandType;
	};
};

export type SendEventCommandMutationVariables = Exact<{
	sendEventCommandInput: SendEventCommandInput;
}>;

export type SendEventCommandMutation = {
	__typename?: 'Mutation';
	sendEventCommand: {
		__typename?: 'CommandHistory';
		created_at: any;
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		recipient_name: string;
		sender_name: string;
		timestamp: number;
		type: CommandType;
	};
};

export type SendMessageMutationVariables = Exact<{
	sendMessageInput: SendMessageInput;
}>;

export type SendMessageMutation = {
	__typename?: 'Mutation';
	sendMessage: {
		__typename?: 'Message';
		channel_id: any;
		conference_id?: any | null;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		status?: MessageStatus | null;
		sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
	};
};

export type SendQuestionMutationVariables = Exact<{
	sendQuestionInput: SendQuestionInput;
}>;

export type SendQuestionMutation = {
	__typename?: 'Mutation';
	sendQuestion: {
		__typename?: 'Question';
		conference_id: any;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		old_status?: QuestionStatus | null;
		question_pool_id: any;
		status: QuestionStatus;
		edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
		sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
	};
};

export type SendTalkroomReactionMutationVariables = Exact<{
	sendTalkroomReactionInput: SendTalkroomReactionInput;
}>;

export type SendTalkroomReactionMutation = {
	__typename?: 'Mutation';
	sendTalkroomReaction: {
		__typename?: 'TalkroomReaction';
		content: Emoji;
		created_at: any;
		event_id: any;
		id: any;
		talkroom_id: any;
		sender: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
	};
};

export type SendUserCommandMutationVariables = Exact<{
	sendUserCommandInput: SendUserCommandInput;
}>;

export type SendUserCommandMutation = {
	__typename?: 'Mutation';
	sendUserCommand: {
		__typename?: 'CommandHistory';
		created_at: any;
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		recipient_name: string;
		sender_name: string;
		timestamp: number;
		type: CommandType;
	};
};

export type SignInMutationVariables = Exact<{
	signInInput: SignInInput;
}>;

export type SignInMutation = {
	__typename?: 'Mutation';
	signIn: {
		__typename?: 'SignInResult';
		access_token: string;
		session_id: any;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type SignInNoPasswordMutationVariables = Exact<{
	signInNoPasswordInput: SignInNoPasswordInput;
}>;

export type SignInNoPasswordMutation = {
	__typename?: 'Mutation';
	signInNoPassword: {
		__typename?: 'SignInResult';
		access_token: string;
		session_id: any;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type SignUpMutationVariables = Exact<{
	signUpInput: SignUpInput;
}>;

export type SignUpMutation = { __typename?: 'Mutation'; signUp: { __typename?: 'Status'; success: boolean } };

export type SignUpNoPasswordMutationVariables = Exact<{
	signUpNoPasswordInput: SignUpNoPasswordInput;
}>;

export type SignUpNoPasswordMutation = {
	__typename?: 'Mutation';
	signUpNoPassword: {
		__typename?: 'SignInResult';
		access_token: string;
		session_id: any;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type StartCollaborativeBroadcastMutationVariables = Exact<{
	startCollaborativeBroadcastInput: StartCollaborativeBroadcastInput;
}>;

export type StartCollaborativeBroadcastMutation = {
	__typename?: 'Mutation';
	startCollaborativeBroadcast: {
		__typename?: 'CollaborativeBroadcastConfiguration';
		broadcast_status?: CollaborativeBroadcastStatus | null;
		broadcast_stream_id?: any | null;
		conference_id: any;
		event_id: any;
		is_enabled: boolean;
		layout: LayoutType;
		backstages?: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}> | null;
		stage?: {
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: { __typename?: 'TalkroomPresence'; participant_number: number } | null;
			raised_hands?: Array<{ __typename?: 'TalkroomRaisedHand'; raised_at: any }> | null;
		} | null;
	};
};

export type StopCollaborativeBroadcastMutationVariables = Exact<{ [key: string]: never }>;

export type StopCollaborativeBroadcastMutation = {
	__typename?: 'Mutation';
	stopCollaborativeBroadcast: {
		__typename?: 'CollaborativeBroadcastConfiguration';
		broadcast_status?: CollaborativeBroadcastStatus | null;
		broadcast_stream_id?: any | null;
		conference_id: any;
		event_id: any;
		is_enabled: boolean;
		layout: LayoutType;
		backstages?: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}> | null;
		stage?: {
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: { __typename?: 'TalkroomPresence'; participant_number: number } | null;
			raised_hands?: Array<{ __typename?: 'TalkroomRaisedHand'; raised_at: any }> | null;
		} | null;
	};
};

export type ToggleHighlightedParticipantInTalkroomMutationVariables = Exact<{
	toggleHighlightedParticipantInput: ToggleHighlightedParticipantInput;
}>;

export type ToggleHighlightedParticipantInTalkroomMutation = {
	__typename?: 'Mutation';
	toggleHighlightedParticipantInTalkroom: {
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	};
};

export type ToggleHighlightedQuestionMutationVariables = Exact<{
	toggleHighlightedQuestionInput: ToggleHighlightedQuestionInput;
}>;

export type ToggleHighlightedQuestionMutation = {
	__typename?: 'Mutation';
	toggleHighlightedQuestion: {
		__typename?: 'QuestionPool';
		conference_id: any;
		created_at: any;
		description?: string | null;
		event_id: any;
		highlighted_question_id?: any | null;
		id: any;
		name: string;
	};
};

export type ToggleRaisedHandInTalkroomMutationVariables = Exact<{
	toggleRaisedHandInput: ToggleRaisedHandInput;
}>;

export type ToggleRaisedHandInTalkroomMutation = {
	__typename?: 'Mutation';
	toggleRaisedHandInTalkroom: {
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	};
};

export type UnassignPublicChannelMutationVariables = Exact<{
	unassignPublicChannelInput: UnassignPublicChannelInput;
}>;

export type UnassignPublicChannelMutation = {
	__typename?: 'Mutation';
	unassignPublicChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type UpdateConferenceMutationVariables = Exact<{
	id: Scalars['UUID'];
	updateConferenceInput: UpdateConferenceInput;
}>;

export type UpdateConferenceMutation = {
	__typename?: 'Mutation';
	updateConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type UpdateEventMutationVariables = Exact<{
	updateEventInput: UpdateEventInput;
}>;

export type UpdateEventMutation = {
	__typename?: 'Mutation';
	updateEvent: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type UpdateEventConfigThemeMutationVariables = Exact<{
	event_id: Scalars['UUID'];
	updateEventConfigThemeInput: UpdateEventConfigThemeInput;
}>;

export type UpdateEventConfigThemeMutation = {
	__typename?: 'Mutation';
	updateEventConfigTheme: {
		__typename?: 'EventConfig';
		event_id: any;
		is_geo_restricted: boolean;
		type: EventType;
		auth_methods: Array<
			| { __typename?: 'SSOConfig'; type: AuthMethodType }
			| { __typename?: 'SignInConfig'; type: AuthMethodType }
			| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpConfig'; type: AuthMethodType }
			| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
		>;
		theme: {
			__typename?: 'EventConfigTheme';
			contrastThreshold: number;
			background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
			primary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			secondary: {
				__typename?: 'ThemeColorConfig';
				contrastText?: any | null;
				dark?: any | null;
				light?: any | null;
				main: any;
			};
			tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
		};
	};
};

export type UpdatePasswordMutationVariables = Exact<{
	updatePasswordInput: UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = {
	__typename?: 'Mutation';
	updatePassword: { __typename?: 'Status'; success: boolean };
};

export type UpdatePublicChannelMutationVariables = Exact<{
	id: Scalars['UUID'];
	updatePublicChannelInput: UpdatePublicChannelInput;
}>;

export type UpdatePublicChannelMutation = {
	__typename?: 'Mutation';
	updatePublicChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type UpdateQuestionPoolMutationVariables = Exact<{
	question_pool_id: Scalars['UUID'];
	updateQuestionPoolInput: UpdateQuestionPoolInput;
}>;

export type UpdateQuestionPoolMutation = {
	__typename?: 'Mutation';
	updateQuestionPool: {
		__typename?: 'QuestionPool';
		conference_id: any;
		created_at: any;
		description?: string | null;
		event_id: any;
		highlighted_question_id?: any | null;
		id: any;
		name: string;
	};
};

export type UpdateStreamMutationVariables = Exact<{
	id: Scalars['UUID'];
	updateStreamInput: UpdateStreamInput;
}>;

export type UpdateStreamMutation = {
	__typename?: 'Mutation';
	updateStream: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type UpdateUserMutationVariables = Exact<{
	updateUserInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
	__typename?: 'Mutation';
	updateUser: {
		__typename?: 'User';
		created_at: any;
		event_id: any;
		id: any;
		reset_token?: any | null;
		reset_token_expiration_time?: number | null;
		roleNames: Array<RoleName>;
		updated_at: any;
		userEventId?: any | null;
		userProfileId?: any | null;
		user_email: string;
		user_name?: string | null;
		profile?: {
			__typename?: 'Profile';
			event_id: string;
			id: string;
			information?: any | null;
			language: string;
			seen_first?: any | null;
			seen_last?: any | null;
			seen_updated?: any | null;
			user_email: string;
			user_name: string;
		} | null;
	};
};

export type UpdateUserSessionGeolocationMutationVariables = Exact<{
	updateUserSessionGeolocationInput: UpdateUserSessionGeolocationInput;
}>;

export type UpdateUserSessionGeolocationMutation = {
	__typename?: 'Mutation';
	updateUserSessionGeolocation: {
		__typename?: 'Geolocation';
		created_at: any;
		event_id: any;
		position: { __typename?: 'Position'; latitude: number; longitude: number };
	};
};

export type UpsertCollaborativeBroadcastConfigurationMutationVariables = Exact<{
	conference_id: Scalars['String'];
	upsertCollaborativeBroadcastConfigurationInput: UpsertCollaborativeBroadcastConfigurationInput;
}>;

export type UpsertCollaborativeBroadcastConfigurationMutation = {
	__typename?: 'Mutation';
	upsertCollaborativeBroadcastConfiguration: {
		__typename?: 'CollaborativeBroadcastConfiguration';
		broadcast_status?: CollaborativeBroadcastStatus | null;
		broadcast_stream_id?: any | null;
		conference_id: any;
		event_id: any;
		is_enabled: boolean;
		layout: LayoutType;
		backstages?: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}> | null;
		stage?: {
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: { __typename?: 'TalkroomPresence'; participant_number: number } | null;
			raised_hands?: Array<{ __typename?: 'TalkroomRaisedHand'; raised_at: any }> | null;
		} | null;
	};
};

export type UserSessionJoinTableMutationVariables = Exact<{
	userSessionJoinTableInput: UserSessionJoinTableInput;
}>;

export type UserSessionJoinTableMutation = {
	__typename?: 'Mutation';
	userSessionJoinTable: {
		__typename?: 'UserSession';
		created_at: any;
		event_id: any;
		id: any;
		user_email: string;
		user_id: any;
		user_name: string;
		user_table_id?: any | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
		user_table?: {
			__typename?: 'UserTable';
			created_at: any;
			id: any;
			is_force_mute?: boolean | null;
			is_mute?: boolean | null;
			table_id: any;
			updated_at: any;
			user_session_id: any;
			table: {
				__typename?: 'Table';
				config_overwrite: any;
				created_at: any;
				event_id: any;
				group_ref?: string | null;
				icon?: string | null;
				id: any;
				isPrivate?: boolean | null;
				max_participants: number;
				name: string;
				order: number;
				updated_at: any;
			};
		} | null;
	};
};

export type UserSessionLeaveTableMutationVariables = Exact<{ [key: string]: never }>;

export type UserSessionLeaveTableMutation = {
	__typename?: 'Mutation';
	userSessionLeaveTable: {
		__typename?: 'UserSession';
		created_at: any;
		event_id: any;
		id: any;
		user_email: string;
		user_id: any;
		user_name: string;
		user_table_id?: any | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
		user_table?: {
			__typename?: 'UserTable';
			created_at: any;
			id: any;
			is_force_mute?: boolean | null;
			is_mute?: boolean | null;
			table_id: any;
			updated_at: any;
			user_session_id: any;
			table: {
				__typename?: 'Table';
				config_overwrite: any;
				created_at: any;
				event_id: any;
				group_ref?: string | null;
				icon?: string | null;
				id: any;
				isPrivate?: boolean | null;
				max_participants: number;
				name: string;
				order: number;
				updated_at: any;
			};
		} | null;
	};
};

export type ValidateMessageMutationVariables = Exact<{
	message_id: Scalars['UUID'];
}>;

export type ValidateMessageMutation = { __typename?: 'Mutation'; validateMessage: boolean };

export type GetChannelQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetChannelQuery = {
	__typename?: 'Query';
	getChannel: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type GetCollaborativeBroadcastConfigurationQueryVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type GetCollaborativeBroadcastConfigurationQuery = {
	__typename?: 'Query';
	getCollaborativeBroadcastConfiguration: {
		__typename?: 'CollaborativeBroadcastConfiguration';
		broadcast_status?: CollaborativeBroadcastStatus | null;
		broadcast_stream_id?: any | null;
		conference_id: any;
		event_id: any;
		is_enabled: boolean;
		layout: LayoutType;
		backstages?: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}> | null;
		stage?: {
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: { __typename?: 'TalkroomPresence'; participant_number: number } | null;
			raised_hands?: Array<{ __typename?: 'TalkroomRaisedHand'; raised_at: any }> | null;
		} | null;
	};
};

export type GetConferenceQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetConferenceQuery = {
	__typename?: 'Query';
	getConference: {
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	};
};

export type GetCurrentUserQueryVariables = Exact<{
	access_token?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentUserQuery = {
	__typename?: 'Query';
	getCurrentUser: {
		__typename?: 'User';
		created_at: any;
		event_id: any;
		id: any;
		reset_token?: any | null;
		reset_token_expiration_time?: number | null;
		roleNames: Array<RoleName>;
		updated_at: any;
		userEventId?: any | null;
		userProfileId?: any | null;
		user_email: string;
		user_name?: string | null;
		profile?: {
			__typename?: 'Profile';
			event_id: string;
			id: string;
			information?: any | null;
			language: string;
			seen_first?: any | null;
			seen_last?: any | null;
			seen_updated?: any | null;
			user_email: string;
			user_name: string;
		} | null;
	};
};

export type GetEventByNameQueryVariables = Exact<{
	event_name: Scalars['String'];
}>;

export type GetEventByNameQuery = {
	__typename?: 'Query';
	getEventByName: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type GetEventUploadPresignedUrlQueryVariables = Exact<{
	file_name: FileName;
}>;

export type GetEventUploadPresignedUrlQuery = { __typename?: 'Query'; getEventUploadPresignedUrl: string };

export type GetEventWithConfigByNameQueryVariables = Exact<{
	event_name: Scalars['String'];
}>;

export type GetEventWithConfigByNameQuery = {
	__typename?: 'Query';
	getEventWithConfigByName: {
		__typename?: 'EventWithConfig';
		event: {
			__typename?: 'Event';
			created_at: string;
			date_end: string;
			date_start: string;
			event_state: Event_State;
			guestAccess: boolean;
			hasEventPassword?: boolean | null;
			id: string;
			name: string;
			noPassword: boolean;
			updated_at: string;
			social_links: Array<{
				__typename?: 'SocialLink';
				id: any;
				name: string;
				order?: number | null;
				type: SocialLinkType;
				url: any;
			}>;
		};
		eventConfig: {
			__typename?: 'EventConfig';
			event_id: any;
			is_geo_restricted: boolean;
			type: EventType;
			auth_methods: Array<
				| { __typename?: 'SSOConfig'; type: AuthMethodType }
				| { __typename?: 'SignInConfig'; type: AuthMethodType }
				| { __typename?: 'SignInNoPasswordConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpConfig'; type: AuthMethodType }
				| { __typename?: 'SignUpNoPasswordConfig'; type: AuthMethodType }
			>;
			theme: {
				__typename?: 'EventConfigTheme';
				contrastThreshold: number;
				background: { __typename?: 'BackgroundConfig'; default?: any | null; paper?: any | null };
				primary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				secondary: {
					__typename?: 'ThemeColorConfig';
					contrastText?: any | null;
					dark?: any | null;
					light?: any | null;
					main: any;
				};
				tonalOffset: { __typename?: 'ThemeOffsetConfig'; dark: number; light: number };
			};
		};
	};
};

export type GetFileQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetFileQuery = {
	__typename?: 'Query';
	getFile: {
		__typename?: 'File';
		content_type: string;
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		owner_id: any;
		parent_folder_id: any;
		updated_at: any;
		url: any;
		owner?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type GetFileStorageQueryVariables = Exact<{
	getFileStorageInput?: InputMaybe<GetFileStorageInput>;
}>;

export type GetFileStorageQuery = {
	__typename?: 'Query';
	getFileStorage: {
		__typename?: 'FileStorage';
		files: Array<{
			__typename?: 'File';
			content_type: string;
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
			owner?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
		folders: Array<{
			__typename?: 'Folder';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			parent_folder_id: any;
			path: string;
			updated_at: any;
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				links: Array<{
					__typename?: 'Link';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
					owner?: {
						__typename?: 'User';
						created_at: any;
						event_id: any;
						id: any;
						reset_token?: any | null;
						reset_token_expiration_time?: number | null;
						roleNames: Array<RoleName>;
						updated_at: any;
						userEventId?: any | null;
						userProfileId?: any | null;
						user_email: string;
						user_name?: string | null;
					} | null;
				}>;
			}>;
		}>;
		links: Array<{
			__typename?: 'Link';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
		}>;
	};
};

export type GetLinkQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetLinkQuery = {
	__typename?: 'Query';
	getLink: {
		__typename?: 'Link';
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		owner_id: any;
		parent_folder_id: any;
		updated_at: any;
		url: any;
		owner?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	};
};

export type GetQuestionQueryVariables = Exact<{
	question_id: Scalars['UUID'];
}>;

export type GetQuestionQuery = {
	__typename?: 'Query';
	getQuestion: {
		__typename?: 'Question';
		conference_id: any;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		old_status?: QuestionStatus | null;
		question_pool_id: any;
		status: QuestionStatus;
		edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
		sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
	};
};

export type GetQuestionPoolQueryVariables = Exact<{
	question_pool_id: Scalars['UUID'];
}>;

export type GetQuestionPoolQuery = {
	__typename?: 'Query';
	getQuestionPool: {
		__typename?: 'QuestionPool';
		conference_id: any;
		created_at: any;
		description?: string | null;
		event_id: any;
		highlighted_question_id?: any | null;
		id: any;
		name: string;
	};
};

export type GetStreamQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetStreamQuery = {
	__typename?: 'Query';
	getStream: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type GetStreamProviderInfoQueryVariables = Exact<{
	id: Scalars['UUID'];
}>;

export type GetStreamProviderInfoQuery = {
	__typename?: 'Query';
	getStreamProviderInfo: { __typename?: 'IVSStreamInfo'; type: StreamProvider };
};

export type GetUserQueryVariables = Exact<{
	getUserInput: GetUserInput;
}>;

export type GetUserQuery = {
	__typename?: 'Query';
	getUser: {
		__typename?: 'User';
		created_at: any;
		event_id: any;
		id: any;
		reset_token?: any | null;
		reset_token_expiration_time?: number | null;
		roleNames: Array<RoleName>;
		updated_at: any;
		userEventId?: any | null;
		userProfileId?: any | null;
		user_email: string;
		user_name?: string | null;
		profile?: {
			__typename?: 'Profile';
			event_id: string;
			id: string;
			information?: any | null;
			language: string;
			seen_first?: any | null;
			seen_last?: any | null;
			seen_updated?: any | null;
			user_email: string;
			user_name: string;
		} | null;
	};
};

export type GetUserSessionQueryVariables = Exact<{
	getUserSessionInput: GetUserSessionInput;
}>;

export type GetUserSessionQuery = {
	__typename?: 'Query';
	getUserSession: {
		__typename?: 'UserSession';
		created_at: any;
		event_id: any;
		id: any;
		user_email: string;
		user_id: any;
		user_name: string;
		user_table_id?: any | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
		user_table?: {
			__typename?: 'UserTable';
			created_at: any;
			id: any;
			is_force_mute?: boolean | null;
			is_mute?: boolean | null;
			table_id: any;
			updated_at: any;
			user_session_id: any;
			table: {
				__typename?: 'Table';
				config_overwrite: any;
				created_at: any;
				event_id: any;
				group_ref?: string | null;
				icon?: string | null;
				id: any;
				isPrivate?: boolean | null;
				max_participants: number;
				name: string;
				order: number;
				updated_at: any;
			};
		} | null;
	};
};

export type GetUserSessionCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSessionCountQuery = { __typename?: 'Query'; getUserSessionCount: number };

export type GetUserSessionCountsQueryVariables = Exact<{
	getUserSessionCountsInput: GetUserSessionCountsInput;
}>;

export type GetUserSessionCountsQuery = {
	__typename?: 'Query';
	getUserSessionCounts: Array<{
		__typename?: 'UserSessionCount';
		avg_count?: number | null;
		count?: number | null;
		created_at: any;
		max_count?: number | null;
		min_count?: number | null;
		timestamp?: number | null;
	}>;
};

export type ListCommandHistoryQueryVariables = Exact<{
	listCommandHistoryInput: ListCommandHistoryInput;
}>;

export type ListCommandHistoryQuery = {
	__typename?: 'Query';
	listCommandHistory: {
		__typename?: 'CommandHistoryList';
		count?: number | null;
		last_evaluated_key?: string | null;
		commandHistory: Array<{
			__typename?: 'CommandHistory';
			created_at: any;
			event_id: any;
			id: any;
			is_forced: boolean;
			name: CommandName;
			recipient_name: string;
			sender_name: string;
			timestamp: number;
			type: CommandType;
		}>;
	};
};

export type ListConferencesQueryVariables = Exact<{ [key: string]: never }>;

export type ListConferencesQuery = {
	__typename?: 'Query';
	listConferences: Array<{
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	}>;
};

export type ListEventsQueryVariables = Exact<{
	listPaginationInputArgsInput: ListPaginationInputArgsInput;
}>;

export type ListEventsQuery = {
	__typename?: 'Query';
	listEvents: {
		__typename?: 'EventList';
		count?: number | null;
		last_evaluated_key?: string | null;
		events: Array<{
			__typename?: 'Event';
			created_at: string;
			date_end: string;
			date_start: string;
			event_state: Event_State;
			guestAccess: boolean;
			hasEventPassword?: boolean | null;
			id: string;
			name: string;
			noPassword: boolean;
			updated_at: string;
			social_links: Array<{
				__typename?: 'SocialLink';
				id: any;
				name: string;
				order?: number | null;
				type: SocialLinkType;
				url: any;
			}>;
		}>;
	};
};

export type ListFilesQueryVariables = Exact<{
	listFilesInput: ListFilesInput;
}>;

export type ListFilesQuery = {
	__typename?: 'Query';
	listFiles: Array<{
		__typename?: 'File';
		content_type: string;
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		owner_id: any;
		parent_folder_id: any;
		updated_at: any;
		url: any;
		owner?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	}>;
};

export type ListFoldersQueryVariables = Exact<{
	listFoldersInput: ListFoldersInput;
}>;

export type ListFoldersQuery = {
	__typename?: 'Query';
	listFolders: Array<{
		__typename?: 'Folder';
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		parent_folder_id: any;
		path: string;
		updated_at: any;
		files: Array<{
			__typename?: 'File';
			content_type: string;
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
			owner?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
		folders: Array<{
			__typename?: 'Folder';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			parent_folder_id: any;
			path: string;
			updated_at: any;
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				links: Array<{
					__typename?: 'Link';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
					owner?: {
						__typename?: 'User';
						created_at: any;
						event_id: any;
						id: any;
						reset_token?: any | null;
						reset_token_expiration_time?: number | null;
						roleNames: Array<RoleName>;
						updated_at: any;
						userEventId?: any | null;
						userProfileId?: any | null;
						user_email: string;
						user_name?: string | null;
					} | null;
				}>;
			}>;
		}>;
		links: Array<{
			__typename?: 'Link';
			created_at: any;
			event_id: any;
			id: any;
			name: string;
			owner_id: any;
			parent_folder_id: any;
			updated_at: any;
			url: any;
		}>;
	}>;
};

export type ListGeolocationsQueryVariables = Exact<{
	listGeolocationsInput: ListGeolocationsInput;
}>;

export type ListGeolocationsQuery = {
	__typename?: 'Query';
	listGeolocations: Array<{
		__typename?: 'Geolocation';
		created_at: any;
		event_id: any;
		position: { __typename?: 'Position'; latitude: number; longitude: number };
	}>;
};

export type ListLinksQueryVariables = Exact<{
	listLinksInput: ListLinksInput;
}>;

export type ListLinksQuery = {
	__typename?: 'Query';
	listLinks: Array<{
		__typename?: 'Link';
		created_at: any;
		event_id: any;
		id: any;
		name: string;
		owner_id: any;
		parent_folder_id: any;
		updated_at: any;
		url: any;
		owner?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
	}>;
};

export type ListMessagesQueryVariables = Exact<{
	listMessagesInput: ListMessagesInput;
}>;

export type ListMessagesQuery = {
	__typename?: 'Query';
	listMessages: {
		__typename?: 'MessageList';
		count?: number | null;
		last_evaluated_key?: string | null;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
	};
};

export type ListModeratedMessagesQueryVariables = Exact<{
	listMessagesInput: ListMessagesInput;
}>;

export type ListModeratedMessagesQuery = {
	__typename?: 'Query';
	listModeratedMessages: {
		__typename?: 'MessageList';
		count?: number | null;
		last_evaluated_key?: string | null;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
	};
};

export type ListPublicChannelsQueryVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type ListPublicChannelsQuery = {
	__typename?: 'Query';
	listPublicChannels: Array<{
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	}>;
};

export type ListPublicStreamsQueryVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type ListPublicStreamsQuery = {
	__typename?: 'Query';
	listPublicStreams: Array<{
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	}>;
};

export type ListQuestionsQueryVariables = Exact<{
	listQuestionsInput: ListQuestionsInput;
}>;

export type ListQuestionsQuery = {
	__typename?: 'Query';
	listQuestions: {
		__typename?: 'QuestionList';
		count?: number | null;
		last_evaluated_key?: string | null;
		questions: Array<{
			__typename?: 'Question';
			conference_id: any;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			old_status?: QuestionStatus | null;
			question_pool_id: any;
			status: QuestionStatus;
			edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
			sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
		}>;
	};
};

export type ListStreamsQueryVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type ListStreamsQuery = {
	__typename?: 'Query';
	listStreams: Array<{
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	}>;
};

export type ListTalkroomsQueryVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type ListTalkroomsQuery = {
	__typename?: 'Query';
	listTalkrooms: Array<{
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	}>;
};

export type ListUserAssignedConferencesQueryVariables = Exact<{
	user_id: Scalars['UUID'];
}>;

export type ListUserAssignedConferencesQuery = {
	__typename?: 'Query';
	listUserAssignedConferences: Array<{
		__typename?: 'Conference';
		channel_id?: any | null;
		created_at: any;
		date_end: any;
		date_start: any;
		description: string;
		event_id: any;
		id: any;
		name: string;
		type: ConferenceType;
		file_storage: {
			__typename?: 'FileStorage';
			files: Array<{
				__typename?: 'File';
				content_type: string;
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
				owner?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
			folders: Array<{
				__typename?: 'Folder';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				parent_folder_id: any;
				path: string;
				updated_at: any;
				files: Array<{
					__typename?: 'File';
					content_type: string;
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					owner_id: any;
					parent_folder_id: any;
					updated_at: any;
					url: any;
				}>;
				folders: Array<{
					__typename?: 'Folder';
					created_at: any;
					event_id: any;
					id: any;
					name: string;
					parent_folder_id: any;
					path: string;
					updated_at: any;
					links: Array<{
						__typename?: 'Link';
						created_at: any;
						event_id: any;
						id: any;
						name: string;
						owner_id: any;
						parent_folder_id: any;
						updated_at: any;
						url: any;
						owner?: {
							__typename?: 'User';
							created_at: any;
							event_id: any;
							id: any;
							reset_token?: any | null;
							reset_token_expiration_time?: number | null;
							roleNames: Array<RoleName>;
							updated_at: any;
							userEventId?: any | null;
							userProfileId?: any | null;
							user_email: string;
							user_name?: string | null;
						} | null;
					}>;
				}>;
			}>;
			links: Array<{
				__typename?: 'Link';
				created_at: any;
				event_id: any;
				id: any;
				name: string;
				owner_id: any;
				parent_folder_id: any;
				updated_at: any;
				url: any;
			}>;
		};
		frames: Array<{
			__typename?: 'Frame';
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			type: FrameType;
			url: any;
		}>;
		question_pool?: {
			__typename?: 'QuestionPool';
			conference_id: any;
			created_at: any;
			description?: string | null;
			event_id: any;
			highlighted_question_id?: any | null;
			id: any;
			name: string;
		} | null;
		streams: Array<{
			__typename?: 'Stream';
			closed_caption_provider?: ClosedCaptionProvider | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			id: any;
			is_private: boolean;
			name: string;
			order?: number | null;
			provider: StreamProvider;
			status: StreamStatus;
			type: StreamType;
			url: any;
			closed_captions: Array<
				| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
				| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
			>;
		}>;
		talkrooms: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}>;
	}>;
};

export type ListUserChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type ListUserChannelsQuery = {
	__typename?: 'Query';
	listUserChannels: {
		__typename?: 'UserChannels';
		event_id: any;
		user_id: any;
		channels: Array<{
			__typename?: 'Channel';
			conference_id?: any | null;
			created_at: any;
			event_id: any;
			id: any;
			moderation?: ModerationMode | null;
			name: string;
			talkroom_id?: any | null;
			type: ChannelType;
			messages: Array<{
				__typename?: 'Message';
				channel_id: any;
				conference_id?: any | null;
				content: string;
				created_at: any;
				deleted_at?: any | null;
				event_id: any;
				id: any;
				status?: MessageStatus | null;
				sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
			}>;
			users: Array<{
				__typename?: 'ChannelUser';
				created_at: any;
				user_id: any;
				user?: {
					__typename?: 'User';
					created_at: any;
					event_id: any;
					id: any;
					reset_token?: any | null;
					reset_token_expiration_time?: number | null;
					roleNames: Array<RoleName>;
					updated_at: any;
					userEventId?: any | null;
					userProfileId?: any | null;
					user_email: string;
					user_name?: string | null;
					profile?: {
						__typename?: 'Profile';
						event_id: string;
						id: string;
						information?: any | null;
						language: string;
						seen_first?: any | null;
						seen_last?: any | null;
						seen_updated?: any | null;
						user_email: string;
						user_name: string;
					} | null;
				} | null;
			}>;
		}>;
	};
};

export type ListUserSessionLogsQueryVariables = Exact<{
	listUserSessionLogs: ListUserSessionLogsArgs;
}>;

export type ListUserSessionLogsQuery = {
	__typename?: 'Query';
	listUserSessionLogs: {
		__typename?: 'UserSessionLogsList';
		count?: number | null;
		last_evaluated_key?: string | null;
		user_session_logs: Array<{
			__typename?: 'UserSessionLog';
			city?: string | null;
			country?: string | null;
			created_at: any;
			event_id: any;
			id: any;
			logged_in: number;
			logged_out: number;
			session_duration: number;
			user_email: string;
			user_name: string;
		}>;
	};
};

export type ListUserSessionsQueryVariables = Exact<{
	listUserSessionsInput: ListUserSessionsInput;
}>;

export type ListUserSessionsQuery = {
	__typename?: 'Query';
	listUserSessions: {
		__typename?: 'UserSessionList';
		count?: number | null;
		last_evaluated_key?: string | null;
		user_sessions: Array<{
			__typename?: 'UserSession';
			created_at: any;
			event_id: any;
			id: any;
			user_email: string;
			user_id: any;
			user_name: string;
			user_table_id?: any | null;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
			user_table?: {
				__typename?: 'UserTable';
				created_at: any;
				id: any;
				is_force_mute?: boolean | null;
				is_mute?: boolean | null;
				table_id: any;
				updated_at: any;
				user_session_id: any;
				table: {
					__typename?: 'Table';
					config_overwrite: any;
					created_at: any;
					event_id: any;
					group_ref?: string | null;
					icon?: string | null;
					id: any;
					isPrivate?: boolean | null;
					max_participants: number;
					name: string;
					order: number;
					updated_at: any;
				};
			} | null;
		}>;
	};
};

export type ListUsersQueryVariables = Exact<{
	list: ListUsersArgs;
}>;

export type ListUsersQuery = {
	__typename?: 'Query';
	listUsers: {
		__typename?: 'UserList';
		count?: number | null;
		last_evaluated_key?: string | null;
		users: Array<{
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		}>;
	};
};

export type JoinConferenceSubscriptionVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type JoinConferenceSubscription = { __typename?: 'Subscription'; joinConference: boolean };

export type OnChannelCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnChannelCreatedSubscription = {
	__typename?: 'Subscription';
	onChannelCreated: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type OnChannelUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnChannelUpdatedSubscription = {
	__typename?: 'Subscription';
	onChannelUpdated: {
		__typename?: 'Channel';
		conference_id?: any | null;
		created_at: any;
		event_id: any;
		id: any;
		moderation?: ModerationMode | null;
		name: string;
		talkroom_id?: any | null;
		type: ChannelType;
		messages: Array<{
			__typename?: 'Message';
			channel_id: any;
			conference_id?: any | null;
			content: string;
			created_at: any;
			deleted_at?: any | null;
			event_id: any;
			id: any;
			status?: MessageStatus | null;
			sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
		}>;
		users: Array<{
			__typename?: 'ChannelUser';
			created_at: any;
			user_id: any;
			user?: {
				__typename?: 'User';
				created_at: any;
				event_id: any;
				id: any;
				reset_token?: any | null;
				reset_token_expiration_time?: number | null;
				roleNames: Array<RoleName>;
				updated_at: any;
				userEventId?: any | null;
				userProfileId?: any | null;
				user_email: string;
				user_name?: string | null;
				profile?: {
					__typename?: 'Profile';
					event_id: string;
					id: string;
					information?: any | null;
					language: string;
					seen_first?: any | null;
					seen_last?: any | null;
					seen_updated?: any | null;
					user_email: string;
					user_name: string;
				} | null;
			} | null;
		}>;
	};
};

export type OnCollaborativeBroadcastConfigurationUpdatedSubscriptionVariables = Exact<{
	onCollaborativeBroadcastConfigurationUpdatedInput: OnCollaborativeBroadcastConfigurationUpdatedInput;
}>;

export type OnCollaborativeBroadcastConfigurationUpdatedSubscription = {
	__typename?: 'Subscription';
	onCollaborativeBroadcastConfigurationUpdated: {
		__typename?: 'CollaborativeBroadcastConfiguration';
		broadcast_status?: CollaborativeBroadcastStatus | null;
		broadcast_stream_id?: any | null;
		conference_id: any;
		event_id: any;
		is_enabled: boolean;
		layout: LayoutType;
		backstages?: Array<{
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: {
				__typename?: 'TalkroomPresence';
				participant_number: number;
				participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
			} | null;
			raised_hands?: Array<{
				__typename?: 'TalkroomRaisedHand';
				raised_at: any;
				participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
			}> | null;
		}> | null;
		stage?: {
			__typename?: 'Talkroom';
			channel_id?: any | null;
			conference_id: any;
			created_at: any;
			event_id: any;
			highlighted_participant_id?: any | null;
			id: any;
			is_locked: boolean;
			max_participants: number;
			name: string;
			order: number;
			reserved_seats: number;
			type: TalkroomType;
			presence?: { __typename?: 'TalkroomPresence'; participant_number: number } | null;
			raised_hands?: Array<{ __typename?: 'TalkroomRaisedHand'; raised_at: any }> | null;
		} | null;
	};
};

export type OnCollaborativeBroadcastInviteSentSubscriptionVariables = Exact<{
	onCollaborativeBroadcastInviteSentInput: OnCollaborativeBroadcastInviteSentInput;
}>;

export type OnCollaborativeBroadcastInviteSentSubscription = {
	__typename?: 'Subscription';
	onCollaborativeBroadcastInviteSent: {
		__typename?: 'CollaborativeBroadcastCredential';
		conference_id: any;
		event_id: any;
		user_id: any;
		backstage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
				presence?: {
					__typename?: 'TalkroomPresence';
					participant_number: number;
					participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
				} | null;
				raised_hands?: Array<{
					__typename?: 'TalkroomRaisedHand';
					raised_at: any;
					participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
				}> | null;
			};
		};
		stage: {
			__typename?: 'TalkroomCredential';
			talkroom_token: string;
			user_id: any;
			talkroom: {
				__typename?: 'Talkroom';
				channel_id?: any | null;
				conference_id: any;
				created_at: any;
				event_id: any;
				highlighted_participant_id?: any | null;
				id: any;
				is_locked: boolean;
				max_participants: number;
				name: string;
				order: number;
				reserved_seats: number;
				type: TalkroomType;
			};
		};
	};
};

export type OnConferenceCommandSentSubscriptionVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type OnConferenceCommandSentSubscription = {
	__typename?: 'Subscription';
	onConferenceCommandSent: {
		__typename?: 'Command';
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		receiver_id: any;
		type: CommandType;
	};
};

export type OnEventCommandSentSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnEventCommandSentSubscription = {
	__typename?: 'Subscription';
	onEventCommandSent: {
		__typename?: 'Command';
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		receiver_id: any;
		type: CommandType;
	};
};

export type OnEventUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnEventUpdatedSubscription = {
	__typename?: 'Subscription';
	onEventUpdated: {
		__typename?: 'Event';
		created_at: string;
		date_end: string;
		date_start: string;
		event_state: Event_State;
		guestAccess: boolean;
		hasEventPassword?: boolean | null;
		id: string;
		name: string;
		noPassword: boolean;
		updated_at: string;
		social_links: Array<{
			__typename?: 'SocialLink';
			id: any;
			name: string;
			order?: number | null;
			type: SocialLinkType;
			url: any;
		}>;
	};
};

export type OnMessageCreatedSubscriptionVariables = Exact<{
	channel_id?: InputMaybe<Scalars['UUID']>;
	conference_id?: InputMaybe<Scalars['UUID']>;
}>;

export type OnMessageCreatedSubscription = {
	__typename?: 'Subscription';
	onMessageCreated: {
		__typename?: 'Message';
		channel_id: any;
		conference_id?: any | null;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		status?: MessageStatus | null;
		sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
	};
};

export type OnMessageDeletedSubscriptionVariables = Exact<{
	channel_id?: InputMaybe<Scalars['UUID']>;
	conference_id?: InputMaybe<Scalars['UUID']>;
}>;

export type OnMessageDeletedSubscription = {
	__typename?: 'Subscription';
	onMessageDeleted: { __typename?: 'MessageDeleted'; channel_id: any; conference_id: any; event_id: any; id: any };
};

export type OnMessageModeratedSubscriptionVariables = Exact<{
	channel_id: Scalars['UUID'];
}>;

export type OnMessageModeratedSubscription = {
	__typename?: 'Subscription';
	onMessageModerated: {
		__typename?: 'Message';
		channel_id: any;
		conference_id?: any | null;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		status?: MessageStatus | null;
		sender: { __typename?: 'MessageSender'; user_id: any; user_name: string };
	};
};

export type OnQuestionPoolUpdatedSubscriptionVariables = Exact<{
	onQuestionPoolUpdatedInput: OnQuestionPoolUpdatedInput;
}>;

export type OnQuestionPoolUpdatedSubscription = {
	__typename?: 'Subscription';
	onQuestionPoolUpdated: {
		__typename?: 'QuestionPool';
		conference_id: any;
		created_at: any;
		description?: string | null;
		event_id: any;
		highlighted_question_id?: any | null;
		id: any;
		name: string;
	};
};

export type OnQuestionUpdatedSubscriptionVariables = Exact<{
	onQuestionUpdatedInput: OnQuestionUpdatedInput;
}>;

export type OnQuestionUpdatedSubscription = {
	__typename?: 'Subscription';
	onQuestionUpdated: {
		__typename?: 'Question';
		conference_id: any;
		content: string;
		created_at: any;
		deleted_at?: any | null;
		event_id: any;
		id: any;
		old_status?: QuestionStatus | null;
		question_pool_id: any;
		status: QuestionStatus;
		edited_by?: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string } | null;
		sender: { __typename?: 'QuestionSender'; user_email: any; user_id: any; user_name: string };
	};
};

export type OnStreamCreatedSubscriptionVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type OnStreamCreatedSubscription = {
	__typename?: 'Subscription';
	onStreamCreated: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type OnStreamDeletedSubscriptionVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type OnStreamDeletedSubscription = {
	__typename?: 'Subscription';
	onStreamDeleted: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type OnStreamUpdatedSubscriptionVariables = Exact<{
	conference_id: Scalars['UUID'];
}>;

export type OnStreamUpdatedSubscription = {
	__typename?: 'Subscription';
	onStreamUpdated: {
		__typename?: 'Stream';
		closed_caption_provider?: ClosedCaptionProvider | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		id: any;
		is_private: boolean;
		name: string;
		order?: number | null;
		provider: StreamProvider;
		status: StreamStatus;
		type: StreamType;
		url: any;
		closed_captions: Array<
			| { __typename?: 'StreamTextCC'; id: string; type: ClosedCaptionProvider }
			| { __typename?: 'WordlyCC'; id: string; type: ClosedCaptionProvider }
		>;
	};
};

export type OnTalkroomParticipantJoinedSubscriptionVariables = Exact<{
	onTalkroomParticipantJoinedInput: OnTalkroomParticipantJoinedInput;
}>;

export type OnTalkroomParticipantJoinedSubscription = {
	__typename?: 'Subscription';
	onTalkroomParticipantJoined: {
		__typename?: 'TalkroomParticipantJoined';
		conference_id: any;
		event_id: any;
		talkroom_id: any;
		participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
	};
};

export type OnTalkroomParticipantLeftSubscriptionVariables = Exact<{
	onTalkroomParticipantLeftInput: OnTalkroomParticipantLeftInput;
}>;

export type OnTalkroomParticipantLeftSubscription = {
	__typename?: 'Subscription';
	onTalkroomParticipantLeft: {
		__typename?: 'TalkroomParticipantLeft';
		conference_id: any;
		event_id: any;
		talkroom_id: any;
		participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
	};
};

export type OnTalkroomReactionSentSubscriptionVariables = Exact<{
	onTalkroomReactionSentInput: OnTalkroomReactionSentInput;
}>;

export type OnTalkroomReactionSentSubscription = {
	__typename?: 'Subscription';
	onTalkroomReactionSent: {
		__typename?: 'TalkroomReaction';
		content: Emoji;
		created_at: any;
		event_id: any;
		id: any;
		talkroom_id: any;
		sender: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
	};
};

export type OnTalkroomUpdatedSubscriptionVariables = Exact<{
	onTalkroomUpdatedInput: OnTalkroomUpdatedInput;
}>;

export type OnTalkroomUpdatedSubscription = {
	__typename?: 'Subscription';
	onTalkroomUpdated: {
		__typename?: 'Talkroom';
		channel_id?: any | null;
		conference_id: any;
		created_at: any;
		event_id: any;
		highlighted_participant_id?: any | null;
		id: any;
		is_locked: boolean;
		max_participants: number;
		name: string;
		order: number;
		reserved_seats: number;
		type: TalkroomType;
		presence?: {
			__typename?: 'TalkroomPresence';
			participant_number: number;
			participants: Array<{ __typename?: 'TalkroomParticipant'; user_id: any; user_name: string }>;
		} | null;
		raised_hands?: Array<{
			__typename?: 'TalkroomRaisedHand';
			raised_at: any;
			participant: { __typename?: 'TalkroomParticipant'; user_id: any; user_name: string };
		}> | null;
	};
};

export type OnUserCommandSentSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnUserCommandSentSubscription = {
	__typename?: 'Subscription';
	onUserCommandSent: {
		__typename?: 'Command';
		event_id: any;
		id: any;
		is_forced: boolean;
		name: CommandName;
		receiver_id: any;
		type: CommandType;
	};
};

export type OnUserSessionCreatedSubscriptionVariables = Exact<{
	event_id?: InputMaybe<Scalars['String']>;
}>;

export type OnUserSessionCreatedSubscription = {
	__typename?: 'Subscription';
	onUserSessionCreated: {
		__typename?: 'UserSession';
		created_at: any;
		event_id: any;
		id: any;
		user_email: string;
		user_id: any;
		user_name: string;
		user_table_id?: any | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
		user_table?: {
			__typename?: 'UserTable';
			created_at: any;
			id: any;
			is_force_mute?: boolean | null;
			is_mute?: boolean | null;
			table_id: any;
			updated_at: any;
			user_session_id: any;
			table: {
				__typename?: 'Table';
				config_overwrite: any;
				created_at: any;
				event_id: any;
				group_ref?: string | null;
				icon?: string | null;
				id: any;
				isPrivate?: boolean | null;
				max_participants: number;
				name: string;
				order: number;
				updated_at: any;
			};
		} | null;
	};
};

export type OnUserSessionDeletedSubscriptionVariables = Exact<{
	event_id?: InputMaybe<Scalars['String']>;
}>;

export type OnUserSessionDeletedSubscription = {
	__typename?: 'Subscription';
	onUserSessionDeleted: {
		__typename?: 'UserSession';
		created_at: any;
		event_id: any;
		id: any;
		user_email: string;
		user_id: any;
		user_name: string;
		user_table_id?: any | null;
		user?: {
			__typename?: 'User';
			created_at: any;
			event_id: any;
			id: any;
			reset_token?: any | null;
			reset_token_expiration_time?: number | null;
			roleNames: Array<RoleName>;
			updated_at: any;
			userEventId?: any | null;
			userProfileId?: any | null;
			user_email: string;
			user_name?: string | null;
			profile?: {
				__typename?: 'Profile';
				event_id: string;
				id: string;
				information?: any | null;
				language: string;
				seen_first?: any | null;
				seen_last?: any | null;
				seen_updated?: any | null;
				user_email: string;
				user_name: string;
			} | null;
		} | null;
		user_table?: {
			__typename?: 'UserTable';
			created_at: any;
			id: any;
			is_force_mute?: boolean | null;
			is_mute?: boolean | null;
			table_id: any;
			updated_at: any;
			user_session_id: any;
			table: {
				__typename?: 'Table';
				config_overwrite: any;
				created_at: any;
				event_id: any;
				group_ref?: string | null;
				icon?: string | null;
				id: any;
				isPrivate?: boolean | null;
				max_participants: number;
				name: string;
				order: number;
				updated_at: any;
			};
		} | null;
	};
};
