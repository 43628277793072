import validator from 'validator';
import { Language, RuleFunctions, ValidationData, ValidationFunctions } from './types';

const { isEmail, isEmpty, equals, isURL } = validator;

const ISODateRegExp = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/);

const en: ValidationData = {
	email: {
		required: 'Email is required',
		invalid: 'Email must be valid'
	},
	password: {
		required: 'Password is required',
		invalid: 'Password must be valid',
		minLength: (charNumber: number) => `Password must contain a minimum of  ${charNumber} characters`
	},
	password_confirm: {
		required: 'Password confirmation is required',
		invalid: 'Password confirmation must be valid',
		minLength: (charNumber: number) => `Password must contain a minimum of  ${charNumber} characters`,
		notEquals: 'Password and password confirmation must match'
	},
	user_name: {
		required: 'User name is required'
	},
	first_name: {
		required: 'First Name is required'
	},
	last_name: {
		required: 'Last Name is required'
	},
	name_only: {
		required: 'User name is required'
	},
	event_id: {
		required: 'Event ID is required'
	},
	reset_token: {
		required: 'Reset token is required'
	},
	chat: {
		maxLength: (charNumber: number) => `Your message must be contains maximum of ${charNumber} characters`,
		required: 'Field message is required'
	},
	role: {
		required: "Please define the user's role",
		invalid: 'The role assigned is not valid'
	},
	url: {
		required: 'Url is required',
		invalid: 'Url is not valid'
	},
	streamName: {
		required: 'Stream name is required'
	},
	AWSDateTime: {
		required: 'Date is required',
		format: 'Format date must be YYYY-MM-DDThh:mm:ss.sssZ'
	}
};

const fr: ValidationData = {
	email: {
		required: 'Le courriel est obligatoire',
		invalid: 'Le courriel doit être valide'
	},
	password: {
		required: 'Le mot de passe est obligatoire',
		invalid: 'Le mot de passe doit être valide',
		minLength: (charNumber: number) => `Le mot de passe doit contenir ${charNumber} caractères minimum`
	},
	password_confirm: {
		required: 'Le mot de passe de confirmation est obligatoire',
		invalid: 'Le mot de passe de confirmation doit être valide',
		minLength: (charNumber: number) => `Le mot de passe de confirmation doit contenir ${charNumber} caractères minimum`,
		notEquals: 'Le mot de passe et le mot de passe de confirmation doivent être identique'
	},
	user_name: {
		required: "Le nom d'utilisateur est obligatoire"
	},
	first_name: {
		required: 'Le prénom est obligatoire'
	},
	last_name: {
		required: 'Le nom est obligatoire'
	},
	name_only: {
		required: "Le nom d'utilisateur est obligatoire"
	},
	event_id: {
		required: "L'identifiant de l'évenement est obligatoire"
	},
	reset_token: {
		required: 'Le token de réinitialisation est obligatoire'
	},
	chat: {
		maxLength: (charNumber: number) => `Votre message must be contains ${charNumber} characters maximum`,
		required: 'Le champ message est obligatoire'
	},
	role: {
		required: "Veuillez définir le role de l'utilisateur",
		invalid: "Le role attribué n'est pas valide"
	},
	url: {
		required: "L'url est obligatoire",
		invalid: "L'url n'est pas valide"
	},
	streamName: {
		required: 'Le nom du stream est obligatoire.'
	},
	AWSDateTime: {
		required: 'La date est requise',
		format: 'La date doit être au format : YYYY-MM-DDThh:mm:ss.sssZ'
	}
};

const email: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.email.required : en.email.required),
	invalid: (language: Language) => (language === 'fr' ? fr.email.invalid : en.email.invalid)
};

const password: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.password.required : en.password.required),
	minLength: (language: Language, params: { charNumber: number }) =>
		language === 'fr' ? fr.password.minLength?.(params.charNumber) : en.password.minLength?.(params.charNumber)
};

const password_confirm: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.password_confirm.required : en.password_confirm.required),
	minLength: (language: Language, params: { charNumber: number }) =>
		language === 'fr'
			? fr.password_confirm.minLength?.(params.charNumber)
			: en.password_confirm.minLength?.(params.charNumber),
	equals: (language: Language) => (language === 'fr' ? fr.password_confirm.notEquals : en.password_confirm.notEquals)
};

const event_id: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.event_id.required : en.event_id.required)
};
const user_name: ValidationFunctions = {
	required: (language: Language): string => <string>(language === 'fr' ? fr.user_name.required : en.user_name.required)
};

const first_name: ValidationFunctions = {
	required: (language: Language): string =>
		<string>(language === 'fr' ? fr.first_name.required : en.first_name.required)
};

const last_name: ValidationFunctions = {
	required: (language: Language): string => <string>(language === 'fr' ? fr.last_name.required : en.last_name.required)
};

const name_only: ValidationFunctions = {
	required: (language: Language): string => <string>(language === 'fr' ? fr.user_name.required : en.user_name.required)
};

const reset_token: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.reset_token.required : en.reset_token.required)
};

const chat: ValidationFunctions = {
	maxLength: (language: Language, params: { charNumber: number }) =>
		language === 'fr' ? fr.chat.maxLength?.(params.charNumber) : en.chat.maxLength?.(params.charNumber),
	required: (language: Language) => (language === 'fr' ? fr.chat.required : en.chat.required)
};

const role: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.role.required : en.role.required),
	invalid: (language: Language) => (language === 'fr' ? fr.role.invalid : en.role.invalid)
};

const url: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.url.required : en.url.required),
	invalid: (language: Language) => (language === 'fr' ? fr.url.invalid : en.url.invalid)
};

const streamName: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.streamName.required : en.streamName.required)
};

const AWSDateTime: ValidationFunctions = {
	required: (language: Language) => (language === 'fr' ? fr.AWSDateTime.required : en.AWSDateTime.required),
	format: (language: Language) => (language === 'fr' ? fr.AWSDateTime.format : en.AWSDateTime.format)
};

export const ruleEmail = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || email.required(language),
	(v: string) => isEmail(v) || email.invalid(language)
];

export const rulePassword = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || password.required(language),
	(v: string) => v.length >= 6 || password.minLength(language, { charNumber: 6 })
];

export const rulePasswordMatch = (language: Language, password: string): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || password_confirm.required(language),
	(v: string) => v.length >= 6 || password_confirm.minLength(language, { charNumber: 6 }),
	(v: string) => equals(v, password) || password_confirm.equals(language)
];

export const ruleUserName = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || user_name.required(language)
];

export const ruleFirstName = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || first_name.required(language)
];
export const ruleLastName = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || last_name.required(language)
];

export const ruleRequired = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || user_name.required(language)
];

export const ruleNameOnly = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || name_only.required(language)
];

export const ruleEventID = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || event_id.required(language)
];

export const ruleResetToken = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || reset_token.required(language)
];

export const ruleChat = (language: Language, maxChar: number): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || chat.required(language),
	(v: string) => v.length <= maxChar || chat.maxLength(language, { charNumber: maxChar })
];

export const ruleRole = (language: Language, roleList: string[]): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || role.required(language),
	(v: string) => roleList.includes(v) || role.invalid(language)
];

export const ruleUrl = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || url.required(language),
	(v: string) => isURL(v.trim()) || url.invalid(language)
];

export const ruleStreamName = (language: Language): RuleFunctions => [
	() => true,
	(v: string) => !isEmpty(v.trim()) || streamName.required(language)
];

export const ruleAWSDateTime = (language: Language): RuleFunctions => [
	(v: string) => !isEmpty(v.trim()) || AWSDateTime.required(language),
	(v: string) => ISODateRegExp.test(v) || AWSDateTime.format(language)
];
