import { computed, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RoleName } from '@/gql/types';
import { ruleEmail, rulePassword, ruleRole, ruleUserName } from '../../lib/validations';
import { Language } from '../../lib/types';
import { ImportUsersDataBase } from '@/custom/default/vue/components/admin/usersManagement/types';

const useUserValidation = (
	users: Ref<ImportUsersDataBase[]>,
	errors: Ref<Record<string, Record<string, { messages: string[]; isValid: boolean }>>>
) => {
	const { t, locale } = useI18n();

	const userNameValidation = computed(() => ruleUserName(locale.value as Language));
	const userEmailValidation = computed(() => ruleEmail(locale.value as Language));
	const userPasswordValidation = computed(() => rulePassword(locale.value as Language));
	const userRoleValidation = computed(() =>
		ruleRole(locale.value as Language, [...(Object.values(RoleName) as Array<RoleName>).map((value) => value)])
	);

	const validation = ({ id, user_email, user_name, password, role }: ImportUsersDataBase, isModifyUser = false) => {
		const currentError: Record<string, { messages: string[]; isValid: boolean }> = {
			user_name: {
				messages: [],
				isValid: true
			},
			user_email: {
				messages: [],
				isValid: true
			},
			password: {
				messages: [],
				isValid: true
			},
			role: {
				messages: [],
				isValid: true
			}
		};

		userEmailValidation.value.map((f) => addError(f, 'user_email', user_email));

		userNameValidation.value.map((f) => addError(f, 'user_name', user_name));

		if (isModifyUser) {
			userPasswordValidation.value.map((f) => addError(f, 'password', password));
		}

		userRoleValidation.value.map((f) => addError(f, 'role', role));
		addError(isEmailExist, 'user_email', { user_email, id });
		errors.value[id] = currentError;

		return !Object.keys(currentError).some((name) => !currentError[name].isValid);

		function addError(f: Function, name: string, value: string | object) {
			const ruleResult = f(value);

			if (typeof ruleResult === 'string') {
				currentError[name].messages = [...currentError[name].messages, ruleResult];
			}
			currentError[name].isValid = currentError[name].messages.length < 1;

			return (currentError[name].isValid = currentError[name].messages.length < 1);
		}
	};
	function isEmailExist({ user_email, email, id }: { user_email?: string; email?: string; id: string }) {
		const currentEmail = user_email || email;
		const errors = users.value.some((user) => user.user_email === currentEmail && user.id !== id);
		return errors ? t('error_email') : true;
	}

	return {
		validation,
		userEmailValidation,
		userNameValidation,
		userPasswordValidation,
		userRoleValidation,
		isEmailExist
	};
};

export default useUserValidation;
