<template>
	<v-text-field
		v-model="inputVModel"
		:label="label"
		:type="typeValue"
		:rules="rules"
		variant="underlined"
		:autocomplete="autocomplete"
		:aria-label="ariaLabel"
		class="custom-text-field"
		@click:append="togglePassword"
	>
		<template #append>
			<div class="d-flex justify-center align-center ml-1">
				<v-icon
					v-if="type === 'password'"
					class="access"
					tabindex="3"
					:icon="showValue ? 'mdi-eye' : 'mdi-eye-off'"
					@click="togglePassword"
				/>
			</div>
		</template>
	</v-text-field>
</template>

<script setup lang="ts">
	import { useVModel } from '@vueuse/core';
	import { computed, ref, Ref } from 'vue';

	const props = defineProps({
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text'
		},
		rules: {
			type: [Array<any>],
			required: true
		},
		value: {
			type: String,
			default: ""
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		autocomplete: {
			type: String,
			required: false,
			default: ""
		},
		ariaLabel: {
		type: String,
			required: false,
	default: ""
	}
	});

	const showValue: Ref<boolean> = ref(props.type !== 'password');

	const togglePassword = () => (showValue.value = !showValue.value);

	const typeValue = computed(() => {
		if(props.type === 'password') {
			return showValue.value ? 'text' : 'password'
		}
		return props.type
	})

	const emit = defineEmits(['update:value']);
	const inputVModel = useVModel(props, 'value', emit);
</script>

<style lang="scss" scoped>
	.custom-text-field :deep(.v-input__append) {
		padding: 0;
		margin: 0;
	}
</style>
