/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser($authorization_token: String) {
    getCurrentUser(authorization_token: $authorization_token) {
      id
      user_email
      event_id
      event {
        id
        name
        date_start
        date_end
        event_state
        guestAccess
        noPassword
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      roles {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      roleNames
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reset_token
      reset_token_expire_time
      userEventId
      userProfileId
      __typename
    }
  }
`;
export const sessionInfoCsv = /* GraphQL */ `
  query SessionInfoCsv($event_id: String!) {
    sessionInfoCsv(event_id: $event_id) {
      filenames
      __typename
    }
  }
`;
export const eventHistoriesByName = /* GraphQL */ `
  query EventHistoriesByName(
    $name: EventHistoriesNames!
    $event_id: String!
    $date_start: AWSDateTime!
    $date_end: AWSDateTime!
  ) {
    eventHistoriesByName(
      name: $name
      event_id: $event_id
      date_start: $date_start
      date_end: $date_end
    ) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const getEvents = /* GraphQL */ `
  query GetEvents($id: ID!) {
    getEvents(id: $id) {
      id
      name
      date_start
      date_end
      event_state
      guestAccess
      noPassword
      created_at
      updated_at
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        date_start
        date_end
        event_state
        guestAccess
        noPassword
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventHistory = /* GraphQL */ `
  query GetEventHistory($id: ID!) {
    getEventHistory(id: $id) {
      id
      event_id
      user_id
      user_email
      logged_in
      logged_out
      session_time
      user_session
      name
      value
      strValue
      city
      createdAt
      browser
      timestamp
      operating_system
      updatedAt
      __typename
    }
  }
`;
export const listEventHistories = /* GraphQL */ `
  query ListEventHistories(
    $filter: ModelEventHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        user_id
        user_email
        logged_in
        logged_out
        session_time
        user_session
        name
        value
        strValue
        city
        createdAt
        browser
        timestamp
        operating_system
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventHistoryByEvent = /* GraphQL */ `
  query EventHistoryByEvent(
    $event_id: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventHistoryByEvent(
      event_id: $event_id
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        user_id
        user_email
        logged_in
        logged_out
        session_time
        user_session
        name
        value
        strValue
        city
        createdAt
        browser
        timestamp
        operating_system
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfiles = /* GraphQL */ `
  query GetProfiles($id: ID!) {
    getProfiles(id: $id) {
      id
      event_id
      user_email
      user_name
      language
      table_index
      seen_first
      seen_last
      seen_updated
      RelationPollAnswersProfiles {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profilesByEvent = /* GraphQL */ `
  query ProfilesByEvent(
    $event_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByEvent(
      event_id: $event_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActiveUsers = /* GraphQL */ `
  query GetActiveUsers($session_id: ID!) {
    getActiveUsers(session_id: $session_id) {
      session_id
      user_id
      user_email
      expiration_time
      event_id
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user_table {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      createdAt
      updatedAt
      activeUsersProfileId
      activeUsersUser_tableId
      __typename
    }
  }
`;
export const listActiveUsers = /* GraphQL */ `
  query ListActiveUsers(
    $session_id: ID
    $filter: ModelActiveUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActiveUsers(
      session_id: $session_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activeUsersByEvent = /* GraphQL */ `
  query ActiveUsersByEvent(
    $event_id: ID!
    $user_emailSession_id: ModelActiveUsersByEventAndEmailAndSessionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActiveUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeUsersByEvent(
      event_id: $event_id
      user_emailSession_id: $user_emailSession_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPolls = /* GraphQL */ `
  query GetPolls($id: ID!) {
    getPolls(id: $id) {
      id
      event_id
      translate
      type
      question
      answer_1_key
      answer_2_key
      answer_3_key
      answer_4_key
      answer_5_key
      answer_6_key
      current
      RelationPollAnswersPolls {
        items {
          id
          polls_id
          profile_id
          answer
          answer_previous
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPolls = /* GraphQL */ `
  query ListPolls(
    $filter: ModelPollsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        translate
        type
        question
        answer_1_key
        answer_2_key
        answer_3_key
        answer_4_key
        answer_5_key
        answer_6_key
        current
        RelationPollAnswersPolls {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPollAnswers = /* GraphQL */ `
  query GetPollAnswers($id: ID!) {
    getPollAnswers(id: $id) {
      id
      polls_id
      profile_id
      answer
      answer_previous
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPollAnswers = /* GraphQL */ `
  query ListPollAnswers(
    $filter: ModelPollAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPollAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        polls_id
        profile_id
        answer
        answer_previous
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChats = /* GraphQL */ `
  query GetChats($id: ID!) {
    getChats(id: $id) {
      id
      event_id
      profile_id_from
      profile_id_to
      user_profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      sent_on
      updated_on
      type
      content
      deleted
      read
      stream_id
      chatsUser_profileId
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        profile_id_from
        profile_id_to
        user_profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        sent_on
        updated_on
        type
        content
        deleted
        read
        stream_id
        chatsUser_profileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatsByEvent = /* GraphQL */ `
  query ChatsByEvent(
    $event_id: ID!
    $sent_on: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByEvent(
      event_id: $event_id
      sent_on: $sent_on
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        profile_id_from
        profile_id_to
        user_profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        sent_on
        updated_on
        type
        content
        deleted
        read
        stream_id
        chatsUser_profileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatsSortedByDate = /* GraphQL */ `
  query ChatsSortedByDate(
    $type: ChatTypes!
    $sent_on: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsSortedByDate(
      type: $type
      sent_on: $sent_on
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        profile_id_from
        profile_id_to
        user_profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        sent_on
        updated_on
        type
        content
        deleted
        read
        stream_id
        chatsUser_profileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatsDeleteHistory = /* GraphQL */ `
  query GetChatsDeleteHistory($id: ID!) {
    getChatsDeleteHistory(id: $id) {
      id
      event_id
      sent_on
      updated_on
      __typename
    }
  }
`;
export const listChatsDeleteHistories = /* GraphQL */ `
  query ListChatsDeleteHistories(
    $filter: ModelChatsDeleteHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatsDeleteHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        sent_on
        updated_on
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommand = /* GraphQL */ `
  query GetCommand($id: ID!) {
    getCommand(id: $id) {
      id
      event_id
      is_forced
      user_from {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      user_to {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      created_at
      updated_at
      options {
        moveToTable
        __typename
      }
      type
      commandUser_fromId
      commandUser_toId
      __typename
    }
  }
`;
export const listCommands = /* GraphQL */ `
  query ListCommands(
    $filter: ModelCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        is_forced
        user_from {
          id
          user_email
          event_id
          event {
            id
            name
            date_start
            date_end
            event_state
            guestAccess
            noPassword
            created_at
            updated_at
            __typename
          }
          created_at
          updated_at
          roles {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          roleNames
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reset_token
          reset_token_expire_time
          userEventId
          userProfileId
          __typename
        }
        user_to {
          id
          user_email
          event_id
          event {
            id
            name
            date_start
            date_end
            event_state
            guestAccess
            noPassword
            created_at
            updated_at
            __typename
          }
          created_at
          updated_at
          roles {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          roleNames
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reset_token
          reset_token_expire_time
          userEventId
          userProfileId
          __typename
        }
        created_at
        updated_at
        options {
          moveToTable
          __typename
        }
        type
        commandUser_fromId
        commandUser_toId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commandByEvent = /* GraphQL */ `
  query CommandByEvent(
    $event_id: ID!
    $created_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commandByEvent(
      event_id: $event_id
      created_at: $created_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        is_forced
        user_from {
          id
          user_email
          event_id
          event {
            id
            name
            date_start
            date_end
            event_state
            guestAccess
            noPassword
            created_at
            updated_at
            __typename
          }
          created_at
          updated_at
          roles {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          roleNames
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reset_token
          reset_token_expire_time
          userEventId
          userProfileId
          __typename
        }
        user_to {
          id
          user_email
          event_id
          event {
            id
            name
            date_start
            date_end
            event_state
            guestAccess
            noPassword
            created_at
            updated_at
            __typename
          }
          created_at
          updated_at
          roles {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          roleNames
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reset_token
          reset_token_expire_time
          userEventId
          userProfileId
          __typename
        }
        created_at
        updated_at
        options {
          moveToTable
          __typename
        }
        type
        commandUser_fromId
        commandUser_toId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStream = /* GraphQL */ `
  query GetStream($id: ID!) {
    getStream(id: $id) {
      id
      event_id
      order
      language
      value
      url
      auxiliary_streams {
        order
        title
        url
        language
        __typename
      }
      closed_captioning
      created_at
      updated_at
      __typename
    }
  }
`;
export const listStreams = /* GraphQL */ `
  query ListStreams(
    $filter: ModelStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        order
        language
        value
        url
        auxiliary_streams {
          order
          title
          url
          language
          __typename
        }
        closed_captioning
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const streamByEvent = /* GraphQL */ `
  query StreamByEvent(
    $event_id: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    streamByEvent(
      event_id: $event_id
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        order
        language
        value
        url
        auxiliary_streams {
          order
          title
          url
          language
          __typename
        }
        closed_captioning
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlockedUsers = /* GraphQL */ `
  query GetBlockedUsers($id: ID!) {
    getBlockedUsers(id: $id) {
      id
      event_id
      user_id_from
      user_id_to
      force
      created_at
      updated_at
      __typename
    }
  }
`;
export const listBlockedUsers = /* GraphQL */ `
  query ListBlockedUsers(
    $filter: ModelBlockedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        user_id_from
        user_id_to
        force
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const blockedUserByEvent = /* GraphQL */ `
  query BlockedUserByEvent(
    $event_id: ID!
    $user_id_from: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedUserByEvent(
      event_id: $event_id
      user_id_from: $user_id_from
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        user_id_from
        user_id_to
        force
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      slug
      roles {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      slug
      users {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          permissionID
          roleID
          permission {
            id
            slug
            roles {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_at
      updated_at
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $id: ID
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_email
      event_id
      event {
        id
        name
        date_start
        date_end
        event_state
        guestAccess
        noPassword
        created_at
        updated_at
        __typename
      }
      created_at
      updated_at
      roles {
        items {
          id
          roleID
          userID
          role {
            id
            slug
            users {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            permissions {
              items {
                id
                permissionID
                roleID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            created_at
            updated_at
            __typename
          }
          user {
            id
            user_email
            event_id
            event {
              id
              name
              date_start
              date_end
              event_state
              guestAccess
              noPassword
              created_at
              updated_at
              __typename
            }
            created_at
            updated_at
            roles {
              items {
                id
                roleID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            roleNames
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            reset_token
            reset_token_expire_time
            userEventId
            userProfileId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      roleNames
      profile {
        id
        event_id
        user_email
        user_name
        language
        table_index
        seen_first
        seen_last
        seen_updated
        RelationPollAnswersProfiles {
          items {
            id
            polls_id
            profile_id
            answer
            answer_previous
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reset_token
      reset_token_expire_time
      userEventId
      userProfileId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEvent = /* GraphQL */ `
  query UserByEvent(
    $event_id: ID!
    $user_email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEvent(
      event_id: $event_id
      user_email: $user_email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserTable = /* GraphQL */ `
  query GetUserTable($id: ID!) {
    getUserTable(id: $id) {
      id
      is_mute
      is_force_mute
      user {
        session_id
        user_id
        user_email
        expiration_time
        event_id
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        user_table {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        createdAt
        updatedAt
        activeUsersProfileId
        activeUsersUser_tableId
        __typename
      }
      table {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      created_at
      updated_at
      tableUsersId
      userTableUserId
      __typename
    }
  }
`;
export const listUserTables = /* GraphQL */ `
  query ListUserTables(
    $filter: ModelUserTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        is_mute
        is_force_mute
        user {
          session_id
          user_id
          user_email
          expiration_time
          event_id
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          user_table {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          createdAt
          updatedAt
          activeUsersProfileId
          activeUsersUser_tableId
          __typename
        }
        table {
          id
          event_id
          name
          group_ref
          icon
          max_participants
          order
          users {
            items {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            nextToken
            __typename
          }
          config_overwrite
          created_at
          updated_at
          isPrivate
          __typename
        }
        created_at
        updated_at
        tableUsersId
        userTableUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTable = /* GraphQL */ `
  query GetTable($id: ID!) {
    getTable(id: $id) {
      id
      event_id
      name
      group_ref
      icon
      max_participants
      order
      users {
        items {
          id
          is_mute
          is_force_mute
          user {
            session_id
            user_id
            user_email
            expiration_time
            event_id
            profile {
              id
              event_id
              user_email
              user_name
              language
              table_index
              seen_first
              seen_last
              seen_updated
              RelationPollAnswersProfiles {
                nextToken
                __typename
              }
              __typename
            }
            user_table {
              id
              is_mute
              is_force_mute
              user {
                session_id
                user_id
                user_email
                expiration_time
                event_id
                createdAt
                updatedAt
                activeUsersProfileId
                activeUsersUser_tableId
                __typename
              }
              table {
                id
                event_id
                name
                group_ref
                icon
                max_participants
                order
                config_overwrite
                created_at
                updated_at
                isPrivate
                __typename
              }
              created_at
              updated_at
              tableUsersId
              userTableUserId
              __typename
            }
            createdAt
            updatedAt
            activeUsersProfileId
            activeUsersUser_tableId
            __typename
          }
          table {
            id
            event_id
            name
            group_ref
            icon
            max_participants
            order
            users {
              items {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              nextToken
              __typename
            }
            config_overwrite
            created_at
            updated_at
            isPrivate
            __typename
          }
          created_at
          updated_at
          tableUsersId
          userTableUserId
          __typename
        }
        nextToken
        __typename
      }
      config_overwrite
      created_at
      updated_at
      isPrivate
      __typename
    }
  }
`;
export const listTables = /* GraphQL */ `
  query ListTables(
    $filter: ModelTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tableByEvent = /* GraphQL */ `
  query TableByEvent(
    $event_id: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableByEvent(
      event_id: $event_id
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        name
        group_ref
        icon
        max_participants
        order
        users {
          items {
            id
            is_mute
            is_force_mute
            user {
              session_id
              user_id
              user_email
              expiration_time
              event_id
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              user_table {
                id
                is_mute
                is_force_mute
                created_at
                updated_at
                tableUsersId
                userTableUserId
                __typename
              }
              createdAt
              updatedAt
              activeUsersProfileId
              activeUsersUser_tableId
              __typename
            }
            table {
              id
              event_id
              name
              group_ref
              icon
              max_participants
              order
              users {
                nextToken
                __typename
              }
              config_overwrite
              created_at
              updated_at
              isPrivate
              __typename
            }
            created_at
            updated_at
            tableUsersId
            userTableUserId
            __typename
          }
          nextToken
          __typename
        }
        config_overwrite
        created_at
        updated_at
        isPrivate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFileStorage = /* GraphQL */ `
  query GetFileStorage($id: ID!) {
    getFileStorage(id: $id) {
      id
      name
      owner
      created_at
      updated_at
      event_id
      reference {
        bucket
        region
        localUri
        visibility
        key
        mimeType
        __typename
      }
      __typename
    }
  }
`;
export const listFileStorages = /* GraphQL */ `
  query ListFileStorages(
    $filter: ModelFileStorageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileStorages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        created_at
        updated_at
        event_id
        reference {
          bucket
          region
          localUri
          visibility
          key
          mimeType
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDisplayConfig = /* GraphQL */ `
  query GetDisplayConfig($id: ID!) {
    getDisplayConfig(id: $id) {
      id
      event_id
      type
      value
      created_at
      updated_at
      __typename
    }
  }
`;
export const listDisplayConfigs = /* GraphQL */ `
  query ListDisplayConfigs(
    $filter: ModelDisplayConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisplayConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_id
        type
        value
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const displayConfigByEvent = /* GraphQL */ `
  query DisplayConfigByEvent(
    $event_id: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDisplayConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    displayConfigByEvent(
      event_id: $event_id
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        type
        value
        created_at
        updated_at
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRolePermissions = /* GraphQL */ `
  query GetRolePermissions($id: ID!) {
    getRolePermissions(id: $id) {
      id
      permissionID
      roleID
      permission {
        id
        slug
        roles {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRolePermissions = /* GraphQL */ `
  query ListRolePermissions(
    $filter: ModelRolePermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolePermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        permissionID
        roleID
        permission {
          id
          slug
          roles {
            items {
              id
              permissionID
              roleID
              permission {
                id
                slug
                created_at
                updated_at
                __typename
              }
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_at
          updated_at
          __typename
        }
        role {
          id
          slug
          users {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          permissions {
            items {
              id
              permissionID
              roleID
              permission {
                id
                slug
                created_at
                updated_at
                __typename
              }
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_at
          updated_at
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserRoles = /* GraphQL */ `
  query GetUserRoles($id: ID!) {
    getUserRoles(id: $id) {
      id
      roleID
      userID
      role {
        id
        slug
        users {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        permissions {
          items {
            id
            permissionID
            roleID
            permission {
              id
              slug
              roles {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_at
        updated_at
        __typename
      }
      user {
        id
        user_email
        event_id
        event {
          id
          name
          date_start
          date_end
          event_state
          guestAccess
          noPassword
          created_at
          updated_at
          __typename
        }
        created_at
        updated_at
        roles {
          items {
            id
            roleID
            userID
            role {
              id
              slug
              users {
                nextToken
                __typename
              }
              permissions {
                nextToken
                __typename
              }
              created_at
              updated_at
              __typename
            }
            user {
              id
              user_email
              event_id
              event {
                id
                name
                date_start
                date_end
                event_state
                guestAccess
                noPassword
                created_at
                updated_at
                __typename
              }
              created_at
              updated_at
              roles {
                nextToken
                __typename
              }
              roleNames
              profile {
                id
                event_id
                user_email
                user_name
                language
                table_index
                seen_first
                seen_last
                seen_updated
                __typename
              }
              reset_token
              reset_token_expire_time
              userEventId
              userProfileId
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        roleNames
        profile {
          id
          event_id
          user_email
          user_name
          language
          table_index
          seen_first
          seen_last
          seen_updated
          RelationPollAnswersProfiles {
            items {
              id
              polls_id
              profile_id
              answer
              answer_previous
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reset_token
        reset_token_expire_time
        userEventId
        userProfileId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        userID
        role {
          id
          slug
          users {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          permissions {
            items {
              id
              permissionID
              roleID
              permission {
                id
                slug
                created_at
                updated_at
                __typename
              }
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          created_at
          updated_at
          __typename
        }
        user {
          id
          user_email
          event_id
          event {
            id
            name
            date_start
            date_end
            event_state
            guestAccess
            noPassword
            created_at
            updated_at
            __typename
          }
          created_at
          updated_at
          roles {
            items {
              id
              roleID
              userID
              role {
                id
                slug
                created_at
                updated_at
                __typename
              }
              user {
                id
                user_email
                event_id
                created_at
                updated_at
                roleNames
                reset_token
                reset_token_expire_time
                userEventId
                userProfileId
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          roleNames
          profile {
            id
            event_id
            user_email
            user_name
            language
            table_index
            seen_first
            seen_last
            seen_updated
            RelationPollAnswersProfiles {
              items {
                id
                polls_id
                profile_id
                answer
                answer_previous
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reset_token
          reset_token_expire_time
          userEventId
          userProfileId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
