<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import CardPage from '@/custom/solotech-carriere-virtuelle/vue/components/common/CardPage.vue';

	const { t } = useI18n();
</script>
<template>
	<layout-solotech-carriere>
		<card-page :title="t('title')">
			<a class="link d-flex flex-column align-center text-white pt-12 pb-16" href="mailto:carrieres@solotech.com">
				<span class="text-h6 text-center">
					{{ t('content') }}
				</span>
				<v-icon class="icon mt-12">mdi-email-open-outline</v-icon>
			</a>
		</card-page>
	</layout-solotech-carriere>
</template>

<style scoped>
	.link {
		text-decoration: none;
	}
	.icon {
		font-size: 150px;
	}
</style>

<i18n>
{
	"en": {
		"title": "Let’s keep in touch!",
		"content": "Click below to send us your resume and availability by email."
	},
	"fr": {
		"title": "Restons en contact!",
		"content": "Cliquez ci-dessous pour nous envoyer votre CV et vos disponibilités par courriel."
	}
}
</i18n>
