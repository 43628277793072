<script lang="ts" setup>
	import type { ComputedRef, Ref } from 'vue';
	import { computed, ref, useAttrs } from 'vue';
	import { storeToRefs } from 'pinia';
	import type { RuleFunctions, SignInField } from 'lib/types';
	import { useRoute, useRouter } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import useAuthenticationView from '@/composables/useAuthenticationView';
	import { ComponentsName } from '@/plugins/lib/constants';
	import AuthenticationForm from '@/custom/default/vue/components/auth/AuthenticationForm.vue';
	import useToast from '@/features/Toast';
	import { onSignInSuccess } from '@/features/CurrentUser';
	import { ruleEmail, rulePassword } from 'lib/validations';
	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import { useSignInMutation } from '@/gql/operations';
	import { Language } from '@/gql/types';
	import { MessageColor } from '@/features/Toast/types';
	import useCurrentEvent from '@/features/Events/store';

	const { t, locale } = useI18n();
	const attrs = useAttrs();
	const router = useRouter();
	const route = useRoute();

	const { authConfig } = useAuthenticationView();
	const loading = ref(false);

	const emailValidations: ComputedRef<RuleFunctions> = computed(() => ruleEmail(locale.value as Language));
	const passwordValidations: ComputedRef<RuleFunctions> = computed(() => rulePassword(locale.value as Language));

	const credential: Ref<SignInField> = ref({
		user_email: '',
		password: ''
	});

	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const signInMutation = useSignInMutation();
	const onSignIn = async () => {
		loading.value = true;
		try {
			const result = await signInMutation.executeMutation({
				signInInput: {
					event_id: getCurrentEventId.value,
					...credential.value
				}
			});
			if (result.error) {
				loading.value = false;
				useToast().triggerToast(t('form.error'), { color: MessageColor.error });
				throw new Error(result.error.message);
			}
			const redirection = await onSignInSuccess(result.data?.signIn, <string>route.params.eventName);
			await router.push(redirection);
		} catch (e: any) {
			loading.value = false;
			throw new Error(e);
		}
	};
</script>

<template>
	<AuthenticationForm :loading="loading" data-cy="form-signin" autofocus lazy-validation @submit.prevent="onSignIn">
		<input-field
			v-model="credential.user_email"
			:label="t('form.email.label')"
			:rules="emailValidations"
			autofocus
			data-cy="form-signin-input-user_email"
			variant="underlined"
		/>
		<input-field
			v-model="credential.password"
			:label="t('form.password.label')"
			:rules="passwordValidations"
			data-cy="form-signin-input-password"
			type="password"
			variant="underlined"
		/>
		<router-link
			v-if="!authConfig.noSignUp"
			:to="{ name: ComponentsName.SignInRecoveryView, params: { eventName: route.params.eventName } }"
			class="text-caption align-self-end"
			v-text="t('form.forgot_password')"
		/>
		<template #form-footer>
			<section v-if="!authConfig.noPasswordRecovery" class="d-inline-flex gap justify-center text-caption">
				<p>{{ t('form.no_account_text') }}</p>
				<router-link
					:to="{ name: ComponentsName.SignUpView, params: { eventName: route.params.eventName } }"
					v-text="t('form.sign_up_link')"
				/>
			</section>
		</template>
	</AuthenticationForm>
</template>

<style scoped>
	.gap {
		gap: 0.5rem;
	}
</style>

<i18n>
{
	"en": {
		"form": {
			"forgot_password": "Forgot password ?",
			"no_account_text": "Don't have an account ?",
			"sign_up_link": "Create new account",
			"email": {
				"label": "Email"
			},
			"password": {
				"label": "Password"
			},
			"submit": "Sign-in",
			"error": "Credentials errors"
		}
	},
	"fr": {
		"form": {
			"forgot_password": "Mot de passe oublié ?",
			"no_account_text": "Vous n'avez pas de compte ?",
			"sign_up_link": "Inscrivez-vous",
			"email": {
				"label": "Adresse courriel"
			},
			"password": {
				"label": "Mot de passe"
			},
			"submit": "Se Connecter",
			"error": "Email ou mot de passe incorrect"
		}
	}
}
</i18n>
