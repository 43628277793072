/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://mtk63xyugzcn5lkapkyfjeaahu.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yx7jj2e6efcppp2zopk2hh4nim",
    "aws_cloud_logic_custom": [
        {
            "name": "appgalawebGateway",
            "endpoint": "https://00jp22e5eb.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:7d29650a-435a-40a7-a641-0d19f609028d",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_OzrI2sYM8",
    "aws_user_pools_web_client_id": "15u6h04j90emdk0pjlov9dijgk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "appgalaweb01303946088c49e499516850beb6d19975642-prod",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
