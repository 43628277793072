import loadJitsiApi from '@/plugins/loadJitsiAPI';

import type { App, Plugin } from 'vue';
import { Config as ConfigJitsi } from 'jitsi-meet';

import type { JitsiMeetExternalAPIConstructor as JitsiMeetAPIConstructor } from 'jitsi-meet';

export type JitsiMeetExternalAPIConstructor = JitsiMeetAPIConstructor;

export type { ExternalAPIOptions, ParticipantRoleChangedEvent, JitsiMeetExternalAPI } from 'jitsi-meet';

export enum JitsiTableRole {
	user = 'user',
	moderator = 'moderator'
}

export interface Table {
	index: string;
	icon: string;
	link: string;
	name_private: string & { format: (data: string | number) => string };
	name_ref: string;
	private: boolean;
	video_stream_stop: boolean;
	group_ref?: string;
	configOverwrite: ConfigJitsi;
}

export interface JitsiExtendedWindow extends Window {
	JitsiMeetExternalAPI?: JitsiMeetExternalAPIConstructor;
}

const loadScript = async () => {
	try {
		await loadJitsiApi();
	} catch (error) {
		console.error('Jitsi error:', error);
	}
};

export const jitsi = async (app: App): Promise<Plugin> => {
	await loadScript();
	app.config.globalProperties.$Jitsi = (window as JitsiExtendedWindow)
		.JitsiMeetExternalAPI as JitsiMeetExternalAPIConstructor;

	return {
		install() {}
	};
};
