<script lang="ts" setup>
	import { computed, onBeforeMount, ref, Ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import get from 'lodash/get';

	import AdminSupportDatatableLayout from '@/custom/default/vue/components/admin/adminSupport/AdminSupportDatatableLayout.vue';
	import Column from 'primevue/column';

	import { storeToRefs } from 'pinia';
	import { useActiveUsers } from '@/features/ActiveUsers/store';
	import { ActiveUsers, CommandType } from '@/graphql/types';
	import { useTableStore } from '@/features/Table/store';

	const emits = defineEmits(['submitCommand']);
	const { t } = useI18n();

	const loading: Ref<boolean> = ref(false);

	const { activeUsers } = storeToRefs(useActiveUsers());
	const handleClick = (type: CommandType, id: string) => {
		emits('submitCommand', type, id);
	};

	const tableStore = useTableStore();
	const { fetchTables } = tableStore;

	const handleMoveTable = (table: string, activeUser?: ActiveUsers) => {
		if (table !== activeUser?.user_table?.table?.id) {
			emits('submitCommand', CommandType.MOVE_USER_TABLE, activeUser?.user_id, {
				moveToTable: table
			});
		}
	};

	onBeforeMount(() => {
		fetchTables();
	});

	const { getTables } = storeToRefs(tableStore);
	const tables = getTables;
	const tableName = computed(() => (data: ActiveUsers, field: string) => get(data, field) || t('datatable.no_room'));
	const isOnTable = (data: ActiveUsers) => !data?.user_table;
	const isMute = (data: ActiveUsers) => data?.user_table?.is_mute;
	const isForceMute = (data: ActiveUsers) => data?.user_table?.is_force_mute || false;
</script>

<template>
	<admin-support-datatable-layout
		:global-filter-fields="['profile.user_email', 'profile.user_name']"
		:loading="loading"
		:paginator="true"
		:rows="10"
		sort-field="profile.user_email"
		:sort-order="1"
		:value="activeUsers"
	>
		<Column :header="t('datatable.fields.user.username')" field="profile.user_name" />
		<Column :header="t('datatable.fields.user.email')" field="profile.user_email" />
		<Column :header="t('datatable.fields.user.table')" field="user_table.table.name">
			<template #body="{ data, field }">
				<v-select
					v-if="!isOnTable(data)"
					:items="tables"
					item-title="name"
					item-value="id"
					:label="tableName(data, field)"
					density="comfortable"
					@update:model-value="($table: string) => handleMoveTable($table, data)"
				></v-select>
				<span v-else>
					{{ tableName(data, field) }}
				</span>
			</template>
		</Column>
		<Column :header="t('user_commands')">
			<template #body="{ data }">
				<div class="d-flex icon-row">
					<v-btn class="text-indigo" variant="outlined" @click="handleClick(CommandType.REFRESH_USER, data?.user_id)">
						<v-icon icon="mdi-refresh" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.refresh', 0)"
						>
							{{ t('command_tooltip.refresh', 0) }}
						</v-tooltip>
					</v-btn>
					<v-btn class="text-orange" variant="outlined" @click="handleClick(CommandType.LOGOUT_USER, data?.user_id)">
						<v-icon icon="mdi-logout" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.logout', 0)"
						>
							{{ t('command_tooltip.logout', 0) }}
						</v-tooltip>
					</v-btn>
				</div>
			</template>
		</Column>
		<Column :header="t('table_commands')">
			<template #body="{ data }">
				<div class="d-flex icon-row">
					<v-btn
						:class="isOnTable(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="isOnTable(data)"
						@click="handleClick(CommandType.KICK_USER_TABLE, data?.user_id)"
					>
						<v-icon icon="mdi-phone-off" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t('command_tooltip.refresh', 1)"
						>
							{{ t('command_tooltip.kick', 0) }}
						</v-tooltip>
					</v-btn>
					<v-btn
						:class="isOnTable(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="isOnTable(data) || isForceMute(data)"
						@click="handleClick(CommandType.MUTE_USER, data?.user_id)"
					>
						<v-icon :icon="isMute(data) && !isForceMute(data) ? 'mdi-microphone-off' : 'mdi-microphone'" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t(`command_tooltip.${isMute(data) && !isForceMute(data) ? 'unmute' : 'mute'}`, 0)"
						>
							{{ t(`command_tooltip.${isMute(data) && !isForceMute(data) ? 'unmute' : 'mute'}`, 0) }}
						</v-tooltip>
					</v-btn>
					<v-btn
						:class="isOnTable(data) ? 'text-primary' : 'text-purple-lighten-1'"
						class="text-white"
						variant="outlined"
						:disabled="isOnTable(data)"
						@click="handleClick(CommandType.FORCE_MUTE_USER, data?.user_id)"
					>
						<v-icon :icon="isForceMute(data) ? 'mdi-microphone-plus' : 'mdi-microphone-minus'" />
						<v-tooltip
							activator="parent"
							content-class="bg-white"
							location="bottom"
							:aria-label="t(`command_tooltip.force_${isForceMute(data) ? 'unmute' : 'mute'}`, 0)"
						>
							{{ t(`command_tooltip.force_${isForceMute(data) ? 'unmute' : 'mute'}`, 0) }}
						</v-tooltip>
					</v-btn>
				</div>
			</template>
		</Column>
	</admin-support-datatable-layout>
</template>

<i18n>
{
	"en": {
		"user_commands": "Actions",
		"table_commands": "Talk Room Actions",
		"datatable": {
			"empty": "No users found.",
			"search": "Search",
			"no_room": "Not in talkroom",
			"fields": {
				"user": {
					"username": "Username",
					"email": "Email",
					"table": "Table"
				}
			}
		}
	},
	"fr": {
		"user_commands": "Actions",
		"table_commands": "Actions de la salle de discussion",
		"datatable": {
			"empty": "Aucun utilisateurs trouver.",
			"search": "Rechercher",
			"no_room": "N'est pas dans une salle de discussion",
			"fields": {
				"user": {
					"username": "Nom d'utilisateur",
					"email": "Courriel",
					"table": "Table"
				}
			}
		}
	}
}
</i18n>

<style scoped>
	.icon-row {
		gap: 1rem;
	}
</style>
