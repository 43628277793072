/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
export var ModelAttributeTypes;
(function (ModelAttributeTypes) {
    ModelAttributeTypes["binary"] = "binary";
    ModelAttributeTypes["binarySet"] = "binarySet";
    ModelAttributeTypes["bool"] = "bool";
    ModelAttributeTypes["list"] = "list";
    ModelAttributeTypes["map"] = "map";
    ModelAttributeTypes["number"] = "number";
    ModelAttributeTypes["numberSet"] = "numberSet";
    ModelAttributeTypes["string"] = "string";
    ModelAttributeTypes["stringSet"] = "stringSet";
    ModelAttributeTypes["_null"] = "_null";
})(ModelAttributeTypes || (ModelAttributeTypes = {}));
export var EventHistoriesNames;
(function (EventHistoriesNames) {
    EventHistoriesNames["ACTIVE_USER_COUNT"] = "ACTIVE_USER_COUNT";
    EventHistoriesNames["USER_INFO"] = "USER_INFO";
    EventHistoriesNames["SESSION_INFO"] = "SESSION_INFO";
})(EventHistoriesNames || (EventHistoriesNames = {}));
export var ChatTypes;
(function (ChatTypes) {
    ChatTypes["GLOBAL"] = "GLOBAL";
    ChatTypes["PERSONAL"] = "PERSONAL";
})(ChatTypes || (ChatTypes = {}));
export var CommandType;
(function (CommandType) {
    CommandType["LOGOUT_USER"] = "LOGOUT_USER";
    CommandType["LOGOUT_ALL_USER"] = "LOGOUT_ALL_USER";
    CommandType["REFRESH_USER"] = "REFRESH_USER";
    CommandType["REFRESH_ALL_USER"] = "REFRESH_ALL_USER";
    CommandType["MUTE_ALL_USER"] = "MUTE_ALL_USER";
    CommandType["MUTE_USER"] = "MUTE_USER";
    CommandType["FORCE_MUTE_ALL_USER"] = "FORCE_MUTE_ALL_USER";
    CommandType["FORCE_MUTE_USER"] = "FORCE_MUTE_USER";
    CommandType["MOVE_USER_TABLE"] = "MOVE_USER_TABLE";
    CommandType["KICK_USER_TABLE"] = "KICK_USER_TABLE";
    CommandType["KICK_ALL_USER_TABLE"] = "KICK_ALL_USER_TABLE";
})(CommandType || (CommandType = {}));
export var RoleName;
(function (RoleName) {
    RoleName["GUEST"] = "GUEST";
    RoleName["USER"] = "USER";
    RoleName["MODERATOR"] = "MODERATOR";
    RoleName["ADMINISTRATOR"] = "ADMINISTRATOR";
})(RoleName || (RoleName = {}));
export var AvailableLocale;
(function (AvailableLocale) {
    AvailableLocale["en"] = "en";
    AvailableLocale["fr"] = "fr";
})(AvailableLocale || (AvailableLocale = {}));
export var Visibility;
(function (Visibility) {
    Visibility["public"] = "public";
    Visibility["protected"] = "protected";
    Visibility["private"] = "private";
})(Visibility || (Visibility = {}));
export var DisplayType;
(function (DisplayType) {
    DisplayType["MEET_TABLE"] = "MEET_TABLE";
    DisplayType["MAIN_DRAWER"] = "MAIN_DRAWER";
    DisplayType["FILE_ACCESS"] = "FILE_ACCESS";
})(DisplayType || (DisplayType = {}));
export var ModelSortDirection;
(function (ModelSortDirection) {
    ModelSortDirection["ASC"] = "ASC";
    ModelSortDirection["DESC"] = "DESC";
})(ModelSortDirection || (ModelSortDirection = {}));
