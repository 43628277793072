export interface MessageOptions {
	color?: MessageColor;
	confirm?: undefined | (() => void | Promise<void>);
	confirmBtnText?: string;
	cancel?: undefined | (() => void | Promise<void>);
	cancelBtnText?: string;
}

export enum MessageColor {
	primary = 'primary',
	secondary = 'secondary',
	info = 'info',
	warning = 'warning',
	error = 'error',
	success = 'success'
}

export interface Message extends MessageOptions {
	id: string;
	text: string;
	closable: boolean;
	close: () => void;
	createdAt: number;
}
