<script setup lang="ts">
	// import { onMounted } from 'vue';
	import { useI18n } from 'vue-i18n';
	import useErrorHandler from '@/features/ErrorHandler';

	import type { ErrorInterface } from '@/features/ErrorHandler/types';

	const {
		store: { data },
		_store: { create, remove }
	} = useErrorHandler();

	const { t } = useI18n();

	const content = (error: ErrorInterface) => `${error.code}${error.code ? ': ' : ''}${error.message}`;

	// onMounted(async () => {
	// 	const testNewError = () => {
	// 		create({
	// 			id: (Math.random() * 100000).toString(),
	// 			code: 123,
	// 			message: 'Testing, One, Two!'
	// 		} as ErrorInterface);
	// 	};
	//
	// 	if (process.env.NODE_ENV === 'development') {
	// 		await setTimeout(() => testNewError(), 2000);
	// 	}
	// });
</script>

<template>
	<v-alert
		v-for="error in data"
		:key="`error-${error.id}`"
		class="error-alert pa-0"
		:width="400"
		:max-width="400"
		color="error"
		elevation="4"
		transition="slide-y"
		dense
	>
		<v-toolbar density="compact" color="transparent" class="pa-1 pl-2">
			<div class="d-flex align-center w-100">
				<v-icon v-if="error.message" class="mr-4" icon="mdi-alert-octagon-outline" />
				<span v-text="t('error_handler_error')" />
				<v-spacer />
				<v-btn icon="mdi-close" size="x-small" variant="tonal" @click="remove(error)" />
			</div>
		</v-toolbar>

		<div class="px-3 pb-3 text-wrap text-body-1">
			<span v-text="content(error)" />
		</div>
	</v-alert>
</template>

<style>
	.error-alert {
		z-index: 1010;
		position: fixed;
		bottom: 0;
		right: 0;
		margin: 16px;
	}
</style>

<i18n>{
	"en": {
		"error_handler_error": "Error"
	},
	"fr": {
		"error_handler_error": "Erreur"
	}
}
</i18n>
