<script setup lang="ts">
	import { defineComponent, useAttrs } from 'vue';

	defineComponent({
		name: 'DividerText'
	});
	const props = withDefaults(defineProps<{ text?: string }>(), {
		text: ''
	});
	const attrs = useAttrs();
</script>

<template>
	<section class="d-flex align-center gap">
		<v-divider class="text-on-surface" />
		<p>{{ text }}</p>
		<v-divider class="text-on-surface" />
	</section>
</template>

<style>
	.gap {
		gap: 1rem;
	}
</style>
