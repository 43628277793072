import router from '@/plugins/router';
import { unsubscribeUserTable } from '@/features/Table/UserTable';
import useAPUtils from '@/amplify-pinia/useAPUtils';
import { ssoSignIn } from '@/features/CurrentUser';
import useToast from '@/features/Toast';
import useCurrentUser from '@/features/CurrentUser/store';
import { useCommand } from '@/features/Command/store';
import { commandUnsubscription } from '@/features/Command';
import { ComponentsName } from '@/plugins/lib/constants';
import useChat from '@/features/Chat/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';
import { wsClient } from '@/plugins/urql';
import useCurrentEvent from '@/features/Events/store';

const env = import.meta.env.MODE;

class FilesResolver {
	SRC_DIR_CUSTOM_BASE_PATH = '/src/custom';
	DEFAULT_IMG_NOTFOUND = 'notfound.png';
	DEFAULT_IMG_LOGO = 'logo.jpg';
	DEFAULT_IMG_LOGO_SSO = 'logo-sso.png';
	DEFAULT_IMG_LOGO_FR = 'logo-fr.jpg';
	DEFAULT_IMG_LOGO_PNG = 'logo.png';
	DEFAULT_IMG_LOGO_PNG_FR = 'logo-fr.png';
	DEFAULT_IMG_LOGO_DESCRIPTION = 'logoDescription.jpg';
	DEFAULT_IMG_LOGO_DESCRIPTION_PNG = 'logoDescription.png';
	DEFAULT_LOGO_BOTTOM = 'logoBottom.png';
	DEFAULT_IMG_CHROME_ICON = 'chromeIcon.png';
	DEFAULT_IMG_FIREFOX_ICON = 'firefoxIcon.png';
	DEFAULT_IMG_EDGE_ICON = 'edgeIcon.png';
	DEFAULT_IMG_BACKGROUND = 'background.jpg';
	DEFAULT_IMG_LOGIN = 'loginBackground.jpg';
	DEFAULT_IMG_VIEW1 = 'imgView1.jpg';
	DEFAULT_HEADER_LOGO = 'headerLogo.png';

	DEFAULT_FAV_ICON = 'favicon.png';
	GALA_LOGO = 'gala-logo.png';
	assetsImages;

	constructor() {
		this.assetsImages = import.meta.glob('/src/custom/**/assets/**', { eager: true });
	}

	getImageFromPath(imageRelativePath: string) {
		const { getCurrentEventNameFromURL: getCurrentEventName } = useCurrentEvent();

		let imageToFind = `${this.SRC_DIR_CUSTOM_BASE_PATH}/${getCurrentEventName}/assets/${imageRelativePath}`;

		if (!this.assetsImages) return;

		if (!(imageToFind in this.assetsImages)) {
			imageToFind = `${this.SRC_DIR_CUSTOM_BASE_PATH}/default/assets/${imageRelativePath}`;
		}

		// @ts-ignore
		return this.assetsImages[imageToFind]?.default;
	}
}

const handleError = (eventName: string, error: string) => {
	useToast().triggerToast(error);
	return {
		name: ComponentsName.Authentication,
		params: {
			eventName
		}
	};
};

const handleSSOSignIn = async (eventName: string, accessToken: string, session_id: string) => {
	const result = await ssoSignIn(accessToken, session_id);
	if (result?.success) {
		const redirect = localStorage.getItem('redirect');
		if (redirect) {
			localStorage.removeItem('redirect');
			return {
				path: `/event/${eventName}/${redirect}`
			};
		}
		return {
			name: ComponentsName.Main,
			params: { eventName }
		};
	} else if (result.error) {
		return handleError(eventName, 'Impossible to sign you In');
	}
};

const ssoCallback = async (
	eventName: string,
	accessToken: string | undefined,
	session_id: string,
	error: string | undefined
) => {
	if (accessToken) {
		return await handleSSOSignIn(eventName, accessToken, session_id);
	}
	const err = error ?? 'No access token found';
	return handleError(eventName, err);
};

export const currentEnv = () => {
	const isDev: boolean = env === 'development';
	const isStaging: boolean = env === 'staging';
	const isProd: boolean = env === 'production';

	return {
		currentEnv: env,
		isDev,
		isStaging,
		isProd
	};
};

const onSignOut = async (eventName: string) => {
	useCurrentEvent().unsubscribeV2();
	useActiveUsers().unsubscribe();
	useActiveUsers().$reset();
	localStorage.removeItem('authorization_token');
	localStorage.removeItem('session_id');
	sessionStorage.clear();

	await useAPUtils().clearAPStores();

	commandUnsubscription.onCreateCommandUnsubscribe();
	useCommand().$reset();
	unsubscribeUserTable();

	const currentUser = useCurrentUser();
	currentUser.$reset();

	const chat = useChat();
	chat.unsubscribeChat();
	chat.$reset();

	wsClient.dispose();
	await router.push({
		name: ComponentsName.SignInView,
		params: { eventName }
	});
};

const eventNameFromUrl = () =>
	location.href.replace(location.origin, '').replace('/#/event/', '').split('/')[0] || 'default';

const pathToName = (path: string): string =>
	path
		.split('/')
		.pop()
		?.replace(/\.\w+$/, '') ?? '';

export const resolver = new FilesResolver();

export default (): {
	eventNameFromUrl: () => string;
	pathToName: (path: string) => string;
	currentEnv: () => { currentEnv: any; isStaging: boolean; isProd: boolean; isDev: boolean };
	ssoCallback: (
		eventName: string,
		accessToken: string | undefined,
		session_id: string,
		error: string | undefined
	) => Promise<void | null | { name: string; params: { eventName: string } } | { path: string }>;
	onSignOut: (eventName: string) => Promise<void>;
	resolver: FilesResolver;
} => ({
	resolver,
	eventNameFromUrl,
	pathToName,
	currentEnv,
	onSignOut,
	ssoCallback
});
