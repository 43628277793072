<script setup lang="ts">
	import { computed, mergeProps, onBeforeMount, onBeforeUnmount, ref, useAttrs, watch } from 'vue';
	import { useDisplay } from 'vuetify';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';
	import { useElementSize } from '@vueuse/core';
	import useScrollToBottom from '@/composables/useScrollToBottom';
	import useConfig from '@/features/Config';
	import useCurrentUser from '@/features/CurrentUser/store';
	import useDateFormatter from '@/composables/useDateFormatter';
	import { EmitsName } from '@/plugins/lib/constants';
	import type { Chats } from '@/graphql/types';
	import { ChatTypes } from '@/graphql/types';
	import ChatMessage from '@/custom/default/vue/components/chat/ChatMessage.vue';
	import ChatMessageInput from '@/custom/default/vue/components/chat/ChatMessageInput.vue';
	import ChatScrollButton from '@/custom/default/vue/components/chat/ChatScrollButton.vue';
	import useChat from '@/features/Chat/store';
	import { useRoute } from 'vue-router';
	import useCurrentEvent from '@/features/Events/store';

	const props = defineProps({
		isVisible: {
			type: Boolean,
			default: false
		},
		access: {
			type: Array,
			default: () => []
		},
		chatIsOnline: {
			type: Boolean,
			default: true
		},
		platformLoading: {
			type: Boolean,
			default: false
		},
		maxHeight: {
			type: Number,
			default: 800
		},
		title: {
			type: String || null,
			default: null
		}
	});

	const attrs = useAttrs();

	const emits = defineEmits([EmitsName.HandleChangeSelectedUser, EmitsName.ToggleModalChat]);

	const { mobile } = useDisplay();

	const route = useRoute();

	const { isUserAdmin, isUserModerator, get } = storeToRefs(useCurrentUser());
	const chatStore = useChat();
	const { fetchChatGlobal, subscribeChat, unsubscribeChat, deleteAllGlobalChat } = chatStore;
	const { getGlobalChatContents, getGlobalChatContentsByDays, getIsChatGlobalPending, getGlobalMessagesIds } =
		storeToRefs(chatStore);

	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const {
		store: { currentConfig }
	} = useConfig();

	const { t, locale } = useI18n();

	const currentUserId = computed(() => get.value?.id);

	const displayDeleteOptions = ref(false);
	const showDialog = ref(false);

	const maxCharacter = computed(() =>
		!currentUserId.value || !isUserAdmin.value || !isUserModerator.value ? 255 : 5000
	);

	const chatViewer = ref(null);

	const { width, height } = useElementSize(chatViewer);

	const { scrollToBottom, scrollIsBottom, hasNoScroll } = useScrollToBottom(chatViewer, getGlobalChatContents);

	onBeforeMount(async () => {
		await fetchChatGlobal();
	});

	onBeforeUnmount(() => {
		unsubscribeChat();
	});

	watch(
		getGlobalChatContents,
		async () => {
			if (!scrollIsBottom.value) return;
			await scrollToBottom();
		},
		{
			immediate: true
		}
	);

	watch(
		width,
		async (newValue, oldValue) => {
			if (newValue !== oldValue) {
				await scrollToBottom();
			}
		},
		{
			immediate: true
		}
	);

	watch(
		height,
		async (newValue, oldValue) => {
			if (newValue !== oldValue) {
				await scrollToBottom();
			}
		},
		{
			immediate: true
		}
	);

	const deleteAllChatMessages = () => {
		showDialog.value = false;
		if (!getCurrentEventId.value) {
			console.error('No event id found for delete all global chat messages');
		}
		deleteAllGlobalChat();
	};

	const downloadChatHistory = () => {
		const chatRows = getGlobalChatContents.value.reduce((rows: string, item: Chats) => {
			const userName = item.user_profile?.user_name;
			const date = new Date(Date.parse(item.sent_on)).toLocaleString();
			const parsedChat = userName ? `${date} - ${userName}: ${item.content}\n` : '';
			return rows + encodeURIComponent(parsedChat);
		}, 'data:text/txt;charset=utf-8,');

		const link = document.createElement('a');
		link.setAttribute('href', chatRows);
		link.setAttribute('download', 'chatlog.txt');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const titleCard = computed(() => (route.meta.isInControlRoom ? t('control_room_chat') : props.title));
</script>

<template>
	<v-card class="d-flex flex-column overflow-visible bg-custom-blur" rounded="0" v-bind="attrs">
		<slot name="title" :title="title">
			<v-card-title v-if="route.meta.isInControlRoom || title" class="bg-secondary">
				<v-icon v-if="!route.meta.isInControlRoom" icon="mdi-forum-outline" class="mr-2" />
				{{ titleCard }}
			</v-card-title>
		</slot>
		<v-card-text ref="chatViewer" :class="['chat-viewer-vcard-text-overflow', mobile ? 'is-mobile' : '']">
			<div
				v-if="getIsChatGlobalPending"
				class="font-weight-bold font-italic"
				v-text="t('chatviewer_building_chat_history')"
			/>
			<chat-scroll-button
				:has-no-scroll="hasNoScroll"
				:scroll-is-bottom="scrollIsBottom"
				@emit-scroll-to-bottom="scrollToBottom"
			/>
			<template v-for="date in Object.keys(getGlobalChatContentsByDays)" :key="date">
				<span class="divider" v-text="useDateFormatter().formatDate(date, locale)" />
				<template v-for="msg in getGlobalChatContentsByDays[date]" :key="msg.id">
					<chat-message :message="msg" :deletable="displayDeleteOptions" />
				</template>
			</template>
		</v-card-text>

		<v-card-actions class="pt-2 pb-0 ma-0">
			<chat-message-input :max-length="maxCharacter" :chat-type="ChatTypes.GLOBAL" />
			<v-menu v-if="isUserModerator || isUserAdmin" location="left">
				<template #activator="{ props: menu }">
					<v-tooltip location="top" :aria-label="t('chatviewer_display_admin_btn')">
						<template #activator="{ props: tooltip }">
							<v-btn icon="mdi-cog" size="small" v-bind="mergeProps(menu, tooltip)" class="align-self-start" />
						</template>
						<span>{{ t('chatviewer_display_admin_btn') }}</span>
					</v-tooltip>
				</template>
				<v-list density="compact" class="pa-0 bg-grey-darken-1">
					<v-list-item class="text-caption" prepend-icon="mdi-trash-can" border @click="showDialog = true">
						<v-list-item-title class="text-caption">
							{{ t('chatviewer_delete_all_chat_message') }}
						</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="text-caption"
						border
						prepend-icon="mdi-delete-forever"
						@click="displayDeleteOptions = !displayDeleteOptions"
					>
						<v-list-item-title class="text-caption">
							{{ t('chatviewer_display_delete_options.linked', Number(displayDeleteOptions)) }}
						</v-list-item-title>
					</v-list-item>
					<v-list-item class="text-caption" border prepend-icon="mdi-file-download" @click="downloadChatHistory">
						<v-list-item-title class="text-caption">
							{{ t('chatviewer_download_chat_history') }}
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-actions>
	</v-card>
	<v-dialog v-model="showDialog">
		<v-card color="error">
			<v-card-title>
				<v-icon>mdi-alert</v-icon>
			</v-card-title>
			<v-card-text>
				{{ t('chatviewer_delete_chat_text') }}
			</v-card-text>
			<v-card-actions>
				<v-btn @click="showDialog = false">
					{{ t('chatviewer_delete_chat_cancel') }}
				</v-btn>
				<v-btn @click="deleteAllChatMessages">
					<v-icon class="mr-2" end> mdi-alert </v-icon>
					{{ t('chatviewer_delete_chat_confirm') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>
	.chat-viewer-vcard-text-overflow {
		height: 50px;
		flex-grow: 1;
		overflow: auto;
	}
	.chat-viewer-vcard-text-overflow.is-mobile {
		height: 300px;
	}

	.divider {
		display: flex;
		justify-content: center;
		margin: 1em 0 1em;
	}
	.divider:after,
	.divider:before {
		height: 2px;
		margin: 0.5em 1em;
		background: rgb(var(--v-theme-on-background));
		content: '';
		flex: 1;
	}
</style>

<i18n>
{
	"en": {
		"chatviewer_title": "Chat",
		"chatviewer_status_connecting": "Connecting... please wait...",
		"chatviewer_status_failed": "Failed to connect",
		"chatviewer_status_connected": "Connected. You are now LIVE!",
		"chatviewer_text_input": "Enter text",
		"chatviewer_building_chat_history": "Building chat history, please wait...",
		"chatviewer_display_delete_options": {
			"toggle": "Hide | Show",
			"linked": "@:chatviewer_display_delete_options.toggle delete buttons"
		},
		"chatviewer_delete_all_chat_message": "Delete all chat messages",
		"chatviewer_delete_chat_text": "Do you want to delete all chat messages?",
		"chatviewer_delete_chat_confirm": "Delete",
		"chatviewer_delete_chat_cancel": "Cancel",
		"chatviewer_download_chat_history" : "Download chat history",
		"chatviewer_display_admin_btn": "Show chat moderation actions"
	},
	"fr": {
		"chatviewer_title": "Chat",
		"chatviewer_status_connecting": "Connexion en cours...",
		"chatviewer_status_connected": "Connecté avec succès, vous êtes en ligne",
		"chatviewer_status_failed": "Échec de connexion",
		"chatviewer_text_input": "Saisir votre texte",
		"chatviewer_building_chat_history": "Affichage de l'historique de la discussion, veuillez patienter...",
		"chatviewer_display_delete_options": {
			"toggle": "Cacher | Afficher",
			"linked": "@:chatviewer_display_delete_options.toggle les boutons de suppression de message"
		},
		"chatviewer_delete_all_chat_message": "Suppression de tous les messages de la discussion",
		"chatviewer_delete_chat_text": "Êtes-vous sûr de vouloir supprimer tous les messages de la discussion?",
		"chatviewer_delete_chat_confirm": "Supprimer",
		"chatviewer_delete_chat_cancel": "Annuler",
		"chatviewer_download_chat_history" : "Télécharger l'historique de la discussion",
		"chatviewer_display_admin_btn": "Afficher les options d'administration du chat"
	},
	"ja": {
		"chatviewer_title": "チャット",
		"chatviewer_status_connecting": "接続しています...お待ちください...",
		"chatviewer_status_failed": "接続に失敗した",
		"chatviewer_status_connected": "接続しました。あなたは今ライブです！",
		"chatviewer_text_input": "テキストを入力",
		"chatviewer_building_chat_history": "チャット履歴を作成しています。お待ちください...",
		"chatviewer_display_delete_options": {
			"toggle": "隠す |見せる",
			"linked": "@:chatviewer_display_delete_options.toggle 削除ボタン"
		},
		"chatviewer_delete_all_chat_message": "すべてのチャット メッセージを削除する",
		"chatviewer_delete_chat_text": "すべてのチャット メッセージを削除しますか?",
		"chatviewer_delete_chat_confirm": "消去",
		"chatviewer_delete_chat_cancel": "キャンセル",
		"chatviewer_download_chat_history" : "チャット履歴をダウンロード",
		"chatviewer_display_admin_btn": "チャット モデレート アクションを表示"
	}
}
</i18n>
