import BaseModel from '@/amplify-pinia/base-model.class';
import { computed } from 'vue';
import { DisplayType } from '@/graphql/types';

import type { DisplayConfig as DisplayConfigType } from '@/graphql/types';
import type { APBaseStoreStructure, APStorePluginReturnType } from '@/amplify-pinia/types';

const model = 'DisplayConfig';

const storePlugin = (store: APBaseStoreStructure<DisplayConfigType>): APStorePluginReturnType => {
	const configEnabled = computed(() => store.data.value.filter(({ value }) => value));

	const meetTable = computed(() => store.data.value.find((item) => item.type === DisplayType.MEET_TABLE));
	const meetTableIsEnabled = computed(() => !!meetTable.value?.value);

	const fileStorage = computed(() => store.data.value.find((item) => item.type === DisplayType.FILE_ACCESS));
	const fileStorageIsEnabled = computed(() => !!fileStorage.value?.value);

	const hasEnabledDisplayConfig = computed(() => store.data.value.some(({ value }) => value));

	return {
		configEnabled,
		meetTable,
		meetTableIsEnabled,
		fileStorage,
		fileStorageIsEnabled,
		hasEnabledDisplayConfig,
		...store
	};
};

const options = { storePlugin };

export default class DisplayConfig extends BaseModel<DisplayConfigType> {
	constructor() {
		super({
			model,
			options
		});
	}
}
