<script setup lang="ts">
	import { computed, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useDisplay } from 'vuetify';
	import { useRoute } from 'vue-router';
	import { onClickOutside, useVModel } from '@vueuse/core';
	import { ComponentsName } from '@/plugins/lib/constants';
	import bars from '@/custom/solotech-career/assets/bars.png';

	const props = defineProps<{
		drawer: boolean;
	}>();
	const emits = defineEmits(['update:drawer']);
	const route = useRoute();
	const { t } = useI18n();
	const { lgAndUp, mdAndDown } = useDisplay();

	const drawerRef = ref<HTMLElement | null>(null);
	const drawerVModel = useVModel(props, 'drawer', emits);

	onClickOutside(drawerRef, (event: any) => {
		if (!event.target.classList.contains('btn-toggle-drawer') && mdAndDown.value && drawerVModel.value) {
			drawerVModel.value = false;
		}
	});

	const links = [
		{
			name: 'main_drawer_home',
			icon: 'mdi-home-outline',
			to: {
				path: '/event/solotech-career'
			}
		},
		{
			name: 'main_drawer_job_openings',
			icon: 'mdi-briefcase-outline',
			to: {
				name: ComponentsName.JobOpenings
			}
		},
		{
			name: 'main_drawer_about_us',
			icon: 'mdi-information-outline',
			to: {
				name: ComponentsName.AboutUs
			}
		}
	];

	const currentDate = new Date().getTime();
	const secondEventDate = new Date('Wed, 24 July 2024 5:00:00 GMT').getTime();
	const LinkChangeDate = computed(() => currentDate <= secondEventDate);
</script>

<template>
	<v-navigation-drawer ref="drawerRef" :model-value="drawerVModel" :permanent="lgAndUp" :temporary="mdAndDown">
		<v-list nav>
			<v-list-item
				:to="links[0].to"
				color="white"
				:prepend-icon="links[0].icon"
				active-class="active-color"
				:title="t(links[0].name)"
			/>
			<v-list-item
				:href="
					LinkChangeDate
						? 'https://careers.solotech.com/jobs?page=1&locations=Antioch,Tennessee,United%20States%7CMiami,Florida,United%20States%7COrlando,Florida,United%20States%7CPensacola,Florida,United%20States'
						: 'https://careers.solotech.com/jobs?page=1&locations=Sun%20Valley,California,United%20States%7CWestminster,California,United%20States%7CLas%20Vegas,,United%20States'
				"
				target="_blank"
				color="white"
				:prepend-icon="links[1].icon"
				active-class="active-color"
				:title="t(links[1].name)"
			/>
			<v-list-item
				:to="links[2].to"
				color="white"
				:prepend-icon="links[2].icon"
				active-class="active-color"
				:title="t(links[2].name)"
			/>
		</v-list>
		<template #append>
			<v-img class="d-flex ml-auto" width="82px" heigth="83px" :src="bars" alt="" />
		</template>
	</v-navigation-drawer>
</template>

<style scoped>
	.active-color {
		background-color: rgba(237, 26, 105, 0.28);
	}
</style>

<i18n>
	{
		"en": {
			"main_drawer_home": "Home",
			"main_drawer_job_openings": "Job Openings",
			"main_drawer_about_us": "About Us"
		},
		"fr": {
			"main_drawer_home": "Accueil",
			"main_drawer_job_openings": "Offres d'emploi",
			"main_drawer_about_us": "À propos"
		}
	}
</i18n>
