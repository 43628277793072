<template>
	<v-hover v-if="!scrollIsBottom && !hasNoScroll" v-slot="{ isHovering, props }">
		<v-btn
			v-bind="props"
			size="small"
			color="black"
			class="chat-scroll-button position-absolute mb-4"
			:prepend-icon="isHovering ? 'mdi-arrow-down' : 'mdi-pause'"
			@click="scrollToBottom"
		>
			{{ isHovering ? t('chat-scroll-button-scroll') : t('chat-scroll-button-paused') }}
		</v-btn>
	</v-hover>
</template>

<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import { EmitsName } from '@/plugins/lib/constants';

	const { t } = useI18n();

	defineProps({
		scrollIsBottom: {
			type: Boolean,
			default: false,
			required: true
		},
		hasNoScroll: {
			type: Boolean,
			default: false,
			required: true
		}
	});

	const emits = defineEmits([EmitsName.ScrollToBottom]);

	const scrollToBottom = async () => {
		await emits(EmitsName.ScrollToBottom);
	};
</script>

<i18n>
{
  "en":  {
    "chat-scroll-button-paused": "Chat Paused",
    "chat-scroll-button-scroll": "scroll to bottom"
  },
  "fr":  {
    "chat-scroll-button-paused": "Chat en pause",
    "chat-scroll-button-scroll": "défiler vers le bas"
  }
}
</i18n>

<style scoped>
	.chat-scroll-button {
		z-index: 100;
		bottom: 4rem;
		right: 50%;
		transform: translateX(50%);
		opacity: 0.5;
	}
</style>
