const moduleURL = 'https://meet.jit.si/external_api.js';

export default async (): Promise<void> =>
	new Promise((res: Function) => {
		const script = document.createElement('script');
		script.setAttribute('src', moduleURL);
		document.head.appendChild(script);
		script.onload = () => res();
		script.onerror = (err) => {
			throw new Error(`JITSI ERROR: ${JSON.stringify(err)}`);
		};
	});
