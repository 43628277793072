import { defineStore } from 'pinia';
import { Event } from '@/gql/types';
import { OperationResult } from '@urql/core';
import useApp from '@/composables/useApp';
import { AWSSubscription } from '@/graphql';
import { onUpdateEventId } from '@/graphql/subscriptions';
import { GetEventByName, OnEventUpdated, OnUserSessionCreated } from '@/gql/operations';
import { urqlClient } from '@/plugins/urql';
import { pipe, subscribe } from 'wonka';

type EventsState = {
	currentEvent: null | Event;
	subscriptions: {
		onUpdateEvents: () => void;
		onEventUpdated: () => void;
	};
};

type EventsGetters = {
	getCurrentEvent: (state: EventsState) => null | Event;
	getCurrentEventId: () => null | Event['id'];
	getCurrentEventNameFromURL: () => Event['name'];
	getCurrentEventName: () => Event['name'] | undefined;
	getCurrentEventState: () => null | Event['event_state'];
	isCurrentEventActive: () => boolean;
	isCurrentEventIsLocked: () => boolean;
	getCurrentEventDateStart: () => Event['date_start'];
	getCurrentEventDateEnd: () => Event['date_end'];
	getEventHaveNoPassword: () => Event['noPassword'] | null;
};

type EventsActions = {
	fetchCurrentEvent: () => Promise<OperationResult<Event> | { error: string }>;
	setCurrentEvent: (event: Event) => void;
	subscribe: () => void;
	subscribeV2: () => void;
	unsubscribe: () => void;
	unsubscribeV2: () => void;
	updateCurrentEvent: (event: Event) => void;
};

const useCurrentEvent = defineStore<string, EventsState, EventsGetters, EventsActions>('CurrentEvent', {
	state: () => ({
		currentEvent: null,
		subscriptions: {
			onUpdateEvents: () => {},
			onEventUpdated: () => {}
		}
	}),
	getters: {
		getCurrentEvent(state) {
			return state.currentEvent;
		},
		getCurrentEventId() {
			return this.getCurrentEvent?.id ?? null;
		},
		getCurrentEventNameFromURL() {
			const { eventNameFromUrl } = useApp();
			return eventNameFromUrl();
		},
		getCurrentEventName() {
			return this.getCurrentEvent?.name;
		},
		getCurrentEventState() {
			return this.getCurrentEvent?.event_state ?? null;
		},
		isCurrentEventActive() {
			return this.getCurrentEventState === 'OPEN';
		},
		isCurrentEventIsLocked() {
			return this.getCurrentEventState === 'LOCKED';
		},
		getCurrentEventDateStart() {
			return this.getCurrentEvent?.date_start ?? '';
		},
		getCurrentEventDateEnd() {
			return this.getCurrentEvent?.date_end ?? '';
		},
		getEventHaveNoPassword() {
			return this.getCurrentEvent?.noPassword ?? null;
		}
	},
	actions: {
		async fetchCurrentEvent() {
			try {
				const name = this.getCurrentEventNameFromURL;
				if (!name) {
					console.error('fetchCurrentEvent whit GetEventByName error: no name');
					return {
						error: 'fetchCurrentEvent whit GetEventByName error: no name'
					};
				}

				const result = await urqlClient.query(GetEventByName, { event_name: name }).toPromise();

				if (result.error) {
					console.error('fetchCurrentEvent whit GetEventByName error: ', result.error);
					return result;
				}

				if (!result.data) {
					console.error('fetchCurrentEvent whit GetEventByName error: no data', result);
					return result;
				}

				this.setCurrentEvent(result.data.getEventByName);
				return result;
			} catch (e) {
				console.error('fetchCurrentEvent whit GetEventByName error ', e);
				return {
					error: 'fetchCurrentEvent whit GetEventByName error'
				};
			}
		},
		setCurrentEvent(event: Event) {
			this.currentEvent = event;
		},
		updateCurrentEvent(event: Event) {
			this.currentEvent = event;
		},
		subscribe() {
			this.subscriptions.onUpdateEvents = AWSSubscription(
				onUpdateEventId,
				{ id: this.getCurrentEventId ?? '' },
				({
					value: {
						data: { onUpdateEventId }
					}
				}: {
					value: {
						data: {
							onUpdateEventId: Event;
						};
					};
				}) => {
					this.setCurrentEvent(onUpdateEventId);
				},
				(e: any) => {
					console.error('onUpdateEvents sub error: ', e);
				}
			);
		},
		subscribeV2() {
			const self = this;
			try {
				const { unsubscribe } = pipe(
					urqlClient.subscription(OnEventUpdated, {}),
					subscribe((result) => {
						if (result?.data) {
							const event = result.data.onEventUpdated;
							self.setCurrentEvent(event);
						}
					})
				);

				this.subscriptions.onEventUpdated = unsubscribe;
			} catch (e) {
				console.error('OnUserSessionCreated subscription failed, reason:', e);
			}
		},
		unsubscribe() {
			this.subscriptions.onUpdateEvents();
		},
		unsubscribeV2() {
			this.subscriptions.onEventUpdated();
		}
	}
});

export default useCurrentEvent;
