<script lang="ts" setup>
	import { useDisplay } from 'vuetify';
	import { useRoute } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import { useVModel } from '@vueuse/core';
	import { storeToRefs } from 'pinia';

	import useApp from '@/composables/useApp';
	import HelpButton from '@/custom/default/vue/components/common/HelpButton.vue';
	import SocialMediaLink from '@/custom/solotech-career/vue/components/common/SocialMediaLink.vue';
	import useCurrentUser from '@/features/CurrentUser/store';
	import { ComponentsName } from '@/plugins/lib/constants';
	import logo from '@/custom/solotech-career/assets/logo.png';

	const props = defineProps<{
		drawer: boolean;
	}>();
	const emit = defineEmits(['update:drawer']);

	const { t, locale } = useI18n();
	const route = useRoute();
	const { onSignOut } = useApp();
	const { smAndDown, mdAndDown, xs } = useDisplay();
	const { isUserAdmin } = storeToRefs(useCurrentUser());

	const drawerVModel = useVModel(props, 'drawer', emit);
	//@ts-ignore
	const signOut = () => onSignOut(route.params.eventName);
	const toggleDrawer = () => (drawerVModel.value = !drawerVModel.value);
</script>

<template>
	<v-app-bar ref="navbar" class="pl-0" v-bind="$attrs">
		<template #prepend>
			<v-btn
				v-if="mdAndDown"
				id="btn-toggle-drawer"
				class="btn-small pl-2 ml-2 mr-4 bg-primary btn-toggle-drawer"
				size="small"
				@click="toggleDrawer"
			>
				<v-icon v-if="!drawerVModel" class="btn-toggle-drawer">mdi-menu</v-icon>
				<v-icon v-else class="btn-toggle-drawer">mdi-close</v-icon>
			</v-btn>
			<v-img v-if="!mdAndDown" :heigth="'64px'" :width="'150px'" :src="logo" alt="" />
		</template>

		<h2 v-if="!xs" style="margin: auto" class="font-weight-bold">Virtual Job Fair</h2>
		<social-media-link v-if="!smAndDown" />

		<v-btn
			v-if="isUserAdmin"
			:to="{
				name: ComponentsName.ControlRoom,
				params: {
					eventName: $route.params.eventName
				}
			}"
			class="btn-small bg-primary ml-2 ml-md-7"
			rounded="lg"
			size="small"
			target="_blank"
		>
			<v-icon>mdi-account-cog-outline</v-icon>
			<v-tooltip activator="parent" location="bottom">
				{{ t('navigationBar_admin') }}
			</v-tooltip>
		</v-btn>

		<help-button
			size="small"
			class="btn-small ml-2 mr-2 bg-primary"
			:class="isUserAdmin ? '' : 'ml-md-7'"
			rounded="lg"
			:btn-text="t('navigationBar_NeedHelp')"
		/>
		<v-btn
			size="small"
			rounded="lg"
			class="btn-small px-2 bg-primary"
			:aria-label="t('navigationBar_logout')"
			@click="signOut"
		>
			<v-icon>mdi-logout</v-icon>
			<v-tooltip activator="parent" location="bottom">
				{{ t('navigationBar_logout') }}
			</v-tooltip>
		</v-btn>
		<v-menu v-if="smAndDown">
			<template #activator="{ props: menuProps }">
				<v-tooltip location="bottom" :aria-label="t('navigationBar_more')">
					<template #activator="{ props: tooltipProps }">
						<v-btn
							rounded="lg"
							class="btn-small px-2 mx-2 bg-primary"
							size="small"
							v-bind="{ ...menuProps, ...tooltipProps }"
						>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<span v-text="t('navigationBar_more')" />
				</v-tooltip>
			</template>
			<social-media-link />
		</v-menu>
	</v-app-bar>
</template>

<style scoped>
	.btn-small {
		min-width: 31px;
	}
	.list-style {
		list-style: none;
	}
	.current-language {
		background: rgba(237, 26, 105);
	}
</style>

<i18n>
{
	"en": {
		"navigationBar_title": "Welcome to the Virtual Job Fair!",
		"navigationBar_NeedHelp": "Help",
		"navigationBar_videoconference": "Video conference",
		"navigationBar_more": "More options",
		"navigationBar_logout": "Logout",
		"navigationBar_admin": "Admin"
	},
	"fr": {
		"navigationBar_title": "Bienvenue à la foire d'emploi virtuelle!",
		"navigationBar_NeedHelp": "Aide",
		"navigationBar_videoconference": "Vidéo conférence",
		"navigationBar_more": "Autres options",
		"navigationBar_logout": "Logout",
		"navigationBar_admin": "Admin"
	}
}
</i18n>
