<script lang="ts" setup>
	import { ref, useAttrs } from 'vue';
	import { useI18n } from 'vue-i18n';

	import DataTable from 'primevue/datatable';
	import { FilterMatchMode } from 'primevue/api';

	import 'primevue/resources/themes/saga-blue/theme.css';
	import 'primevue/resources/primevue.min.css';
	import 'primeicons/primeicons.css';

	useAttrs();
	const { t } = useI18n();

	const filters = ref({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});
</script>

<template>
	<DataTable v-model:filters="filters" column-resize-mode="fit" data-key="id" responsive-layout="stack">
		<template #header>
			<v-text-field
				v-model="filters['global'].value"
				:label="t('datatable.search')"
				type="search"
				prepend-icon="mdi-magnify"
				variant="underlined"
				class="input-search"
			/>
		</template>
		<template #empty>{{ t('datatable.empty') }}</template>
		<slot />
	</DataTable>
</template>

<style scoped>
	.input-search {
		max-width: 25rem;
	}
</style>
