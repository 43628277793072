<script lang="ts" setup>
	import { computed, ComputedRef, ref, Ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from 'vue-router';
	import { storeToRefs } from 'pinia';

	import { ComponentsName, EmitsName } from '@/plugins/lib/constants';
	import { ruleEmail, rulePassword, rulePasswordMatch, ruleUserName } from 'lib/validations';
	import { RuleFunctions, SignUpField } from 'lib/types';
	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import AuthenticationForm from '@/custom/default/vue/components/auth/AuthenticationForm.vue';
	import useToast from '@/features/Toast';
	import { useSignUpMutation } from '@/gql/operations';
	import { Language } from '@/gql/types';
	import { MessageColor } from '@/features/Toast/types';
	import useCurrentEvent from '@/features/Events/store';

	const emits = defineEmits([EmitsName.AuthRedirect]);

	const { t, locale } = useI18n();
	const router = useRouter();
	const route = useRoute();

	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const userAlreadyExist: Ref<boolean> = ref(false);
	const userNameValidations: ComputedRef<RuleFunctions> = computed(() => ruleUserName(locale.value as Language));
	const emailValidations: ComputedRef<RuleFunctions> = computed(() => ruleEmail(locale.value as Language));
	const passwordValidations: ComputedRef<RuleFunctions> = computed(() => rulePassword(locale.value as Language));
	const passwordConfirmValidations: ComputedRef<RuleFunctions> = computed(() =>
		rulePasswordMatch(locale.value as Language, credentials.value.password)
	);
	const credentials: Ref<SignUpField> = ref({
		password: '',
		password_confirm: '',
		user_email: '',
		user_name: ''
	});

	const acceptTermeCondition: Ref<boolean> = ref(false);

	const isPending: Ref<boolean> = ref(false);
	const resetUserAlreadyExist = () => (userAlreadyExist.value = false);

	const signUpMutation = useSignUpMutation();
	const onSignUp = async () => {
		try {
			isPending.value = true;
			const result = await signUpMutation.executeMutation({
				signUpInput: {
					event_id: getCurrentEventId.value,
					language: locale.value === 'fr' ? Language.Fr : Language.En,
					password: credentials.value.password,
					password_confirm: credentials.value.password_confirm,
					user_email: credentials.value.user_email,
					user_name: credentials.value.user_name
				}
			});

			if (result.data?.signUp.success) {
				isPending.value = false;
				return useToast().triggerToast(t('signup_success_message'), { color: MessageColor.success });
			}
		} catch (e) {
			isPending.value = false;
			userAlreadyExist.value = true;
			useToast().triggerToast(t('signup_user_exists'), { color: MessageColor.error });
		}
	};
</script>

<template>
	<AuthenticationForm :loading="isPending" data-cy="form-register" lazy-validation @submit.prevent="onSignUp">
		<input-field
			v-model:value="credentials.user_name"
			:label="t('signup_name')"
			:rules="userNameValidations"
			autofocus
			data-cy="form-register-input-user_name"
		/>
		<input-field
			v-model:value="credentials.user_email"
			:error-messages="userAlreadyExist ? t('signup_user_exists') : ''"
			:label="t('signup_email')"
			:rules="emailValidations"
			data-cy="form-register-input-user_email"
			@focusin="resetUserAlreadyExist"
		/>
		<input-field
			v-model:value="credentials.password"
			:label="t('signup_password')"
			:rules="passwordValidations"
			:type="'password'"
			data-cy="form-register-input-password"
		/>
		<input-field
			v-model:value="credentials.password_confirm"
			:label="t('signup_password_validation')"
			:rules="passwordConfirmValidations"
			:type="'password'"
			data-cy="form-register-input-password_confirm"
		/>
		<v-checkbox v-model="acceptTermeCondition">
			<template #label>
				<div>
					{{ t('signup_therm.accept') }}
					<v-tooltip location="bottom">
						<template #activator="{ props }">
							<router-link target="_blank" to="#" v-bind="props" @click.stop>{{
								t('signup_therm.terme_condition')
							}}</router-link>
						</template>
					</v-tooltip>
				</div>
			</template>
		</v-checkbox>
		<template #form-submit="{ disabled, loading }">
			<v-btn
				:loading="loading"
				:disabled="!acceptTermeCondition && disabled"
				color="primary"
				type="submit"
				data-cy="form-register-submit"
			>
				{{ t('signup_button') }}
			</v-btn>
			<p class="text-center my-2">
				{{ t('signup_already_account') }}
				<router-link :to="{ name: ComponentsName.SignInView, params: { eventName: route.params.eventName } }">{{
					t('signup_login')
				}}</router-link>
			</p>
		</template>
	</AuthenticationForm>
</template>

<i18n>
{
	"en": {
		"signup_email": "Your email address",
		"signup_name": "Your complete name",
		"signup_password": "New password",
		"signup_password_validation": "Type again the new password",
		"signup_cancel": "Cancel",
		"signup_button": "Create",
		"signup_password_dont_match": "Passwords don't match",
		"signup_success_message": "Your account has been created with success !",
		"signup_user_exists": "User already exists",
		"signup_therm": {
			"accept": "I accept the",
			"terme_condition": "Terms and Conditions"
		},
		"signup_already_account": "Already have an account?",
		"signup_login": "Log in"
	},
	"fr": {
		"signup_email": "Votre adresse courriel",
		"signup_name": "Votre nom complet",
		"signup_password": "Nouveau mot de passe",
		"signup_password_validation": "Saisir à nouveau le mot de passe",
		"signup_cancel": "Annuler",
		"signup_button": "Créer",
		"signup_password_dont_match": "Le mot de passe validé doit avoir la même valeur",
		"signup_success_message": "Votre compte à été créé avec succès !",
		"signup_user_exists": "L'utilisateur existe déja",
		"signup_therm": {
			"accept": "J'accepte les",
			"terme_condition": "Termes et Conditions"
		},
		"signup_already_account": "Vous avez déjà un compte ?",
		"signup_login": "Connexion"

	}
}
</i18n>
