import { storeToRefs } from 'pinia';

import { AWSGraphqlMutation, AWSGraphqlQuery, AWSSubscription } from '@/graphql';
import { onCreateCommand } from '@/graphql/subscriptions';
import { CommandType, CreateCommandInput, CommandOptions, Table } from '@/graphql/types';
import { createCommand } from '@/graphql/mutations';
import { commandByEvent } from '@/graphql/queries';

import useApp from '@/composables/useApp';
import useCurrentUser from '@/features/CurrentUser/store';
import { useCommand } from '@/features/Command/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';
import { useTableStore } from '@/features/Table/store';
import { toggleForceMuteUser, toggleMuteUser } from '@/features/Table/UserTable';
import useCurrentEvent from '../Events/store';

export const commandUnsubscription = {
	onCreateCommandUnsubscribe: () => {}
};

export async function sendCommand(data: CreateCommandInput) {
	await AWSGraphqlMutation(createCommand, data);
}

export async function fetchCommands(event_id: string) {
	const store = useCommand();

	const {
		data: {
			commandByEvent: { items }
		}
	} = await AWSGraphqlQuery(commandByEvent, {
		event_id: event_id
	});
	store.setListCommands(items);
}

export function createCommandSubscription(initJitsiMeet: (table: Table) => Promise<void>) {
	const store = useCommand();
	const { getEventID } = storeToRefs(useCurrentUser());

	const next = async (response: any) => {
		const {
			value: {
				data: { onCreateCommand: command }
			}
		} = response;
		store.addCommand(command);
		await commandExecution(
			initJitsiMeet,
			command.type,
			command.is_forced,
			command.commandUser_fromId,
			command.commandUser_toId || null,
			command.options || null
		);
	};
	const error = (error: any) => {
		console.error('Error command subscription', error);
	};

	commandUnsubscription.onCreateCommandUnsubscribe = AWSSubscription(
		onCreateCommand,
		{
			event_id: getEventID.value
		},
		next,
		error
	);
}

async function commandExecution(
	initJitsiMeet: (table: Table) => Promise<void>,
	type: CommandType,
	force: boolean,
	commandUser_fromId: string,
	commandUser_toId?: string,
	options?: CommandOptions
) {
	const { isUserAdmin, isUserModerator, getUserID } = storeToRefs(useCurrentUser());
	const { getCurrentEventName: getEventName } = storeToRefs(useCurrentEvent());
	const tableStore = useTableStore();
	const { leaveTable, getTable } = tableStore;
	const { currentTable, jitsiMeet, haveCurrentTable, jitsiConfig } = storeToRefs(tableStore);

	const { onSignOut } = useApp();
	const activeUser = useActiveUsers().getActiveUser(getUserID.value || '');
	const isOnTable = currentTable.value?.id === commandUser_toId;
	const isSameUserID = commandUser_toId === getUserID.value;

	if (!force && (isUserAdmin.value || isUserModerator.value)) {
		return;
	}

	if (type === CommandType.LOGOUT_ALL_USER || (type === CommandType.LOGOUT_USER && (isSameUserID || isOnTable))) {
		await onSignOut(getEventName.value || '');
		return;
	}

	if (type === CommandType.REFRESH_ALL_USER || (type === CommandType.REFRESH_USER && (isSameUserID || isOnTable))) {
		location.reload();
		return;
	}

	const jitsiInstance = jitsiMeet.value;
	if (!haveCurrentTable.value) return;
	if (type === CommandType.MUTE_ALL_USER || (type === CommandType.MUTE_USER && (isSameUserID || isOnTable))) {
		jitsiInstance?.executeCommand('toggleAudio');
		await toggleMuteUser(activeUser?.session_id);
		return;
	}

	if (
		type === CommandType.FORCE_MUTE_ALL_USER ||
		(type === CommandType.FORCE_MUTE_USER && (isSameUserID || isOnTable))
	) {
		const {
			data: { updateUserTable: currentUserTable }
		} = await toggleForceMuteUser(activeUser?.session_id);
		if (!(await jitsiMeet.value?.isAudioMuted())) jitsiMeet.value?.executeCommand('toggleAudio');
		const toggleMicrophoneButton = jitsiConfig.value?.configOverwrite?.toolbarButtons?.filter((button: string) =>
			currentUserTable?.is_force_mute ? button !== 'microphone' : button
		);

		jitsiInstance?.executeCommand('overwriteConfig', {
			toolbarButtons: toggleMicrophoneButton
		});
		return;
	}

	if (
		type === CommandType.KICK_ALL_USER_TABLE ||
		(type === CommandType.KICK_USER_TABLE && (isSameUserID || isOnTable))
	) {
		leaveTable();
	}

	if (
		isSameUserID &&
		type === CommandType.MOVE_USER_TABLE &&
		options?.moveToTable &&
		currentTable.value?.id !== options?.moveToTable
	) {
		const newTableId = options?.moveToTable;
		const tableToJoin = getTable(newTableId);
		if (tableToJoin) {
			await leaveTable();
			return await initJitsiMeet(tableToJoin);
		}
	}
}
