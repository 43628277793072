<template>
	<v-card class="fill-height text-white event-management-container">
		<v-card-title class="bg-secondary">
			<h1 class="text-h6 font-weight-bold text-uppercase">{{ t('control_room_management') }}</h1>
		</v-card-title>
		<v-container>
			<v-tabs v-model="tab" grow>
				<v-tab
					v-for="(item, i) in tabs"
					:key="`tab-${i}`"
					:text="item.title.toUpperCase()"
					:value="item.value"
					class="text-h5"
				/>
			</v-tabs>
			<v-window v-model="tab">
				<v-window-item v-for="(item, i) in tabs" :key="`tab-${i}`" :value="item.value" class="pa-4">
					<h2 class="my-4">{{ item.title }}</h2>
					<component :is="item.value" />
				</v-window-item>
			</v-window>
		</v-container>
	</v-card>
</template>

<script lang="ts" setup>
	import { ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { ComponentsName } from '@/plugins/lib/constants';

	import type { Ref } from 'vue';

	const { t } = useI18n();

	const tab: Ref<string> = ref('');
	const tabs: {
		value: string;
		title: string;
	}[] = [
		{
			value: ComponentsName.EventControl,
			title: t('event_control')
		},
		{
			value: ComponentsName.ControlRoomStreams,
			title: t('stream_config')
		},

		{
			value: ComponentsName.AdminConfig,
			title: t('admin_config')
		}
	];
</script>

<i18n>
{
	"en": {
		"event_control": "Event state",
		"stream_config": "Stream Configuration",
		"admin_config": "Visibility"
	},
	"fr": {
		"event_control": "Contrôle des événements",
		"stream_config": "Configuration des streams",
		"admin_config": "Visibilité"
	}
}
</i18n>
