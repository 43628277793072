<script lang="ts" setup>
	import type { ComputedRef, Ref } from 'vue';
	import { computed, ref } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';

	import { ruleEmail } from 'lib/validations';
	import { PasswordRecoveryField, RuleFunctions } from 'lib/types';
	import useToast from '@/features/Toast';
	import InputField from '@/custom/default/vue/components/common/InputField.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import AuthenticationForm from '@/custom/default/vue/components/auth/AuthenticationForm.vue';
	import { Language } from '@/gql/types';
	import { useResetPasswordMutation } from '@/gql/operations';
	import { MessageColor } from '@/features/Toast/types';
	import useCurrentEvent from '@/features/Events/store';

	const props = withDefaults(
		defineProps<{
			email?: string;
		}>(),
		{ email: '' }
	);

	const { t, locale } = useI18n();
	const router = useRouter();
	const route = useRoute();
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const emailValidations: ComputedRef<RuleFunctions> = computed(() => ruleEmail(locale.value as Language));
	const fields: Ref<PasswordRecoveryField> = ref({
		user_email: props.email
	});
	const isPending: Ref<boolean> = ref(false);

	const resetPasswordMutation = useResetPasswordMutation();
	const onPasswordRecovery = async () => {
		try {
			isPending.value = true;
			const result = await resetPasswordMutation.executeMutation({
				resetPasswordInput: {
					event_id: getCurrentEventId.value,
					...fields.value
				}
			});

			if (result.error) {
				isPending.value = false;
			}

			if (result.data?.resetPassword.success) {
				useToast().triggerToast(t('password_recovery_toast_message'), { color: MessageColor.success });
				router.push({ name: 'login', params: { eventName: route.params.eventName } });
			}
		} catch (e: any) {
			isPending.value = false;
			throw new Error(e);
		}
	};
</script>

<template>
	<AuthenticationForm
		:loading="isPending"
		autofocus
		data-cy="form-recovery"
		lazy-validation
		@submit.prevent="onPasswordRecovery"
	>
		<input-field
			v-model:value="fields.user_email"
			:autofocus="true"
			:label="t('password_recovery_email')"
			:rules="emailValidations"
			data-cy="form-recovery-input-user_email"
		/>
		<template #form-submit="{ disabled, loading }">
			<div class="d-flex justify-end gap">
				<v-btn
					:to="{ name: ComponentsName.SignInView, params: { eventName: route.params.eventName } }"
					color="primary"
					v-text="t('password_recovery_cancel')"
				/>
				<v-btn :disabled="disabled" :loading="loading" color="primary" data-cy="form-recovery-submit" type="submit"
					>{{ t('password_recovery_confirm') }}
				</v-btn>
			</div>
		</template>
	</AuthenticationForm>
</template>

<style scoped>
	.gap {
		gap: 1rem;
	}
</style>

<i18n>
{
	"en": {
		"password_recovery_title": "Password recovery",
		"password_recovery_email": "Email",
		"password_recovery_confirm": "Reset",
		"password_recovery_cancel": "Cancel",
		"password_recovery_toast_message": "If this email is valid, you should have received instructions by email to change your password"
	},
	"fr": {
		"password_recovery_title": "Réinitialiser votre mot de passe",
		"password_recovery_email": "Courriel",
		"password_recovery_cancel": "Annuler",
		"password_recovery_confirm": "Réinitialiser",
		"password_recovery_toast_message": "Si ce courriel est valide, vous devez avoir reçu des instructions par e-mail pour changer votre mot de passe"
	}
}
</i18n>
