import { defineStore } from 'pinia';
import { AWSGraphqlMutation, AWSGraphqlQuery } from '@/graphql';
import { listUserTables, tableByEvent } from '@/graphql/queries';
import { Table } from '@/graphql/types';
import { JitsiTableRole } from '@/plugins/jitsi';
import orderBy from 'lodash/orderBy';
import { deleteUserTable } from '@/graphql/mutations';
import { TableActions, TableGetters, TableStates } from '@/features/Table/types';
import useCurrentEvent from '@/features/Events/store';
import { useActiveUsers } from '@/features/ActiveUsers/store';

export const useTableStore = defineStore<string, TableStates, TableGetters, TableActions>('Table', {
	state: () => ({
		tables: [],
		nextPage: null,
		currentTable: null,
		meetContainer: null,
		jitsiMeet: null,
		jitsiConfig: null,
		haveCurrentTable: false,
		currentTableLock: false,
		role: JitsiTableRole.user
	}),
	getters: {
		getTables(state) {
			return state.tables;
		},
		getTable: (state) => (tableId) => state.tables.find(({ id }) => id === tableId),
		getTablesSortedByOrder(state) {
			return orderBy(state.tables, ['order'], ['asc']);
		},
		getTablesByGroup() {
			return this.getTablesSortedByOrder.reduce((acc: Record<string, Table[]>, table): Record<string, Table[]> => {
				if (table.group_ref && Array.isArray(acc[table.group_ref])) {
					acc[table.group_ref] = [...acc[table.group_ref], table];
					return acc;
				}
				if (table.group_ref) {
					acc[table.group_ref] = [table];
					return acc;
				}
				return acc;
			}, {});
		},
		tablesHaveGroup(state) {
			return state.tables.every((table) => table.group_ref !== '');
		},
		haveTables(state) {
			return state.tables.length > 0;
		}
	},
	actions: {
		fetchTables(limit = 100, nextToken?: string) {
			const { getCurrentEventId } = useCurrentEvent();

			AWSGraphqlQuery(tableByEvent, { limit, event_id: getCurrentEventId, nextToken })
				.then(
					({
						data: {
							tableByEvent: { items, nextToken }
						}
					}) => {
						this.setTables(items);
						this.nextPage = nextToken;
					}
				)
				.catch((error) => {
					throw Error(error);
				});
		},
		setTables(payload) {
			this.tables = payload;
		},
		setCurrentTable(payload: Table | null) {
			this.currentTable = payload;
			this.haveCurrentTable = !!this.currentTable;
		},
		setRole(payload) {
			this.role = payload;
		},
		setMeetContainer(payload) {
			this.meetContainer = payload;
		},
		setJitsiMeet(payload) {
			this.jitsiMeet = payload;
		},
		setJitsiConfig(payload) {
			this.jitsiConfig = payload;
		},
		setCurrentTableLock(payload) {
			this.currentTableLock = payload;
		},
		async leaveTable() {
			const sessionId = localStorage.getItem('session_id');
			let userTable = this.currentTable?.users?.items.find((userTable) => userTable?.user?.session_id === sessionId);

			if (!userTable) {
				const {
					data: {
						listUserTables: { items }
					}
				} = await AWSGraphqlQuery(listUserTables, {
					filter: { userTableUserId: { eq: sessionId } }
				});
				userTable = items[0];
			}

			this.setCurrentTable(null);
			this.setRole(JitsiTableRole.user);
			this.setJitsiConfig(null);
			this.setJitsiMeet(null);
			if (this.meetContainer) {
				this.meetContainer.innerHTML = '';
			}
			if (userTable) {
				await AWSGraphqlMutation(deleteUserTable, { id: userTable?.id });
				useActiveUsers().setTableToCurrentActiveUser(userTable?.userTableUserId);
			}
		}
	}
});
