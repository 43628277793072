<template>
	<v-card>
		<v-card-title class="bg-secondary">
			<h1 class="text-h6 font-weight-bold text-uppercase">{{ t('control_room_commands') }}</h1>
		</v-card-title>
		<v-container class="d-flex flex-wrap flex-gap-2">
			<div v-for="(command, index) in commandsAction" :key="`command_${command.content}_${index}`">
				<v-btn
					v-show="displayCommandGlobalTableButton(command.type)"
					:class="command.color"
					:prepend-icon="command.icon"
					class="text-white"
					@click="openDialog(command.type)"
				>
					{{ t(`commands.${command.content}`) }}
					<v-tooltip
						activator="parent"
						content-class="bg-white"
						location="bottom"
						:aria-label="t(`command_tooltip.${command.content}`, 1)"
					>
						{{ t(`command_tooltip.${command.content}`, 1) }}
					</v-tooltip>
				</v-btn>
			</div>
		</v-container>
		<v-container>
			<v-tabs v-model="tab" :direction="tabsOrientation" grow>
				<v-tab class="text-h5 text-uppercase" :text="t('tab.user_title')" :value="TAB_TITLE.USER_SUPPORT" />
				<v-tab class="text-h5 text-uppercase" :text="t('tab.table_title')" :value="TAB_TITLE.TABLE_SUPPORT" />
				<v-tab class="text-h5 text-uppercase" :text="t('tab.history_title')" :value="TAB_TITLE.HISTORY_SUPPORT" />
			</v-tabs>
			<v-window v-model="tab">
				<v-window-item :value="TAB_TITLE.USER_SUPPORT">
					<active-user-command @submit-command="openDialog" />
				</v-window-item>
				<v-window-item :value="TAB_TITLE.TABLE_SUPPORT">
					<talk-room-support @submit-command="openDialog" />
				</v-window-item>
				<v-window-item :value="TAB_TITLE.HISTORY_SUPPORT">
					<history-support />
				</v-window-item>
			</v-window>
		</v-container>
	</v-card>
	<v-dialog v-model="isOpenConfirmationDialog">
		<v-alert
			:title="t('modal.title')"
			class="align-self-center"
			border="start"
			density="compact"
			type="error"
			width="50rem"
		>
			<div>{{ t('modal.description', { type: selectedCommandType }) }}</div>
			<v-divider class="my-4 bg-white"></v-divider>
			<div class="d-flex justify-end flex-gap-1">
				<v-btn class="bg-indigo-darken-2" @click="closeDialog">{{ t('modal.buttons.cancel') }}</v-btn>
				<v-btn class="bg-pink-darken-4" @click="sendGlobalCommand()">{{ t('modal.buttons.submit') }}</v-btn>
				<v-btn @click="sendGlobalCommand(true)">{{ t('modal.buttons.force') }}</v-btn>
			</div>
		</v-alert>
	</v-dialog>
</template>

<script lang="ts" setup>
	import { computed, ComputedRef, defineComponent, onBeforeMount, Ref, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';
	import { v4 as uuidV4 } from 'uuid';
	import { useDisplay } from 'vuetify';
	import useToast from '@/features/Toast';
	import useCurrentUser from '@/features/CurrentUser/store';
	import { sendCommand } from '@/features/Command';
	import { useActiveUsers } from '@/features/ActiveUsers/store';

	import { CommandOptions, CommandType } from '@/graphql/types';
	import { ComponentsName } from '@/plugins/lib/constants';

	import ActiveUserCommand from '@/custom/default/vue/components/admin/adminSupport/ActiveUserSupport.vue';
	import TalkRoomSupport from '@/custom/default/vue/components/admin/adminSupport/TalkRoomSupport.vue';
	import HistorySupport from '@/custom/default/vue/components/admin/adminSupport/HistorySupport.vue';
	import { useTableStore } from '@/features/Table/store';

	enum TAB_TITLE {
		USER_SUPPORT,
		TABLE_SUPPORT,
		HISTORY_SUPPORT
	}

	defineComponent({
		name: ComponentsName.AdminSupport
	});

	const { t } = useI18n();
	const { getUserID, getEventID } = storeToRefs(useCurrentUser());
	const { triggerToast } = useToast();
	const activeUserStore = useActiveUsers();
	const tableStore = useTableStore();
	const { activeUsers } = storeToRefs(activeUserStore);
	const { mdAndDown } = useDisplay();
	const tabsOrientation: ComputedRef<'vertical' | 'horizontal'> = computed(() =>
		mdAndDown.value ? 'vertical' : 'horizontal'
	);

	const tab: Ref<TAB_TITLE> = ref(TAB_TITLE.USER_SUPPORT);

	const commandsAction = ref([
		{ icon: 'mdi-refresh', type: CommandType.REFRESH_ALL_USER, content: 'refresh', color: 'bg-indigo' },
		{ icon: 'mdi-logout', type: CommandType.LOGOUT_ALL_USER, content: 'logout', color: 'bg-amber-darken-4' },
		{ icon: 'mdi-phone-off', type: CommandType.KICK_ALL_USER_TABLE, content: 'kick', color: 'bg-red' },
		{ icon: 'mdi-microphone-off', type: CommandType.MUTE_ALL_USER, content: 'mute', color: 'bg-purple-lighten-1' },
		{
			icon: 'mdi-microphone-minus',
			type: CommandType.FORCE_MUTE_ALL_USER,
			content: 'force_mute',
			color: 'bg-purple-lighten-1'
		}
	]);

	const selectedCommandType: Ref<CommandType | null> = ref(null);
	const isOpenConfirmationDialog: Ref<boolean> = ref(false);
	const userIdTo: Ref<string | null> = ref(null);
	const commandOptions: Ref<object | null> = ref(null);

	const displayCommandGlobalTableButton = (type: CommandType) => {
		const tableCommandButton = [
			CommandType.KICK_ALL_USER_TABLE,
			CommandType.MUTE_ALL_USER,
			CommandType.FORCE_MUTE_ALL_USER
		].includes(type);

		return tab.value === TAB_TITLE.TABLE_SUPPORT || !tableCommandButton;
	};
	const closeDialog = () => (isOpenConfirmationDialog.value = false);
	const openDialog = (type: CommandType, user_id_to?: string, command_options?: CommandOptions) => {
		isOpenConfirmationDialog.value = true;
		selectedCommandType.value = type;
		userIdTo.value = user_id_to || null;
		commandOptions.value = command_options || null;
	};

	const sendGlobalCommand = async (is_forced = false) => {
		if (!getEventID?.value || !getUserID?.value) {
			triggerToast(t('commands.error'));
			throw new Error('Error on sendGlobalCommand: Cannot get EventID or UserID !');
		}

		if (!selectedCommandType?.value) {
			triggerToast(t('commands.error'));
			throw new Error('CommandType must not be null');
		}

		await sendCommand({
			id: uuidV4(),
			event_id: getEventID.value,
			commandUser_fromId: getUserID.value,
			commandUser_toId: userIdTo.value,
			type: selectedCommandType.value,
			is_forced,
			options: commandOptions.value
		});
		isOpenConfirmationDialog.value = false;
		triggerToast(t('commands.submit'));
	};

	onBeforeMount(() => {
		tableStore.fetchTables();
	});
</script>

<style scoped>
	.flex-gap-1 {
		gap: 1rem;
	}
	.flex-gap-2 {
		gap: 2rem;
	}
</style>

<i18n>
{
	"en": {
		"commands": {
			"logout": "Disconnect Users from Event",
			"kick": " Disconnect Users from Talk Room",
			"refresh": "Refresh Event Page",
			"mute": "Mute User’s Microphone",
			"force_mute": "Force Mute User’s Microphone",
			"submit": "The command has been sent",
			"error": "Error while sending command, please reload your page and try again."
		},
		"command_tooltip": {
			"logout": "Disconnect user from the event. | Disconnect all users from the event. | Disconnect all users of this table from the event.",
			"kick": "Disconnects the user from the talk room. | Disconnects all users from the tables. | Disconnects all users from the table.",
			"refresh": "Refresh user event page. | Refresh the event page for all users. | Refresh the event page for all users on this table.",
			"force_mute": "Turns off the microphone of user connected to the table | Turns off the microphone of all users connected to the tables. | Turns off the microphone of all users on this table.",
			"force_unmute": "Turns on the microphone of user connected to the table | Turns on the microphone of all users connected to the tables | Turns on the microphone of all users on this table.",
			"mute": "Mutes the microphone of the user connected to the table. | Mutes the microphone of all users connected to the tables. | Mutes the microphone of all users on this table.",
			"unmute": "UnMutes the microphone of the user connected to the table. | UnMutes the microphone of all users connected to the tables. | UnMutes the microphone of all users on this table."
		},
		"modal": {
			"title": "Warning",
			"description": "Are you sure you want to do {type} ?",
			"buttons": {
				"submit": "Confirm",
				"cancel": "Cancel",
				"force": "Force Submit"
			}
		},
		"event": {
			"title": "Event Commands"
		},
		"tab": {
			"title": "{ tabName } commands",
			"user_title": "User Commands",
			"table_title": "Talk Room Actions",
			"history_title": "Commands History"
		}
	},
	"fr": {
		"support": {
			"title": "Commandes Utilisateurs Connectés"
		},
		"commands": {
			"logout": "Déconnecter les utilisateurs",
			"kick": "Déconnecter les utilisateurs de la table",
			"refresh": "Rafraichit l'évènement",
			"mute": "Couper le microphone des utilisateurs",
			"force_mute": "Forcer la coupure du micro des utilisateurs",
			"submit": "La commande à bien été envoyé",
			"error": "Erreur lors de l'envoi de la commande, merci de recharger votre page et réssayer."
		},
		"command_tooltip": {
			"logout": "Déconnecte l'utilisateur de l'évènement. | Déconnecte tous les utilisateurs de l'évènement. | Déconnecte tous les utilisateurs sur cette table de l'évènement.",
			"kick": "Déconnecte l'utilisateur de la table. | Déconnecte tous les utilisateurs de toutes les tables. | Déconnecte tous les utilisateurs de la table.",
			"refresh": "Rafraichit la page de l'évènement pour l'utilisateur. | Rafraichit la page de l'évènement pour tous les utilisateurs. | Rafraichit la page de l'évènement pour tous les utilisateurs de la table.",
			"force_mute": "Coupe et Désactive le micro de l'utilisateur connecté sur la table. | Coupe et Désactive le micro de tous les utilisateurs connectés sur les tables. | Coupe et Désactive le micro de tous les utilisateurs connectés sur la table.",
			"force_unmute": "Active le micro de l'utilisateur connecté sur la table. | Active le micro de tous les utilisateurs connectés sur les tables. | Active le micro de tous les utilisateurs connectés sur la table.",
			"mute": "Coupe le micro de l'utilisateur connecté sur la table. | Coupe le micro de tous les utilisateurs connectés sur les tables. | Coupe le micro de tous les utilisateurs connectés sur la table.",
			"unmute": "Active le micro de l'utilisateur connecté sur la table. | Active le micro de tous les utilisateurs connectés sur les tables. | Active le micro de tous les utilisateurs connectés sur la table."
		},
		"modal": {
			"title": "Attention",
			"description": "Êtes-vous sûr de vouloir effectuer {type} ?",
			"buttons": {
				"submit": "Confirmer",
				"cancel": "Annuler",
				"force": "Forcer la soumission"
			}
		},
		"event": {
			"title": "Commande de l'évènement"
		},
		"tab": {
			"user_title": "Commande Utilisateurs",
			"table_title": "Commandes des Tables",
			"history_title": "Historique des Commandes"
		}
	}
}
</i18n>
