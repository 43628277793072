<script setup lang="ts">
	import { defineComponent, ref, watch } from 'vue';
	import LayoutSolotechCarriere from '@/custom/solotech-carriere-virtuelle/vue/components/layout/LayoutSolotechCarriere.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import CardPage from '@/custom/solotech-carriere-virtuelle/vue/components/common/CardPage.vue';
	import { useI18n } from 'vue-i18n';

	defineComponent({
		name: ComponentsName.AboutUs
	});

	const { locale } = useI18n();

	type lang = 'en' | 'fr';

	const urlIframe = {
		en: 'https://drive.google.com/file/d/1RuoM86RNCkmYHgl2GZFdrUpQ8G5fYfzZ/preview',
		fr: 'https://drive.google.com/file/d/1HfjGf9WFqakcZ_q1kf5rnL6t5mZXajhV/preview'
	};

	const srcIframe = ref(getSrcIframe(locale.value as lang));

	watch(
		locale,
		(value, oldValue) => {
			if (value !== oldValue) {
				srcIframe.value = getSrcIframe(value as lang);
			}
		},
		{ immediate: true }
	);

	function getSrcIframe(locale: lang) {
		return urlIframe[locale] ?? urlIframe['fr'];
	}
</script>

<template>
	<layout-solotech-carriere>
		<card-page :container-full-height="true">
			<iframe :src="srcIframe" class="min-h-100" width="100%" height="100%"></iframe>
		</card-page>
	</layout-solotech-carriere>
</template>
