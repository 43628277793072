<template>
	<v-card v-if="users.length > 0" data-cy="datatable-card-alert-message" class="card-height mb-4">
		<v-card-title :class="{ 'text-error': type === 'error', 'text-success': type === 'success' }">
			<h3
				class="headline"
				v-text="
					`${
						type === 'error'
							? t('alert.title_error', {
									count: users.length
							  })
							: t('alert.title_success', {
									count: users.length
							  })
					}`
				"
			/>
		</v-card-title>
		<v-card-text class="card-body pa-4">
			<v-alert v-for="(error, index) in users" :key="index" class="mb-4" :value="true" :type="type">
				<div>
					<ul class="mb-2">
						<li class="font-weight-bold" v-text="`${t('labels.username')}: ${error.user_name},`" />
						<li class="font-weight-bold" v-text="`${t('labels.email')}: ${error.user_email},`" />
						<li class="font-weight-bold" v-text="`${t('labels.role')}: ${error.role}`" />
					</ul>
				</div>
			</v-alert>
		</v-card-text>
	</v-card>
</template>

<script lang="ts" setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';

	import type { SuccessAndError } from './types';

	const props = defineProps<{
		value: SuccessAndError;
	}>();

	const { t } = useI18n();

	const users = computed(() => props.value.value);
	const type = computed(() => props.value.type);
</script>

<style scoped>
	.card-height {
		max-height: 20rem;
		overflow: scroll;
	}
</style>

<i18n>
{
	"en": {
		"alert": {
			"title_error": "{count} user has not been imported | {count} users have not been imported",
			"title_success": "{count} user has been imported | {count} users have been imported"
		}
	},
	"fr": {
		"alert": {
			"title_error": "{count} utilisateur n'a pas été importé | {count} utilisateurs n'ont pas été importés",
			"title_success": "{count} utilisateur a été importé | {count} utilisateurs ont été importés"
		}
	}
}
</i18n>
