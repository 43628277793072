import useAPUtils from '@/amplify-pinia/useAPUtils';
import { getActivePinia, storeToRefs } from 'pinia';

import { StoreGeneric } from 'pinia';
import type { ExtendedPinia } from '@/amplify-pinia/types';

export default class ErrorHandler {
	model = 'ErrorHandler';

	constructor() {
		if (!this._store) {
			useAPUtils().makeStore({ model: this.model })();
		}
	}

	get _store() {
		return (getActivePinia() as ExtendedPinia)?._s?.get(this.model) as unknown as StoreGeneric;
	}

	get store() {
		return storeToRefs(this._store);
	}
}
