<script lang="ts" setup>
	import { defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';

	import { ComponentsName } from '@/plugins/lib/constants';
	import PasswordRecovery from '@/custom/default/vue/components/auth/PasswordRecovery.vue';
	import AuthenticationCard from '@/custom/default/vue/components/auth/AuthenticationCard.vue';

	defineComponent({
		name: ComponentsName.SignInRecoveryView
	});

	const { t, locale } = useI18n();
</script>

<template>
	<AuthenticationCard>
		<template #auth-card-title>
			<h1 class="text-body-1 text-uppercase font-weight-bold">{{ t('password_recovery_title') }}</h1>
		</template>
		<template #auth-card-method>
			<PasswordRecovery />
		</template>
	</AuthenticationCard>
</template>

<i18n>
{
	"en": {
		"password_recovery_title": "Password recovery"
	},
	"fr": {
		"password_recovery_title": "Réinitialiser votre mot de passe"
	}
}
</i18n>
