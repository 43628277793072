import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { loadFonts } from './plugins/webfontloader';
import { App as AppType, createApp } from 'vue';
import App from '@/App.vue';
import { sentry } from '@/plugins/sentry';
import { amplifyPinia } from '@/amplify-pinia';
import useConfig from '@/features/Config';
import { jitsi } from '@/plugins/jitsi';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import router from '@/plugins/router';
import PrimeVue from 'primevue/config';
import useCurrentEvent from '@/features/Events/store';
import VueApexCharts from 'vue3-apexcharts';

navigator.serviceWorker.getRegistrations().then(function (registrations) {
	for (let registration of registrations) {
		registration.unregister().catch((err) => console.error('Service worker unregister error: ', err));
	}
});

Amplify.configure(awsconfig);

loadFonts().catch((error) => console.error('Fonts loaded error: ', error));
createVueApp().catch((error) => console.error('Vue app created error: ', error));

async function createVueApp(): Promise<AppType> {
	// get the event's name from location
	const eventName = eventNameFromUrl();
	const app = createApp(App);

	app.use(sentry).use(router);

	app.config.globalProperties.eventName = eventName;

	const amplifyPiniaPlugin = await amplifyPinia(app);
	app.use(amplifyPiniaPlugin);
	const { fetchCurrentEvent } = useCurrentEvent();
	const result = await fetchCurrentEvent();

	const jitsiPlugin = await jitsi(app);
	app.use(jitsiPlugin).use(i18n);

	await registerEventComponents(app, eventName);

	const Config = useConfig();
	const { locale } = app.config.globalProperties.$i18n;
	if (!result.error) {
		await Config.find({ filter: { name: { eq: eventName } } }).catch((err) =>
			console.error('Config find error in createVueApp function :', err)
		);
	}

	Config.store.currentConfig.value.locale = locale;
	app.use(vuetify()).use(PrimeVue).use(VueApexCharts).mount('#app');

	return app;
}

function eventNameFromUrl() {
	return location.href.replace(location.origin, '').replace('/#/event/', '').split('/')[0] || 'default';
}

async function registerEventComponents(app: AppType, eventName: string) {
	if (typeof app.component === 'function') {
		const components = await getRelevantComponents(eventName);

		Object.entries(components).forEach(([path, definition]) => {
			// @ts-ignore
			app.component(path, definition.default);
		});
	}
}

async function getRelevantComponents(eventName: string) {
	const modules = getAllVueComponents();
	const components = {};

	await Promise.all(
		Object.keys(modules)
			.filter((filePath) => filePath.includes('/src/custom/default'))
			.map(async (filePath) => {
				// @ts-ignore
				components[pathToName(filePath)] = await modules[filePath]();
			})
	);

	if (eventName) {
		await Promise.all(
			Object.keys(modules)
				.filter((filePath) => filePath.includes(`/src/custom/${eventName}/`))
				.map(async (filePath) => {
					// @ts-ignore
					components[pathToName(filePath)] = await modules[filePath]();
				})
		);
	}

	return components;
}

function getAllVueComponents() {
	// get a list of all components and views available in src/custom
	const components = import.meta.glob('@/custom/**/vue/components/**/*.vue');
	const views = import.meta.glob('@/custom/**/vue/views/**/*.vue');
	return { ...components, ...views };
}

function pathToName(path: string): string {
	return (
		path
			.split('/')
			.pop()
			?.replace(/\.\w+$/, '') ?? ''
	);
}
