import { computed, ref, watch } from 'vue';
import i18n, { getBrowserLocale } from '@/plugins/i18n';
import BaseModel from '@/amplify-pinia/base-model.class';
import useApp from '@/composables/useApp';
// @ts-ignore
import colors from 'vuetify/lib/util/colors';

import type { APApiCallParams, APBaseStoreStructure, APStorePluginReturnType } from '@/amplify-pinia/types';
import type { I18nMessage, Config as ConfigType } from './types';
import useCurrentEvent from '@/features/Events/store';

const model = 'Config';

const toHex = (themeColors: Record<string, string>) =>
	Object.keys(themeColors).reduce((acc, key) => {
		if (themeColors[key].startsWith('#')) return { ...acc, [key]: themeColors[key] };

		const [color, variant] = themeColors[key].replace('colors.', '').split('.');
		const value = colors[color][variant ?? 'base'];

		if (!color || !variant || !value) throw new Error(`Invalid color path ${acc[key]}`);

		return { ...acc, [key]: value };
	}, {} as Record<string, string>);

const recursivePropsCloning = <T>(a: Record<string, unknown>, b: Record<string, unknown>): T => {
	if (![a, b].some((x) => typeof x !== 'object') && ![a, b].some((x) => Array.isArray(x))) {
		return {
			...b,
			...Object.keys(a).reduce((acc, key) => {
				if (typeof a[key] === 'object' && !Array.isArray(a[key])) {
					return {
						...acc,
						[key]: recursivePropsCloning<T>(
							a[key] as Record<string, unknown>,
							(b[key] as Record<string, unknown>) ?? {}
						)
					};
				} else if (a[key] !== undefined) {
					return { ...acc, [key]: a[key] };
				}
				return { ...acc, [key]: b[key] };
			}, {})
		} as T;
	}
	throw new Error('Invalid data type');
};

export const storePlugin = (store: APBaseStoreStructure<ConfigType>): APStorePluginReturnType => {
	const currentConfig = computed<ConfigType | undefined>(() => {
		const env = import.meta.env.MODE;
		const { getCurrentEventNameFromURL: getCurrentEventName } = useCurrentEvent();

		const configEventName = getCurrentEventName ?? 'default';

		if (!store.keyedById.value) return undefined;

		const current = store.keyedById.value[`${configEventName}-${env}`];

		if (current && configEventName !== 'default') {
			return { ...current, id: `${configEventName}-${env}` };
		}

		return { ...current, id: `${configEventName}-${env}` };
	});
	const pending = ref();
	const subscriptions = ref();

	watch(currentConfig, (newValue) => {
		if (!newValue) return;

		i18n.global.mergeLocaleMessage('en', newValue.messages?.en as I18nMessage);
		i18n.global.mergeLocaleMessage('fr', newValue.messages?.fr as I18nMessage);
		i18n.global.locale.value = getBrowserLocale({ countryCodeOnly: true }) as string;
	});

	return { currentConfig, ...store, pending, subscriptions };
};

const options = {
	storePlugin
};

export default class Config extends BaseModel<ConfigType> {
	constructor() {
		super({
			model,
			options
		});
	}

	async find(params: APApiCallParams<ConfigType>): Promise<ConfigType[]> {
		const { pathToName } = useApp();
		const configs: Record<string, { default: ConfigType }> = {};
		const eventName = params.filter?.name?.eq;
		const modules = import.meta.glob('@/assets/config/**.json');
		await Promise.all(
			Object.keys(modules)
				.filter((str) =>
					[
						`/src/assets/config/${eventName}-${import.meta.env.MODE}.json`,
						`/src/assets/config/default-${import.meta.env.MODE}.json`
					].includes(str)
				)
				.map(async (filePath: string) => {
					// @ts-ignore
					configs[pathToName(filePath)] = await modules[filePath]();
				})
		);

		const importedConfigs = Object.keys(configs).map((path) => {
			let item = configs[path].default as ConfigType;
			const defaultItemPath = `default-${import.meta.env.MODE}`;

			if (path !== defaultItemPath) {
				const defaultItem = configs[defaultItemPath].default as ConfigType;
				item = recursivePropsCloning<ConfigType>(item, defaultItem) as ConfigType;
			}

			const id = path;
			const colors = toHex(item.theme.colors);

			// fallback background color
			if (colors.primary && !colors.background) {
				colors.background = colors.primary;
			}

			// fallback surface color
			if (colors.primary && !colors.surface) {
				colors.surface = colors.background;
			}

			// fallback tertiary color
			if (!colors.tertiary) {
				colors.tertiary = configs[defaultItemPath]?.default.theme.colors.tertiary ?? '#2b3d43';
			}

			return {
				...item,
				id,
				path,
				theme: {
					...item.theme,
					colors
				}
			} as ConfigType;
		});
		super._store.create(importedConfigs);
		return importedConfigs;
	}

	// ********************************
	// ********************************
	// Disable unused base-model api methods...
	// ********************************
	// ********************************
	async get(id: string): Promise<ConfigType> {
		throw new Error('AP Error: method get is not allowed on Config model');
	}

	async create(input: Partial<ConfigType>): Promise<ConfigType> {
		throw new Error('AP Error: method create is not allowed on Config model');
	}

	async update(input: Partial<ConfigType>): Promise<ConfigType> {
		throw new Error('AP Error: method update is not allowed on Config model');
	}

	async delete(input: Partial<ConfigType>): Promise<ConfigType> {
		throw new Error('AP Error: method delete is not allowed on Config model');
	}
}
