<script lang="ts" setup>
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';
	import useCurrentEvent from '@/features/Events/store';

	const { getCurrentEventDateStart, isCurrentEventIsLocked } = storeToRefs(useCurrentEvent());
	const { t } = useI18n();

	const eventDateStart: Date = new Date(getCurrentEventDateStart.value);
</script>

<template>
	<v-alert v-if="isCurrentEventIsLocked" color="primary" type="warning">
		<p
			class="mb-2"
			v-text="
				t('eventlock-message', {
					startDate: eventDateStart.toLocaleDateString(),
					startTime: eventDateStart.toLocaleTimeString()
				})
			"
		/>
	</v-alert>
</template>

<i18n>
{
	"en": {
		"eventlock-message": "This event is not yet accessible. Please come back on {startDate} at {startTime}."

	},
	"fr": {
		"eventlock-message": "Cet événement n'est pas encore accessible. Veuillez revenir le {startDate} à {startTime}."
	},
	"ja": {
		"eventlock-message": "このページは {startDate}  {startTime} 時にアクセス可能となります。"

	}
}
</i18n>
