<script lang="ts" setup>
	import { defineComponent, Ref, ref } from 'vue';
	import { storeToRefs } from 'pinia';
	import { useI18n } from 'vue-i18n';
	import { useTheme } from 'vuetify';
	import { omit } from 'lodash';
	import * as yup from 'yup';
	import { useForm } from 'vee-validate';
	import { useElementSize } from '@vueuse/core';
	import useCurrentUser from '@/features/CurrentUser/store';
	import ChatEmojiMenu from '@/custom/default/vue/components/chat/ChatEmojiMenu.vue';

	import { ChatTypes } from '@/graphql/types';
	import useStringFormatter from '@/composables/useStringFormatter';
	import useCurrentEvent from '@/features/Events/store';
	import { AWSGraphqlMutation } from '@/graphql';
	import { createChats } from '@/graphql/mutations';

	const { current } = useTheme();

	defineComponent({ name: 'ChatMessageInput' });

	const props = withDefaults(
		defineProps<{
			chatType: ChatTypes;
			recipient?: string | null;
			placeholder?: string | null;
			maxLength?: number;
			autoGrow?: boolean;
		}>(),
		{
			maxLength: 255,
			recipient: null,
			placeholder: null,
			autoGrow: true
		}
	);

	const { t } = useI18n();

	const { get: currentUser } = storeToRefs(useCurrentUser());
	const { getCurrentEventId } = storeToRefs(useCurrentEvent());

	const chatInput: Ref<HTMLElement | null> = ref(null);
	const { width } = useElementSize(chatInput);
	const { sanitize } = useStringFormatter();

	const schema = yup.object({
		content: yup.string().max(props.maxLength),
		sent_on: yup.string()
	});

	const { errors, meta, useFieldModel, handleSubmit, resetForm } = useForm({
		validationSchema: schema,
		initialValues: {
			content: '',
			type: props.chatType,
			event_id: '',
			profile_id_from: '', // currentUserId.value,
			profile_id_to: '', // props.recipient,
			deleted: false,
			read: false,
			sent_on: ''
		}
	});

	const content = useFieldModel('content');

	const onSubmit = handleSubmit((values) => {
		if (!meta.value.dirty) return;

		const stream_id = JSON.parse(localStorage.getItem('app-media-player') as string)[getCurrentEventId.value as string]
			.streamId;

		const payload = omit(
			{
				...values,
				stream_id,
				content: sanitize(values.content),
				event_id: getCurrentEventId.value,
				chatsUser_profileId: currentUser.value?.profile?.id,
				profile_id_from: currentUser.value?.profile?.id,
				profile_id_to: props.chatType === ChatTypes.PERSONAL ? props.recipient : undefined,
				sent_on: new Date().toISOString()
			},
			['user_profile']
		);

		AWSGraphqlMutation(createChats, payload).catch((err) => {
			console.error('create message error', err);
		});
		resetForm();
	});

	const inputEmoji = (emoji: string) => {
		content.value += emoji;
		chatInput.value?.focus();
	};

	const addLineBreak = () => {
		content.value += '\n';
	};
</script>

<template>
	<v-form class="chat-message-input d-flex w-100 max-h-100 align-center on-surface">
		<v-textarea
			ref="chatInput"
			v-model="content"
			:error-messages="errors.content"
			:placeholder="t('chatviewer_text_input')"
			:auto-grow="autoGrow"
			density="compact"
			rows="1"
			variant="outlined"
			class="on-surface"
			@keydown.enter.exact.prevent="onSubmit"
			@keydown.enter.shift.exact.prevent="addLineBreak"
		>
			<template #prepend-inner>
				<chat-emoji-menu :chat-width="width" @emoji-click="inputEmoji" />
			</template>

			<template #append-inner>
				<v-btn :disabled="!meta.dirty" icon="mdi-send" size="small" variant="plain" @click="onSubmit" />
			</template>
		</v-textarea>
	</v-form>
</template>

<style>
	.chat-message-input .v-field--appended {
		padding-inline-start: 0;
		padding-inline-end: 0;
	}

	.chat-message-input .v-field__append-inner {
		--v-input-padding-top: 0;
		margin-inline-start: 4px;
	}

	.chat-message-input .v-field__prepend-inner {
		--v-input-padding-top: 0;
		margin-inline-end: 4px;
	}
</style>

<i18n>
{
	"en": {
		"chatviewer_text_input": "Enter text"
	},
	"fr": {
		"chatviewer_text_input": "Saisir votre texte"
	},
	"ja": {
	"chatviewer_text_input": "テキストを入力"
}
}
</i18n>
