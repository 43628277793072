<script lang="ts" setup>
	import { ref, useAttrs } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';

	import SignInAdmin from '@/custom/default/vue/components/auth/Signin/SignInAdmin.vue';
	import useCurrentEvent from '@/features/Events/store';

	const { t } = useI18n();
	const attrs = useAttrs();

	const props = withDefaults(
		defineProps<{ appendLogo?: string | undefined; prependLogo?: string | undefined; adminAccess?: boolean }>(),
		{
			adminAccess: false,
			appendLogo: undefined,
			prependLogo: undefined
		}
	);

	const showAdminForm = ref(false);
	const { isCurrentEventIsLocked } = storeToRefs(useCurrentEvent());
</script>

<template>
	<v-btn v-if="!showAdminForm" v-bind="attrs" :disabled="isCurrentEventIsLocked">
		<div class="d-flex align-center gap">
			<slot name="prepend-logo">
				<v-img v-if="prependLogo" :src="prependLogo" width="30" />
			</slot>
			<slot name="title">
				{{ t('title') }}
			</slot>
			<slot name="append-logo">
				<v-img v-if="appendLogo" :src="appendLogo" width="30" />
			</slot>
		</div>
	</v-btn>
	<sign-in-admin v-else @show-admin="showAdminForm = !showAdminForm" />
	<v-btn
		v-if="adminAccess"
		class="ma-5 text-white position-fixed btn-admin--custom access"
		color="primary"
		tabindex="6"
		@click="showAdminForm = !showAdminForm"
	>
		{{ showAdminForm ? 'Back / Retour' : 'Admin' }}
	</v-btn>
</template>

<style scoped>
	.gap {
		gap: 1rem;
	}
	.btn-admin--custom {
		bottom: 0;
		right: 0;
	}
</style>

<i18n>
{
	"en": {
		"title": "Sign in with"
	},
	"fr": {
		"title": "Connectez-vous avec"
	}
}
</i18n>
