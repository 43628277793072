<script setup lang="ts">
	import { computed, defineComponent, useAttrs, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { ComponentsName } from '@/plugins/lib/constants';
	import useConfig from '@/features/Config';

	import type { Locale } from '@intlify/core-base';

	defineComponent({ name: ComponentsName.HelpButton });

	const props = withDefaults(
		defineProps<{
			classCard?: string | undefined;
			btnMainPage?: boolean;
			languageSwitcher?: boolean;
		}>(),
		{
			classCard: undefined
		}
	);

	const { t, locale, availableLocales } = useI18n();

	const {
		store: { currentConfig }
	} = useConfig();
	const attrs = useAttrs();
	const panel = ref(['firstPanel']);
	const dialog = ref(false);
	const displayedColor = ref<string>();

	const nextLocale = computed(() => availableLocales.find((l) => l !== locale.value) as Locale);
	const toggleLocale = () => {
		locale.value = nextLocale.value;
	};
</script>

<template>
	<v-btn class="access" v-bind="attrs" @click="dialog = true">
		<slot name="default" v-bind="{ panel, dialog, displayedColor }">
			<span v-text="btnMainPage ? t('helpButton_NeedHelp') : t('helpButton_NeedHelpAuth')" />
		</slot>
	</v-btn>

	<v-dialog v-model="dialog" hide-overlay max-width="800px">
		<v-card class="help-button-card" :class="classCard ? classCard : ''" rounded="0">
			<v-card-title class="d-flex justify-space-between bg-primary elevation-4">
				<h1 v-text="t('helpButton_NeedHelp')" />
				<v-btn
					v-if="languageSwitcher"
					class="access"
					:aria-label="t('helpButton_language-switcher_aria-label')"
					:aria-valuetext="t('helpButton_language-switcher_valuetext')"
					variant="text"
					name="locale-switcher"
					@click="toggleLocale"
					>{{ nextLocale }}</v-btn
				>
			</v-card-title>

			<v-card-text class="help-button-card-text">
				<div class="d-flex justify-center mb-4">
					<span class="text-h4 font-weight-bold" v-text="t('helpButton_title')" />
				</div>

				<v-expansion-panels class="mb-5">
					<v-expansion-panel key="helpButton_part1" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`1) ${t('helpButton_part1_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text class="text-break">
							<p v-text="`A) ${t('helpButton_part1_a')}`" />
							<ul class="px-6">
								<li v-text="`${t('helpButton_part1_a_1')}`" />
							</ul>
							<p v-text="`B) ${t('helpButton_part1_a_2')}`" />

							<p v-text="`C) ${t('helpButton_part1_b')}`" />
							<p v-text="`D) ${t('helpButton_part1_c')}`" />
							<p v-text="`E) ${t('helpButton_part1_d')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part2" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`2) ${t('helpButton_part2_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part2_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part3" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`3) ${t('helpButton_part3_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part3_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part4" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`4) ${t('helpButton_part4_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part4_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part5" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`5) ${t('helpButton_part5_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part5_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part6" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`6) ${t('helpButton_part6_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part6_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>

					<v-expansion-panel key="helpButton_part7" elevation="0">
						<v-expansion-panel-title>
							<span v-text="`7) ${t('helpButton_part7_title')}`" />
						</v-expansion-panel-title>

						<v-expansion-panel-text>
							<p v-text="`A) ${t('helpButton_part7_a')}`" />
						</v-expansion-panel-text>
					</v-expansion-panel>
				</v-expansion-panels>

				<div class="text-center mt-1">
					<span v-text="t('helpButton_subtitle')" />
				</div>

				<div class="text-center mt-1">
					<span v-text="t('helpButton_text')" />
				</div>

				<div v-show="!!currentConfig.NeedHelp.url_video_conference" class="mt-1">
					<v-icon icon="mdi-video" />

					<v-btn
						:style="{ backgroundColor: displayedColor + ' !important' }"
						:href="currentConfig.NeedHelp.url_video_conference"
						target="_blank"
					>
						{{ t('helpButton_videoconference') }}
					</v-btn>
				</div>

				<v-list class="mt-2 bg-transparent">
					<v-list-item
						v-if="!!currentConfig.NeedHelp.email"
						:title="currentConfig.NeedHelp.email"
						:href="`mailto:${currentConfig.NeedHelp.email}`"
						prepend-icon="mdi-email"
						density="compact"
					/>

					<v-list-item
						v-if="!!currentConfig.NeedHelp.phone_number1"
						:title="`${currentConfig.NeedHelp.phone_number1} ${t('helpButton_toll_free')}`"
						prepend-icon="mdi-phone"
						density="compact"
					/>

					<v-list-item
						v-if="!!currentConfig.NeedHelp.phone_number2"
						:title="`${currentConfig.NeedHelp.phone_number2} Montreal`"
						prepend-icon="mdi-phone"
						density="compact"
					/>

					<v-list-item
						v-if="!!currentConfig.NeedHelp.phone_number3"
						:title="currentConfig.NeedHelp.phone_number3"
						prepend-icon="mdi-phone"
						density="compact"
					/>
				</v-list>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn class="nav_close bg-primary access" variant="outlined" @click="dialog = false">
					{{ t('helpButton_close') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
	.help-button-card {
		background-color: rgba(var(--v-theme-primary), 0.7);
		backdrop-filter: blur(8px);
	}
	.help-button-card-text {
		max-height: 80vh;
		overflow-y: auto;
	}
	.btn-help-custom {
		top: 0;
		right: 0;
	}
</style>

<i18n>
{
	"en": {
		"helpButton_language-switcher_aria-label": "Language toggler",
		"helpButton_language-switcher_aria-valuetext": "English",
		"helpButton_NeedHelp": "Help",
		"helpButton_NeedHelpAuth": "Need Help?",
		"helpButton_videoconference": "Video conference",
		"helpButton_close": "Close",
		"helpButton_title": "FREQUENTLY ASKED QUESTIONS",
		"helpButton_subtitle": "FOR ASSISTANCE, FEEL FREE TO CONTACT US BY MAIL AT ANY POINT DURING THE EVENT.",
		"helpButton_text": "If the event has started, a support technician is monitoring at all times and will be able to help you in order for you to enjoy the event.",
		"helpButton_part1_title": "I CAN’T ACCESS THE SITE",
		"helpButton_part1_a": "Please ensure that the URL is correct it should be:",
		"helpButton_part1_a_1": "https://www.solotech.events/#/event/(your event)",
		"helpButton_part1_a_1_custom_name": "custom name",
		"helpButton_part1_a_2" : "Please ensure that the password is correct",
		"helpButton_part1_a_2_custom_name": "custom per event",
		"helpButton_part1_b": "Occasionally reloading the page might be required.",
		"helpButton_part1_c": "Occasionally clearing the cookie might be required.",
		"helpButton_part1_d": "VPN or firewall might block the entire site, video and audio.",
		"helpButton_part2_title": "I CAN’T HEAR OR SEE ANYBODY!",
		"helpButton_part2_a": "Please ensure that you are using the latest version of Google Chrome, Firefox or Microsoft Edge or that a firewall isn't preventing it.",
		"helpButton_part3_title": "I CAN'T ACTIVATE MY CAMERA!",
		"helpButton_part3_a": "Please ensure that your camera is not already active in another application or tab.",
		"helpButton_part4_title": "HOW DO I MUTE MY MICROPHONE?",
		"helpButton_part4_a": "While in a table please click the “Mute Microphone” button which you can find on the bottom centre of the table view.",
		"helpButton_part5_title": "THERE’S AN ECHO!",
		"helpButton_part5_a": " Please use headphones or earphones for the best experience. Your microphone may pick up sound coming out of your speakers. Also, please mute your microphone when you are not speaking to prevent echo or feedback.",
		"helpButton_part6_title": "EVERYONE IS CHOPPY, WHAT DO I DO?",
		"helpButton_part6_a": "While in a table, click the 3 dots ( ⋮ ) and then click on Manage Video Quality. Drag the slider more towards the left and it should help; otherwise you may need a stronger or more stable internet connection.",
		"helpButton_part7_title": "WHAT’S THE DIFFERENCE BETWEEN THE 2 TEXT CHATS?",
		"helpButton_part7_a": " The chat you see while in a table is only f or members of your table. The Global Chat is available for everyone across all tables, including the broadcasters themselves!",
		"helpButton_end": "The present FAQ does not solve your issue, feel free to send an email or call us for assistance.",
		"helpButton_toll_free": "Toll free"
	},
	"fr": {
		"helpButton_language-switcher_aria-label": "Sélecteur de langue",
		"helpButton_language-switcher_aria-valuetext": "Français",
		"helpButton_NeedHelp": "Besoin d'aide?",
		"helpButton_NeedHelpAuth": "Besoin d'aide?",
		"helpButton_videoconference": "Vidéo conférence",
		"helpButton_close": "Fermer",
		"helpButton_title": "Foire Aux Questions",
		"helpButton_subtitle": "N'hésitez pas à nous contacter par courriel à tout moment durant l’événement.",
		"helpButton_text": "Si l'événement a débuté, le technicien de service pourra vous assister à tout moment afin que vous puissiez profiter de l'événement.",
		"helpButton_part1_title": "JE N'AI PAS ACCÈS AU SITE",
		"helpButton_part1_a": " Assurez-vous d’avoir la bonne URL, il devrait être :",
		"helpButton_part1_a_1": "https://www.solotech.events/#/event/[nom personnalisé]",
		"helpButton_part1_a_1_custom_name": "nom personnalisé",
		"helpButton_part1_a_2" : "Assurez-vous d’avoir le bon mot de passe",
		"helpButton_part1_a_2_custom_name": "personnalisé par événement.",
		"helpButton_part1_b": "Parfois, il peut être nécessaire de rafraîchir la page.",
		"helpButton_part1_c": "Parfois, la suppression des témoins (cookies) peut être nécessaire.",
		"helpButton_part1_d": "Un RPV (VPN) ou un pare-feu peuvent bloquer l'intégralité du site, de la vidéo ou de l'audio.",
		"helpButton_part2_title": "JE NE PEUX PAS ENTENDRE NI VOIR PERSONNE !",
		"helpButton_part2_a": "Assurez-vous d'utiliser la dernière version de Google Chrome, Firefox, Microsoft Edge et que votre pare-feu est désactivé.",
		"helpButton_part3_title": "JE NE PEUX PAS ACTIVER MA CAMÉRA !",
		"helpButton_part3_a": "Assurez-vous que votre caméra n'est pas déjà active dans une autre application ou un autre onglet.",
		"helpButton_part4_title": "COMMENT DÉSACTIVER MON MICROPHONE ?",
		"helpButton_part4_a": "Lorsque vous êtes dans une table, cliquez sur le bouton Mettre le microphone en sourdine que vous pouvez trouver en bas au centre de l’écran de la table.",
		"helpButton_part5_title": "IL Y A UN ÉCHO !",
		"helpButton_part5_a": "Pour une meilleure expérience, veuillez utiliser des écouteurs. Veuillez également le désactiver lorsque vous ne parlez pas pour éviter l'écho ou le retour de son.",
		"helpButton_part6_title": "LA QUALITÉ VIDÉO SE DÉGRADE, QUE DOIS-JE FAIRE ?",
		"helpButton_part6_a": "Dans le menu de la table, cliquez sur les 3 points ( ⋮ ) puis cliquez sur Gérer la qualité vidéo. Faites glisser le curseur vers la gauche et cela devrait vous aider, autrement vous aurez peut-être besoin d'une connexion Internet plus forte ou plus stable",
		"helpButton_part7_title": "QUELLE EST LA DIFFÉRENCE ENTRE LES 2 CLAVARDAGES?",
		"helpButton_part7_a": "Le clavardage que vous voyez lorsque vous êtes dans une table est réservé aux membres de votre table. Le clavardage principal est accessible à tous sur toutes les tables, y compris les présentateurs.",
		"helpButton_end": "Si la présente FAQ ne résout pas votre problème, n'hésitez pas à envoyer un courriel ou nous appeler pour obtenir de l'aide.",
		"helpButton_toll_free": "Sans frais"
	},
	"ja":{
		"helpButton_NeedHelp": "ヘルプ",
		"helpButton_videoconference": "ビデオ会議",
		"helpButton_close": "閉じる",
		"helpButton_title": "FAQ",
		"helpButton_subtitle": "サポートが必要な場合はいつでも是非メールで連絡してください。",
		"helpButton_text": "イベントが開始されると、サポート技術者が常時モニタリングしており、イベントを楽しんでいただけるようにお手伝いします。",
		"helpButton_part1_title": "サイトにアクセスできません",
		"helpButton_part1_a": "URL が正しいことを確認してください。",
		"helpButton_part1_a_1": "https://www.solotech.events/#/event/[custom name]",
		"helpButton_part1_a_1_custom_name": "custom name",
		"helpButton_part1_a_2": "パスワードが正しいことを確認してください",
		"helpButton_part1_a_2_custom_name": "イベントごとのカスタム",
		"helpButton_part1_b": "ページの再読み込みが必要になる場合があります。",
		"helpButton_part1_c": "場合によってはCookieのクリアが必要になることがあります。",
		"helpButton_part1_d": "VPNまたはファイアウォールが、サイト全体、配信、オーディオをブロックする可能性があります。",
		"helpButton_part2_title": "誰も聞こえない、見えない！",
		"helpButton_part2_a": "Google Chrome、Firefox、または Microsoft Edge の最新バージョンを使用していること、またはファイアウォールがそれを妨げていないことを確認してください。",
		"helpButton_part3_title": "カメラを起動できません!",
		"helpButton_part3_a": "カメラが別のアプリケーションまたはタブでアクティブになっていないことを確認してください。",
		"helpButton_part4_title": "マイクのミュート方法",
		"helpButton_part4_a": "テーブルの中央下にある 「マイクをミュート」 ボタンをクリックしてください。",
		"helpButton_part5_title": "エコーがあります！",
		"helpButton_part5_a": "イヤホンを使うと最高の体験ができます。そして、話さない時にはマイクをミュートにしてください。",
		"helpButton_part6_title": "ラグが発生している。どうすればいいですか?",
		"helpButton_part6_a": "テーブルの中の3ドット( ⋮ )から「通話品質を管理する」をクリックして、 スライダを左にドラッグしてください。改善しない場合は、より強力で安定したインターネット接続が必要になります。",
		"helpButton_part7_title": "2 つのテキスト チャットの違いは何ですか?",
		"helpButton_part7_a": "テーブル内に表示されるチャットは、テーブルのメンバーのみを対象としています。視聴ページ右横のチャットは、メディアの皆さんと主催者が自由にコミュニケーションするためのものです。",
		"helpButton_end": "この FAQ では問題が解決しません場合是非メールでご連絡してください．",
		"helpButton_toll_free":""
	}
}
</i18n>
