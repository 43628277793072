<template>
	<v-app :class="{ leaving }">
		<message-toast />
		<router-view />
	</v-app>
</template>

<script setup lang="ts">
	import { defineComponent, getCurrentInstance, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRoute } from 'vue-router';
	import { useTheme } from 'vuetify';
	import useAPUtils from '@/amplify-pinia/useAPUtils';
	import useToast from '@/features/Toast';
	import MessageToast from '@/custom/default/vue/components/common/MessageToast.vue';
	import { provideClient } from '@urql/vue';
	import { urqlClient } from '@/plugins/urql';
	import useCurrentEvent from '@/features/Events/store';

	defineComponent({ name: 'App' });

	provideClient(urqlClient);

	const app = getCurrentInstance();
	const { triggerToast } = useToast();
	const route = useRoute();
	const { locale } = useI18n();
	const { global, current, themes } = useTheme();
	const { subscribe: eventSubscribe, unsubscribe: eventUnsubscribe } = useCurrentEvent();
	const leaving = ref(false);

	watch(
		() => route.params.eventName,
		async (newValue, oldValue) => {
			if (newValue !== oldValue) {
				eventUnsubscribe();
				eventSubscribe();
			}

			const env = import.meta.env.MODE;
			const themeName = `${newValue ?? 'default'}-${env}`;
			const defaultThemeName = `default-${env}`;
			global.name.value = themes.value[themeName] ? themeName : defaultThemeName;

			if (!!newValue && newValue !== app?.appContext.config.globalProperties.eventName) {
				// @ts-ignore
				global.current.value.dark = false;
				leaving.value = true;
				if (newValue === oldValue) return;
				location.reload();
			}
		}
	);

	watch(
		locale,
		(newValue) => {
			document.querySelector('html')?.setAttribute('lang', newValue);
		},
		{
			immediate: true
		}
	);

	onBeforeMount(() => {
		const navLang = navigator.language.toLowerCase().substring(0, 2);
		locale.value = ['en', 'fr', 'ja'].includes(navLang) ? navLang : 'en';
		eventSubscribe();
	});

	onBeforeUnmount(async () => {
		await useAPUtils().clearAPStores();
	});
</script>

<style lang="scss">
	.leaving {
		animation: fadeOut 300ms;
	}
	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.max-h-100 {
		max-height: 100%;
	}
	.max-w-100 {
		max-width: 100%;
	}
	.min-h-100 {
		min-height: 100%;
	}
	.min-w-100 {
		min-width: 100%;
	}
	.clickable {
		cursor: pointer;
	}
	.opacity-100 {
		opacity: 1 !important;
	}

	.mainview-loading {
		font-size: 1.5em;
		align-self: center;
	}

	.app-background {
		height: 100%;
		width: 100%;
		background-size: cover;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.authentication-background {
		position: relative;
		height: 100vh;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover !important;
	}
	.authentication-signin {
		display: flex;
		align-items: center;
	}
	.signin-background {
		width: inherit;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease;
	}
	.fade-leave-active {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* TODO: Get rid of vuetify' classes modifications made here */
	.v-card {
		width: 100%;
		height: 100%;
	}

	.v-card-title {
		text-transform: uppercase !important;
		font-weight: bolder !important;
	}
	.v-card-subtitle {
		font-style: italic;
		padding-top: 0.6em !important;
	}
	.bg-custom-blur {
		background-color: rgba(var(--v-theme-surface), 0.5) !important;
		color: var(--v-theme-on-surface) !important;
		backdrop-filter: blur(8px);
	}
	.v-tooltip .v-overlay__content {
		background-color: rgba(var(--v-theme-surface), 1) !important;
		color: rgba(var(--v-theme-on-surface), 1) !important;
	}
	div.access.is-focused {
		border-radius: 4px;
	}
	.access .v-field--focused,
	.access .v-selection-control--focus-visible,
	.access:focus-visible {
		border-radius: 4px;
		border: 1px solid white;
		box-shadow: 0 0 0 3px rgba(255, 0, 0, 1);
	}
	div.access-alt.is-focused,
	.access-alt:focus-visible {
		border-radius: 4px;
		border: 2px solid white;
		box-shadow: 0 0 0 5px rgba(100, 0, 255, 1);
	}
</style>
