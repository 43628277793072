import type { App, Plugin } from 'vue';
import { Breadcrumb, init, vueRouterInstrumentation } from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '@/plugins/router';
import { currentEnv } from '@/composables/useApp';

export const sentry = (app: App): Plugin => {
	init({
		app,
		// @HINT: To show the sentry log in the console, set true
		logErrors: false,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		denyUrls: [/127\.0\.0\.1:*/i, /localhost:*/i],
		integrations: [
			new BrowserTracing({
				routingInstrumentation: vueRouterInstrumentation(router),
				tracePropagationTargets: ['https://dev.solotech.events', 'https://solotech.events', /^\//]
			})
		],
		environment: import.meta.env.MODE,
		// trackComponents: true,
		// hooks: ['mount', 'update', 'destroy'],
		tracesSampleRate: 0.5,
		maxBreadcrumbs: 20,
		beforeBreadcrumb: (breadcrumb, hint): Breadcrumb | null => {
			const isGraphqlQlRequest = hint?.input?.match(/"query\s|"mutation\s|"subscription\s/);
			if (isGraphqlQlRequest) {
				try {
					const request = JSON.parse(hint?.input);
					breadcrumb.data = {
						...(breadcrumb.data || {}),
						request: request.query.split('(')[0].trim()
					};
				} catch (err) {
					if (!currentEnv().isProd) console.warn('fail to define graphql context for sentry breadcrumb', err);
				}
			}
			return breadcrumb;
		}
	});
	return {
		install() {}
	};
};
