import { escape } from 'lodash';
import linkifyHtml from 'linkify-html';

const sanitize = (text: string) => escape(text);
const vLinkify = {
	beforeMount: (el: any, binding: any) => {
		const addBreakLine = el.innerHTML.replaceAll('\n', '<br/>');
		el.innerHTML = linkifyHtml(addBreakLine, {
			...binding.value,
			target: '_blank'
		});
	}
};
const useStringFormatter = () => ({ vLinkify, sanitize });

export default useStringFormatter;
