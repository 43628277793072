<script lang="ts" setup>
	import { computed, defineComponent, ref, UnwrapRef } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useDisplay } from 'vuetify';
	import LayoutSolotechCarriere from '@/custom/solotech-carriere-virtuelle/vue/components/layout/LayoutSolotechCarriere.vue';
	import { ComponentsName } from '@/plugins/lib/constants';
	import CardJobOpening from '@/custom/solotech-carriere-virtuelle/vue/components/jobOpening/CardJobOpening.vue';
	import { itemJobOpening } from '@/custom/solotech-carriere-virtuelle/vue/components/jobOpening/types';
	import CardPage from '@/custom/solotech-carriere-virtuelle/vue/components/common/CardPage.vue';

	defineComponent({
		name: ComponentsName.JobOpenings
	});

	const { t } = useI18n();
	const { mdAndDown } = useDisplay();

	const cardSecondRef = ref<{ componentRef: UnwrapRef<HTMLElement | null> } | null>(null);

	const items: Record<string, itemJobOpening[]> = {
		'jobOpenings.live_production': [
			{
				img: 'shop.png',
				content: 'jobOpenings.live_production.content_1',
				number: '1'
			},
			{
				img: 'production.png',
				content: 'jobOpenings.live_production.content_2',
				number: '2'
			},
			{
				img: 'transport.png',
				content: 'jobOpenings.live_production.content_3',
				number: '3'
			}
		],
		'jobOpenings.sales_systems_integration': [
			{
				img: 'permanent-installations.png',
				content: 'jobOpenings.sales_systems_integration.content_1',
				number: '4'
			},
			{
				img: 'services-remotes.png',
				content: 'jobOpenings.sales_systems_integration.content_2',
				number: '5'
			},
			{
				img: 'e-commerce.png',
				content: 'jobOpenings.sales_systems_integration.content_3',
				number: '6'
			},
			{
				img: 'sales.png',
				content: 'jobOpenings.sales_systems_integration.content_4',
				number: '7'
			},
			{
				img: 'project-management.png',
				content: 'jobOpenings.sales_systems_integration.content_5',
				number: '8'
			}
		],
		'jobOpenings.corporate_function': [
			{
				img: 'corporate-functions.png',
				content: [
					'jobOpenings.corporate_function.content_1',
					'jobOpenings.corporate_function.content_2',
					'jobOpenings.corporate_function.content_3',
					'jobOpenings.corporate_function.content_4'
				],
				number: '9'
			}
		]
	};

	const minHeight = computed(() => {
		const height = cardSecondRef.value?.componentRef?.clientHeight;
		if (height) {
			return `${height}px`;
		}
		return 'auto';
	});
</script>
<template>
	<layout-solotech-carriere>
		<card-page :title="t('jobOpenings.title')">
			<v-row :no-gutters="mdAndDown">
				<v-col cols="12" lg="6" class="mb-6">
					<card-job-opening
						:min-height="minHeight"
						title="jobOpenings.live_production"
						:items="items['jobOpenings.live_production']"
					/>
				</v-col>
				<v-col cols="12" lg="6" class="mb-6">
					<card-job-opening
						ref="cardSecondRef"
						title="jobOpenings.sales_systems_integration"
						:items="items['jobOpenings.sales_systems_integration']"
					/>
				</v-col>
			</v-row>
			<v-row no-gutters class="justify-center">
				<v-col cols="12" lg="6" class="mb-6">
					<card-job-opening
						:min-height="minHeight"
						title="jobOpenings.corporate_function"
						:items="items['jobOpenings.corporate_function']"
					/>
				</v-col>
			</v-row>
		</card-page>
	</layout-solotech-carriere>
</template>

<i18n>
{
	"en": {
		"jobOpenings": {
			"title": "Job opportunities - Now hiring!",
			"live_production_title": "Live Production",
			"sales_systems_integration_title": "Sales and Systems Integration",
			"corporate_function_title": "Corporate Functions",
			"live_production": {
				"content_1": "Shop : clerk, technician (LX, Rig., Video, Audio)",
				"content_2": "Production Team : senior technician, chief (LX, Rig., Video Audio)",
				"content_3": "Transport : coordinator/ sr. coordinator customs & transport Coordination: technical coordinator"
			},
			"sales_systems_integration": {
				"content_1": "Installation technicians, integrator, system designer,project manager, project coordinator, programmer, warehouse clerk",
				"content_2": "Remote technician, service technician, bench and repair technician",
				"content_3": "Sales representative, coordinator, technical coordinator",
				"content_4": "Business developer, sales representative",
				"content_5": "Sales and support coordinator, project manager, technical project manager"
			},
			"corporate_function": {
				"content_1": "IT support technician, BI analyst, cybersecurity intern, software development intern, IT intern, BI intern",
				"content_2": "Financial analyst, tax manager, FP&A manager, accounts receivable intern, accounts payable intern, corporate accounting intern",
				"content_3": "Marketing coordinator, communication intern",
				"content_4": "Buyer/ planner fabrication, procurement intern"
			}
		}
	},
	"fr": {
		"jobOpenings": {
			"title": "Offres d'emploi - Nous embauchons maintenant!",
			"live_production_title": "Solutions événementielles",
			"sales_systems_integration_title": "Ventes et Integration de systemes",
			"corporate_function_title": "Fonctions corporatives",
			"live_production": {
				"content_1": "Atelier : Commis, technicien (éclairage, gréage, vidéo, audio)",
				"content_2": "Équipe de production : technicien principal, chef (LX, Rig., Vidéo, Audio)",
				"content_3": "Transport : Coordonnateur / Coordonnateur senior transports & douanes Coordination : Coordonnateur technique"
			},
			"sales_systems_integration": {
				"content_1": "Technicien d’installation, intégrateur, concepteur de systèmes,chargé de projets, coordonnateur de projets, programmeur, commis d'entrepôt",
				"content_2": "Technicien audiovisuel (service), technicien audiovisuel (réparation), technicien de résolution de problèmes à distance",
				"content_3": "Représentant des ventes, coordonnateur, coordonnateur technique",
				"content_4": "Développeur d’affaires, représentant des ventes",
				"content_5": "Coordonnateur de support aux ventes, chargé de projets, chargé de projets techniques"
			},
			"corporate_function": {
				"content_1": "Technicien de support TI, analyste intelligence d’affaires, stagiaire en sécurité informatique, stagiaire développement de logiciel, stagiaire TI, stagiaire en intelligence d’affaires",
				"content_2": "Analyste financier, gestionnaire fiscalité, gestionnaire planification et analyse financière, stagiaires aux comptes recevables/payables, stagiaire comptabilité corporative",
				"content_3": "Coordonnateur Marketing, stagiaire en communication",
				"content_4": "Acheteur, stagiaire en approvisionnement"
			}
		}
	}
}
</i18n>
