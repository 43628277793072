<template>
	<v-card class="text-white">
		<v-card-title class="bg-secondary" v-text="t('control_room_dashboard')" />
		<v-row class="px-6">
			<v-col cols="12" class="d-lg-flex py-6">
				<v-col cols="12" lg="4" class="pl-0">
					<v-sheet>
						<v-label class="pb-6 opacity-100">{{ t('user_audit_date') }}</v-label>
						<datepicker v-model="dateRange" name="date_range" color="white" :clearable="false" no-today range />
					</v-sheet>
				</v-col>
			</v-col>
			<v-col col="12">
				<v-tabs v-model="tab">
					<v-tab :value="TabValues.ACTIVE_USERS">
						<span class="text-h6 text-uppercase">{{ t('user_audit_tab_active_users_title') }}</span>
					</v-tab>
					<v-tab :value="TabValues.GEOLOCATION">
						<span class="text-h6 text-uppercase">{{ t('user_audit_tab_geolocation_title') }}</span>
					</v-tab>
					<v-tab :value="TabValues.SESSION_LOG">
						<span class="text-h6 text-uppercase">{{ t('user_audit_tab_session_log_title') }}</span>
					</v-tab>
				</v-tabs>
				<v-window v-model="tab">
					<v-window-item :value="TabValues.ACTIVE_USERS">
						<active-users-chart :date-start="dateStart" :date-end="dateEnd" />
					</v-window-item>
					<v-window-item :value="TabValues.GEOLOCATION">
						<geolocation-map :date-start="dateStart" :date-end="dateEnd" />
					</v-window-item>
					<v-window-item :value="TabValues.SESSION_LOG">
						<session-log :date-start="dateStart" :date-end="dateEnd" />
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>
	</v-card>
</template>

<script setup lang="ts">
	import { ref, Ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { storeToRefs } from 'pinia';
	import Datepicker from '@vuepic/vue-datepicker';
	import SessionLog from '@/custom/default/vue/components/admin/dashboard/SessionLog.vue';
	import ActiveUsersChart from '@/custom/default/vue/components/admin/dashboard/ActiveUsersChart.vue';
	import GeolocationMap from '@/custom/default/vue/components/admin/dashboard/GeolocationMap.vue';
	import useCurrentEvent from '@/features/Events/store';

	enum TabValues {
		'ACTIVE_USERS',
		'GEOLOCATION',
		'SESSION_LOG'
	}

	const { t, locale } = useI18n();
	const { getCurrentEventDateStart, getCurrentEventDateEnd } = storeToRefs(useCurrentEvent());

	const tab: Ref<TabValues> = ref(TabValues.ACTIVE_USERS);

	const dateRange: Ref<Date[]> = ref([
		new Date(getCurrentEventDateStart.value),
		new Date(getCurrentEventDateEnd.value)
	]);
	const dateStart: Ref<Date> = ref(dateRange.value[0]);
	const dateEnd: Ref<Date> = ref(dateRange.value[1]);

	watch(
		dateRange,
		([rangeStart, rangeEnd]) => {
			dateStart.value = rangeStart;
			dateEnd.value = rangeEnd;
		},
		{
			immediate: true
		}
	);
</script>

<i18n>
{
  "en": {
		"user_audit_tab_active_users_title": "User activity graph",
		"user_audit_tab_geolocation_title": "Geolocation",
		"user_audit_tab_session_log_title": "User log",
		"user_audit_date": "Select a range of dates and times"
  },
  "fr": {
		"user_audit_tab_active_users_title": "Active utilisateur",
		"user_audit_tab_geolocation_title": "Géolocalisation",
		"user_audit_tab_session_log_title": "Registre utilisateur",
		"user_audit_start_time": "Sélectionner une plage de dates et d'heures"
  }
}
</i18n>
