<script setup lang="ts">
	import { useI18n } from 'vue-i18n';
	import logoTable from '@/custom/solotech-carriere-virtuelle/assets/logoTable.png';
	withDefaults(defineProps<{ title?: string | null; containerFullHeight?: boolean }>(), {
		title: null,
		containerFullHeight: false
	});

	const { t } = useI18n();
</script>
<template>
	<v-container :class="{ 'h-100': containerFullHeight }" fluid>
		<v-card :class="{ 'h-100': containerFullHeight }" class="bg-custom-blur pb-5">
			<v-card-title v-if="title" class="title d-flex align-center pl-0 mb-2 bg-secondary">
				<v-img class="title-img mr-6" height="34" width="32" :src="logoTable" />
				<h2 class="text-subtitle-1">{{ t(title) }}</h2>
			</v-card-title>
			<div class="px-2" :class="{ 'h-100': containerFullHeight }">
				<slot></slot>
			</div>
		</v-card>
	</v-container>
</template>

<style scoped>
	.title {
		font-weight: 700;
		overflow: visible;
		white-space: pre-wrap;
	}
	.title-img {
		max-width: 32px;
	}
</style>
