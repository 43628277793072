import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';

const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	inheritLocale: true,
	locale: 'en',
	fallbackRoot: true,
	fallbackLocale: 'en',
	fallbackWarn: false,
	messages
});

export function getBrowserLocale(options = {}) {
	const defaultOptions = { countryCodeOnly: false };
	const opt = { ...defaultOptions, ...options };

	const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

	if (!navigatorLocale) {
		return null;
	}

	return opt.countryCodeOnly ? navigatorLocale.trim().split(/[-_]/)[0] : navigatorLocale.trim();
}

export default i18n;
